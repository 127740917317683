import { PUBLIC_CHAMBER_PAGE_ERROR, PUBLIC_CHAMBER_PAGE_LOADING, PUBLIC_CHAMBER_PAGE_SUCCESS, PUBLIC_COMPANY_PAGE_ERROR, PUBLIC_COMPANY_PAGE_LOADING, PUBLIC_COMPANY_PAGE_SUCCESS, PUBLIC_LANDING_PAGE_ERROR, PUBLIC_LANDING_PAGE_LOADING, PUBLIC_LANDING_PAGE_SUCCESS, PUBLIC_PROFILE_PAGE_ERROR, PUBLIC_PROFILE_PAGE_LOADING, PUBLIC_PROFILE_PAGE_SUCCESS, RESET_PUBLIC_CHAMBER_PAGE, RESET_PUBLIC_COMPANY_PAGE, RESET_PUBLIC_LANDING_PAGE, RESET_PUBLIC_PROFILE_PAGE } from "../../Constant";

let initialState = {
  getLandingPage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  getCompanyPage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  getChamberPage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  getProfilePage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },

};

export default function PublicPageReducer(state = initialState, action) {
  switch (action.type) {

    // getLandingPage
    case PUBLIC_LANDING_PAGE_LOADING:
      return { ...state, getLandingPage: { ...state.getLandingPage, loading: true, data: null, error: false, errors: null, success: false } };

    case PUBLIC_LANDING_PAGE_SUCCESS:
      return { ...state, getLandingPage: { ...state.getLandingPage, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case PUBLIC_LANDING_PAGE_ERROR:
      return { ...state, getLandingPage: { ...state.getLandingPage, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_PUBLIC_LANDING_PAGE:
      return { ...state, getLandingPage: { ...state.getLandingPage, loading: false, error: false, success: false } };


    // getcompny page
    case PUBLIC_COMPANY_PAGE_LOADING:
      return { ...state, getCompanyPage: { ...state.getCompanyPage, loading: true, data: null, error: false, errors: null, success: false } };

    case PUBLIC_COMPANY_PAGE_SUCCESS:
      return { ...state, getCompanyPage: { ...state.getCompanyPage, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case PUBLIC_COMPANY_PAGE_ERROR:
      return { ...state, getCompanyPage: { ...state.getCompanyPage, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_PUBLIC_COMPANY_PAGE:
      return { ...state, getCompanyPage: { ...state.getCompanyPage, loading: false, error: false, success: false } };


    // getchamber page
    case PUBLIC_CHAMBER_PAGE_LOADING:
      return { ...state, getChamberPage: { ...state.getChamberPage, loading: true, data: null, error: false, errors: null, success: false } };

    case PUBLIC_CHAMBER_PAGE_SUCCESS:
      return { ...state, getChamberPage: { ...state.getChamberPage, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case PUBLIC_CHAMBER_PAGE_ERROR:
      return { ...state, getChamberPage: { ...state.getChamberPage, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_PUBLIC_CHAMBER_PAGE:
      return { ...state, getChamberPage: { ...state.getChamberPage, loading: false, error: false, success: false } };



    // profile page
    case PUBLIC_PROFILE_PAGE_LOADING:
      return { ...state, getProfilePage: { ...state.getProfilePage, loading: true, data: null, error: false, errors: null, success: false } };

    case PUBLIC_PROFILE_PAGE_SUCCESS:
      return { ...state, getProfilePage: { ...state.getProfilePage, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case PUBLIC_PROFILE_PAGE_ERROR:
      return { ...state, getProfilePage: { ...state.getProfilePage, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_PUBLIC_PROFILE_PAGE:
      return { ...state, getProfilePage: { ...state.getProfilePage, loading: false, error: false, success: false } };

    default:
      return state;
  }
}
