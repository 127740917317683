import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { addProfile, getUserDetails } from '../../services/Action/userAction'
import { toast } from 'react-toastify'
import Toaster from '../../Toaster/Toaster'
import $ from 'jquery'
import { RESET_ADD_PROFILE } from '../../services/Constant'
import { useTranslation } from 'react-i18next'

function AddProfile(props) {
  const navigate = useNavigate()
  const notify = (msg) => toast.error(msg)
  const { t } = useTranslation()

  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  const socialOptions = [
    // { label: 'Whatsapp', value: 'Whatsapp' },
    { label: 'SnapChat', value: 'SnapChat' },
    { label: 'Instagram', value: 'Instagram' },
    { label: 'Linkedin', value: 'Linkedin' },
    { label: 'Youtube', value: 'Youtube' },
    { label: 'Skype', value: 'Skype' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Wechat', value: 'Wechat' },
    { label: 'Twitter', value: 'Twitter' },
    { label: 'Line', value: 'Line' },
    { label: 'Tiktok', value: 'Tiktok' },
  ]

  const [selectedSocialMedia, setSelectedSocialMedia] = useState(socialOptions)
  const [image, setImage] = useState('')

  // const profileDetails = useSelector(state => state.addProfileReducer.data)

  const [state, setState] = useState({
    owner_name_english: '',
    owner_name_chinese: '',
    profile_image: '',
    socialId: {
      telegramId: '',
      Whatsapp: '',
      SnapChat: '',
      Instagram: '',
      Linkedin: '',
      Youtube: '',
      Skype: '',
      Facebook: '',
      Wechat: '',
      Twitter: '',
      Line: '',
      TikTok: ''
    },
    contact_no: '',
    email: '',
    address1: '',
    address2: '',
    address3: '',
  })

  const [errors, setErrors] = useState({
    owner_name_english: false,
    owner_name_chinese: false,
    telegramId: false,
    contact_no: false,
    email: false,
    address1: false,
    address2: false,
    address3: false,
  })



  useEffect(() => {
    if (props.userDetailsReducer.success) {
      setState(prevState => ({
        ...prevState,
        email: props.userDetailsReducer?.data?.data?.email,
        socialId: {
          ...prevState.socialId,
          telegramId: props.userDetailsReducer?.data?.data?.tgid
        }

      }))
    }

  }, [props.userDetailsReducer])

  useEffect(() => {
    if (props.addProfileReducer.success) {
      localStorage.setItem("profilestatus", 1)
      navigate('/add-company')
    }
    if (props.addProfileReducer.error) {
      setErrors(prevState => ({
        ...prevState,
        email: props.addProfileReducer.errors
      }))
      notify(props.addProfileReducer.errors)
      props.resetAddProfile()
    }
  }, [props.addProfileReducer])

  // useEffect(() => {
  //   document.getElementsByClassName('dropdown-heading')[0].setAttribute("id", "dropdown-heading")
  //   if (document.querySelectorAll('.dropdown-heading-value>.gray').length !== 0) {
  //     document.querySelectorAll('.dropdown-heading-value>.gray')[0].innerHTML = `${t('selectSocialMedia')}`
  //   }
  //   props.getUserDetails()
  // }, [$(".clear-selected-button").length, selectedSocialMedia])

  useEffect(() => {
    const profileType = localStorage.getItem("profilestatus")
    if (profileType === '1') {
      navigate('/add-company')
    }

  }, [localStorage.getItem("profilestatus")])

  // const convertBase64 = (file) => new Promise((resolve, reject) => {
  //   if (file !== '') {
  //     const base64 = new FileReader()
  //     base64.readAsDataURL(file)
  //     base64.onload = () => {
  //       resolve(base64.result)
  //     }
  //     base64.onerror = (error) => { reject("error", error) }
  //   }
  // })


  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'owner_name_english') {
      if (value.length <= 24) {
        setState(prevState => ({
          ...prevState,
          owner_name_english: value
        }))
      } else return
    } else if (name === 'owner_name_chinese') {
      if (value.length <= 12) {
        setState(prevState => ({
          ...prevState,
          owner_name_chinese: value
        }))
      } else return
    } else {
      setState(prevState => ({
        ...prevState,
        [name]: value
      }))
    }

    switch (name) {
      case "owner_name_english":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            owner_name_english: 'Enter Owner English name'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            owner_name_english: false
          }))
        }
        break;

      case "owner_name_chinese":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            owner_name_chinese: "Enter Owner Chinese name"
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            owner_name_chinese: false
          }))
        }
        break;

      case "email":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            email: "Enter email"
          }))
        } else if (!validEmailRegex.test(value)) {
          setErrors(prevState => ({
            ...prevState,
            email: "Enter valid email"
          }))
        }
        else {
          setErrors(prevState => ({
            ...prevState,
            email: false
          }))
        }
        break;

      case "contact_no":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            contact_no: "Enter contact number"
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            contact_no: false
          }))
        }
        break;

      case "address1":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            address1: "Enter address1"
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            address1: false
          }))
        }
        break;
      case "address2":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            address2: "Enter address2"
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            address2: false
          }))
        }
        break;
      case "address3":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            address3: "Enter address3"
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            address3: false
          }))
        }
        break;

      default: break;
    }


  }

  const hadleSocialValue = (e) => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      socialId: {
        ...prevState.socialId,
        [name]: value
      }
    }))

    switch (name) {
      case "telegramId":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            telegramId: "Enter Telegram Id"
          }))
        }

        else {
          setErrors(prevState => ({
            ...prevState,
            telegramId: false
          }))
        }
        break;

      default: break;
    }
  }


  const onChangePicture = (e) => {
    setImage(e.target.files[0])
    if (e.target.files[0]) {
      setState(prevState => ({
        ...prevState,
        profile_image: URL.createObjectURL(e.target.files[0] ?? '')
      }))
    }

    // setProfileImage(e.target.files[0])
  }

  const checkUrl = (str) => {
    if (str !== '') {
      if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
        return str
      } else {
        return "https://" + str
      }
    } else return ''

  }

  const handleAddProfile = async () => {
    if (state.owner_name_english === '') {
      setErrors(prevState => ({
        ...prevState,
        owner_name_english: "The english field is mandatory.",
      }))
    }
    if (state.owner_name_chinese === '') {
      setErrors(prevState => ({
        ...prevState,
        owner_name_chinese: "The chinese field is mandatory.",
      }))
    }
    if (state.socialId.telegramId === '') {
      setErrors(prevState => ({
        ...prevState,
        telegramId: "The tgid field is mandatory.",
      }))
    }
    if (state.email === '') {
      setErrors(prevState => ({
        ...prevState,
        email: "The email field is mandatory.",
      }))
    }
    if (state.contact_no === '') {
      setErrors(prevState => ({
        ...prevState,
        contact_no: "The contact field is mandatory.",
      }))
    }
    if (state.address1 === '') {
      setErrors(prevState => ({
        ...prevState,
        address1: "The address1 field is mandatory.",
      }))
    }
    if (state.address2 === '') {
      setErrors(prevState => ({
        ...prevState,
        address2: "The address2 field is mandatory.",
      }))
    }
    if (state.address3 === '') {
      setErrors(prevState => ({
        ...prevState,
        address3: "The address3 field is mandatory.",
      }))
    }

    if (
      state.owner_name_english !== '' &&
      state.owner_name_chinese !== '' &&
      state.socialId.telegramId !== '' &&
      state.email !== '' &&
      state.contact_no !== '' &&
      state.address1 !== '' &&
      state.address2 !== '' &&
      state.address3 !== ''
    ) {

      // const baseImage = await convertBase64(image).then(res => { return res })
      props.addProfile({
        owner_name_english: state.owner_name_english,
        owner_name_chinese: state.owner_name_chinese,
        telegramId: state.socialId.telegramId,
        email: state.email,
        contact: state.contact_no,
        address1: state.address1,
        address2: state.address2,
        address3: state.address3,
        WhatsApp: checkUrl(state.socialId.Whatsapp),
        WeChat: checkUrl(state.socialId.Wechat),
        Line: checkUrl(state.socialId.Line),
        Instagram: checkUrl(state.socialId.Instagram),
        Facebook: checkUrl(state.socialId.Facebook),
        Twitter: checkUrl(state.socialId.Twitter),
        TikTok: checkUrl(state.socialId.TikTok),
        Youtube: checkUrl(state.socialId.Youtube),
        Linkedin: checkUrl(state.socialId.Linkedin),
        SnapChat: checkUrl(state.socialId.SnapChat),
        Skype: checkUrl(state.socialId.Skype),
        profile_image: image
      })
    } else {
      const errorMsg = Object.values(errors).find(e => !!e)
      notify(errorMsg)
    }
  }


  return (
    <React.Fragment>
      <div className="row justify-content-center">
        <div className="col-lg-12 col-md-8 col-12">
          <div>
            <div>
              <h5 className='text-center page-title'>Complete your Profile</h5>
              <div className="row mt-1 g-2">
                <div className="col-6">
                  <div className="form-group mb-0">
                    <input type="text" name="owner_name_english" id="owner_name_english" className={classNames("form-control", {
                      "error": errors.owner_name_english
                    })} placeholder={`${t('ownerNameEnglish')}`} value={state.owner_name_english} onChange={handleChange} />
                    {
                      errors.owner_name_english && <div className='h6 text-danger ps-3'>{errors.owner_name_english}</div>
                    }
                  </div>
                  {/* <span className="error text-left"> </span> */}
                </div>
                <div className="col-6">
                  <div className="form-group mb-0">
                    <input type="text" name="owner_name_chinese" id="owner_name_chinese" className={classNames("form-control", {
                      "error": errors.owner_name_chinese
                    })} placeholder={`${t('ownerNameChiense')}`} value={state.owner_name_chinese} onChange={handleChange} />
                    {
                      errors.owner_name_chinese && <div className='h6 text-danger ps-3'>{errors.owner_name_chinese}</div>
                    }
                  </div>
                  {/* <span className="error text-left"> </span> */}
                </div>
              </div>

              {/* address */}

              {/* Profile */}
              <div className='profile-img profilepage-img'>
                <input type="file" className="input-file file-upload d-none" id="file-upload" name='profile_image' accept='image/jpeg, image/jpg, image/png' onChange={onChangePicture} />
                <label htmlFor="file-upload">
                  {
                    state.profile_image === '' ? <>
                      <div className=' my-2 d-flex justify-content-center pointer'>
                        <div className='text-center'>
                          <img src='/images/loginPage/image.png' width={'180px'} />
                          <div className=''>{t('uploadProfileImage')}</div>
                          <div className=''>{t('size')} 180 x 180</div>
                        </div>

                      </div></> : <>
                      <img src={state.profile_image} id="profileImage" alt="Personal Profile Image" />
                    </>
                  }
                </label>
                {/* <div className="text-center">
                  <a><label className="font-color pointer m-2" htmlFor="file-upload">Browse</label></a>|<a><span className="font-color pointer m-2" >Clear</span></a>
                </div> */}
              </div>

              {/* section */}
              <div className="row g-2">
                <div className="col-6">
                  <div className="form-group mb-1">
                    <input type="text" name="telegramId" id="telegramId" className={classNames("form-control", {
                      "error": errors.telegramId
                    })} value={state.socialId["telegramId"]} placeholder={`${t('telegramId')}`} onChange={hadleSocialValue} disabled />
                    {
                      errors.telegramId && <div className='h6 text-danger ps-3'>{errors.telegramId}</div>
                    }
                  </div>
                  {/* <span className="error text-left"> </span> */}
                </div>
                <div className="col-6">
                  <div className="form-group mb-1">
                    <input type="number" name="contact_no" id="contact_no" className={classNames("form-control", {
                      "error": errors.contact_no
                    })} placeholder={`${t('contactNo')}`} value={state.contact_no} onChange={handleChange} />
                    {
                      errors.contact_no && <div className='h6 text-danger ps-3'>{errors.contact_no}</div>
                    }
                  </div>
                  {/* <span className="error text-left"> </span> */}
                </div>

              </div>

              <div className="row gx-2">
                <div className="col-12">
                  <div className="form-group mb-2">
                    <input type="text" name="WhatsApp" id="WhatsApp" className={classNames("form-control")} placeholder={`https://WhatsApp`}
                      value={state.socialId['WhatsApp']} onChange={(e) => hadleSocialValue(e)} />
                  </div>
                  {/* {
            errors.owner_name_chinese && <div className='h6 text-danger ps-3'>{errors.owner_name_chinese}</div>
          } */}
                </div>
              </div>

              {/* <div className="row">
                <div className="col text-black" id='social-select'>
                  <MultiSelect
                    options={socialOptions}
                    value={selectedSocialMedia}
                    onChange={setSelectedSocialMedia}
                    labelledBy={`${t('selectSocialMedia')}`} />
                </div>

              </div> */}





              <div className="row">
                <div className="col text-black" >
                  <div className="form-group mb-1">
                    <input name="address1" id="address1" className={classNames("form-control", {
                      "error": errors.address1
                    })} placeholder={`${t('address1')}`} value={state.address1} onChange={handleChange} />
                    {/* <span className="error text-left"> </span> */}
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col text-black" >
                  <div className="form-group mb-1">
                    <input name="address2" id="address2" className={classNames("form-control", {
                      "error": errors.address2
                    })} placeholder={`${t('address2')}`} value={state.address2} onChange={handleChange} />
                    {/* <span className="error text-left"> </span> */}
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col text-black" >
                  <div className="form-group mb-1">
                    <input name="address3" id="address3" className={classNames("form-control", {
                      "error": errors.address3
                    })} placeholder={`${t('address3')}`} value={state.address3} onChange={handleChange} />
                    {/* <span className="error text-left"> </span> */}
                  </div>
                </div>

              </div>

              <div className='py-2 my-2 bg-white form-group'>
                <div className='h5 text-center'>Below are optional</div>
              </div>


              <div className="row">
                <div className="col text-black" >
                  <div className="form-group mb-1">
                    <input type="email" name="email" id="email" className={classNames("form-control", {
                      "error": errors.email
                    })} placeholder={`${t('email')}`} value={state.email} onChange={handleChange} />
                    {/* <span className="error text-left"> </span> */}
                  </div>
                </div>

              </div>

              <div className="row justify-content-start">
                {selectedSocialMedia.length !== 0 && selectedSocialMedia.map((e, i) => {
                  if (e.value !== "Whatsapp") {
                    return (
                      <div className="col-md-6 col-12 mb-2" key={i}>
                        <div className="form-group mb-0 d-flex align-items-center" >
                          <input type="text" name={e.value} id={e.value} className="form-control ml-5 " value={state.socialId[e.value]} onChange={hadleSocialValue}
                            placeholder={`https://${e.label}`} />
                        </div>
                      </div>
                    )
                  } else return

                })}
              </div>

              <div className="row mt-3">
                <div className="col text-black" >
                  <div className="justify-content-center bg-red">
                    <button type="submit" className="btn btn-theme1 w-100 " onClick={handleAddProfile}><span className="font-color text-white">{t('Save')}</span></button>
                  </div>
                </div>

              </div>

            </div>


          </div>
        </div>
      </div>

      <Toaster />
    </React.Fragment>
  )
}


const mapStateToProp = state => ({
  // loginReducer: state.loginReducer,
  addProfileReducer: state.addProfileReducer,
  userDetailsReducer: state.userDetailsReducer,

})

const mapDispatchToProps = dispatch => ({
  addProfile: user => dispatch(addProfile(user)),
  getUserDetails: user => dispatch(getUserDetails(user)),
  resetAddProfile: user => dispatch({ type: RESET_ADD_PROFILE })
})

export default connect(mapStateToProp, mapDispatchToProps)(AddProfile)