import { DETAILS_ERROR, DETAILS_LOADING, DETAILS_SUCCESS, RESET_DETAILS } from "../../Constant";

let initialState = {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false,
};

export default function userDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case DETAILS_LOADING:
      return { ...state, loading: true, error: false, errors: [], success: false };

    case DETAILS_SUCCESS:
      return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

    case DETAILS_ERROR:
      return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

    case RESET_DETAILS:
      return { ...state, loading: false, error: false, errors: false, success: false };

    default:
      return state;
  }
}
