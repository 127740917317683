import React from 'react'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, InstapaperShareButton, LineIcon, LineShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'

function ShareSocial() {
 const currentUser = localStorage.getItem('username')
 return (
  <React.Fragment>
   <div className='d-flex justify-content-around'>
    <span className='pointer'>
     <FacebookShareButton size={50} url={`https://addmy.co/${currentUser}`}>
      <FacebookIcon size={50} round />
     </FacebookShareButton>
    </span>
    <span className='pointer'>
     <EmailShareButton size={50} url={`https://addmy.co/${currentUser}`}>
      <EmailIcon size={50} round />
     </EmailShareButton>
    </span>
    <span className='pointer'>
     <WhatsappShareButton size={50} url={`https://addmy.co/${currentUser}`}>
      {/* <WhatsappShareButton size={50} url={`https://www.example.com/webpage/`}> */}
      <WhatsappIcon size={50} round />
     </WhatsappShareButton>
    </span>

   </div>
   <div className='d-flex justify-content-around mt-3'>
    <span className='pointer'>
     <LineShareButton size={50} url={`https://addmy.co/${currentUser}`}>
      <LineIcon size={50} round />
     </LineShareButton>
    </span>
    <span className='pointer'>
     <TelegramShareButton size={50} url={`https://addmy.co/${currentUser}`}>
      <TelegramIcon size={50} round />
     </TelegramShareButton>
    </span>
    <span className='pointer'>
     <LinkedinShareButton size={50} url={`https://addmy.co/${currentUser}`}>
      <LinkedinIcon size={50} round />
     </LinkedinShareButton>
    </span>
   </div>
  </React.Fragment>
 )
}

export default ShareSocial