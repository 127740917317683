// reset store
export const RESET_STORE = 'RESET_STORE'

//  registartion
export const REGISTRATION_LOADING = 'REGISTRATION_LOADING'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_ERORR = 'REGISTRATION_ERORR'
export const RESET_REGISTRATION = 'RESET_REGISTRATION'

// login
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const RESET_LOGIN = 'RESET_LOGIN'

// login
export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD'

// get banner
export const BANNER_LOADING = 'BANNER_LOADING'
export const BANNER_SUCCESS = 'BANNER_SUCCESS'
export const BANNER_ERROR = 'BANNER_ERROR'


// addProfile
export const ADD_PROFILE_LOADING = 'ADD_PROFILE_LOADING'
export const ADD_PROFILE_SUCCESS = 'ADD_PROFILE_SUCCESS'
export const ADD_PROFILE_ERROR = 'ADD_PROFILE_ERROR'
export const RESET_ADD_PROFILE = 'RESET_ADD_PROFILE'

// updateProfile
export const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'
export const RESET_UPDATE_PROFILE = 'RESET_UPDATE_PROFILE'


// add company
export const ADD_COMAPNY_LOADING = 'ADD_COMAPNY_LOADING'
export const ADD_COMAPNY_SUCCESS = 'ADD_COMAPNY_SUCCESS'
export const ADD_COMAPNY_ERROR = 'ADD_COMAPNY_ERROR'
export const RESET_ADD_COMAPNY = 'RESET_ADD_COMAPNY'

// update company
export const UPDATE_COMAPNY_LOADING = 'UPDATE_COMAPNY_LOADING'
export const UPDATE_COMAPNY_SUCCESS = 'UPDATE_COMAPNY_SUCCESS'
export const UPDATE_COMAPNY_ERROR = 'UPDATE_COMAPNY_ERROR'
export const RESET_UPDATE_COMAPNY = 'RESET_UPDATE_COMAPNY'

// userDetails
export const DETAILS_LOADING = 'DETAILS_LOADING'
export const DETAILS_SUCCESS = 'DETAILS_SUCCESS'
export const DETAILS_ERROR = 'DETAILS_ERROR'
export const RESET_DETAILS = 'RESET_DETAILS'

// companyDetails
export const COMAPNY_DETAILS_LOADING = 'COMAPNY_DETAILS_LOADING'
export const COMAPNY_DETAILS_SUCCESS = 'COMAPNY_DETAILS_SUCCESS'
export const COMAPNY_DETAILS_ERROR = 'COMAPNY_DETAILS_ERROR'
export const RESET_COMAPNY_DETAILS = 'RESET_COMAPNY_DETAILS'

// delete companyDetails
export const DELETE_COMAPNY_DETAILS_LOADING = 'DELETE_COMAPNY_DETAILS_LOADING'
export const DELETE_COMAPNY_DETAILS_SUCCESS = 'DELETE_COMAPNY_DETAILS_SUCCESS'
export const DELETE_COMAPNY_DETAILS_ERROR = 'DELETE_COMAPNY_DETAILS_ERROR'
export const RESET_DELETE_COMAPNY_DETAILS = 'RESET_DELETE_COMAPNY_DETAILS'


// landing page
export const LANDING_PAGE_DETAILS_LOADING = 'LANDING_PAGE_DETAILS_LOADING'
export const LANDING_PAGE_DETAILS_SUCCESS = 'LANDING_PAGE_DETAILS_SUCCESS'
export const LANDING_PAGE_DETAILS_ERROR = 'LANDING_PAGE_DETAILS_ERROR'

// contact list
export const GET_CONTACT_LIST_LOADING = 'GET_CONTACT_LIST_LOADING'
export const GET_CONTACT_LIST_SUCCESS = 'GET_CONTACT_LIST_SUCCESS'
export const GET_CONTACT_LIST_ERROR = 'GET_CONTACT_LIST_ERROR'

// contact list
export const REMOVE_CONTACT_LOADING = 'REMOVE_CONTACT_LOADING'
export const REMOVE_CONTACT_SUCCESS = 'REMOVE_CONTACT_SUCCESS'
export const REMOVE_CONTACT_ERROR = 'REMOVE_CONTACT_ERROR'
export const RESET_REMOVE_CONTACT = 'RESET_REMOVE_CONTACT'

// contact notification list
export const GET_NOTIFICATION_LOADING = 'GET_NOTIFICATION_LOADING'
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS'
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR'
export const RESET_GET_NOTIFICATION = 'RESET_GET_NOTIFICATION'

// get membership tenure
export const TENURE_LOADING = 'TENURE_LOADING'
export const TENURE_SUCCESS = 'TENURE_SUCCESS'
export const TENURE_ERROR = 'TENURE_ERROR'
export const RESET_TENURE = 'RESET_TENURE'

// get payment details
export const PAYMENT_DETAILS_LOADING = 'PAYMENT_DETAILS_LOADING'
export const PAYMENT_DETAILS_SUCCESS = 'PAYMENT_DETAILS_SUCCESS'
export const PAYMENT_DETAILS_ERROR = 'PAYMENT_DETAILS_ERROR'
export const RESET_PAYMENT_DETAILS = 'RESET_PAYMENT_DETAILS'

// toncoin payment
export const TONCOIN_PAYMENT_LOADING = 'TONCOIN_PAYMENT_LOADING'
export const TONCOIN_PAYMENT_SUCCESS = 'TONCOIN_PAYMENT_SUCCESS'
export const TONCOIN_PAYMENT_ERROR = 'TONCOIN_PAYMENT_ERROR'
export const RESET_TONCOIN_PAYMENT = 'RESET_TONCOIN_PAYMENT'

// paypal payment
export const PAYPAL_PAYMENT_LOADING = 'PAYPAL_PAYMENT_LOADING'
export const PAYPAL_PAYMENT_SUCCESS = 'PAYPAL_PAYMENT_SUCCESS'
export const PAYPAL_PAYMENT_ERROR = 'PAYPAL_PAYMENT_ERROR'
export const RESET_PAYPAL_PAYMENT = 'RESET_PAYPAL_PAYMENT'

// chamber details
export const CHAMBER_DETAILS_LOADING = 'CHAMBER_DETAILS_LOADING'
export const CHAMBER_DETAILS_SUCCESS = 'CHAMBER_DETAILS_SUCCESS'
export const CHAMBER_DETAILS_ERROR = 'CHAMBER_DETAILS_ERROR'
export const RESET_CHAMBER_DETAILS = 'RESET_CHAMBER_DETAILS'

// save chamber details
export const SAVE_CHAMBER_DETAILS_LOADING = 'SAVE_CHAMBER_DETAILS_LOADING'
export const SAVE_CHAMBER_DETAILS_SUCCESS = 'SAVE_CHAMBER_DETAILS_SUCCESS'
export const SAVE_CHAMBER_DETAILS_ERROR = 'SAVE_CHAMBER_DETAILS_ERROR'
export const RESET_SAVE_CHAMBER_DETAILS = 'RESET_SAVE_CHAMBER_DETAILS'

// delete chamber details
export const DELETE_CHAMBER_DETAILS_LOADING = 'DELETE_CHAMBER_DETAILS_LOADING'
export const DELETE_CHAMBER_DETAILS_SUCCESS = 'DELETE_CHAMBER_DETAILS_SUCCESS'
export const DELETE_CHAMBER_DETAILS_ERROR = 'DELETE_CHAMBER_DETAILS_ERROR'
export const RESET_DELETE_CHAMBER_DETAILS = 'RESET_DELETE_CHAMBER_DETAILS'

// save folder details
export const GET_FOLDER_LOADING = 'GET_FOLDER_LOADING'
export const GET_FOLDER_SUCCESS = 'GET_FOLDER_SUCCESS'
export const GET_FOLDER_ERROR = 'GET_FOLDER_ERROR'
export const RESET_GET_FOLDER = 'RESET_GET_FOLDER'

// Add folder details
export const ADD_FOLDER_LOADING = 'ADD_FOLDER_LOADING'
export const ADD_FOLDER_SUCCESS = 'ADD_FOLDER_SUCCESS'
export const ADD_FOLDER_ERROR = 'ADD_FOLDER_ERROR'
export const RESET_ADD_FOLDER = 'RESET_ADD_FOLDER'

// Add Contact in  folder details
export const ADD_CONTACT_IN_FOLDER_LOADING = 'ADD_CONTACT_IN_FOLDER_LOADING'
export const ADD_CONTACT_IN_FOLDER_SUCCESS = 'ADD_CONTACT_IN_FOLDER_SUCCESS'
export const ADD_CONTACT_IN_FOLDER_ERROR = 'ADD_CONTACT_IN_FOLDER_ERROR'
export const RESET_ADD_CONTACT_IN_FOLDER = 'RESET_ADD_CONTACT_IN_FOLDER'

// Public LandingPage
export const PUBLIC_LANDING_PAGE_LOADING = 'PUBLIC_LANDING_PAGE_LOADING'
export const PUBLIC_LANDING_PAGE_SUCCESS = 'PUBLIC_LANDING_PAGE_SUCCESS'
export const PUBLIC_LANDING_PAGE_ERROR = 'PUBLIC_LANDING_PAGE_ERROR'
export const RESET_PUBLIC_LANDING_PAGE = 'RESET_PUBLIC_LANDING_PAGE'

// Public company page
export const PUBLIC_COMPANY_PAGE_LOADING = 'PUBLIC_COMPANY_PAGE_LOADING'
export const PUBLIC_COMPANY_PAGE_SUCCESS = 'PUBLIC_COMPANY_PAGE_SUCCESS'
export const PUBLIC_COMPANY_PAGE_ERROR = 'PUBLIC_COMPANY_PAGE_ERROR'
export const RESET_PUBLIC_COMPANY_PAGE = 'RESET_PUBLIC_COMPANY_PAGE'

// Public company page
export const PUBLIC_CHAMBER_PAGE_LOADING = 'PUBLIC_CHAMBER_PAGE_LOADING'
export const PUBLIC_CHAMBER_PAGE_SUCCESS = 'PUBLIC_CHAMBER_PAGE_SUCCESS'
export const PUBLIC_CHAMBER_PAGE_ERROR = 'PUBLIC_CHAMBER_PAGE_ERROR'
export const RESET_PUBLIC_CHAMBER_PAGE = 'RESET_PUBLIC_CHAMBER_PAGE'

// Public profile page
export const PUBLIC_PROFILE_PAGE_LOADING = 'PUBLIC_PROFILE_PAGE_LOADING'
export const PUBLIC_PROFILE_PAGE_SUCCESS = 'PUBLIC_PROFILE_PAGE_SUCCESS'
export const PUBLIC_PROFILE_PAGE_ERROR = 'PUBLIC_PROFILE_PAGE_ERROR'
export const RESET_PUBLIC_PROFILE_PAGE = 'RESET_PUBLIC_PROFILE_PAGE'

// Add to contact
export const ADD_TO_CONTACT_LOADING = 'ADD_TO_CONTACT_LOADING'
export const ADD_TO_CONTACT_SUCCESS = 'ADD_TO_CONTACT_SUCCESS'
export const ADD_TO_CONTACT_ERROR = 'ADD_TO_CONTACT_ERROR'
export const RESET_ADD_TO_CONTACT = 'RESET_ADD_TO_CONTACT'


// Invitation
export const INVITATION_LOADING = 'INVITATION_LOADING'
export const INVITATION_SUCCESS = 'INVITATION_SUCCESS'
export const INVITATION_ERROR = 'INVITATION_ERROR'
export const RESET_INVITATION = 'RESET_INVITATION'


// get background
export const GET_BACKGROUND_LOADING = 'GET_BACKGROUND_LOADING'
export const GET_BACKGROUND_SUCCESS = 'GET_BACKGROUND_SUCCESS'
export const GET_BACKGROUND_ERROR = 'GET_BACKGROUND_ERROR'
export const RESET_GET_BACKGROUND = 'RESET_GET_BACKGROUND'

// background
export const SET_BACKGROUND_LOADING = 'SET_BACKGROUND_LOADING'
export const SET_BACKGROUND_SUCCESS = 'SET_BACKGROUND_SUCCESS'
export const SET_BACKGROUND_ERROR = 'SET_BACKGROUND_ERROR'
export const RESET_SET_BACKGROUND = 'RESET_SET_BACKGROUND'

// background
export const GET_SYSTEM_IAMGE_LOADING = 'GET_SYSTEM_IAMGE_LOADING'
export const GET_SYSTEM_IAMGE_SUCCESS = 'GET_SYSTEM_IAMGE_SUCCESS'
export const GET_SYSTEM_IAMGE_ERROR = 'GET_SYSTEM_IAMGE_ERROR'
export const RESET_GET_SYSTEM_IAMGE = 'RESET_GET_SYSTEM_IAMGE'

// background
export const IMASG_LOADING = 'IMASG_LOADING'
export const IMASG_SUCCESS = 'IMASG_SUCCESS'
export const IMASG_ERROR = 'IMASG_ERROR'
export const RESET_IMASG = 'RESET_IMASG'



// delete folder
export const DELETE_FOLDER_LOADING = 'DELETE_FOLDER_LOADING'
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS'
export const DELETE_FOLDER_ERROR = 'DELETE_FOLDER_ERROR'
export const RESET_DELETE_FOLDER = 'RESET_DELETE_FOLDER'

// delete folder
export const UPDATE_FOLDER_LOADING = 'UPDATE_FOLDER_LOADING'
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS'
export const UPDATE_FOLDER_ERROR = 'UPDATE_FOLDER_ERROR'
export const RESET_UPDATE_FOLDER = 'RESET_UPDATE_FOLDER'

//languge
export const LANGUAGE_LOADING = 'LANGUAGE_LOADING'
export const LANGUAGE_SUCCESS = 'LANGUAGE_SUCCESS'
export const LANGUAGE_ERROR = 'LANGUAGE_ERROR'
export const RESET_LANGUAGE = 'RESET_LANGUAGE'

//add coustom image background image
export const ADD_IMAGE_BACKGROUND_LOADING = 'ADD_IMAGE_BACKGROUND_LOADING'
export const ADD_IMAGE_BACKGROUND_SUCCESS = 'ADD_IMAGE_BACKGROUND_SUCCESS'
export const ADD_IMAGE_BACKGROUND_ERROR = 'ADD_IMAGE_BACKGROUND_ERROR'
export const RESET_ADD_IMAGE_BACKGROUND = 'RESET_ADD_IMAGE_BACKGROUND'

//get coustom image  background image
export const GET_IMAGE_BACKGROUND_LOADING = 'GET_IMAGE_BACKGROUND_LOADING'
export const GET_IMAGE_BACKGROUND_SUCCESS = 'GET_IMAGE_BACKGROUND_SUCCESS'
export const GET_IMAGE_BACKGROUND_ERROR = 'GET_IMAGE_BACKGROUND_ERROR'
export const RESET_GET_IMAGE_BACKGROUND = 'RESET_GET_IMAGE_BACKGROUND'

//get coustom image  background image
export const NOTIFICATION_DELETE_LOADING = 'NOTIFICATION_DELETE_LOADING'
export const NOTIFICATION_DELETE_SUCCESS = 'NOTIFICATION_DELETE_SUCCESS'
export const NOTIFICATION_DELETE_ERROR = 'NOTIFICATION_DELETE_ERROR'
export const RESET_NOTIFICATION_DELETE = 'RESET_NOTIFICATION_DELETE'


//Send Request for the refferal
export const SEND_REQUEST_FOR_REFFERAL_LOADING = 'SEND_REQUEST_FOR_REFFERAL_LOADING'
export const SEND_REQUEST_FOR_REFFERAL_SUCCESS = 'SEND_REQUEST_FOR_REFFERAL_SUCCESS'
export const SEND_REQUEST_FOR_REFFERAL_ERROR = 'SEND_REQUEST_FOR_REFFERAL_ERROR'
export const RESET_SEND_REQUEST_FOR_REFFERAL = 'RESET_SEND_REQUEST_FOR_REFFERAL'


//get Refferal image
export const GET_REFFERAL_IMAGES_LOADING = 'GET_REFFERAL_IMAGES_LOADING'
export const GET_REFFERAL_IMAGES_SUCCESS = 'GET_REFFERAL_IMAGES_SUCCESS'
export const GET_REFFERAL_IMAGES_ERROR = 'GET_REFFERAL_IMAGES_ERROR'
export const RESET_GET_REFFERAL_IMAGES = 'RESET_GET_REFFERAL_IMAGES'

//get image Catagory
export const GET_CATAGORY_LIST_LOADING = 'GET_CATAGORY_LIST_LOADING'
export const GET_CATAGORY_LIST_SUCCESS = 'GET_CATAGORY_LIST_SUCCESS'
export const GET_CATAGORY_LIST_ERROR = 'GET_CATAGORY_LIST_ERROR'
export const RESET_GET_CATAGORY_LIST = 'RESET_GET_CATAGORY_LIST'


//upload images
export const UPLOAD_IMAGE_LOADING = 'UPLOAD_IMAGE_LOADING'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR'
export const RESET_UPLOAD_IMAGE = 'RESET_UPLOAD_IMAGE'

//Edit images
export const EDIT_IMAGE_LOADING = 'EDIT_IMAGE_LOADING'
export const EDIT_IMAGE_SUCCESS = 'EDIT_IMAGE_SUCCESS'
export const EDIT_IMAGE_ERROR = 'EDIT_IMAGE_ERROR'
export const RESET_EDIT_IMAGE = 'RESET_EDIT_IMAGE'

//delete images
export const DELETE_IMAGE_LOADING = 'DELETE_IMAGE_LOADING'
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_ERROR = 'DELETE_IMAGE_ERROR'
export const RESET_DELETE_IMAGE = 'RESET_DELETE_IMAGE'

//search User From Telegram
export const SEARCH_TELEGRAM_USER_LOADING = 'SEARCH_TELEGRAM_USER_LOADING'
export const SEARCH_TELEGRAM_USER_SUCCESS = 'SEARCH_TELEGRAM_USER_SUCCESS'
export const SEARCH_TELEGRAM_USER_ERROR = 'SEARCH_TELEGRAM_USER_ERROR'
export const RESET_SEARCH_TELEGRAM_USER = 'RESET_SEARCH_TELEGRAM_USER'

//referral membership tenure
export const GET_REFERRAL_TENURE_LOADING = 'GET_REFERRAL_TENURE_LOADING'
export const GET_REFERRAL_TENURE_SUCCESS = 'GET_REFERRAL_TENURE_SUCCESS'
export const GET_REFERRAL_TENURE_ERROR = 'GET_REFERRAL_TENURE_ERROR'
export const RESET_GET_REFERRAL_TENURE = 'RESET_GET_REFERRAL_TENURE'


//get referral Report
export const GET_REFERRAL_REPORT_LOADING = 'GET_REFERRAL_REPORT_LOADING'
export const GET_REFERRAL_REPORT_SUCCESS = 'GET_REFERRAL_REPORT_SUCCESS'
export const GET_REFERRAL_REPORT_ERROR = 'GET_REFERRAL_REPORT_ERROR'
export const RESET_GET_REFERRAL_REPORT = 'RESET_GET_REFERRAL_REPORT'


//get referral Report
export const CHECKOUT_SESSION_LOADING = 'CHECKOUT_SESSION_LOADING'
export const CHECKOUT_SESSION_SUCCESS = 'CHECKOUT_SESSION_SUCCESS'
export const CHECKOUT_SESSION_ERROR = 'CHECKOUT_SESSION_ERROR'
export const RESET_CHECKOUT_SESSION = 'RESET_CHECKOUT_SESSION'


// success payment
export const SUCCESS_PAYMENT_LOADING = 'SUCCESS_PAYMENT_LOADING'
export const SUCCESS_PAYMENT_SUCCESS = 'SUCCESS_PAYMENT_SUCCESS'
export const SUCCESS_PAYMENT_ERROR = 'SUCCESS_PAYMENT_ERROR'
export const RESET_SUCCESS_PAYMENT = 'RESET_SUCCESS_PAYMENT'


//get referral Report
export const MEMBERSHIP_CHECKOUT_SESSION_LOADING = 'MEMBERSHIP_CHECKOUT_SESSION_LOADING'
export const MEMBERSHIP_CHECKOUT_SESSION_SUCCESS = 'MEMBERSHIP_CHECKOUT_SESSION_SUCCESS'
export const MEMBERSHIP_CHECKOUT_SESSION_ERROR = 'MEMBERSHIP_CHECKOUT_SESSION_ERROR'
export const RESET_MEMBERSHIP_CHECKOUT_SESSION = 'RESET_MEMBERSHIP_CHECKOUT_SESSION'

//success stripe payment
export const MEMBERSHIP_STRIPE_PAYMENT_LOADING = 'MEMBERSHIP_STRIPE_PAYMENT_LOADING'
export const MEMBERSHIP_STRIPE_PAYMENT_SUCCESS = 'MEMBERSHIP_STRIPE_PAYMENT_SUCCESS'
export const MEMBERSHIP_STRIPE_PAYMENT_ERROR = 'MEMBERSHIP_STRIPE_PAYMENT_ERROR'
export const RESET_MEMBERSHIP_STRIPE_PAYMENT = 'RESET_MEMBERSHIP_STRIPE_PAYMENT'

//payment configration
export const GET_PAYMENT_CONFIG_LOADING = 'GET_PAYMENT_CONFIG_LOADING'
export const GET_PAYMENT_CONFIG_SUCCESS = 'GET_PAYMENT_CONFIG_SUCCESS'
export const GET_PAYMENT_CONFIG_ERROR = 'GET_PAYMENT_CONFIG_ERROR'
export const RESET_GET_PAYMENT_CONFIG = 'RESET_GET_PAYMENT_CONFIG'

// socialIcons
// export const socialMediaIcons = {
//  Telegram: '/images/loginPage/telegram.png',
//  Facebook: '/images/facebook.png',
//  Instagram: '/images/instagram.png',
//  Line: '/images/line.png',
//  Skype: '/images/skype.png',
//  Twitter: '/images/twitter.png',
//  WeChat: '/images/wechat.png',
//  WhatsApp: '/images/whatsApp.png',
//  Youtube: '/images/youtube.png',
//  Linkedin: '/images/Linkedin.png',
//  SnapChat: '/images/SnapChat.png'
// }

export const socialMediaIcons = {
 Telegram: 'fa-brands fa-telegram',
 Facebook: 'fa-brands fa-facebook-f',
 Instagram: 'fa-brands fa-instagram',
 Line: 'fa-brands fa-line',
 Skype: 'fa-brands fa-skype',
 Twitter: 'fa-brands fa-twitter',
 WeChat: 'fa-brands fa-weixin',
 WhatsApp: 'fa-brands fa-whatsapp',
 Youtube: 'fa-brands fa-youtube',
 Linkedin: 'fa-brands fa-linkedin',
 SnapChat: 'fa-brands fa-snapchat',
 Email: 'fa-solid  fa-envelope',
 TikTok: 'fa-brands fa-tiktok'
}


// export const hexagonalIcon = {
//  'Telegram': '/images/telegramHexagon.png',
//  'Youtube': '/images/youtubeHexagon.png',
//  'Facebook': '/images/facebookHexagon.png',
//  'Instagram': '/images/instagramHexagon.png',
//  'website': '/images/websiteHexagon.png',
//  'fanpage': '/images/fanpageHexagon.png',
// }
export const hexagonalIcon = {
 'Telegram': 'fa-brands fa-telegram',
 'Youtube': 'fa-brands fa-youtube',
 'Facebook': 'fa-brands fa-facebook-f',
 'Instagram': 'fa-brands fa-instagram',
 'website': 'fa-solid fa-globe',
 //  'fanpage': '/images/fanpageHexagon.png',
}

