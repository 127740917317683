import { ADD_TO_CONTACT_ERROR, ADD_TO_CONTACT_LOADING, ADD_TO_CONTACT_SUCCESS, INVITATION_ERROR, INVITATION_LOADING, INVITATION_SUCCESS, RESET_ADD_TO_CONTACT, RESET_INVITATION } from "../../Constant";

let initialState = {
  addToContact: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    errorData: null,
    success: false
  },
  invitation: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
};

export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CONTACT_LOADING:
      return { ...state, addToContact: { ...state.addToContact, loading: true, error: false, errors: [], success: false } };

    case ADD_TO_CONTACT_SUCCESS:
      return { ...state, addToContact: { ...state.addToContact, loading: false, error: false, errors: [], success: true, data: action.response.data, errorData: null } };

    case ADD_TO_CONTACT_ERROR:
      return { ...state, addToContact: { ...state.addToContact, loading: false, error: true, errors: action.errors, success: false, data: null, errorData: action.errorData } };

    case RESET_ADD_TO_CONTACT:
      return { ...state, addToContact: { ...state.addToContact, loading: false, error: false, success: false, errorData: null } };


    //  invitation
    case INVITATION_LOADING:
      return { ...state, invitation: { ...state.invitation, loading: true, error: false, errors: [], success: false } };

    case INVITATION_SUCCESS:
      return { ...state, invitation: { ...state.invitation, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case INVITATION_ERROR:
      return { ...state, invitation: { ...state.invitation, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_INVITATION:
      return { ...state, invitation: { ...state.invitation, loading: false, error: false, success: false } };


    default:
      return state;
  }
}
