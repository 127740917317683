
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import QrCodeGenrate from '../../components/QrCodeGenrate/QrCodeGenrate'
import { getPaymentDetails } from '../../services/Action/MemberShip/memberShipAction'
import Toaster from '../../Toaster/Toaster'
import "react-datepicker/dist/react-datepicker.css";
import { submitPaypalPayment, submitStripePayment, submitToncoinPayment } from '../../services/Action/Payment/PaymentAction'
import { Trans, useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import { RESET_MEMBERSHIP_CHECKOUT_SESSION } from '../../services/Constant'
import moment from 'moment'

function PaymentDetails(props) {

  const { id, option } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = (msg) => toast.error(msg)
  const successNotify = (msg) => toast.success(msg)
  const paymentStatus = localStorage.getItem("paymentstatus")
  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })
  const [loading, setLoading] = useState(false)

  const userDetails = useSelector(state => state.userDetailsReducer.data)

  const [paymentOption, setPaymentOption] = useState('3')
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [props.backGroundReducer])
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [])

  const [stripeDetails, setStripeDetails] = useState({
    period: '',
    amount: '',
    membership_id: ''
  })
  const [paypalState, setPayPalState] = useState({
    period: '',
    first_name: '',
    last_name: '',
    paypalId: '',
    transaction: '',
    date: '',
    amount: '',
    currency: '',
    id: ''
  })


  const [payPalError, setPayPalError] = useState({
    period: false,
    first_name: false,
    last_name: false,
    paypalId: false,
    transaction: false,
    date: false,
    amount: false
  })

  // useEffect(() => {
  //  const paymentStatus = localStorage.getItem('paymentstatus')
  //  if (paymentStatus === '0' || paymentStatus === '1') {
  //   navigate('/membership-plane')
  //  }
  // }, [])

  const [promoteMsg, setPromoteMsg] = useState(0);

  useEffect(() => {
    props.getPaymentDetails(id)
    setLoading(true)
  }, [id])

  useEffect(() => {
    if (props.paymentDetailsReducer.success) {
      const data = props.paymentDetailsReducer.data.data
      setPaymentOption(`${data?.paymentOption}`)
      setPromoteMsg(data?.promotion_message)
      // setToncoinState(prevState => ({
      //   ...prevState,
      //   period: data.membershiperiod,
      //   toncoin: data.toncoin
      // }))
      setStripeDetails(pre => ({
        ...pre,
        amount: data.paypal,
        period: data.membershiperiod,
        membership_id: data._id,
      }))
      setPayPalState(prevState => ({
        ...prevState,
        period: data.membershiperiod,
        amount: data.paypal,
        currency: data.currency,
        id: data._id
      }))
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [props.paymentDetailsReducer])

  useEffect(() => {
    if (props.toncoinReducer.success || props.paypalReducer.success) {
      successNotify(`${t('successTransactionMsg')}`)
      localStorage.setItem("paymentstatus", 0)
      setTimeout(() => {
        navigate('/membership-plane')
      }, 2000)
    }

  }, [props.toncoinReducer, props.paypalReducer])

  useEffect(() => {
    if (props.stripePaymentReducer.success) {
      const data = props.stripePaymentReducer.data
      console.log("datd", props.stripePaymentReducer);
      window.open(data.url, "_self")
      props.resetcheckout()
    }

  }, [props.stripePaymentReducer])


  const handleChange = (e) => {
    const { value, name } = e.target

    if (option === '1') {
      setPayPalState(prevState => ({
        ...prevState,
        [name]: value
      }))

      switch (name) {
        case "first_name":
          if (value === '') {
            setPayPalError(prevState => ({
              ...prevState,
              first_name: 'Enter first name'
            }))
          } else {
            setPayPalError(prevState => ({
              ...prevState,
              first_name: false
            }))
          }

        case "last_name":
          if (value === '') {
            setPayPalError(prevState => ({
              ...prevState,
              last_name: 'Enter Last name'
            }))
          } else {
            setPayPalError(prevState => ({
              ...prevState,
              last_name: false
            }))
          }

        case "paypalId":
          if (value === '') {
            setPayPalError(prevState => ({
              ...prevState,
              paypalId: 'Enter Paypal ID'
            }))
          } else {
            setPayPalError(prevState => ({
              ...prevState,
              paypalId: false
            }))
          }

        case "transaction":
          if (value === '') {
            setPayPalError(prevState => ({
              ...prevState,
              transaction: 'Enter transaction'
            }))
          } else {
            setPayPalError(prevState => ({
              ...prevState,
              transaction: false
            }))
          }
        default: break
      }
    }
  }

  const handleDate = (selected_date) => {
    if (option === '1') {
      setPayPalState(prevState => ({
        ...prevState,
        date: selected_date
      }))
      setPayPalError(prevState => ({
        ...prevState,
        date: false
      }))
    }

  }

  const handlePayment = () => {

    if (option === '1') {

      if (paypalState.first_name === '') {
        setPayPalError(prevState => ({
          ...prevState,
          first_name: 'Enter first name'
        }))
        notify('Enter first name')
      } else if (paypalState.last_name === '') {
        setPayPalError(prevState => ({
          ...prevState,
          last_name: 'Enter last name'
        }))
        notify('Enter last name')
      } else if (paypalState.paypalId === '') {
        setPayPalError(prevState => ({
          ...prevState,
          paypalId: 'Enter Paypal id'
        }))
        notify('Enter Paypal id')
      } else if (paypalState.transaction === '') {
        setPayPalError(prevState => ({
          ...prevState,
          transaction: 'Enter transaction'
        }))
        notify('Enter transaction')
      } else if (paypalState.date === '') {
        setPayPalError(prevState => ({
          ...prevState,
          date: 'Enter valid date'
        }))
        notify('Enter valid date')
      }

      if (
        paypalState.first_name !== '' &&
        paypalState.last_name !== '' &&
        paypalState.paypalId !== '' &&
        paypalState.transaction !== '' &&
        paypalState.date !== ''
      ) {
        const paymentDetails = {
          membershiperiod: paypalState.period,
          firstname: paypalState.first_name,
          lastname: paypalState.last_name,
          paypalid: paypalState.paypalId,
          transactionid: paypalState.transaction,
          paypal: paypalState.id,
          date: paypalState.date,
          paymenttype: "2",
          amount: paypalState.amount
        }
        props.submitPaypalPayment(paymentDetails)
      }
    } else if (option === '2') {
      props.submitStripePayment({
        membership_period: stripeDetails.period,
        membership_amount: stripeDetails.amount,
        membership_id: stripeDetails.membership_id
      })

    }
  }

  return (
    <React.Fragment>
      <div className="container">
        {loading ? <div className='my-4 d-flex justify-content-center'><Spinner /> </div> :
          <div className="tags">
            {option === '1' && <>
              {
                parseInt(promoteMsg) === 0 ?
                  <div className='border text-center p-2' style={{ fontSize: '19px', background: '#d3edfa' }}>
                    <Trans i18nKey={'SelectedPlanMsg'}>
                      {{ tonCoinPeriod: paypalState.period }},
                      {{ payPalCurrency: paypalState.currency }},
                      {{ payPalAmount: paypalState.amount }},
                      {/* {{ tonCoinAmount: toncoinState.toncoin }} */}
                    </Trans>
                    {/* {t('demo', {name: 'yash123'})} */}
                  </div> :
                  <div className='border text-center p-2 mt-4' style={{ fontSize: '19px', background: '#d3edfa' }}>
                    <Trans i18nKey={'PromotionMsg'}>
                      Text <u><a href="https://T.me/Addmycompany">link</a></u>
                    </Trans>
                  </div>
              }
            </>}
            <div className="row">
              <div className="col-12">
                {option === '1' && <div className="row mt-5">
                  <div className="col-lg-6 col-12">
                    <div className="m-auto">
                      <h5 className="mt-2 mb-4 text-center">{`${t('submitTransactionDetails')}`}</h5>
                      <div className="row ">
                        <div className="col-10 px-0 m-auto list_input">
                          <form action="">
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" placeholder={`${t('membshipPeriod')}`} value={`${paypalState.period} Year`} name='period' onChange={handleChange} disabled />
                            </div>
                            <div className="input-group mb-3">
                              <input type="text" className={classNames("form-control", { "error": payPalError.first_name })} placeholder={`${t('PayerFirstNAme')}`} value={paypalState.first_name} name='first_name' onChange={handleChange} />
                            </div>
                            <div className="input-group mb-3">
                              <input type="text" className={classNames("form-control", { "error": payPalError.last_name })} placeholder={`${t('PayerLastNAme')}`} value={paypalState.last_name} name='last_name' onChange={handleChange} />
                            </div>
                            <div className="input-group mb-3">
                              <input type="text" className={classNames("form-control", { "error": payPalError.paypalId })} placeholder={`${t('gmailID')}`} value={paypalState.paypalId} name='paypalId' onChange={handleChange} />
                            </div>
                            <div className="input-group mb-3">
                              <input type="text" className={classNames("form-control", { "error": payPalError.transaction })} placeholder={`${t('transactionId')}`} value={paypalState.transaction} name='transaction' onChange={handleChange} />
                            </div>
                            <div className="input-group mb-3">
                              <ReactDatePicker
                                className={classNames('form-control', { "error": payPalError.date })}
                                selected={paypalState.date}
                                onChange={(date) => handleDate(date)}
                                placeholderText={'DD/MM/YYYY'}
                                dateFormat={'dd/MM/yyyy'}
                                onKeyDown={(e) => e.preventDefault()}
                                maxDate={new Date()}
                              />
                            </div>
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" placeholder="9.9 USD" value={`${paypalState.currency} ${paypalState.amount} `} name='amount' disabled />
                            </div>
                            <div className="text-center mb-3">
                              <div className=" py-2 rounded pointer" onClick={handlePayment} style={{
                                color: '#ffffff',
                                backgroundColor: '#00A3CC'
                              }}>{t('Submit')}</div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-6 text-center my-3 order-first order-md-last d-flex justify-content-center flex-column">
                    <div className='border d-flex justify-content-center flex-column rounded' style={{ height: '225px', background: '#d3edfa' }}>
                      <h5 style={{ fontSize: '24px' }}>{t('makeDepositeHere')}</h5>
                      <a href='Paypal.me/Bluemarketer' target={'_blank'} className="text-blue pointer" style={{ fontSize: '17px' }}><u>Paypal.me/Bluemarketer</u></a>
                    </div>
                  </div>
                  {/* {
                    promoteMsg === 0 &&
                    <div className="col-md-6 text-center my-3 order-first order-md-last d-flex justify-content-center flex-column">
                      <div className='border d-flex justify-content-center flex-column rounded' style={{ height: '225px', background: '#d3edfa' }}>
                        <h5 style={{ fontSize: '24px' }}>{t('makeDepositeHere')}</h5>
                        <a href='Paypal.me/Bluemarketer' target={'_blank'} className="text-blue pointer" style={{ fontSize: '17px' }}><u>Paypal.me/Bluemarketer</u></a>
                      </div>
                    </div>
                  } */}
                </div>}
                {
                  option === '2' && <><div className='mt-4'>
                    <div className="text-center h4 my-2" style={{
                      color: '#ffffff',
                      background: '#00A3CC'
                    }}>{t('Review Payment Details')}</div>
                  </div>
                    <div className='bg-white py-4 px-4'>
                      <div className='row align-items-center mb-3'>
                        <div className='col-md-5  h6'>Telegram id :-</div>
                        <input className='col-md-6 border rounded py-1' value={userDetails?.data?.tgid} disabled />
                      </div>
                      <div className='row align-items-center mb-3'>
                        <div className='col-md-5  h6'>Membership Tenure :-</div>
                        <input className='col-md-6 border rounded py-1' value={`${stripeDetails.period} Year`} disabled />
                      </div>
                      <div className='row align-items-center mb-3'>
                        <div className='col-md-5 h6'>Payment Date :-</div>
                        <input className='col-6 border rounded py-1' value={moment().format("DD-MM-yyyy")} disabled />
                      </div>
                      <div className='row align-items-center mb-3'>
                        <div className='col-md-5 h6'>Price to pay  :-</div>
                        <input className='col-6 border rounded py-1' value={`HK$ ${stripeDetails.amount}`} disabled />
                      </div>
                      <div className=' mt-4 border py-1 rounded text-white bg-red pointer text-center ' style={{ minWidth: '150px' }} onClick={handlePayment}>{t('ProceedtoPay')}</div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>}
      </div>
      <Toaster />
    </React.Fragment>
  )
}

const mapStateToProp = state => ({
  paymentDetailsReducer: state.memberShipTenureReduce.paymentDetails,
  toncoinReducer: state.paymentReducer.toncoinPayment,
  paypalReducer: state.paymentReducer.paypalPayment,
  stripePaymentReducer: state.paymentReducer.stripePayment,
  backGroundReducer: state.themeReducer.getBackGround,
})

const mapDispatchToProps = dispatch => ({
  getPaymentDetails: user => dispatch(getPaymentDetails(user)),
  submitToncoinPayment: paymentDetails => dispatch(submitToncoinPayment(paymentDetails)),
  submitPaypalPayment: paymentDetails => dispatch(submitPaypalPayment(paymentDetails)),
  submitStripePayment: paymentDetails => dispatch(submitStripePayment(paymentDetails)),
  resetcheckout: paymentDetails => dispatch({ type: RESET_MEMBERSHIP_CHECKOUT_SESSION }),
})

export default connect(mapStateToProp, mapDispatchToProps)(PaymentDetails)