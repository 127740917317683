import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { removeContact } from '../../services/Action/userAction'
import { Modal } from 'react-bootstrap'

function Contact(props) {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [threeDots, setThreeDots] = useState(false)
  const [options, setOptions] = useState(false)
  const [contactId, setContactID] = useState();
  const handleRemoveContact = (contact_id) => {
    // props.removeContact(contact_id)
    setContactID(contact_id)
    setShow(true)
  }
  // removeContact
  const addTofolder = () => {
    props.setSelectedContactId(props.list.contact_id)
    props.setIsFolderList(true)
  }
  const removeFavContact = () => {
    props.removeContact(contactId)
  }
  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (<>
    <div className='col-md-6 col-6 text-center searchItem position-relative mb-3' key={props.index} onMouseEnter={() => setThreeDots(true)} onMouseLeave={() => setThreeDots(false)}>
      {options && <div className='position-absolute border bg-white rounded' style={{ right: '30px', width: '100%', top: '15px' }} onMouseEnter={() => setOptions(true)} onMouseLeave={() => setOptions(false)} onClick={addTofolder}>
        <div className='border-bottom py-2 pointer' style={{
          color: props.theme.font !== '' ? props.theme.font : '#ffffff',
          background: (!!props.theme.theme && props.theme.theme !== '') ? props.theme.theme : '#ffffff'
        }}>{t('addtoFolder')}</div>
      </div>}
      <div className='position-absolute pointer' style={{ top: '5px', right: '5px' }} onMouseEnter={() => setOptions(true)} onMouseLeave={() => setOptions(false)}>
        <img src='/images/folderIcon.png' width='25px' />
      </div>
      {
        (props?.list?.userdetails[0]?.profile_image !== '' && props?.list?.userdetails[0]?.profile_image !== null && props?.list?.userdetails[0]?.profile_image !== undefined) ?
          <>
            <img src={`${props.list.profile_image}${props?.list?.userdetails[0]?.profile_image}`} className="pointer contact-img"
              style={{ borderRadius: '50%' }} onClick={() => navigate(`/${props?.list?.userdetails[0]?.username}`)} /></>
          : (props?.list?.userdetails[0]?.video !== '' && props?.list?.userdetails[0]?.video !== null && props?.list?.userdetails[0]?.video !== undefined) ?
            <div >
              <video controls autoPlay muted loop playsinline style={{ borderRadius: '50%', objectFit: 'fill' }} onClick={() => navigate(`/${props.list.userdetails[0]?.username}`)} className="pointer contact-img"><source src={`${props.list.profile_image}${props.list.userdetails[0]?.video}`} id="profileVideo" alt="Personal Profile Video" /></video> </div> :
            <img src={'/images/loginPage/image.png'} className="img-fluid profile-icon" style={{ borderRadius: '50%' }} />
      }


      <div className=' border-radius-25 fs-w searchStrEng mt-1 pointer' style={{
        backgroundColor: (!!props.theme.theme && props.theme.theme !== '') ? props.theme.theme : '#007CB6',
        color: props.theme.font !== '' ? props.theme.font : '#ffffff',
        padding: '0px 10px',
        overflowX: 'auto',
        whiteSpace: 'nowrap'
      }} onClick={() => navigate(`/${props?.list.userdetails[0]?.username}/profile`)}>
        {props.list.userdetails[0]?.owner_name_english}
      </div>

      <div className=' mt-1 border-radius-25 fs-w searchStrChines pointer' style={{
        backgroundColor: (!!props.theme.theme && props.theme.theme !== '') ? props.theme.theme : '#007CB6',
        color: props.theme.font !== '' ? props.theme.font : '#ffffff',
        padding: '0px 10px',
        overflowX: 'auto',
        whiteSpace: 'nowrap'
      }} onClick={() => navigate(`/${props?.list.userdetails[0]?.username}/profile`)}>
        {props.list.userdetails[0]?.owner_name_chinese}
      </div>
      {/* {
    !!props.list?.contactfolders_data ?
     props.list?.contactfolders_data.lenght !== 0 && <div className='d-none mt-1 border-radius-25 fs-w searchStrTags'>{props.list?.contactfolders_data[0]?.tag}</div>
     : <div className='d-none mt-1 border-radius-25 fs-w searchStrTags'>{props.list?.tag}</div>
   } */}

      <div className='d-none mt-1 border-radius-25 fs-w searchStrCompanyEng'>{props.list.userdetails[0]?.companydetails[0]?.company_name_english}</div>
      <div className='d-none mt-1 border-radius-25 fs-w searchStrCompanyChinese'>{props.list.userdetails[0]?.companydetails[0]?.company_name_chinese}</div>
      {/* <div className='bg-red text-white mt-1 border-radius-25 fs-w pointer' onClick={() => handleRemoveContact(props.list._id)}>{t('removeMe')}</div> */}
      <div className='bg-red text-white mt-1 border-radius-25 fs-w pointer' onClick={() => handleRemoveContact(props.list.contact_id)}>{t('removeMe')}</div>
    </div>
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <React.Fragment>
        <div className='p-5'>
          <h5 className='text-blue text-center'>
            {t('sureRemove')}
          </h5>
          <div className='d-flex justify-content-center mt-4'>
            <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
              setShow(false)
            }}>{t('no')}</div>
            <div className='border rounded mx-4 px-4 py-2 pointer' onClick={removeFavContact} >{t('yes')}</div>
          </div>
        </div>
      </React.Fragment>
    </Modal>
  </>
  )
}

const mapStateToProp = state => ({
})

const mapDispatchToProps = dispatch => ({
  removeContact: (contact_id) => dispatch(removeContact(contact_id))
})

export default connect(mapStateToProp, mapDispatchToProps)(Contact)