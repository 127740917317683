import { LANDING_PAGE_DETAILS_ERROR, LANDING_PAGE_DETAILS_LOADING, LANDING_PAGE_DETAILS_SUCCESS } from "../../Constant";

let initialState = {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false
};

export default function landingPageREducer(state = initialState, action) {
  switch (action.type) {
    case LANDING_PAGE_DETAILS_LOADING:
      return { ...state, loading: true, error: false, errors: [], success: false };

    case LANDING_PAGE_DETAILS_SUCCESS:
      return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

    case LANDING_PAGE_DETAILS_ERROR:
      return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

    // case RESET_ADD_PROFILE:
    //   return { ...state, loading: false, error: false, success: false };

    default:
      return state;
  }
}
