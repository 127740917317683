import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import QrCodeGenrate from '../../components/QrCodeGenrate/QrCodeGenrate'
import Spinner from '../../components/Spinner/Spinner'
import { addToContact, invitation } from '../../services/Action/Contact/contactAction'
import { getPublicLandingPage } from '../../services/Action/PublicAction/PublicAction'
import { RESET_ADD_TO_CONTACT, RESET_INVITATION, socialMediaIcons } from '../../services/Constant'
import Toaster from '../../Toaster/Toaster'
import { addContactINFolder, addFolder, getFolder } from '../../services/Action/Folder/folderAction'
import { getContactNotification } from '../../services/Action/userAction'

function Landingpage(props) {
   const { username } = useParams()
   const { t } = useTranslation()
   const [status, setStatus] = useState(0)
   const [isOpen, setIsOpen] = useState(false)
   const [isAddtoFolderOpen, setIsAddtoFolderOpen] = useState(false)
   const userId = useSelector(state => state.userDetailsReducer.data)?.data?._id
   const [folderList, setFloderList] = useState([])
   const [addtoFolder, setAddtoFolder] = useState(null)
   const [isAddMore, setIsAddMore] = useState(false)
   const [folderName, setFolderName] = useState('')
   const [errorData, setErrorData] = useState(null)
   const userType = localStorage.getItem('usertype')
   const currenUserName = localStorage.getItem('username')
   const token = localStorage.getItem('token')
   const navigate = useNavigate()
   const location = useLocation()
   const notify = (msg) => toast.error(msg)
   const [loading, setLoading] = useState(true)
   const [userNotFound, setUserNotFound] = useState(true)
   const [isSendRequest, setIsSendRequest] = useState(false)

   useEffect(() => {
      if (currenUserName === username) {
         navigate(`/${username}/1`)
      }
   }, [currenUserName])

   useEffect(() => {
      props.isPublic(true)
   }, [])

   const [state, setState] = useState({
      profile_image: '',
      video: '',
      company_name_english: '',
      company_name_chinese: '',
      owner_name_english: '',
      owner_name_chinese: '',
      contact: '',
      address1: '',
      address2: '',
      address3: '',
      socialId: {}
   })

   const [socialIcon, setSocialIcon] = useState([])

   useEffect(() => {
      props.getPublicLandingPage(username)
   }, [username])
   useEffect(() => {
      props.getPublicLandingPage(username)
   }, [])
   const [theme, setTheme] = useState({
      theme: '',
      font: ''
   })

   useEffect(() => {
      setLoading(props.PublicLandingPage.loading)
      if (props.PublicLandingPage.success) {
         const user = props.PublicLandingPage.data.data[0]
         const details = props.PublicLandingPage.data.data[0]?.userDoc.sort((a, b) => a.company_order - b.company_order)[0]
         setState(prevState => ({
            ...prevState,
            user_id: details?.user_id,
            company_name_english: details?.company_name_english,
            company_name_chinese: details?.company_name_chinese,
            profile_image: user?.profile_image,
            video: user?.video,
            owner_name_english: user?.owner_name_english,
            owner_name_chinese: user?.owner_name_chinese,
            address1: user?.address1,
            address2: user?.address2,
            address3: user?.address3,
            contact: user?.contact,
            socialId: {
               Telegram: user?.telegramId,
               Facebook: user?.Facebook,
               Instagram: user?.Instagram,
               SnapChat: user?.SnapChat,
               Line: user?.Line,
               Skype: user?.Skype,
               Twitter: user?.Twitter,
               WeChat: user?.WeChat,
               WhatsApp: user?.WhatsApp,
               Youtube: user?.Youtube,
               Linkedin: user?.Linkedin,
               Email: user?.email,
               TikTok: user?.TikTok
            }
         }))
         setUserNotFound(false)
      }

      if (props.PublicLandingPage.error) {
         // notify(props.PublicLandingPage.errors)
         setUserNotFound(true)
      }

   }, [props.PublicLandingPage])

   useEffect(() => {
      if (props.PublicLandingPage.success) {
         const _theme = props.PublicLandingPage.data.data[0].theme
         if (_theme !== null && _theme !== undefined) {
            setTheme(prevState => ({
               ...prevState,
               font: _theme.fontcolor === null ? '' : _theme.fontcolor,
               theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
            }))
         }
      }

   }, [props.PublicLandingPage])


   useEffect(() => {
      const list = { ...state.socialId }
      delete list['Telegram']
      delete list['WhatsApp']
      const filterList = []

      Object.values(list).map((value, index) => value && value !== '' && filterList.push(Object.keys(list)[index]))

      const groupList = []
      filterList.map((list, index) => {
         if (index % 5 == 0) {
            const list = filterList.slice(index, index + 5)
            groupList.push(list)
         }
      })
      setSocialIcon([...groupList])

   }, [state.socialId])

   const redirectUrl = (str) => {
      if (str !== '' && str !== undefined) {
         if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
            window.open(str, "_blank")
         } else {
            window.open(`https://${str}`, '_blank')
         }
      } else {
         return
      }
   }

   const handleAddtoContact = () => {
      if (token === null) {
         navigate('/login', { state: { addToContact: location.pathname } })
      } else {
         props.addToContact({
            contact_id: state.user_id
         })
      }
   }

   useEffect(() => {
      if (props.addToContactReducer.success) {
         setIsSendRequest(true)
         setTimeout(() => {
            navigate('/')
            setIsSendRequest(false)
            props.resetAddContact()
         }, 3000)
      }
      if (props.addToContactReducer.error) {
         if (!!props.addToContactReducer.errorData) {
            setErrorData(props.addToContactReducer.errorData)
            setIsOpen(true)
         } else {
            notify(props.addToContactReducer.errors)
         }
         props.resetAddContact()
      }

   }, [props.addToContactReducer])

   const CopyContact = async (value) => {
      if (value !== '') {
         await navigator.clipboard.writeText(value).then(() => {
            toast.success('Copied Contact no.')
         })
      }
   }

   useEffect(() => {

      setLoading(props.getFolderReducer?.getFolder.loading)
      if (props.getFolderReducer?.getFolder.success) {
         const list = props.getFolderReducer.getFolder.data.data
         setFloderList([...list])
         setIsOpen(false)
      }
   }, [props.getFolderReducer])

   const handleApprove = (status) => {
      props.invitation({
         status: status,
         id: errorData._id
      })
      setStatus(status)

   }

   useEffect(() => {
      if (props.invitationReducer.success) {
         if (parseInt(status) === 1) {
            props.getFolder()
            setIsAddtoFolderOpen(true)
            props.getContactNotification(userId)
         } else {
            setIsOpen(false)
            setIsAddtoFolderOpen(false)
         }
         props.resetInvitation()
      }

   }, [props.invitationReducer])

   const hadleContactAddToFolder = () => {
      if (!addtoFolder) {
         notify("Select Folder")
      }
      if (!!addtoFolder && !!errorData.contact_id) {
         props.addContactINFolder({
            contact_id: errorData.contact_id,
            folder_id: addtoFolder
         })
      }

   }

   useEffect(() => {
      if (props.addContactInFolderReducer.success) {
         setIsAddtoFolderOpen(false)
         setIsAddMore(false)
         setAddtoFolder(false)
         setFolderName('')
         setErrorData(null)
      }

   }, [props.addContactInFolderReducer])

   const handleAddFolder = () => {
      if (folderName !== '') {
         props.addFolder({ FolderName: folderName })
         setIsAddMore(false)
         setFolderName('')
      }
   }

   useEffect(() => {
      if (props.addFolderReducer.success) {
         setIsAddMore(false)
         setFolderName('')
         props.getFolder()
      }
   }, [props.addFolderReducer])

   return (
      <React.Fragment>
         {/* <Helmet> */}
            {/* <title>{`${state.company_name_english} | ${state.company_name_chinese} \n ${state.owner_name_english} | ${state.owner_name_chinese}`}</title>
            <meta property="og:title" content={`${state.company_name_english} | ${state.company_name_chinese} \n ${state.owner_name_english} | ${state.owner_name_chinese}`} />

            <meta property="og:url" content={`https://addmy.co/${username}`} />
            <meta property="og:description" content={`${state.company_name_english} | ${state.company_name_chinese} \n ${state.owner_name_english} | ${state.owner_name_chinese}`} />
            <meta property="og:description" content={`${state.company_name_english} | ${state.company_name_chinese} \n ${state.owner_name_english} | ${state.owner_name_chinese}`} />
            {
               state.profile_image !== '' ? <meta property="og:image" content={state.profile_image} /> : state.video !== '' ?
                  <meta property="og:image" content={state.video} /> :
                  <meta property="og:image" content={state.profile_image} />
            }
            <meta property="og:video:width" content="350" />
            <meta property="og:video:height" content="200" />
            <meta property="og:type" content="video.movie" /> */}
         {/* </Helmet> */}
         {
            loading ? <Spinner /> :
               <>
                  {userNotFound ?
                     <div className='p-5 text-center bg-white'>
                        <h4> {t('userNotFound')} </h4>
                     </div> :
                     <div className="">
                        <div className="tags mt-2">
                           <div className='preview-badge mb-2 w-100 scrollable-field' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff',
                              background: theme.theme !== '' ? theme.theme : '#007CB6',
                              fontWeight: 'bold',
                              fontSize: '1.2rem'
                           }}>{state.company_name_english}</div>
                           <div className='preview-badge mb-2 w-100 scrollable-field' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff',
                              background: theme.theme !== '' ? theme.theme : '#007CB6',
                              fontWeight: 'bold',
                              fontSize: '1.2rem'
                           }}>{state.company_name_chinese}</div>

                           <div className="great text-center mt-2 profile-box" >
                              {/* <img src={state.profile_image} className="img-fluid profile-icon" /> */}
                              {
                                 state.profile_image !== '' ? <img src={state.profile_image} className="img-fluid profile-icon" style={{ borderRadius: '50%' }} /> : state.video !== '' ?
                                    <video controls autoPlay muted loop playsinline style={{ borderRadius: '50%', objectFit: 'fill' }} src={state.video} ><source id="profileVideo" alt="Personal Profile Video" /></video> :
                                    <img src={'/images/loginPage/image.png'} className="img-fluid profile-icon" style={{ borderRadius: '50%' }} />
                              }
                              {/* <img src={'/images/loginPage/image.png'} className="img-fluid profile-icon" /> */}
                           </div>

                           <div className="row justify-content-center mt-2">
                              <div className="col-7">
                                 <div className="input-group mb-2">
                                    <div className='preview-badge w-100' style={{
                                       color: theme.font !== '' ? theme.font : '#ffffff',
                                       background: theme.theme !== '' ? theme.theme : '#007CB6',
                                       fontWeight: 'bold',
                                       fontSize: '1.2rem'
                                    }}>{state.owner_name_english}</div>
                                 </div>
                                 <div className="input-group mb-2">
                                    <div className='preview-badge w-100' style={{
                                       color: theme.font !== '' ? theme.font : '#ffffff',
                                       background: theme.theme !== '' ? theme.theme : '#007CB6',
                                       fontWeight: 'bold',
                                       fontSize: '1.2rem'
                                    }}>{state.owner_name_chinese}</div>
                                 </div>
                              </div>
                           </div>

                           <div className="row justify-content-center my-2">
                              <div className="col-lg-12 col-md-8 col-12">
                                 <div className="your_list text-center ">
                                    <div id="carouselExample" className="carousel slide socialIcon-carousel" >
                                       <div className="carousel-inner">
                                          <div className="carousel-item active social-icon">
                                             <a onClick={() => navigate(`/${username}/2`)} style={{
                                                background: theme.theme !== '' ? theme.theme : '#007CB6'
                                             }}><img src='/images/company.svg' className='img-fluid' alt='#' /></a>
                                             <a href="#" onClick={() => redirectUrl(state.socialId.WhatsApp)} style={{
                                                background: theme.theme !== '' ? theme.theme : '#007CB6'
                                             }}><i className="fa-brands fa-whatsapp"></i></a>
                                             <a href="#" onClick={() => redirectUrl(`https://t.me/${state.socialId.Telegram}`)} style={{
                                                background: theme.theme !== '' ? theme.theme : '#007CB6'
                                             }}><i className="fa-brands fa-telegram"></i></a>
                                             <a href={`tel:${state.contact}`} style={{
                                                background: theme.theme !== '' ? theme.theme : '#007CB6'
                                             }} onClick={() => { return CopyContact(state.contact) }}><i className="fa-solid fa-phone"></i></a>
                                             <a onClick={() => navigate(`/${username}/3`)} style={{
                                                background: theme.theme !== '' ? theme.theme : '#007CB6'
                                             }}><img src='/images/chamber.svg' className='img-fluid' alt='#' /></a>

                                          </div>
                                          {
                                             socialIcon?.map((array, index) => {
                                                return (
                                                   <div key={index} className="carousel-item social-icon">
                                                      {
                                                         array.map((e, i) => {
                                                            return (
                                                               <a href="#" style={{
                                                                  background: theme.theme !== '' ? theme.theme : '#007CB6'
                                                               }} key={i} onClick={() => redirectUrl(e === 'Email' ?
                                                                  `https://mail.google.com/mail/u/0/?fs=1&to=${state.socialId[e]}&tf=cm`
                                                                  : state.socialId[e])}><i className={socialMediaIcons[e]}></i></a>
                                                            )
                                                         })
                                                      }
                                                   </div>)
                                             })
                                          }
                                       </div>
                                       {socialIcon.length > 0 && <button className="carousel-control-prev" type="button" style={{ justifyContent: 'start' }} data-bs-target="#carouselExample" data-bs-slide="prev">
                                          <div className='text-black'><img src='/images/left-arrow.png' width='15px' height='30px' /></div>
                                       </button>}
                                       {socialIcon.length > 0 && <button className="carousel-control-next" type="button" style={{ justifyContent: 'end' }} data-bs-target="#carouselExample" data-bs-slide="next">
                                          <div className='text-black'><img src='/images/right-arrow.png' width='15px' height='30px' /></div>
                                       </button>}
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="row justify-content-center d-none d-md-flex">
                              <div className="col-lg-10 col-md-8 col-12">
                                 <div className="never_in text-center p-2 preview-area profile-area form-control border-radius-0" style={{
                                    background: '#ffffff',
                                    border: '0',
                                    // color: theme.font !== '' ? theme.font : '#4434a1',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                 }}>
                                    <p>{state.address1}<br /> {state.address2} <br /> {state.address3}</p>
                                 </div>
                              </div>
                           </div>

                           <div className="row justify-content-center mt-2 align-items-center position-relative">
                              <div className="col-auto px-0 d-flex">
                                 <div className='qr-container  '>
                                    <QrCodeGenrate url={`https://addmy.co/${username}`} />
                                 </div>
                              </div>
                              <div className="col-auto d-flex addtoContact">
                                 <div className="for text-center d-grid" onClick={handleAddtoContact}>
                                    <img src="/images/Asset23@3x.png" className="pointer" width="55px" alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>}
               </>
         }
         <Toaster />
         <Modal
            show={isSendRequest}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => {
               setIsSendRequest(false)
            }}
         >
            <React.Fragment>
               <div className='py-5 px-3 text-center'>
                  <div>
                     <img src='/images/check.png' style={{ width: '60px' }} />
                  </div>
                  <div className='mt-3'>
                     <h6>
                        {t('sendRequest')}
                     </h6>
                     <h6 >
                        {t('afterAproveMsg')} {username}
                     </h6>
                  </div>
               </div>
            </React.Fragment>
         </Modal>


         <Modal
            show={isOpen}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => {
               setIsOpen(false)
               // setTag(null)
            }}
         >
            <React.Fragment>
               <div className='m-4 text-center'><h5>This user already added you in his contact list , do you want to add him in your contact as well?</h5></div>
               <div className='pt-2 px-5 pb-4 d-flex justify-content-center'>
                  <div className='me-2 border py-2 rounded text-white bg-green pointer text-center ' style={{ width: '150px' }}
                     onClick={() => handleApprove(1)}
                  >{t('Approve')}</div>

                  <div className='me-2 border py-2 rounded text-white bg-red pointer text-center ' style={{ width: '150px' }}
                     onClick={() => handleApprove(2)}
                  >{t('Reject')}</div>
               </div>
               <Toaster />
            </React.Fragment>
         </Modal>

         <Modal
            show={isAddtoFolderOpen}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => {
               setIsAddtoFolderOpen(false)
               // setTag(null)
            }}
         >
            <React.Fragment>
               <Modal.Header><h4>In which folder you want to add this contact</h4></Modal.Header>
               <div className='pt-2 px-5 pb-5'>
                  <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                     {
                        folderList.map((e, i) => {
                           return (
                              <div key={i} className={'d-flex justify-content-between align-items-center py-2 border-bottom'}>
                                 <div>{e.Folder}</div>
                                 <div><input type={'checkbox'} className='pointer' value={e._id} checked={addtoFolder === e._id} onChange={() => setAddtoFolder(e._id)} /></div>
                              </div>
                           )
                        })
                     }
                  </div>
                  {userType !== '0' &&
                     <div className='me-2 mt-2 border py-1 rounded text-white bg-red pointer text-center ' style={{ minWidth: '150px' }}
                        onClick={() => setIsAddMore(true)}>+ {t('addMoreFolder')}</div>}
                  <div>

                  </div>
                  <div className='d-flex justify-content-center mt-4'>
                     <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                        setIsAddtoFolderOpen(false)
                        setAddtoFolder(null)
                     }}>{t('Cancel')}</div>
                     <div className='border rounded mx-4 px-4 py-2 pointer' onClick={hadleContactAddToFolder}>{t('addtoFolder')}</div>
                  </div>
               </div>
               <Toaster />
            </React.Fragment>
         </Modal>

         <Modal
            show={isAddMore}
            aria-labelledby="contained-modal-title-vcenter"
            centered
         // onHide={() => setIsAddMore(false)}
         >
            <React.Fragment>
               <div className='p-5'>
                  <div className='m-auto'>
                     <input className='form-control' type="text" name="addFolder" value={folderName} placeholder={`${t('enterFolderName')}`}
                        onChange={(e) => {
                           setFolderName(e.target.value)
                        }} />
                  </div>
                  <div className='d-flex justify-content-center mt-4'>
                     <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                        setIsAddMore(false)
                        setFolderName('')
                     }}>{t('Cancel')}</div>
                     <div className='border rounded mx-4 px-4 py-2 pointer' onClick={handleAddFolder}>{t('create')}</div>
                  </div>
               </div>
            </React.Fragment>
         </Modal>

      </React.Fragment>
   )
}

const mapStateToProp = state => ({
   PublicLandingPage: state.PublicPageReducer.getLandingPage,
   addToContactReducer: state.contactReducer.addToContact,
   getFolderReducer: state.folderReducer,
   addContactInFolderReducer: state.folderReducer.addContactInFolder,
   addFolderReducer: state.folderReducer.addFolder,
   invitationReducer: state.contactReducer.invitation,
})

const mapDispatchToProps = dispatch => ({
   getPublicLandingPage: (username) => dispatch(getPublicLandingPage(username)),
   getContactNotification: (user_id) => dispatch(getContactNotification(user_id)),
   addToContact: (details) => dispatch(addToContact(details)),

   getFolder: () => dispatch(getFolder()),
   addContactINFolder: (details) => dispatch(addContactINFolder(details)),

   invitation: (details) => dispatch(invitation(details)),
   resetInvitation: () => dispatch({ type: RESET_INVITATION }),

   addFolder: (folder) => dispatch(addFolder(folder)),
   resetAddContact: () => dispatch({ type: RESET_ADD_TO_CONTACT }),
})

export default connect(mapStateToProp, mapDispatchToProps)(Landingpage)