import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setBackground } from '../../services/Action/Theme/themeAction'
import { RESET_GET_BACKGROUND, RESET_SET_BACKGROUND } from '../../services/Constant'

function ThemePage(props) {

 const { t } = useTranslation()
 const [fontColor, setFontColor] = useState('#000000')
 const [themeColor, setThemeColor] = useState('#00A3CC')
 const navigate = useNavigate()
 const handleSaveTheme = () => {
  props.setBackground({
   fontcolor: fontColor,
   bgcolor: themeColor
  })
 }
 useEffect(() => {
  if (props.backGroundReducer.success) {
   const _theme = props.backGroundReducer?.data?.data
   if (_theme !== null) {
    setFontColor(_theme.fontcolor)
    setThemeColor(_theme.backgroundcolor)
   }
  }
 }, [props.backGroundReducer])

 useEffect(() => {
  if (props.chngeTheme.success) {
   navigate('/')
   window.location.reload()
   props.resetTheme()
  }

 }, [props.chngeTheme])

 return (
  <div className='row justify-content-center'>
   <div className='col-12 col-md-10 mt-3'>
    <div className='d-flex justify-content-between align-items-center bg-white border py-2'>
     <div className='mx-4'><h5 >{t('fontaColor')}</h5></div>
     <div className='mx-4'>
      <input type="color" name="" value={fontColor} onChange={(e) => setFontColor(e.target.value)} />
     </div>
    </div>
   </div>
   <div className='col-12 col-md-10 mt-3'>
    <div className='d-flex justify-content-between align-items-center bg-white border py-2'>
     <div className='mx-4'><h5 >{t('themeColor')}</h5></div>
     <div className='mx-4'>
      <input type="color" name="" value={themeColor} onChange={(e) => setThemeColor(e.target.value)} />
     </div>
    </div>
   </div>
   <div className='col-12 col-md-12 mt-3'>
    <div className='d-flex justify-content-center align-items-center py-2'>
     <div className='border rounded py-2 px-4 pointer bg-red text-white' onClick={handleSaveTheme}>{t('Save')}</div>
    </div>
   </div>
  </div>
 )
}

const mapStateToProp = state => ({
 chngeTheme: state.themeReducer.setThemeAndBackground,
 backGroundReducer: state.themeReducer.getBackGround,
 // companyDetailsReducer: state.companyDetailsReducer,
 // userDetailsReducer: state.userDetailsReducer,
 // updateCompanyReducer: state.updateCompanyReducer,
})

const mapDispatchToProps = dispatch => ({
 setBackground: details => dispatch(setBackground(details)),
 // updateCompanyDetails: details => dispatch(updateCompany(details)),
 resetTheme: () => dispatch({ type: RESET_SET_BACKGROUND }),
 resetGetBackground: () => dispatch({ type: RESET_GET_BACKGROUND }),

})

export default connect(mapStateToProp, mapDispatchToProps)(ThemePage)