import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteNotification, getImessage } from '../../services/Action/userAction'

function IPage(props) {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })

  useEffect(() => {
    props.getImessage()
  }, [])

  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [props.backGroundReducer])
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [])


  useEffect(() => {
    setLoading(props.getImessageReducer.loading)
    if (props.getImessageReducer.success) {
      setData(props.getImessageReducer.data)
    }

  }, [props.getImessageReducer])


  useEffect(() => {
    if (props.deleteNotificationReducer.success) {
      props.getImessage()
    }
  }, [props.deleteNotificationReducer])



  return (
    <React.Fragment>
      {
        (loading) ? <Spinner /> :
          <>
            {data.length === 0 ? <>
              <div className='p-5 text-center' style={{
                color: '#000000',
                backgroundColor: '#ffffff'
              }}>
                <h6> {t('noNotification')} </h6>
              </div>
            </> : <div>
              {
                data.map((e, index) => {

                  return (
                    <div key={index} className='m-2'>
                      <div className='row border p-2' style={{
                        color: '#000000',
                        backgroundColor: '#ffffff'
                      }}>
                        <div className='d-flex justify-content-between align-items-center px-1'>
                          <h5 className='mb-0' onClick={() => navigate(`/${e.userDoc[0].username}`)}><b className='pointer' >{e.userDoc[0].owner_name_english}</b></h5>
                          <div onClick={() => {
                            props.deleteNotification(e._id)
                          }}>
                            <button className='btn close-btn'><i class="fa-solid fa-xmark"></i></button></div>
                        </div>
                        <div className=' px-1'>
                          {e.message}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>}
          </>
      }
    </React.Fragment>
  )
}


const mapStateToProp = state => ({
  getImessageReducer: state.getImessageReducer,
  backGroundReducer: state.themeReducer.getBackGround,
  deleteNotificationReducer: state.NotificationReducer,

  // notificationReducer: state.notificationReducer,
  // invitationReducer: state.contactReducer.invitation,
})

const mapDispatchToProps = dispatch => ({
  getImessage: () => dispatch(getImessage()),
  deleteNotification: (id) => dispatch(deleteNotification(id)),
  // getContactList: (user_id) => dispatch(getContactList(user_id)),
  // invitation: (details) => dispatch(invitation(details)),
  // resetInvitation: () => dispatch({type: RESET_INVITATION}),
})

export default connect(mapStateToProp, mapDispatchToProps)(IPage)  