import classNames from 'classnames'
import moment from 'moment/moment'
import React, { startTransition, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMemberShipTenure } from '../../services/Action/MemberShip/memberShipAction'
import { RESET_PAYPAL_PAYMENT, RESET_TENURE, RESET_TONCOIN_PAYMENT } from '../../services/Constant'
import { getPAymentOptions } from '../../services/Action/Payment/PaymentAction'

function Membership_page(props) {

 const { t } = useTranslation()

 const userType = localStorage.getItem("usertype")
 const paymentstatus = localStorage.getItem("paymentstatus")

 const userDetails = props.userDetailsReducer?.data?.data

 const navigate = useNavigate()

 const [activeYear, setActiveYear] = useState('index-0')
 const [selectedPeriod, setSelectedPeriod] = useState(null)

 const [paymentOption, setPaymentOption] = useState('')
 const [isTenure, setIsTenure] = useState(false)

 const [tenureData, setTenureData] = useState(null)

 const [isDonater, setIsDonater] = useState(false)


 const [theme, setTheme] = useState({
  theme: '',
  font: ''
 })


 useEffect(() => {
  if (localStorage.getItem('usertype') === '2') {
   setIsDonater(true)
  }
 }, [localStorage])


 useEffect(() => {
  if (props.getPaymentOptionsReducer.success) {
   const data = props.getPaymentOptionsReducer.data
   console.log("dataata", data);
   setPaymentOption(`${data.data[0]?.payment_id}`)
  }
 }, [props.getPaymentOptionsReducer])

 useEffect(() => {
  if (props.memberShipTenureReduce.success) {
   setTenureData(props.memberShipTenureReduce.tenureData.data)
   setSelectedPeriod(props.memberShipTenureReduce.tenureData.data.sort((a, b) => a?.membershiperiod - b?.membershiperiod)[0]?._id)
   setIsTenure(true)
   props.resetTenure()
  }
 }, [props.memberShipTenureReduce])


 useEffect(() => {
  if (props.backGroundReducer.success) {
   const _theme = props.backGroundReducer?.data?.data
   if (_theme !== null) {
    setTheme(prevState => ({
     ...prevState,
     font: _theme.fontcolor === null ? '' : _theme.fontcolor,
     theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
    }))
   }

  }
 }, [props.backGroundReducer])

 useEffect(() => {
  if (props.backGroundReducer.success) {
   const _theme = props.backGroundReducer?.data?.data
   if (_theme !== null) {
    setTheme(prevState => ({
     ...prevState,
     font: _theme.fontcolor === null ? '' : _theme.fontcolor,
     theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
    }))
   }
  }

 }, [])



 useEffect(() => {
  if (props.userDetailsReducer.success) {
   const paymentstatus = props.userDetailsReducer.data.data.paymentstatus
   localStorage.setItem("paymentstatus", paymentstatus)
  }

 }, [props.userDetailsReducer])

 useEffect(() => {
  if (props.paymentReducer.paypalPayment.success || props.paymentReducer.toncoinPayment.success) {
   window.location.reload(false)
   // props.resetPaypalPayment()
   // props.resetToncoinPayment()
  }
 }, [props.paymentReducer])

 const handleBussinessPlan = () => {
  props.getMemberShipTenure()
  props.getPAymentOptions()

 }

 return (
  <div className="">
   <h1 className="text-center my-2" style={{
    color: '#ffffff',
    background: '#00A3CC'
   }}>{t('upgradeYourMemberShip')}</h1>
   <div className='row'>

    {/* Bussiness Member */}
    <div className='col col-lg-12 mt-3'>
     <div className="card">
      <div className="card-body" style={{ minHeight: '525px' }}>
       <div className="row">
        <div className="col-12">
         <table className="table">
          <thead>
           <tr>
            <td colSpan={'2'}>
             <h5 className="p-2 text-center border rounded" style={{
              color: '#ffffff',
              backgroundColor: '#00A3CC'
             }}>{t('businessMembership')}</h5>
            </td>
           </tr>
          </thead>
          <tbody>
           <tr>
            <td>{t('coustomUrl')}</td>
            <td>{t('yes')}</td>
           </tr>
           <tr>
            <td>{t('contactList')}</td>
            <td>{t('Unlimited')}</td>
           </tr>
           {/* <tr>
            <td>{t('tags')}</td>
            <td>3 {t('tags')}</td>
           </tr> */}
           <tr>
            <td>{t('folder')}</td>
            <td>{t('Unlimited')}</td>
           </tr>
           <tr>
            <td>{t('profileImage')}</td>
            <td>{t('imageOrVideo')}</td>
           </tr>
           <tr>
            <td>{t('backGroundImage')}</td>
            <td>{t('yes')}</td>
           </tr>
           <tr>
            <td>{t('subsidiaryCompany')}</td>
            <td>{t('Unlimited')}</td>
           </tr>
           <tr>
            <td>{t('Chamber')}</td>
            <td>{t('Unlimited')}</td>
           </tr>
          </tbody>
         </table>
         <div className="into_link">
          <div className={classNames("text-center border   py-1 w-100 m-0 pointer")}
           style={{
            color: '#ffffff',
            backgroundColor: '#00A3CC'
           }}
           onClick={paymentstatus !== '0' ? handleBussinessPlan : () => { }}>
           {(userType === '1' || userType === '2') ? `${t('renewMemberShip')}` : `${t('UpgradeMembership')}`}
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   {paymentstatus === '0' && <div className='col text-center mt-4 py-2 h6' style={{ background: '#d3edfa' }}>
    {t('underReview')}
   </div>}
   {paymentstatus === '2' && <div className='col text-center text-danger mt-4 py-2 h6' style={{ background: '#d3edfa' }}>
    {t('rejectedMsg')}
   </div>}

   {
    // enddate
    userDetails?.enddate &&
    <div className='text-center my-3 bg-white'>
     <p style={{
      fontSize: '20px',
      color: '#D50078'
     }}>{t('expiringMembership')} : <span><b>{moment(userDetails?.enddate).format('DD-MM-yyyy')}</b></span></p>
    </div>
   }
   <hr />

   {isTenure && <div className='bg-white py-3'>

    <div className='mt-4 d-flex justify-content-center '>
     <div className='d-flex flex-column'>
      <div className='fs-5 text-center' style={{ color: theme.font !== '' && theme.font }}>
       {t('selectTenure')}
      </div>
      <hr className='my-2' />
      <div className='mt-2 row justify-content-center mb-4'>

       {
        tenureData && tenureData.sort((a, b) => a.membershiperiod - b.membershiperiod).map((e, i) => {
         return (
          <div className=' m-2 border p-2 rounded pointer text-center col-7 col-sm-3 mb-1' style={{
           border: `1px solid ${(!!theme.theme && theme.theme !== '') ? theme.theme : '#007CB6'}`,
           minWidth: '110px',
           color: activeYear === `index-${i}` ? theme.font !== '' ? theme.font : '#ffffff' : '#000000',
           backgroundColor: activeYear === `index-${i}` ? (!!theme.theme && theme.theme !== '') ? theme.theme : '#007CB6' : 'transparent',
          }}
           onClick={() => { setActiveYear(`index-${i}`); setSelectedPeriod(e._id) }}
          >{`${e.membershiperiod} ${t('year')}`}</div>
         )
        })
       }
      </div>
     </div>
    </div>
   </div>}
   {isTenure &&

    <div className='text-center my-3 bg-white'>
     <p style={{
      fontSize: '20px',
      color: '#D50078'
     }}>{t('selectPaymentMethod')}</p>
    </div>

   }
   {isTenure && <div className='mt-4 d-flex justify-content-center bg-white'>
    <div className='d-flex flex-column'>
     <div className='mt-2 row justify-content-center mb-4'>

      {
       (paymentOption === '1' || paymentOption === '3') && <div className=' mt-2 me-2 border py-1 rounded text-white bg-red pointer text-center col-7 col-sm-5' style={{ minWidth: '110px' }} onClick={() => navigate(`/payment-details/${selectedPeriod}/1`)}>Paypal</div>
      }
      {
       (paymentOption === '2' || paymentOption === '3') && <div className=' mt-2 me-2 border py-1 rounded text-white bg-red pointer text-center col-7 col-sm-5' style={{ minWidth: '110px' }} onClick={() => navigate(`/payment-details/${selectedPeriod}/2`)}>Stripe</div>
      }
     </div>
    </div>
   </div>}

   <Modal
    show={isDonater}
    aria-labelledby="contained-modal-title-vcenter"
    centered

   >
    <React.Fragment>
     <div className='p-5'>
      <div className='text-center' style={{ fontSize: '20px', fontWeight: '600' }}>
       “Thanks for being a donator.
       Being a donator you can enjoy premium member benefits without upgrading your membership”
      </div>
      <div className='mt-4 text-center'>
       <span className='py-2 px-5 rounded bg-red text-white pointer' onClick={() => {
        setIsDonater(false)
        navigate(-1)
       }}>OK</span>
      </div>
     </div>

    </React.Fragment>
   </Modal>
  </div>
 )
}

const mapStateToProp = state => ({
 userDetailsReducer: state.userDetailsReducer,
 memberShipTenureReduce: state.memberShipTenureReduce,
 backGroundReducer: state.themeReducer.getBackGround,
 paymentReducer: state.paymentReducer,
 getPaymentOptionsReducer: state.paymentReducer.getPaymentOptions

})

const mapDispatchToProps = dispatch => ({
 // updateProfile: user => dispatch(updateProfile(user)),
 // getUserDetails: () => dispatch(getUserDetails()),
 // resetUserDetails: () => dispatch({ type: RESET_DETAILS })
 getMemberShipTenure: () => dispatch(getMemberShipTenure()),
 getPAymentOptions: () => dispatch(getPAymentOptions()),
 resetTenure: () => dispatch({ type: RESET_TENURE }),
 resetPaypalPayment: () => dispatch({ type: RESET_PAYPAL_PAYMENT }),
 resetToncoinPayment: () => dispatch({ type: RESET_TONCOIN_PAYMENT }),

})

export default connect(mapStateToProp, mapDispatchToProps)(Membership_page)
