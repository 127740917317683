import axios from 'axios'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { chamberVideo_Url, getVideo_Url } from '../../apiSheet'
import Spinner from '../../components/Spinner/Spinner'
import { deleteChamber, getChamberDetails, saveChamberDetails } from '../../services/Action/Chamber/ChamberAction'
import { RESET_DELETE_CHAMBER_DETAILS, RESET_SAVE_CHAMBER_DETAILS } from '../../services/Constant'
import Toaster from '../../Toaster/Toaster'
import $ from 'jquery'
import { MultiSelect } from 'react-multi-select-component'


function Chamber(props) {
  const { t } = useTranslation()
  const notify = (msg) => toast.error(msg)
  const userType = localStorage.getItem('usertype')
  const numberValidation = RegExp(/^[0-9]*$/)

  const location = useLocation()
  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })

  const [loadingMedia, setLoadingMedia] = useState(false)


  const navigate = useNavigate()

  const initialState = {
    chamber_name_english: '',
    chamber_name_chinese: '',
    chamberdesignation: '',
    detail: '',
    // tgchannel: '',
    chamberwebsite: '',
    // chamberfanpage: '',
    image: '',
    video: '',
    chamber_order: '',
    socialId: {
      telegramId: '',
      WhatsApp: '',
      SnapChat: '',
      Instagram: '',
      Linkedin: '',
      Youtube: '',
      Skype: '',
      Facebook: '',
      WeChat: '',
      Twitter: '',
      Line: ''
    },
  }

  const initialError = {
    chamber_name_english: false,
    chamber_name_chinese: false,
    chamberdesignation: false,
    chamberwebsite: false,
    chamber_order: false
  }


  const socialOptions = [
    { label: 'Instagram', value: 'Instagram' },
    { label: 'Youtube', value: 'Youtube' },
    { label: 'Facebook', value: 'Facebook' },
  ]

  const userName = useSelector(state => state.userDetailsReducer.data)?.data?.username

  // state
  const [selectedSocialMedia, setSelectedSocialMedia] = useState(socialOptions)
  const [listSocialIds, setListSocialIds] = useState([])
  const [state, setState] = useState([initialState])
  const [errors, setErrors] = useState([initialError])
  const [index, setIndex] = useState(0)
  const [mediaType, setMediaType] = useState([])

  // useeffect

  useEffect(() => {
    props.getChamberDetails()
  }, [])

  useEffect(() => {
    if (props.chamber_details.success) {
      if (props.chamber_details.data.data.length !== 0) {
        const _data = props.chamber_details.data.data
        const details = _data.sort((a, b) => a.chamber_order - b.chamber_order).map((e, i) => {
          const socialID = {
            Instagram: e.Instagram ?? '',
            Facebook: e.Facebook ?? '',
            Youtube: e.Youtube ?? '',
            telegramId: e.tgchannel ?? ''
          }

          const socialList = { ...socialID }
          Object.keys(socialList).map((list) => (socialList[list] === '' || socialList[list] === undefined || socialList[list] === null) && delete socialList[list])
          const list = Object.keys(socialList).map(list => {
            return socialOptions.find(option => option.value === list)
          })
          listSocialIds[i] = list
          return {
            _id: e._id,
            chamber_name_english: e.chamber_name_english,
            chamber_name_chinese: e.chamber_name_chinese,
            chamberdesignation: e.chamberdesignation,
            detail: e.detail,
            chamberwebsite: e.chamberwebsite,
            // chamberfanpage: e.chamberfanpage,
            image: e.image,
            video: e.video,
            chamber_order: e.chamber_order,
            socialId: {
              ...socialID
            },
          }
        })
        const errorList = props.chamber_details.data.data.map(e => {
          return initialError
        })

        if (!!location.state) {
          if (location.state.addMore) {
            setState([...details, { ...initialState }])
            setListSocialIds([...listSocialIds, []])
            setErrors([...errorList, { ...initialError }])
            setIndex([...details, { ...initialState }].length - 1)
          }

        } else {
          setState([...details])
          setErrors([...errorList])
        }

      }
    }

  }, [props.chamberReducer])


  // useEffect(() => {
  //   if (listSocialIds.length !== 0) {
  //     const _list = listSocialIds[index]
  //     setSelectedSocialMedia(_list)
  //   }
  // }, [listSocialIds.length, index])

  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [props.backGroundReducer])
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [])

  useEffect(() => {
    document.getElementsByClassName('dropdown-heading')[0]?.setAttribute("id", "dropdown-heading")
    if (document.querySelectorAll('.dropdown-heading-value>.gray').length !== 0) {
      document.querySelectorAll('.dropdown-heading-value>.gray')[0].innerHTML = `${t('selectSocialMedia')}`
    }
  }, [$(".clear-selected-button").length, selectedSocialMedia])

  useEffect(() => {
    const type = state?.map((e, i) => {
      if (state[i].image === '') {
        if (state[i].video === '') {
          return '0'
        } else {
          return '1'
        }
      } else return "0"
    })
    setMediaType([...type])

  }, [state])

  useEffect(() => {
    if (props.save_chamber.success) {
      props.getChamberDetails()
      toast.success("Update Chamber Successfuly")
      setTimeout(() => {
        props.resetSaveChamber()
        navigate(`/${userName}/3`)
      }, 2000)
    }
  }, [props.save_chamber])

  // functions

  const handleChange = (e, index) => {
    const { name, value } = e.target
    const list = state.map((e, i) => {
      if (i === index) {
        if (name === 'chamber_order') {
          if (numberValidation.test(value)) {
            return { ...e, chamber_order: value }
          } else return { ...e, chamber_order: '0' }
        } else { return { ...e, [name]: value } }

      } else return e
    })
    setState([...list])

    switch (name) {
      case 'chamber_name_english':
        if (value === '') {
          const list = errors.map((e, i) => {
            if (i === index) {
              return { ...e, "chamber_name_english": "Enter Chamber name English" }
            } else return e
          })
          setErrors([...list])
        } else {
          const list = errors.map((e, i) => {
            if (i === index) {
              return { ...e, "chamber_name_english": false }
            } else return e
          })
          setErrors([...list])
        }
        break;

      case 'chamber_name_chinese':
        if (value === '') {
          const list = errors.map((e, i) => {
            if (i === index) {
              return { ...e, "chamber_name_chinese": "Enter Chamber name Chinese" }
            } else return e
          })
          setErrors([...list])
        } else {
          const list = errors.map((e, i) => {
            if (i === index) {
              return { ...e, "chamber_name_chinese": false }
            } else return e
          })
          setErrors([...list])
        }
        break;

      case 'chamberdesignation':
        if (value === '') {
          const list = errors.map((e, i) => {
            if (i === index) {
              return { ...e, "chamberdesignation": "Enter Chamber Designation" }
            } else return e
          })
          setErrors([...list])
        } else {
          const list = errors.map((e, i) => {
            if (i === index) {
              return { ...e, "chamberdesignation": false }
            } else return e
          })
          setErrors([...list])
        }
        break;

      case 'chamberwebsite':
        if (value === '') {
          const list = errors.map((e, i) => {
            if (i === index) {
              return { ...e, "chamberwebsite": "Enter Chamber Website" }
            } else return e
          })
          setErrors([...list])
        } else {
          const list = errors.map((e, i) => {
            if (i === index) {
              return { ...e, "chamberwebsite": false }
            } else return e
          })
          setErrors([...list])
        }
        break;

      case "chamber_order":
        if (value === '' || value === 0) {
          const list = errors.map((e, i) => {
            if (i === index) {
              return { ...e, "chamber_order": 'Enter order for chamber_order' }
            } else return e
          })
          setErrors([...list])
        } else {
          const OrderList = state.map(e => { return e.chamber_order.toString() })
          if (OrderList.includes(value)) {
            const list = errors.map((e, i) => {
              if (i === index) {
                return { ...e, "chamber_order": "This display order already assigned" }
              } else return e
            })
            notify(`${t('alredyOrderPresent')}`)
            setErrors([...list])
          } else if (parseInt(value) > state.length) {
            const list = errors.map((e, i) => {
              if (i === index) {
                return { ...e, "chamber_order": "This display order can not be grater than lenght of list" }
              } else return e
            })
            notify(`${t('orderGreaterthanLenght')}`)
            setErrors([...list])
          } else {
            const list = errors.map((e, i) => {
              if (i === index) {
                return { ...e, "chamber_order": false }
              } else return e
            })
            setErrors([...list])
          }

        }
        break;


      default: break;
    }
  }

  const hadleSocialValue = (e, index) => {
    const { name, value } = e.target
    const list = state.map((e, i) => {
      if (i === index) {
        return {
          ...e,
          socialId: {
            ...e.socialId,
            [name]: value
          }
        }
      } else return e
    })
    setState([...list])
  }

  const checkUrl = (str) => {
    if (str !== '') {
      if (str?.indexOf("http://") === 0 || str?.indexOf("https://") === 0) {
        return str
      } else {
        return "https://" + str
      }
    } else return ''

  }
  const handleDeleteChamber = () => {
    const id = state[index]?._id
    if (!!id) {
      props.deleteChamber(id)

    } else {
      state.splice(index, 1)
      setIndex(index - 1)
    }
    // props.deleteChamber(id)
  }

  useEffect(() => {
    if (props.delete_chamber.success) {
      if (index > 1) {
        setIndex(index - 1)
      } else {
        navigate(`/${userName}/3`)
      }
      props.getChamberDetails()
      props.resetDeleteChamber()
    }
  }, [props.delete_chamber])

  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const base64 = new FileReader()
    base64.readAsDataURL(file)
    base64.onload = () => {
      resolve(base64.result)
    }
    base64.onerror = (error) => { reject("error", error) }
  })

  const getDuration = (file) => {
    const url = URL.createObjectURL(file)
    return new Promise((resolve) => {
      const video = document.createElement('video')
      const source = document.createElement('source')
      video.preload = 'metadata'
      source.src = url
      video.appendChild(source)
      video.onloadedmetadata = () => {
        resolve(video.duration)
      }
    })
  }

  const getVideoUrl = (video) => {
    return new Promise((resolve, rejcet) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        url: `${chamberVideo_Url}`,
        data: {
          "video": video
        }
      };

      axios(requestOptions).then(res => {
        resolve(res.data)
      }).catch(error => {
        rejcet("error", error)
      })
    })
  }

  const handleMedia = async (event, index) => {
    const file = event.target.files[0]
    const type = event.target.files[0].type
    const mediaUrl = URL.createObjectURL(event.target.files[0])
    const baseFile = await convertBase64(event.target.files[0]).then(res => { return res })
    const list = state.map(async (e, i) => {
      if (i === index) {
        if (type.includes('image')) {
          // mediaType[index] = '0'
          return {
            ...e,
            image: mediaUrl,
            video: '',
            _image: baseFile,
            _video: ''
          }
        } else if (type.includes('video')) {
          const duration = await getDuration(file)
          setLoadingMedia(true)
          const video = await getVideoUrl(file).then((res) => {
            setLoadingMedia(false)
            return res
          })
          if (duration <= 60) {
            return {
              ...e,
              image: '',
              video: mediaUrl,
              _video: video.data[0].video,
              _image: ''
            }
          } else {
            notify(`${t('videoMaxSize')}`)
            return {
              ...e,
              image: '',
              video: '',
              _video: '',
              _image: ''
            }
          }
        }
      } else {
        return e
      }
    })
    const result = await Promise.all(list)
    setState([...result])
  }

  const removeMedia = (event, index) => {
    const list = state.map((e, i) => {
      if (i === index) {
        return {
          ...e,
          image: '',
          video: '',
          _image: '',
          _video: ''
        }
      } else {
        return e
      }
    })
    setState([...list])
  }

  const handleSubmitChamber = () => {
    state.find((e, i) => {
      if (e.chamber_name_english === '') {
        setIndex(i)
        const list = errors.map((e, i) => {
          if (i === index) {
            notify('Enter Chamber name English')
            return { ...e, "chamber_name_english": "Enter Chamber name English" }
          } else return e
        })
        setErrors([...list])
      } else if (e.chamber_name_chinese === '') {
        setIndex(i)
        const list = errors.map((e, i) => {
          if (i === index) {
            notify('Enter Chamber name Chinese')
            return { ...e, "chamber_name_chinese": "Enter Chamber name Chinese" }
          } else return e
        })
        setErrors([...list])
      } else if (e.chamberdesignation === '') {
        setIndex(i)
        const list = errors.map((e, i) => {
          if (i === index) {
            notify('Enter Chamber Designation')
            return { ...e, "chamberdesignation": "Enter Chamber Designation" }
          } else return e
        })
        setErrors([...list])
      } else if (e.chamberwebsite === '') {
        setIndex(i)
        const list = errors.map((e, i) => {
          if (i === index) {
            notify('Enter Chamber Website')
            return { ...e, "chamberwebsite": "Enter Chamber Website" }
          } else return e
        })
        setErrors([...list])
      } else if (e.chamber_order === 0 || e.chamber_order === '0' || e.chamber_order === '') {
        setIndex(i)
        const list = errors.map((e, j) => {
          if (j === i) {
            notify('Enter order for chamber_order')
            return { ...e, "chamber_order": 'Enter order for chamber_order' }
          } else return e
        })
        setErrors([...list])
      }
    })

    const isValid = state.map((e, i) => {
      if (
        e.chamber_name_english !== '' &&
        e.chamber_name_chinese !== '' &&
        e.chamberdesignation !== '' &&
        e.chamberwebsite !== '' &&
        e.chamber_order !== '' &&
        e.chamber_order !== '0' &&
        e.chamber_order !== 0 &&
        !errors[i].chamber_order
      ) {
        return true
      } else return false
    })

    if (isValid.every(e => e)) {
      const details = state.map(e => {
        const socialList = {
          Instagram: checkUrl(e.socialId.Instagram),
          Facebook: checkUrl(e.socialId.Facebook),
          Youtube: checkUrl(e.socialId.Youtube),

          // WhatsApp: checkUrl(e.socialId.WhatsApp),
          // WeChat: checkUrl(e.socialId.WeChat),
          // Line: checkUrl(e.socialId.Line),
          // Twitter: checkUrl(e.socialId.Twitter),
          // Linkedin: checkUrl(e.socialId.Linkedin),
          // SnapChat: checkUrl(e.socialId.SnapChat),
          // Skype: checkUrl(e.socialId.Skype),
        }

        // Object.keys(socialList).map((list) => (socialList[list] === '' || socialList[list] === undefined || socialList[list] === null) && delete socialList[list])

        return {
          ...(e._id && { _id: e._id }),
          chamber_name_english: e.chamber_name_english,
          chamber_name_chinese: e.chamber_name_chinese,
          chamberdesignation: e.chamberdesignation,
          detail: e.detail,
          tgchannel: e.socialId.telegramId,
          chamberwebsite: e.chamberwebsite,
          image: e._image && e._image !== '' && e._image,
          video: e._video && e._video !== '' && e._video,
          chamber_order: e.chamber_order,
          ...socialList

        }
      })
      props.saveChamberDetails({
        user_id: props.userDetailsReducer?.data?.data?._id,
        data: [...details]
      })
    }
    //  call update function
  }

  return (
    <React.Fragment>
      {!userName ? <Spinner /> : <div className="position-relative">
        {/*  left */}
        <div className='position-absolute pointer' style={{ left: '-5px', top: '0px' }} onClick={() => setIndex(index - 1)}>
          {state && state.length > 1 && index > 0 && <span>
            <img src='/images/left-arrow.png' width='20px' height='25px' />
          </span>}
        </div>
        {/* right */}
        <div className='position-absolute pointer' style={{ right: '-5px', top: '0px' }} onClick={() => setIndex(index + 1)}>
          {state && state.length > 1 && index < state.length - 1 && <span>
            <img src='/images/right-arrow.png' width='20px' height='25px' />
          </span>}
        </div>

        {/* middle */}
        <div className="">
          <div className=" mt-3 ">
            {state.length > 1 && <div className="text-end mb-2 me-5">
              <span className="bg-red py-2 px-5 pointer text-white rounded" onClick={() => handleDeleteChamber()}>{t('delete')}</span>
            </div>}
          </div>
          <h5 className='text-center page-title'>Enter your chamber detail</h5>
          <div className="row gx-2 mt-3">
            <div className="col-md-6">
              <input type="text" className={classNames("form-control mb-2", { "error": errors[index]?.chamber_name_english })}
                placeholder={`${t('chamberEnglishName')}`} name='chamber_name_english' value={state[index]?.chamber_name_english} onChange={(e) => handleChange(e, index)} />
              {
                errors[index]?.chamber_name_english && <div className='h6 text-danger ps-3'>{errors[index]?.chamber_name_english}</div>
              }
            </div>
            <div className="col-md-6">
              <input type="text" className={classNames("form-control mb-2", { "error": errors[index]?.chamber_name_chinese })}
                placeholder={`${t('chamberChienseName')}`} name='chamber_name_chinese' value={state[index]?.chamber_name_chinese} onChange={(e) => handleChange(e, index)} />
              {
                errors[index]?.chamber_name_chinese && <div className='h6 text-danger ps-3'>{errors[index]?.chamber_name_chinese}</div>
              }
            </div>
            <div className="col-12">
              <input type="text" className={classNames("form-control mb-2", { "error": errors[index]?.chamberdesignation })}
                placeholder={`${t('chmaberDesignation')}`} name='chamberdesignation' value={state[index]?.chamberdesignation} onChange={(e) => handleChange(e, index)} />
              {
                errors[index]?.chamberdesignation && <div className='h6 text-danger ps-3'>{errors[index]?.chamberdesignation}</div>
              }
            </div>
            <div className="col-12 text-center">
              <div className='profile-img'>
                <input type="file" className="input-file file-upload d-none" id="file-upload" name='profile_image' accept={userType !== '0' ? 'video/*, image/*' : 'image/*'} onChange={(e) => handleMedia(e, index)} />
                <label htmlFor='file-upload'>
                  {/* <img src={(state[index]??.image && state[index]?.image !== '') ? state[index]??.image : "./images/loginPage/image.png"} id="profileImage" alt="Personal Profile Image" /> */}

                  {
                    loadingMedia ? <div className='videoImage_Placeholder'><Spinner /> </div> :
                      <>
                        {
                          mediaType[index] === '0' ? <>
                            {
                              (state[index]?.image && state[index]?.image !== '') ? <img src={state[index]?.image} id="profileImage" alt="Personal Profile Image" style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: "0px",
                              }} /> : null
                            }</> : <>
                            {
                              (state[index]?.video && state[index]?.video !== '') ? <video controls autoPlay muted loop playsinline style={{ objectFit: 'fill' }} src={state[index]?.video}><source id="profileVideo" alt="Personal Profile Video" /></video> : null
                            }
                          </>
                        }
                        {
                          (!state[index]?.image && state[index]?.image === '' && !state[index]?.video && state[index]?.video === '') &&
                          <>
                            <div className=' my-2 pointer videoImage_Placeholder'>
                              <div className='text-center'>
                                <img src={'/images/loginPage/image.png'} className="img-fluid" />
                                <p className='mb-0'>Please upload </p>
                                <p className='mb-0'>640 width by 360 high Image</p>
                                <p className='mb-0'>or</p>
                                <p className='mb-0 text-yellow'>Premium Member Upload 1 Minute Video</p>
                              </div>
                            </div>
                          </>
                        }
                      </>
                  }
                </label>
              </div>
              <div className='my-2 d-flex justify-content-center'>
                <div className='mx-2'>
                  <label htmlFor='file-upload' style={{
                    color: theme.font !== '' ? theme.font : '#ffffff',
                    background: (!!theme.theme && theme.theme !== '') ? theme.theme : '#007CB6',
                  }} className=' py-1 px-3 pointer rounded'>
                    {t('Browse')}
                  </label>
                </div>
                <div className='mx-2 py-1 px-3 pointer rounded' style={{
                  color: theme.font !== '' ? theme.font : '#ffffff',
                  background: (!!theme.theme && theme.theme !== '') ? theme.theme : '#007CB6',
                }} onClick={(e) => removeMedia(e, index)}>{t('Cancel')}</div>
              </div>
            </div>
            <div className="col-12 mb-2">
              <textarea className="form-control compChamber-area" placeholder={`${t('chamberDetails')}`} id="floatingTextarea2" rows="5" name="detail" value={state[index]?.detail} onChange={(e) => handleChange(e, index)} />
            </div>
            <div className="col-md-6 col-12">
              <input type="text" className={classNames("form-control mb-2", { "error": errors[index]?.chamberwebsite })}
                placeholder={`${t('chamberWebsite')}`} name='chamberwebsite' value={state[index]?.chamberwebsite} onChange={(e) => handleChange(e, index)} />
              {/* {
                errors[index]?.chamberwebsite && <div className='h6 text-danger ps-3'>{errors[index]?.chamberwebsite}</div>
              } */}
            </div>
            <div className="col-md-6 col-12">
              <input type="text" className="form-control mb-2" placeholder={`https://t.me/Telegram Id`} name='telegramId' value={state[index]?.socialId?.telegramId} onChange={(e) => hadleSocialValue(e, index)} />
            </div>

            <div className="row justify-content-start gx-2 mb-2">
              {selectedSocialMedia &&
                selectedSocialMedia?.length !== 0 && selectedSocialMedia?.map((e, i) => {
                  return (
                    <div className="col-md-6 col-12 mb-2" key={i}>
                      <div className="form-group mb-0 d-flex align-items-center">
                        <input type="text" name={e?.value} id={e?.value} className="form-control ml-5 " value={state[index]?.socialId[e?.value]} onChange={(e) => hadleSocialValue(e, index)}
                          style={{
                            // paddingLeft: '70px'
                          }} placeholder={`https://${e?.label}`} />
                      </div>
                    </div>
                  )
                })}
            </div>
            {/* <div className="col-12">
              <input type="text" className="form-control mb-2" placeholder={`${t('chamberFanpage')}`} name='chamberfanpage' value={state[index]?.chamberfanpage} onChange={(e) => handleChange(e, index)} />
            </div> */}
            {/* chamber_order */}
            <div className="col-12">
              <input type="number" className={classNames("form-control mb-2", {
                "error": errors[index]?.chamber_order
              })} placeholder={`${t('displayOrder')}`} name='chamber_order' id='chamber_order' value={state[index]?.chamber_order} onChange={(e) => handleChange(e, index)} />
              {
                errors[index]?.chamber_order && <div className='h6 text-danger ps-3'>{errors[index]?.chamber_order}</div>
              }
            </div>
            {/* 
            <div className="col-12">
              <div className="col text-black" id='social-select'>
                <MultiSelect
                  options={socialOptions}
                  value={selectedSocialMedia}
                  onChange={(e) => {
                    handleSelectedSocial(e)
                  }}
                  labelledBy={`${t('selectSocialMedia')}`} />
              </div>

            </div> */}

          </div>
          <div className="bg-red" onClick={handleSubmitChamber}>
            <button type="submit" className="btn btn-theme1 w-100 "><span className="font-color text-white">{t('Save')}</span></button>
          </div>
        </div>
      </div>}
      <Toaster />
    </React.Fragment >
  )
}

const mapStateToProp = state => ({
  chamber_details: state.chamberReducer.chamber_details,
  chamberReducer: state.chamberReducer,
  save_chamber: state.chamberReducer.save_chamber,
  userDetailsReducer: state.userDetailsReducer,
  backGroundReducer: state.themeReducer.getBackGround,
  delete_chamber: state.chamberReducer.delete_chamber,

  // companyDetailsReducer: state.companyDetailsReducer,
})

const mapDispatchToProps = dispatch => ({
  getChamberDetails: user => dispatch(getChamberDetails(user)),
  saveChamberDetails: details => dispatch(saveChamberDetails(details)),
  resetSaveChamber: details => dispatch({ type: RESET_SAVE_CHAMBER_DETAILS }),
  deleteChamber: id => dispatch(deleteChamber(id)),
  resetDeleteChamber: () => dispatch({ type: RESET_DELETE_CHAMBER_DETAILS }),

  // getCompanyDetails: user => dispatch(getCompanyDetails(user)),
  // updateCompanyDetails: details => dispatch(updateCompany(details)),
})

export default connect(mapStateToProp, mapDispatchToProps)(Chamber)