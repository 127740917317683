import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'
import { connect } from 'react-redux'
import { getPAymentOptions, successStripePaymentAction } from '../../services/Action/Payment/PaymentAction'
import { RESET_MEMBERSHIP_STRIPE_PAYMENT } from '../../services/Constant'
import { useTranslation } from 'react-i18next'
import { getMemberShipTenure } from '../../services/Action/MemberShip/memberShipAction'

function SuccessStripePayment(props) {
 const navigate = useNavigate()
 const { t } = useTranslation()
 const params = useParams()
 const [loading, setLoading] = useState(true)

 useEffect(() => {

  if (!props.successPaymentReducer.success) {
   props.successStripePaymentAction({
    membership_id: params.id
   })
  }
 }, [])

 useEffect(() => {
  if (props.successPaymentReducer.success) {
   setLoading(false)
   props.resetSuccess()
  }
 }, [props.successPaymentReducer])

 return (
  <div className='p-4 bg-white'>
   {loading ? <Spinner /> : <div>
    <div className='d-flex justify-content-center mb-3'><img src='/images/check.png' style={{ width: '100px' }} /></div>
    <div className='text-center text-success h5'>{`${t('successPayment')}`}.</div>
    <div className='d-flex justify-content-center mt-3'><div className='bg-red text-white h6 py-2 px-4 border rounded pointer' onClick={() => {
     props.getPAymentOptions()
     props.getMemberShipTenure()
     navigate('/membership-plane')
    }}>OK</div></div>
   </div>}
  </div>
 )
}

const mapStateToProp = state => ({
 successPaymentReducer: state.paymentReducer.successPayment
})

const mapDispatchToProps = dispatch => ({
 successStripePaymentAction: detail => dispatch(successStripePaymentAction(detail)),
 getMemberShipTenure: () => dispatch(getMemberShipTenure()),
 getPAymentOptions: () => dispatch(getPAymentOptions()),
 resetSuccess: detail => dispatch({ type: RESET_MEMBERSHIP_STRIPE_PAYMENT }),
})

export default connect(mapStateToProp, mapDispatchToProps)(SuccessStripePayment)