import { GET_NOTIFICATION_ERROR, GET_NOTIFICATION_LOADING, GET_NOTIFICATION_SUCCESS, RESET_GET_NOTIFICATION } from "../../Constant";

let initialState = {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATION_LOADING:
      return { ...state, loading: true, error: false, errors: [], success: false };

    case GET_NOTIFICATION_SUCCESS:
      return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

    case GET_NOTIFICATION_ERROR:
      return { ...state, loading: false, error: true, errors: action.errors, success: false, message: null };

    case RESET_GET_NOTIFICATION:
      return { ...state, loading: false, error: false, errors: null, success: false};

    // case RESET_ADD_COMAPNY:
    //   return { ...state, loading: false, error: false, success: false };

    default:
      return state;
  }
}
