import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Toaster from '../../Toaster/Toaster'
import { Modal } from 'react-bootstrap'
import Spinner from '../../components/Spinner/Spinner'
import { getImageCatagoy, getRefferalImage, uploadImage } from '../../services/Action/Reffaral/RefferalAction'
import ReferralImageList from '../../components/ReferralImage/ReferralImageList'
import $ from "jquery";
import { toast } from 'react-toastify'
import { RESET_UPLOAD_IMAGE } from '../../services/Constant'
function RefferalImage(props) {

  const userDetails = useSelector(state => state.userDetailsReducer.data)
  const isRefferal = userDetails?.data?.isReferral
  const refImageStatus = userDetails?.data?.refimgstatue


  const { username } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)

  const [isUpload, setIsUpload] = useState(false)

  const [imageList, setImageList] = useState([])
  const [userTotalImage, setUserTotalImage] = useState(0)
  const [imageCatagory, setImageCatagory] = useState([])

  const [isOpen, setIsopen] = useState(false)
  const [prevImage, setPrevImage] = useState('')

  const [isConfirm, setIsConfirm] = useState(false)


  useEffect(() => {
    const currentUser = localStorage.getItem("username")
    if (!!username && isRefferal) {
      if (currentUser === username && parseInt(isRefferal) === 1 && parseInt(refImageStatus) === 1) {
        setLoading(false)
      } else {
        setLoading(false)
        navigate('/')
      }
    }

  }, [username, isRefferal])

  const InitialState = {
    catagory: '',
    image: [],
    imageFile: []

  }
  const InitialError = {
    catagory: false,
    image: [],
    totalImage: false
  }

  const [state, setState] = useState(InitialState)
  const [error, setError] = useState(InitialError)
  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })

  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [props.backGroundReducer])

  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }

  }, [])

  useEffect(() => {
    if (!props.getRefferalImageReducer.success) {
      props.getRefferalImageAction()
      setLoading(true)
    }
  }, [])
  useEffect(() => {
    if (props.getRefferalImageReducer.success) {
      const data = props.getRefferalImageReducer.data
      const list = data.data.map(e => {
        const a = e.Thumbnail.map(image => {
          return {
            image: image,
            categoryname: e.categoryname,
            user_id: e.user_id,
            id: e.id
          }
        })
        return a
      })
      setImageList(list.flat(1))
      setUserTotalImage(data.totalImage)
      setLoading(false)
    }
  }, [props.getRefferalImageReducer])

  useEffect(() => {
    if (!props.getImageCatagoryListReducer.success) {
      props.getImageCatagoy()
      setLoading(true)
    }
  }, [])
  useEffect(() => {
    if (props.getImageCatagoryListReducer.success) {
      const data = props.getImageCatagoryListReducer.data
      setImageCatagory([...data])
      setLoading(false)
    }
  }, [props.getImageCatagoryListReducer])

  // useEffect(() => {
  //   if (props.getRefferalImageReducer.success) {
  //     const data = props.getRefferalImageReducer.data
  //     setLoading(false)
  //   } else if (props.getRefferalImageReducer.error) {
  //     setLoading(false)
  //   }

  // }, [props.getRefferalImageReducer])

  // const handleChageCatagory = (catagory_id) => {
  //   setState(prev => ({
  //     ...prev,
  //     catagory: catagory_id
  //   }))
  // }

  const onChangeInput = async (e) => {
    if (e.target.files.length > (10 - parseInt(userTotalImage))) {
      setError(prev => ({
        ...prev,
        totalImage: `You have left only ${10 - parseInt(userTotalImage)} images for upload`
      }))

    } else {
      const files = await e.target.files
      const length = await Object.keys(files).map(e => {
        if (e !== 'length') {
          return files[e]
        } else return false
      }).length

      setError(prev => ({
        ...prev,
        image: [...Array(length).keys()].map(e => false)
      }))

      const errorsList = [...Array(files.length).keys()].map(e => false)
      const list = await Object.keys(files).map(e => {
        if (e !== 'length') {
          var reader = new FileReader();
          //Read the contents of Image File.
          reader.readAsDataURL(files[e]);
          reader.onload = function (render) {
            //Initiate the JavaScript Image object.
            var image = new Image();
            //Set the Base64 string return from FileReader as source.
            image.src = render.target.result;
            //Validate the File Height and Width.
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (width > 760 || height > 2100) {
                errorsList[parseInt(e)] = "Max W 900 x H 2100  allowed"
                setError(prev => ({
                  ...prev,
                  image: errorsList
                }))
              } else {
                if (((files[e].size / (1024 * 1024)).toFixed(2)) > 1) {
                  errorsList[parseInt(e)] = "Max 1 MB size is allowed"

                  setError(prev => ({
                    ...prev,
                    image: errorsList
                  }))
                } else {
                  errorsList[parseInt(e)] = false
                  setError(prev => ({
                    ...prev,
                    image: errorsList
                  }))
                }
              }
            };
          }

          return files[e]
        } else return
      })
      const urls = list.map(e => {
        return URL.createObjectURL(e)
      })
      setState(prev => ({
        ...prev,
        imageFile: list,
        image: urls
      }))
    }
  }

  const handleRemove = (index) => {
    const _state = state.image
    const _stateFile = state.imageFile
    const _error = error.image
    _state.splice(index, 1)
    _stateFile.splice(index, 1)
    _error.splice(index, 1)
    setState(pre => ({
      ...pre,
      image: [..._state],
      imageFile: [..._stateFile]
    }))
    setError(pre => ({
      ...pre,
      image: [..._error]
    }))
  }

  const handleUpload = () => {
    // if ()
    if (state.catagory === '') {
      setError(prev => ({
        ...prev,
        catagory: "Required"
      }))
    }
    if (state.image.length === 0) {
      setError(prev => ({
        ...prev,
        image: "Required"
      }))
    }

    const allValid = error.image.every(e => !e)

    if (allValid) {
      if (state.catagory !== '' && state.image.length !== 0) {
        props.uploadImage({
          image: state.imageFile,
          category: state.catagory,
          user_id: userDetails.data._id
        })
      }
    } else {
      setIsConfirm(true)
    }
  }

  const handleConfirm = () => {
    const isValidFile = error.image.map((e, i) => {
      if (!e) {
        return state.imageFile[i]
      }
    }).filter(e => !!e)
    if (isValidFile.length !== 0) {
      props.uploadImage({
        image: isValidFile,
        category: state.catagory,
        user_id: userDetails.data._id
      })
    } else {
      setIsopen(false)
      setIsConfirm(false)
      setIsUpload(false)
      setError(InitialError)
      setState(InitialState)
      props.getRefferalImageAction()
      props.getImageCatagoy()
      toast.success('Image Upload Successfully')
      props.resetUploadImage()
    }
  }

  useEffect(() => {
    if (props.uploadImageReducer.success) {
      setIsopen(false)
      setIsConfirm(false)
      setIsUpload(false)
      setError(InitialError)
      setState(InitialState)
      props.getRefferalImageAction()
      props.getImageCatagoy()
      toast.success('Image Upload Successfully')
      props.resetUploadImage()
    }
  }, [props.uploadImageReducer])

  return (
    <>
      {loading ? <Spinner /> : <div>
        {parseInt(refImageStatus) === 1 && parseInt(userTotalImage) < 10 && <div className='d-sm-flex justify-content-end'>
          <div className='text-center'>
            <div className='text-white bg-red rounded py-2 px-3 pointer text-decoration-none'
              onClick={() => {
                $('.dropify').dropify();
                setIsUpload(true)
              }}>+ Upload Images</div>
          </div>
        </div>}
        <div className='mt-4'>
          <div className='row justify-content-center contactListDiv'>
            <div className='col-12'>
              {
                (imageList && imageList.length === 0) ? <div className='d-flex justify-content-center mt-5' style={{ fontWeight: 'bold', fontSize: '20px' }}>No Images Uploaded</div> :
                  <div className='row justify-content-between'>
                    {
                      imageList.map((e, i) => {
                        return (
                          <ReferralImageList index={i} data={e} theme={theme} catagory={imageCatagory} setIsopen={setIsopen} setPrevImage={setPrevImage} />
                        )
                      })
                    }
                  </div>
              }
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
      }

      <Modal
        show={isUpload}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size='md'
        onHide={() => setLoading(false)}
      >
        <div className='p-4 p-md-5'>
          <h5 className='text-center'>Upload Image </h5>
          <div className='mt-3'>
            <div>
              <label><strong>Catagory:</strong></label>
              <select class="form-select" name="catagory"
                value={state.catagory}
                onChange={(e) => {
                  setState(state => ({
                    ...state,
                    catagory: e.target.value
                  }))
                  if (e.target.value === '') {
                    setError(pre => ({
                      ...pre,
                      catagory: 'required'
                    }))
                  } else {
                    setError(pre => ({
                      ...pre,
                      catagory: false
                    }))
                  }
                }}>
                <option value=''>Select Catagory</option>
                {
                  imageCatagory.map((e, i) => {
                    return <option value={e._id} key={i}>{e.categoryname}</option>
                  })
                }
              </select>
              {error.catagory && <div><h6 className='text-red'>{error.catagory}</h6></div>}
            </div>
          </div>
          <div className=' pt-4'>
            <label><strong>Images:</strong></label>
            {state.image.length === 0 ? <div className="custom-file-upload">
              <input
                type="file"
                name="image"
                id="image"
                accept='image/jpeg, image/jpg, image/png'
                multiple
                label="image"
                className='form-control ref-imgControl'
                onChange={onChangeInput}
              />
              {/* <label htmlFor="image">
                <div className="upload-placeholder text-center">
                </div>
              </label> */}
              <div className='h6 text-center mt-2'>W 900 x H 2100 and max 1 mb of size</div>
              {error.totalImage && <div className='text-center'><h6 className='text-red'>{error.totalImage}</h6></div>}
            </div> :
              <div className='mt-4'>
                <div className='row justify-content-center contactListDiv' style={{
                  maxHeight: '550px',
                  overflowY: 'auto'
                }}>
                  <div className='col-12'>
                    <div className='row justify-content-between p-3'>
                      {
                        state.image.map((e, i) => {
                          return (
                            <div className='col-md-5 col-6 text-center searchItem  mb-3 rounded py-3' key={i} style={{
                              border: `2px solid ${!!error.image[i] ? 'red' : 'green'}`
                            }}>
                              <div className='position-relative'>
                                <img src={e} className="pointer mb-3 img-fluid" style={{
                                  width: '140px',
                                  height: '140px'
                                }} />
                                <div className='position-absolute pointer' style={{ top: '-30px', right: '-20px', background: 'white' }} onClick={() => handleRemove(i)}>
                                  <img src='/images/remove.png' width='20px' />
                                </div>
                              </div>
                              {
                                !!error.image[i] && <div className='text-center'><h6 className='text-red'>{error.image[i]}</h6></div>
                              }
                              {/* {
                                  !error.image[i] && <div className='text-center'><h6 className='text-success'>Valid Image</h6></div>
                                } */}
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className='d-flex justify-content-center mt-4'>
            <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
              setIsUpload(false)
              setError(InitialError)
              setState(InitialState)
            }}>Cancel</div>
            {state.image.length !== 0 && <div className='border text-white bg-red rounded mx-4 px-4 py-2 pointer' onClick={handleUpload}>Upload</div>}
          </div>
        </div>
      </Modal>

      <Modal
        show={isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setIsopen(false)
          setPrevImage('')
        }}
      >
        <React.Fragment>
          <div className='p-5'>
            <img src={prevImage} alt="" style={{ width: '100%', height: 'auto' }} />

          </div>
        </React.Fragment>
      </Modal>

      <Modal
        show={isConfirm}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setIsConfirm(false)
        }}
        centered
      >
        <React.Fragment>
          <div className='pt-4 px-3 text-center'>
            <h4>Upload only Valid Image</h4>
          </div>
          <div className='d-flex justify-content-center my-4'>
            <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
              setIsConfirm(false)
            }}>Cancel</div>
            <div className='border text-white bg-red rounded mx-4 px-4 py-2 pointer' onClick={handleConfirm}>Confirm</div>
          </div>
        </React.Fragment>
      </Modal>


      <Toaster />
    </>
  )
}

const mapStateToProp = state => ({
  getRefferalImageReducer: state.RefferalReducer.getRefferalImage,
  getImageCatagoryListReducer: state.RefferalReducer.getImageCatagoryList,
  uploadImageReducer: state.RefferalReducer.uploadImage,
  backGroundReducer: state.themeReducer.getBackGround,
  backGroundReducer: state.themeReducer.getBackGround,
})

const mapDispatchToProps = dispatch => ({
  getImageCatagoy: () => dispatch(getImageCatagoy()),
  getRefferalImageAction: () => dispatch(getRefferalImage()),
  uploadImage: (details) => dispatch(uploadImage(details)),
  resetUploadImage: (details) => dispatch({ type: RESET_UPLOAD_IMAGE }),
})

export default connect(mapStateToProp, mapDispatchToProps)(RefferalImage)