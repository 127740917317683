
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Banner from '../../components/Banner/Banner'
import { forgotPassword, login } from '../../services/Action/Register/registerAction'
import { RESET_FORGOT_PASSWORD } from '../../services/Constant'
// import { getUserDetails } from '../../services/Action/userAction'
// import { RESET_LOGIN } from '../../services/Constant'
import Toaster from '../../Toaster/Toaster'

function ForgotPassword(props) {
  const notify = (msg) => toast(msg)
  const navigate = useNavigate()
  const userDetails = useSelector(state => state.loginReducer.data)
  const [state, setState] = useState({
    email: '',
  })
  const [errors, setErrors] = useState({
    email: false,
  })


  const token = localStorage.getItem('token')

  useEffect(() => {
    if (token) {
      navigate('/')
    }

  }, [token])

  // useEffect(() => {
  //   if (props.loginReducer.error) {
  //     notify(props.loginReducer.errors)
  //   }
  //   if (props.loginReducer.success) {
  //     // props.getUserDetails()
  //     const profileType = localStorage.getItem("profilestatus")
  //     const companyType = localStorage.getItem("companystatus")
  //     if (profileType === '0' && companyType === '0') {
  //       navigate('/add-profile')
  //     } else if (profileType !== '0' && companyType === '0') {
  //       navigate('/add-company')
  //     } else {
  //       navigate('/')
  //     }
  //   }
  // }, [props.loginReducer, userDetails])

  const handleInput = (e) => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      [name]: value
    }))

    switch (name) {
      case 'email':
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            email: 'Enter Email'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            email: false
          }))
        }
        break;

      default: break;
    }
  }
  const [showmsg, setShomsg] = useState(false);
  const handleSendPassword = () => {
    if (state.email === '') {
      setErrors(prevState => ({
        ...prevState,
        email: 'Enter Email'
      }))
      notify(errors.email)
    }

    if (!errors.email &&
      state.email !== '') {
      const details = {
        email: state.email,
      }
      props.forgotPassword(details)
    }
  }

  useEffect(() => {
    if (props.forgotPasswordReducer.success) {
      toast.success('Your new login details has been sent on your email')
      setTimeout(() => {
        navigate('/')
        props.resetForgotPassword()
      }, 2000)
    } else if (props.forgotPasswordReducer.error) {
      notify(props.forgotPasswordReducer.errors)
      props.resetForgotPassword()
    }
  }, [props.forgotPasswordReducer])


  return (
    <React.Fragment>
      <div className="px-0 mt-4">
        <div className="cold d-flex justify-content-center">
          <h1>AddMy.Co</h1>
          <img src="./images/loginPage/telegram.png" height="40px" className="m-sm-3 px-3 " alt="" />
        </div>

        <div className=" mt-3 ">
          <div className="into ">
            <div className="more ">
              <div className="think_in pt-md-5 pt-3 pb-3">
                <img src="./images/loginPage/image.png" className="hold_in" alt="" />
                <div className="row justify-content-center ">
                  <div className="col-10  ">
                    <div className="shows mt-4">
                      <div className="input-group text_bold">
                        <input type="text" className={classNames("form-control text-center ", {
                          "error": errors.email
                        })} placeholder="Email" name='email' value={state.email} onChange={handleInput} />
                      </div>
                      {/* {errors.userName && <div className='text-red'>* {errors.userName}</div>} */}
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center mt-3" >
                  <div className="col-10 pb-3">
                    <div className="d-flex justify-content-center">

                      <a href="/login" className="list_now">
                        <p className="heading_in">Login</p>
                      </a>
                    </div>
                    <button className="over_button p-2" onClick={handleSendPassword}>Send Password</button>
                    {
                      showmsg &&
                      <h6 className='text-center mt-4'>Your updated password sent on your email</h6>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Banner />
      </div>
      <Toaster />
    </React.Fragment>
  )
}

// export default LoginPage

const mapStateToProp = state => ({
  forgotPasswordReducer: state.forgotPasswordReducer,
  // loginReducer: state.loginReducer,
  // userDetailsReducer: state.userDetailsReducer

})

const mapDispatchToProps = dispatch => ({
  forgotPassword: display => dispatch(forgotPassword(display)),
  resetForgotPassword: display => dispatch({ type: RESET_FORGOT_PASSWORD }),
  // login: user => dispatch(login(user)),
  // getUserDetails: user => dispatch(getUserDetails())
  // resetReducer: () => dispatch({type: RESET_LOGIN})
})

export default connect(mapStateToProp, mapDispatchToProps)(ForgotPassword)