import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { baseUrl } from '../../apiSheet'
import QrCodeGenrate from '../../components/QrCodeGenrate/QrCodeGenrate'
import { getCompanyDetails } from '../../services/Action/userAction'
import Toaster from '../../Toaster/Toaster'

function ScanMyCode(props) {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = (msg) => toast.success(msg)
  const currenUser = localStorage.getItem("username")

  const urlRef = useRef()

  const userDetails = useSelector(state => state.userDetailsReducer.data)?.data
  const comapnyDetails = useSelector(state => state.companyDetailsReducer.data)?.data

  useEffect(() => {
    props.getCompanyDetails()
  }, [])


  useEffect(() => {
    if (!id || id === 'null') {
      navigate('/')
    }
  }, [id])
  const handleCopyUrl = async () => {

    await navigator.clipboard.writeText(urlRef.current.value).then(() => {
      notify('Copied!!')
    })


  }

  const CopyImage = async () => {
    const canvas = document.getElementById('qrcode')
    if (canvas) {
      const url = canvas.toDataURL("image/png")
      const data = await fetch(url)
      const blob = await data.blob()

      await navigator.clipboard.write([
        new ClipboardItem({
          "image/png": blob
        })
      ]).then(() => {
        notify('Copied QR code')
      })

    }
  }

  const handleCopyDetails = async () => {
    if (!!userDetails && comapnyDetails) {
      const detail = `Name: ${userDetails.owner_name_english}, company name: ${comapnyDetails[0]?.company_name_english}, Designation: ${comapnyDetails[0]?.companydesignation}, Addmy addres: ${baseUrl}/${userDetails.username}`
      await navigator.clipboard.writeText(JSON.stringify(detail)).then(() => {
        notify('Details Copied!!')
      })
    }
  }
  return (
    <div className='container'>
      <div className="d-flex align-items-center flex-column justify-content-center" style={{ height: '55vh' }}>
        <div className='col-md-6 col-12 justify-content-center d-flex align-items-center'>
          {/* <input className='form-control ' type="text" name="url" value={"Copy QR code"} style={{ paddingRight: '45px' }} disabled /> */}
          <h4 className='text-white'>{t('copyQRCode')}</h4>
          <img src="/images/copying.png" className='pointer mx-2' alt="" width={'25px'} style={{
            top: "7px",
            bottom: '0px',
            right: '10px'
          }} onClick={CopyImage} />
        </div>
        <div className='qr-container pt-3 pb-3'>
          <QrCodeGenrate url={`https://addmy.co/${currenUser}`} />
        </div>
        <h4 className='text-white mt-4'>{t('scanQRCode')}</h4>
        <div className='col-md-7 col-12 position-relative'>
          <input className='form-control ' ref={urlRef} type="text" name="url" value={`https://addmy.co/${currenUser}`} style={{ paddingRight: '45px' }} disabled />
          <img src="/images/copying.png" className='position-absolute pointer' alt="" width={'25px'} style={{
            top: "7px",
            bottom: '0px',
            right: '10px'
          }} onClick={handleCopyUrl} />
        </div>

        <div className='col-md-7 col-12 position-relative mt-4 aa'>
          <input className='form-control' type="text" name="url" value={`Copy my Details`} style={{ paddingRight: '45px' }} disabled />
          <img src="/images/copying.png" className='position-absolute pointer' alt="" width={'25px'} style={{
            top: "7px",
            bottom: '0px',
            right: '10px'
          }} onClick={handleCopyDetails} />
        </div>

      </div>
      <Toaster />
    </div>
  )
}

const mapStateToProp = state => ({
  companyDetailsReducer: state.companyDetailsReducer,
})

const mapDispatchToProps = dispatch => ({
  getCompanyDetails: user => dispatch(getCompanyDetails(user)),

})

export default connect(mapStateToProp, mapDispatchToProps)(ScanMyCode)