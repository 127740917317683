import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiSelect } from 'react-multi-select-component'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { success } from 'toastr'
import { addCompany, getUserDetails } from '../../services/Action/userAction'
import Toaster from '../../Toaster/Toaster'
import $ from 'jquery'

function AddCompany(props) {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = (msg) => toast.error(msg)
  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  const numberValidation = RegExp(/^[0-9]*$/)

  const initialState = {
    company_name_english: '',
    company_name_chinese: '',
    company_designation: '',
    image: '',
    socialId: {
      Facebook: '',
      telegramId: '',
      Whatsapp: '',
      SnapChat: '',
      Instagram: '',
      Linkedin: '',
      Youtube: '',
      Skype: '',
      Wechat: '',
      Twitter: '',
      Line: '',
      TikTok: ''
    },
    description: '',
    landLine_no: '',
    fax: '',
    // telegramId: '',
    email: '',
    website: '',
    // fanpage: '',
    priority: ''
  }

  const initialError = {
    company_name_english: false,
    company_name_chinese: false,
    company_designation: false,
    telegramId: false,
    priority: false,
    email: false
  }


  const socialOptions = [
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Instagram', value: 'Instagram' },
    { label: 'Youtube', value: 'Youtube' },
    // { label: 'Whatsapp', value: 'Whatsapp' },
    // { label: 'SnapChat', value: 'SnapChat' },
    // { label: 'Linkedin', value: 'Linkedin' },
    // { label: 'Skype', value: 'Skype' },
    // { label: 'Wechat', value: 'Wechat' },
    // { label: 'Twitter', value: 'Twitter' },
    // { label: 'Line', value: 'Line' },
    // { label: 'TikTok', value: 'TikTok' },
  ]

  const [selectedSocialMedia, setSelectedSocialMedia] = useState(socialOptions)
  const [state, setState] = useState(initialState)
  const [errors, setErrors] = useState(initialError)
  const [isSave, setIsSave] = useState(false)
  const [images, setImage] = useState('')

  useEffect(() => {
    if (props.addCompanyReduce.success) {
      if (isSave) {
        localStorage.setItem("companystatus", "1")
        navigate('/')
      } else {
        setErrors(initialError)
        setState(initialState)
        setImage('')
      }
    }
  }, [props.addCompanyReduce])


  useEffect(() => {
    const companyStatus = localStorage.getItem("companystatus")
    if (companyStatus === '1') {
      navigate('/')
    }
  }, [localStorage.getItem("companystatus")])

  useEffect(() => {
    props.getUserDetails()
  }, [])

  const handleInput = (e) => {
    const { name, value } = e.target
    // setState(prevState => ({
    //   ...prevState,
    //   [name]: value
    // }))
    if (name === 'priority') {
      if (numberValidation.test(value) && value.length < 2) {
        setState(prevState => ({
          ...prevState,
          priority: value
        }))
      } else {
        setState(prevState => ({
          ...prevState,
          priority: value.charAt(value.length - 1)
        }))
      }
    } else {
      setState(prevState => ({
        ...prevState,
        [name]: value
      }))
    }

    switch (name) {
      case "company_name_english":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            company_name_english: "Enter Company name in English"
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            company_name_english: false
          }))
        }
        break;

      case "company_name_chinese":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            company_name_chinese: 'Enter Company name in Chinese'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            company_name_chinese: false
          }))
        }
        break;

      case "company_designation":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            company_designation: 'Enter Company designation'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            company_designation: false
          }))
        }
        break;



      case "email":
        if (!validEmailRegex.test(value)) {
          setErrors(prevState => ({
            ...prevState,
            email: "Enter valid email"
          }))
        }
        else {
          setErrors(prevState => ({
            ...prevState,
            email: false
          }))
        }
        break;


      case "priority":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            priority: 'Enter order for priority'
          }))
        } else if (parseInt(value) === 0) {
          setErrors(prevState => ({
            ...prevState,
            priority: "Order grether than 0"
          }))
          notify(`${t('orderGreterthan0')}`)
        }
        else {
          setErrors(prevState => ({
            ...prevState,
            priority: false
          }))
        }
        break;

      default: break
    }
  }

  const hadleSocialValue = (e) => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      socialId: {
        ...prevState.socialId,
        [name]: value
      }
    }))

    switch (name) {
      case "telegramId":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            telegramId: 'Enter TelegamId'
          }))
        }
        else {
          setErrors(prevState => ({
            ...prevState,
            telegramId: false
          }))
        }
        break;

      default: break;
    }
  }



  const handleImage = (e) => {
    setImage(e.target.files[0])
    if (e.target.files[0]) {
      setState(prevState => ({
        ...prevState,
        image: URL.createObjectURL(e.target.files[0] ?? '')
      }))
    }
  }

  const checkUrl = (str) => {
    if (str !== '') {
      if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
        return str
      } else {
        return "https://" + str
      }
    } else return ''

  }


  const hadleAddCompany = () => {
    if (state.company_name_english === '') {
      setErrors(prevState => ({
        ...prevState,
        company_name_english: "Enter Company name in English"
      }))
      notify(errors.company_name_english)
    }
    if (state.company_name_chinese === '') {
      setErrors(prevState => ({
        ...prevState,
        company_name_chinese: 'Enter Company name in Chinese'
      }))
      notify(errors.company_name_chinese)
    }
    if (state.company_designation === '') {
      setErrors(prevState => ({
        ...prevState,
        company_designation: 'Enter Company designation'
      }))
      notify(errors.company_designation)
    }
    if (state.socialId.telegramId === '') {
      setErrors(prevState => ({
        ...prevState,
        telegramId: 'Enter TelegamId'
      }))
      notify(errors.telegramId)
    }
    if (state.priority === '') {
      setErrors(prevState => ({
        ...prevState,
        priority: 'Enter order for priority'
      }))
      notify(errors.priority)
    }
    if (parseInt(state.priority) === 0) {
      setErrors(prevState => ({
        ...prevState,
        priority: "Order grether than 0"
      }))
    }

    if (
      state.company_name_chinese !== '' &&
      state.company_name_english !== '' &&
      state.company_designation !== '' &&
      state.socialId.telegramId !== '' &&
      state.priority !== '' &&
      state.priority !== 0 && !errors.email
    ) {

      props.addCompany({
        company_name_english: state.company_name_english,
        company_name_chinese: state.company_name_chinese,
        companydesignation: state.company_designation,
        telegramId: `https://t.me/${state.socialId.telegramId}`,
        description: state.description,
        email: state.email,
        contact: state.landLine_no,
        fax: state.fax,
        website: state.website,
        // fanpage: state.fanpage,
        image: images,
        company_order: state.priority,
        WhatsApp: checkUrl(state.socialId.Whatsapp),
        WeChat: checkUrl(state.socialId.Wechat),
        Line: checkUrl(state.socialId.Line),
        Instagram: checkUrl(state.socialId.Instagram),
        Facebook: checkUrl(state.socialId.Facebook),
        Twitter: checkUrl(state.socialId.Twitter),
        Youtube: checkUrl(state.socialId.Youtube),
        Linkedin: checkUrl(state.socialId.Linkedin),
        SnapChat: checkUrl(state.socialId.SnapChat),
        Skype: checkUrl(state.socialId.Skype),
        TikTok: checkUrl(state.socialId.TikTok),
      })
    }
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="tags">
          <div className="text-end mb-2">
            <button className="btn btn-sm btn-primary" onClick={() => {
              setIsSave(false)
              hadleAddCompany()
            }}>{t('AddmoreCompany')}</button>
          </div>
          <h5 className='text-center page-title'>Enter your company details</h5>
          <div className="row mt-3 ">
            <div className="col-12">
              <input type="text" className={classNames("form-control mb-2 ", {
                "error": errors.company_name_english
              })} placeholder={`${t('companyNameEnglish')}`} name='company_name_english' id='company_name_english' value={state.company_name_english} onChange={handleInput} />
              {
                errors?.company_name_english && <div className='h6 text-danger ps-3'>{errors?.company_name_english}</div>
              }
            </div>
            <div className="col-12">
              <input type="text" className={classNames("form-control mb-2 ", {
                "error": errors.company_name_chinese
              })} placeholder={`${t('companyNameChinese')}`} name='company_name_chinese' id='company_name_chinese' value={state.company_name_chinese} onChange={handleInput} />
              {
                errors?.company_name_chinese && <div className='h6 text-danger ps-3'>{errors?.company_name_chinese}</div>
              }
            </div>
            <div className="col-12">
              <input type="text" className={classNames("form-control mb-2 ", {
                "error": errors.company_designation
              })} placeholder={`${t('companyDesignation')}`} name='company_designation' id='company_designation' value={state.company_designation} onChange={handleInput} />
              {
                errors?.company_designation && <div className='h6 text-danger ps-3'>{errors?.company_designation}</div>
              }
            </div>
            <div className="col-12 text-center  mb-2">
              <div className='profile-img'>
                <input type="file" className="input-file file-upload d-none" id="file-upload" accept='images/*' name='profile_image' onChange={handleImage} />
                <label htmlFor="file-upload">
                  {
                    state.image === '' ? <>
                      <div className='my-2 pointer videoImage_Placeholder'>
                        <div className='text-center'>
                          {/* <img src='/images/upload.png' width={'30px'} />
                            {userType === '0' ? <div style={{
                              color: theme.font !== '' ? theme.font : '#007CB6',
                            }}>{t('uploadCompanyImage')} ({t('size')} 580*325)</div> : <div className='text-blue'>{t('uploadCompanyImage')} ({t('size')} 580*325)<br /> {t('video')} ({t('2MinVideoSize')})
                            </div>
                            } */}
                          <img src={'/images/loginPage/image.png'} className="img-fluid" />
                          <p className='mb-0'>Please upload </p>
                          <p className='mb-0'>640 width by 360 high Image</p>
                          <p className='mb-0'>or</p>
                          <p className='mb-0 text-yellow'>Premium Member Upload 1 Minute Video</p>
                        </div>
                      </div></> : <>
                      <img src={state.image} id="profileImage" alt="Personal Profile Image" style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '0px'
                      }} />
                    </>
                  }
                </label>
              </div>
            </div>
            <div className="col-12 custom-preview">
              <textarea className="form-control preview-area" placeholder={`${t('companyDescription')}`} id="description" name='description' value={state.description} onChange={handleInput} />
            </div>
          </div>

          <div className="row mt-2">
            {/* <div className="col text-black" id='social-select'>
              <MultiSelect
                options={socialOptions}
                value={selectedSocialMedia}
                onChange={setSelectedSocialMedia}
                labelledBy={`${t('selectSocialMedia')}`} />
            </div> */}
          </div>

          <div className="row gx-2">
            <div className="col-md-6 col-12 mb-2">
              <input type="text" className="form-control mb-0 " placeholder={`${t('website')}`} name="website" id='website' value={state.website} onChange={handleInput} />
            </div>
            <div className="col-md-6 col-12 mb-2">
              <input type="text" className={classNames("form-control mb-0 ", {
                "error": errors.telegramId
              })} placeholder={`${t('telegramId')}`} name='telegramId' id='telegramId' value={state.socialId.telegramId} onChange={hadleSocialValue} />
              {
                errors?.telegramId && <div className='h6 text-danger ps-3'>{errors?.telegramId}</div>
              }
            </div>
            {/* <div className="col-6 mt-2">
              <input type="number" className="form-control mb-2 " placeholder={`${t('tel/Contact')}`} name='landLine_no' id='landLine_no' value={state.landLine_no} onChange={handleInput} />
            </div>
            <div className="col-6 mt-2">
              <input type="text" className="form-control mb-2 " placeholder={`${t('fax')}`} name='fax' id='fax' value={state.fax} onChange={handleInput} />
            </div>

            <div className="col-6">
              <input type="text" className="form-control mb-2 " placeholder={`${t('email')}`} name='email' id='email' value={state.email} onChange={handleInput} />
            </div> */}

            {/* <div className="col-6">
              <input type="text" className="form-control mb-2 " placeholder={`${t('fanPage')}`} name='fanpage' id='fanpage' value={state.fanpage} onChange={handleInput} />
            </div> */}
            <div className="row gx-2 justify-content-start">
              {selectedSocialMedia.length !== 0 && selectedSocialMedia.map((e, i) => {
                return (
                  <div className="col-md-6 col-12 mb-2" key={i}>
                    <div className="form-group mb-0 d-flex align-items-center" >
                      <input type="text" name={e.value} id={e.value} className="form-control ml-5 " value={state.socialId[e.value]} onChange={(e) => hadleSocialValue(e)}
                        placeholder={`https://${e.label}`} />
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="col-12">
              <input type="number" className={classNames("form-control mb-2", {
                "error": errors.priority
              })} placeholder={`${t('displayOrder')}`} name='priority' id='priority' value={state.priority} onChange={handleInput} />
              {
                errors?.priority && <div className='h6 text-danger ps-3'>{errors?.priority}</div>
              }
            </div>

            <div className="col-12 bg-red">
              <button type="submit" className="btn btn-theme1 w-100 " onClick={() => {
                setIsSave(true)
                hadleAddCompany()
              }}><span className="font-color text-white ">{t('Save')}</span></button>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </React.Fragment>
  )
}

const mapStateToProp = state => ({
  addCompanyReduce: state.addCompanyReduce
})

const mapDispatchToProps = dispatch => ({
  addCompany: user => dispatch(addCompany(user)),
  getUserDetails: user => dispatch(getUserDetails(user))
})

export default connect(mapStateToProp, mapDispatchToProps)(AddCompany)