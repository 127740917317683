import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const languageType = localStorage.getItem('languageType')
i18n.use(initReactI18next).init({
  fallbackLng: languageType === '1'? 'en' : languageType === '2' ? 'zh-Hant' : 'en',
  lng: languageType === '1'? 'en' : languageType === '2' ? 'zh-Hant' : 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    'zh-Hant': {
      translations: require('./locales/zh-Hant/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'zh-Hant'];

export default i18n;