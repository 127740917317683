import axios from "axios";
import { memberTenure_Url, paymentDetails_Url } from "../../../apiSheet";
import { PAYMENT_DETAILS_ERROR, PAYMENT_DETAILS_LOADING, PAYMENT_DETAILS_SUCCESS, TENURE_ERROR, TENURE_LOADING, TENURE_SUCCESS } from "../../Constant";

export const getMemberShipTenure = () => {
 return (dispatch) => {
  dispatch({
   type: TENURE_LOADING,
  });
  const requestOptions = {
   method: "GET",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: memberTenure_Url,
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: TENURE_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: TENURE_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: TENURE_ERROR,
       errors: validationError
      });
     }
   });
 }

}

export const getPaymentDetails = (id) => {
 return (dispatch) => {
  dispatch({
   type: PAYMENT_DETAILS_LOADING,
  });
  const requestOptions = {
   method: "GET",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: `${paymentDetails_Url}/${id}`,
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: PAYMENT_DETAILS_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: PAYMENT_DETAILS_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: PAYMENT_DETAILS_ERROR,
       errors: validationError
      });
     }
   });
 }

}