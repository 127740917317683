import axios from "axios";
import { addComapny_Url, addProfile_Url, deleteCompany_Url, deleteNotification_Url, getCompany_Url, getContactList_Url, getContactNotification_Url, getNotification_Url, getProfile_Url, landingPage_Url, removeContactList_Url, updateCompany_Url, updateProfile_Url } from "../../apiSheet";
import { ADD_COMAPNY_ERROR, ADD_COMAPNY_LOADING, ADD_COMAPNY_SUCCESS, ADD_PROFILE_ERROR, ADD_PROFILE_LOADING, ADD_PROFILE_SUCCESS, COMAPNY_DETAILS_ERROR, COMAPNY_DETAILS_LOADING, COMAPNY_DETAILS_SUCCESS, DELETE_COMAPNY_DETAILS_ERROR, DELETE_COMAPNY_DETAILS_LOADING, DELETE_COMAPNY_DETAILS_SUCCESS, DETAILS_ERROR, DETAILS_LOADING, DETAILS_SUCCESS, GET_CONTACT_LIST_ERROR, GET_CONTACT_LIST_LOADING, GET_CONTACT_LIST_SUCCESS, GET_NOTIFICATION_ERROR, GET_NOTIFICATION_LOADING, GET_NOTIFICATION_SUCCESS, IMASG_ERROR, IMASG_LOADING, IMASG_SUCCESS, LANDING_PAGE_DETAILS_ERROR, LANDING_PAGE_DETAILS_LOADING, LANDING_PAGE_DETAILS_SUCCESS, NOTIFICATION_DELETE_ERROR, NOTIFICATION_DELETE_LOADING, NOTIFICATION_DELETE_SUCCESS, REMOVE_CONTACT_ERROR, REMOVE_CONTACT_LOADING, REMOVE_CONTACT_SUCCESS, UPDATE_COMAPNY_ERROR, UPDATE_COMAPNY_LOADING, UPDATE_COMAPNY_SUCCESS } from "../Constant";


//  userDetails , profile and company 
export const getUserDetails = () => {
  return (dispatch) => {
    dispatch({
      type: DETAILS_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: getProfile_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DETAILS_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DETAILS_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: DETAILS_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const getCompanyDetails = () => {
  return (dispatch) => {
    dispatch({
      type: COMAPNY_DETAILS_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: getCompany_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: COMAPNY_DETAILS_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: COMAPNY_DETAILS_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: COMAPNY_DETAILS_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const addProfile = (userDetails) => {
  return (dispatch) => {
    dispatch({
      type: ADD_PROFILE_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json, multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: addProfile_Url,
      data: userDetails
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_PROFILE_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_PROFILE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: ADD_PROFILE_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const updateProfile = ({ user_id, userDetails }) => {
  debugger
  return (dispatch) => {
    dispatch({
      type: ADD_PROFILE_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json, multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
        // "content-Type": "multipart/form-data"
      },
      url: `${updateProfile_Url}/${user_id}`,
      data: userDetails
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_PROFILE_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_PROFILE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: ADD_PROFILE_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const addCompany = (userDetails) => {
  return (dispatch) => {
    dispatch({
      type: ADD_COMAPNY_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json, multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: addComapny_Url,
      data: userDetails
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_COMAPNY_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_COMAPNY_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: ADD_PROFILE_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const updateCompany = ({ user_id, userDetails }) => {

  return (dispatch) => {
    dispatch({
      type: UPDATE_COMAPNY_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updateCompany_Url}`,
      data: {
        "user_id": user_id,
        "data": userDetails
      }
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_COMAPNY_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_COMAPNY_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: UPDATE_COMAPNY_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const deleteCompany = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_COMAPNY_DETAILS_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteCompany_Url}/${id}`
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_COMAPNY_DETAILS_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_COMAPNY_DETAILS_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: DELETE_COMAPNY_DETAILS_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const getLandingPage = () => {
  return (dispatch) => {
    dispatch({
      type: LANDING_PAGE_DETAILS_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: landingPage_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: LANDING_PAGE_DETAILS_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LANDING_PAGE_DETAILS_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: LANDING_PAGE_DETAILS_ERROR,
              errors: validationError
            });
          }
      });
  };
}


//  contact list
export const getContactList = (user_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_CONTACT_LIST_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getContactList_Url}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_CONTACT_LIST_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_CONTACT_LIST_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_CONTACT_LIST_ERROR,
              errors: validationError
            });
          }
      });
  };
}


//  contact notification list
export const getContactNotification = (user_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_NOTIFICATION_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getContactNotification_Url}/${user_id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_NOTIFICATION_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_NOTIFICATION_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_NOTIFICATION_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const removeContact = (contact_id) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_CONTACT_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${removeContactList_Url}/${contact_id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: REMOVE_CONTACT_SUCCESS,
          response: {
            message: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: REMOVE_CONTACT_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: REMOVE_CONTACT_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const getImessage = () => {
  return (dispatch) => {
    dispatch({
      type: IMASG_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getNotification_Url}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: IMASG_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: IMASG_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: IMASG_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const deleteNotification = (id) => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATION_DELETE_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteNotification_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: NOTIFICATION_DELETE_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: NOTIFICATION_DELETE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: NOTIFICATION_DELETE_ERROR,
              errors: validationError
            });
          }
      });
  };
}
