import { RESET_UPDATE_COMAPNY, UPDATE_COMAPNY_ERROR, UPDATE_COMAPNY_LOADING, UPDATE_COMAPNY_SUCCESS } from "../../Constant";

let initialState = {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false,
};

export default function updateCompanyReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_COMAPNY_LOADING:
      return { ...state, loading: true, error: false, errors: [], success: false };

    case UPDATE_COMAPNY_SUCCESS:
      return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

    case UPDATE_COMAPNY_ERROR:
      return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

    case RESET_UPDATE_COMAPNY:
      return { ...state, loading: false, error: false, success: false };

    default:
      return state;
  }
}
