import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../../components/Spinner/Spinner'
import { deleteChamber, getChamberDetails } from '../../services/Action/Chamber/ChamberAction'
import { getPublicChamberPage, getPublicLandingPage } from '../../services/Action/PublicAction/PublicAction'
import { RESET_DELETE_CHAMBER_DETAILS, socialMediaIcons } from '../../services/Constant'
import Toaster from '../../Toaster/Toaster'
import { hexagonalIcon } from '../../services/Constant'

function ChamberPreview(props) {

  const token = localStorage.getItem('token')
  const { username } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userType = localStorage.getItem('usertype')

  const [loading, setLoading] = useState(true)
  const [isChamberPage, setIsChamberPage] = useState(false)
  const [state, setState] = useState([])
  const [index, setIndex] = useState(0)
  const currentUser = localStorage.getItem('username')
  const userDetails = useSelector(state => state.userDetailsReducer.data)
  const userContact = userDetails?.data?.contact

  const [bottomIcon, setBottomIcon] = useState([])

  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })

  useEffect(() => {
    if (token !== null && currentUser === username) {
      props.getChamberDetails()
      if (props.backGroundReducer.success) {
        const _theme = props.backGroundReducer?.data?.data
        if (_theme !== null) {
          setTheme(prevState => ({
            ...prevState,
            font: (_theme.fontcolor === null || _theme.fontcolor === undefined) ? '' : _theme.fontcolor,
            theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
          }))
        }
      }
    } else {
      props.isPublic(true)
      props.getPublicChamberPage(username)
      props.getPublicLandingPage(username)
    }
  }, [])

  useEffect(() => {
    if (currentUser === username) {
      if (props.backGroundReducer.success) {
        const _theme = props.backGroundReducer?.data?.data
        if (_theme !== null) {
          setTheme(prevState => ({
            ...prevState,
            font: (_theme.fontcolor === null || _theme.fontcolor === undefined) ? '' : _theme.fontcolor,
            theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
          }))
        }
      }
    }


  }, [props.backGroundReducer])
  useEffect(() => {
    if (currentUser !== username) {
      if (props.PublicLandingPage.success) {
        const _theme = props.PublicLandingPage.data.data[0].theme
        if (_theme !== null && _theme !== undefined) {
          setTheme(prevState => ({
            ...prevState,
            font: (_theme.fontcolor === null || _theme.fontcolor === undefined) ? '' : _theme.fontcolor,
            theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
          }))
        }
      }
    }


  }, [props.PublicLandingPage])

  useEffect(() => {
    const socialList = state.map((e, i) => {
      const list = {
        'Telegram': e.socialId.Telegram,
        'Youtube': e.socialId.Youtube,
        'Facebook': e.socialId.Facebook,
        'Instagram': e.socialId.Instagram,
        'website': e.chamberwebsite,
        // 'fanpage': e.chamberfanpage,
      }

      const filterList = []

      Object.values(list).map((value, index) => value && value !== '' && filterList.push(Object.keys(list)[index]))

      const groupList = []
      filterList.map((list, index) => {
        if (index % 5 == 0) {
          const list = filterList.slice(index, index + 5)
          groupList.push(list)
        }
      })

      return [...groupList]
    })

    setBottomIcon([...socialList])

  }, [state])

  useEffect(() => {
    if (token !== null && currentUser === username) {
      setLoading(props.chamber_details.loading)
      if (props.chamber_details.success) {
        if (props.chamber_details.data.data.length !== 0) {
          const _data = props.chamber_details.data.data
          const details = _data.sort((a, b) => a.chamber_order - b.chamber_order).map(e => {
            const socialID = {
              Telegram: e.tgchannel,
              WhatsApp: e.WhatsApp,
              SnapChat: e.SnapChat,
              Instagram: e.Instagram,
              Linkedin: e.Linkedin,
              Youtube: e.Youtube,
              Skype: e.Skype,
              Facebook: e.Facebook,
              WeChat: e.WeChat,
              Twitter: e.Twitter,
              Line: e.Line,
            }

            return {
              _id: e._id,
              chamber_name_english: e.chamber_name_english,
              chamber_name_chinese: e.chamber_name_chinese,
              chamberdesignation: e.chamberdesignation,
              detail: e.detail,
              tgchannel: e.tgchannel,
              chamberwebsite: e.chamberwebsite,
              chamberfanpage: e.chamberfanpage,
              image: e.image,
              video: e.video,
              chamber_order: e.chamber_order,
              socialId: {
                ...socialID
              },

            }
          })
          setState([...details])
        }
        else {
          navigate(`/update-chamber`)
        }
      }
    } else {
      // setState public chamber api
      setLoading(props.PublicChamberPageReducer.loading)
      if (props.PublicChamberPageReducer.success) {
        if (props.PublicChamberPageReducer.data.data.length !== 0) {
          const _data = props.PublicChamberPageReducer.data.data
          const details = _data.sort((a, b) => a.chamber_order - b.chamber_order).map(e => {
            const socialID = {
              Telegram: e.tgchannel,
              WhatsApp: e.WhatsApp,
              SnapChat: e.SnapChat,
              Instagram: e.Instagram,
              Linkedin: e.Linkedin,
              Youtube: e.Youtube,
              Skype: e.Skype,
              Facebook: e.Facebook,
              WeChat: e.WeChat,
              Twitter: e.Twitter,
              Line: e.Line,
            }
            return {
              _id: e._id,
              chamber_name_english: e.chamber_name_english,
              chamber_name_chinese: e.chamber_name_chinese,
              chamberdesignation: e.chamberdesignation,
              detail: e.detail,
              tgchannel: e.tgchannel,
              chamberwebsite: e.chamberwebsite,
              chamberfanpage: e.chamberfanpage,
              image: e.image,
              video: e.video,
              chamber_order: e.chamber_order,
              socialId: {
                ...socialID
              },
            }
          })
          setState([...details])
        }
        else {
          setIsChamberPage(true)
        }
      }
    }
  }, [props.chamber_details, props.PublicChamberPageReducer])

  const redirectUrl = (str) => {
    if (str !== '' && str !== undefined) {
      if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
        window.open(str, "_blank")
      } else {
        window.open(`https://${str}`, '_blank')
      }
    } else {
      return
    }
  }
  const CopyContact = async (value) => {
    if (value !== '') {
      await navigator.clipboard.writeText(value).then(() => {
        toast.success('Copied Contact no.')
      })
    }
  }

  return (
    <React.Fragment>
      {
        loading ? <Spinner /> :
          <>
            {isChamberPage ? <>
              <div>{t('noChamberDetails')}</div>
            </> :
              <div className='position-relative'>
                {/*  left */}
                <div className='position-absolute pointer navArrow' style={{ left: '-21px' }} onClick={() => setIndex(index - 1)}>
                  {state && state.length > 1 && index > 0 && <span>
                    <img src='/images/left-arrow.png' width='15px' height='40px' />
                  </span>}
                </div>

                {/* right */}
                <div className='position-absolute pointer navArrow' style={{ right: '-21px' }} onClick={() => setIndex(index + 1)}>
                  {state && state.length > 1 && index < state.length - 1 && <span>
                    <img src='/images/right-arrow.png' width='15px' height='40px' />
                  </span>}
                </div>

                {/* icons */}
                <div className="mb-3">
                  <div className="your_list text-center ">
                    <div id="carouselExample" className="carousel slide socialIcon-carousel" >
                      <div className="carousel-inner">
                        <div className="carousel-item active social-icon d-flex justify-content-between ">
                          <a onClick={() => navigate(`/${username}/2`)} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }}><img src='/images/company.svg' className='img-fluid' alt='#' /></a>
                          <a href="#" onClick={() => redirectUrl(state[index].socialId.WhatsApp)} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }}><i className="fa-brands fa-whatsapp"></i></a>
                          <a href="#" onClick={() => redirectUrl(`${state[index].tgchannel}`)} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }}><i className="fa-brands fa-telegram"></i></a>
                          <a href={`tel:${userContact}`} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }} onClick={() => { return CopyContact(userContact) }}><i className="fa-solid fa-phone"></i></a>
                          <a onClick={() => navigate(`/${username}/profile`)} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }}><img src='/images/profile.svg' className='img-fluid' alt='#' /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classNames(" mb-2 preview-badge scrollable-field")} style={{
                  color: '#00AEEF',
                  border: `2px solid #00AEEF`,
                  background: '#FFFFFF',
                }}>{state[index]?.chamber_name_english}</div>


                <div className={classNames(" mb-2 preview-badge scrollable-field")} style={{
                  color: '#00AEEF',
                  border: `2px solid #00AEEF`,
                  background: '#FFFFFF',
                }}>{state[index]?.chamber_name_chinese}</div>


                <div className={classNames(" mb-2 preview-badge")} style={{
                  color: '#00AEEF',
                  border: `2px solid #00AEEF`,
                  background: '#FFFFFF',
                  // color: theme.font !== '' ? theme.font : '#007CB6',
                }}>{state[index]?.chamberdesignation}</div>

                <div className=" text-center">
                  <div className='profile-img '>
                    <label htmlFor='file-upload' style={{
                      // border: `2px solid ${theme.theme !== '' ? theme.theme : '#2FB0E9'}`,
                      borderRadius: '10px'
                    }}>
                      {
                        <>
                          {
                            (state?.[index]?.image && state?.[index]?.image !== '') ? <img src={state?.[index]?.image} id="profileImage" alt="Personal Profile Image" style={{
                              width: '100%',
                              height: '100%',
                              borderRadius: "0px",
                            }} /> : <>
                              {
                                (state?.[index]?.video && state?.[index]?.video !== '') ? <video controls autoPlay muted loop style={{ objectFit: 'fill' }} src={state?.[index]?.video}><source id="profileVideo" alt="Personal Profile Video" /></video> : <img src={'/images/loginPage/image.png'} id="profileImage" alt="Personal Profile Image" />
                              }
                            </>
                          }
                        </>
                      }
                    </label>
                  </div>
                </div>
                <div className=" mt-2 custom-preview">
                  <div className="preview-area form-control">{state[index]?.detail !== '' ? state[index]?.detail : 'Chamber details'}</div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-12 mb-3">
                    <div className="your_list text-center ">
                      <div id="carouselExample2" className="carousel slide socialIcon-carousel" >
                        <div className="carousel-inner">
                          {
                            bottomIcon[index]?.map((array, i) => {
                              return (
                                <div key={i} className={classNames("carousel-item social-icon octagone-icons mt-3", {
                                  'active': i === 0
                                })}>
                                  {
                                    array.map((e, i) => {
                                      return <div key={i} onClick={() => {
                                        if (e === 'Telegram') {
                                          redirectUrl(state[index].socialId[e])
                                        } else if (e === 'website') {
                                          redirectUrl(state[index]?.chamberwebsite)
                                        } else if (e === 'fanpage') {
                                          redirectUrl(state[index]?.chamberfanpage)
                                        } else {
                                          redirectUrl(state[index].socialId[e])
                                        }
                                      }}>
                                        <a className='' style={{
                                          background: theme.theme !== '' ? theme.theme : '#007CB6'
                                        }}
                                          href='#'>
                                          <i className={hexagonalIcon[e]}></i>
                                        </a></div>
                                    })
                                  }
                                </div>)
                            })
                          }
                        </div>
                        {/* {bottomIcon?.[index]?.length > 1 && <button className="carousel-control-prev" type="button" style={{ justifyContent: 'start' }} data-bs-target="#carouselExample2" data-bs-slide="prev">
                          <div className='text-black'><img src='/images/left-arrow.png' width='15px' height='30px' /></div>
                        </button>}
                        {bottomIcon?.[index]?.length > 1 && <button className="carousel-control-next" type="button" style={{ justifyContent: 'end' }} data-bs-target="#carouselExample2" data-bs-slide="next">
                          <div className='text-black'><img src='/images/right-arrow.png' width='15px' height='30px' /></div>
                        </button>} */}
                      </div>
                    </div>
                  </div>
                </div>
                {token !== null && currentUser === username && <div className="row justify-content-center">

                  <div className="col-sm-6 col-6" >
                    <div className="justify-content-center bg-red">
                      <button type="submit" className="btn btn-theme1 w-100 " onClick={() => navigate(`/update-chamber`)} ><span className="font-color text-white">{t('updateChamber')}</span></button>
                    </div>
                  </div>
                  {((userType === '1' || userType === '2') || (userType === '0' && state.length < 3)) && <div className="col-sm-6 col-6" >
                    <div className="justify-content-center">
                      <button type="submit" className="btn btn-theme1 rounded-0 bg-green w-100 " onClick={() => navigate(`/update-chamber`, {
                        state: {
                          addMore: true
                        }
                      })}><span className="font-color text-white">{t('addmoreChamber')}</span></button>
                    </div>
                  </div>}
                </div>
                }
              </div>}
          </>
      }
      <Toaster />
    </React.Fragment>
  )
}


const mapStateToProp = state => ({
  chamber_details: state.chamberReducer.chamber_details,
  PublicChamberPageReducer: state.PublicPageReducer.getChamberPage,
  backGroundReducer: state.themeReducer.getBackGround,
  PublicLandingPage: state.PublicPageReducer.getLandingPage,

})

const mapDispatchToProps = dispatch => ({
  getChamberDetails: user => dispatch(getChamberDetails(user)),
  getPublicChamberPage: username => dispatch(getPublicChamberPage(username)),
  getPublicLandingPage: (username) => dispatch(getPublicLandingPage(username)),
  resetDeleteChamber: () => dispatch({ type: RESET_DELETE_CHAMBER_DETAILS }),

  // saveChamberDetails: details => dispatch(saveChamberDetails(details)),
  // getCompanyDetails: user => dispatch(getCompanyDetails(user)),
  // updateCompanyDetails: details => dispatch(updateCompany(details)),
})


export default connect(mapStateToProp, mapDispatchToProps)(ChamberPreview)