import { ADD_COMAPNY_ERROR, ADD_COMAPNY_LOADING, ADD_COMAPNY_SUCCESS, RESET_ADD_COMAPNY } from "../../Constant";

let initialState = {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false
};

export default function addCompanyReduce(state = initialState, action) {
  switch (action.type) {
    case ADD_COMAPNY_LOADING:
      return { ...state, loading: true, error: false, errors: [], success: false };

    case ADD_COMAPNY_SUCCESS:
      return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

    case ADD_COMAPNY_ERROR:
      return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

    case RESET_ADD_COMAPNY:
      return { ...state, loading: false, error: false, success: false };

    default:
      return state;
  }
}
