import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import Spinner from '../../components/Spinner/Spinner'
import { getContactList, removeContact } from '../../services/Action/userAction'
import $ from 'jquery'
import { RESET_DELETE_FOLDER, RESET_REMOVE_CONTACT } from '../../services/Constant'
import Contact from '../../components/ContactList/Contact'
import classNames from 'classnames'
import { Modal } from 'react-bootstrap'
import { addContactINFolder, addFolder, deleteFolder, getFolder, updateFolderName } from '../../services/Action/Folder/folderAction'
import { CSVLink } from 'react-csv'
import FolderContact from '../../components/ContactList/FolderContact'
import { useTranslation } from 'react-i18next'
import Toaster from '../../Toaster/Toaster'
import { toast } from 'react-toastify'

function ContactList(props) {

  //  const { id } = useParams()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [selectedFolder, setSelectedFolder] = useState('All')
  const [isFolderList, setIsFolderList] = useState(false)
  const [addtoFolder, setAddtoFolder] = useState(null)
  const [displayContact, setDisplayContact] = useState([])
  const [isAddMore, setIsAddMore] = useState(false)
  const [folderName, setFolderName] = useState('')
  const [selectedContactId, setSelectedContactId] = useState(null)
  const notify = (msg) => toast.error(msg)
  const [csvData, setCsvData] = useState([])
  const [isUpdateFolder, setIsUpdateFolder] = useState(false)
  const contactList = useSelector(state => state.getContactListReducer.data)
  const userDetails = useSelector(state => state.userDetailsReducer.data)
  const userId = userDetails !== null ? userDetails.data._id : null
  const userName = useSelector(state => state.userDetailsReducer.data)?.data?.username
  // const [tag, setTag] = useState(null)
  // const [tagList, setTagList] = useState([])
  const userType = localStorage.getItem('usertype')
  const [folderList, setFloderList] = useState([])
  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })


  //   if contact id change than
  // useEffect(() => {
  //   if (!!selectedContactId) {
  //     const selectedList = contactList.find(e => e.contact_id === selectedContactId)
  //     if (selectedList.contactfolders_data.length !== 0) {
  //       const list = selectedList?.contactfolders_data[0]?.tag
  //       const listArray = list.split(',')
  //       setTagList([...listArray])
  //     } else {
  //       setTagList([])
  //     }
  //   }
  // }, [selectedContactId])

  useEffect(() => {
    props.getContactList()
    props.getFolder()
  }, [])

  // useEffect(() => {
  //   if (props.userDetailsReducer.success) {
  //     const data = props.userDetailsReducer.data.data
  //     const taglist = data.tags !== undefined ? data.tags !== '' ? data.tags.split(',') : [] : []
  //     setTagList([...taglist])
  //   }

  // }, [props.userDetailsReducer])

  useEffect(() => {
    if (props.addFolderReducer.success) {
      props.getContactList()
      props.getFolder()
    }
  }, [props.addFolderReducer])


  useEffect(() => {
    if (selectedFolder !== 'All') {
      const list = folderList.find(e => e._id === selectedFolder)
      setDisplayContact([...list.folderdetails])
    }
  }, [selectedFolder])

  useEffect(() => {
    if (props.deleteFolderReducer.success) {
      props.getContactList()
      setSelectedFolder('All')
      props.getFolder()
      props.resetRemoveFolder()
    }
  }, [props.deleteFolderReducer])

  useEffect(() => {
    if (props.addContactInFolderReducer.success) {
      props.getContactList()
      props.getFolder()
      setIsFolderList(false)
      // setTagList([])
      // setTag(null)
      setAddtoFolder(null)
      setAddtoFolder(null)
    }

  }, [props.addContactInFolderReducer])

  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [props.backGroundReducer])

  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }

  }, [])

  useEffect(() => {
    setLoading(props.getFolderReducer?.getFolder.loading)
    if (props.getFolderReducer?.getFolder.success) {
      const list = props.getFolderReducer.getFolder.data.data
      setFloderList([...list])
      // folderList.push(list)
      // setFloderList(list)
    }
  }, [props.getFolderReducer])


  useEffect(() => {
    setLoading(props.getContactListReducer.loading)
    if (props.getContactListReducer.success) {
      const _list = contactList?.filter(e => e.status === 1)
      setDisplayContact([..._list])
    }
  }, [props.getContactListReducer])

  useEffect(() => {
    if (props.removeContactReducer.success) {
      props.getContactList()
      props.getFolder()
      setSelectedFolder('All')
      props.resetRemoveContact()
    }
  }, [props.removeContactReducer])

  useEffect(() => {
    if (selectedFolder === 'All') {
      if (props.getContactListReducer.success) {
        const _list = contactList?.filter(e => e.status === 1)
        setDisplayContact([..._list])
      }
    } else {
      const currenFolderContact = folderList.find(e => e._id === selectedFolder)?.folderdetails
      const list = currenFolderContact?.filter(e => e?.userdetails?.contacts[0]?.status === 1)
      setDisplayContact([...list])
    }
  }, [selectedFolder])

  const handleChange = (e) => {
    const { value } = e.target
    setSearchText(value)
    $(".contactListDiv .searchStrEng").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(value) === -1) {
        if ($($(".contactListDiv .searchStrChines")[i]).text().toLowerCase().indexOf(value) === -1) {
          if ($($(".contactListDiv .searchStrCompanyEng")[i]).text().toLowerCase().indexOf(value) === -1) {
            if ($($(".contactListDiv .searchStrCompanyChinese")[i]).text().toLowerCase().indexOf(value) === -1) {
              if ($($(".contactListDiv .searchStrTags")[i]).text().toLowerCase().indexOf(value) === -1) {
                const _tagList = $($(".contactListDiv .searchStrTags")[i]).text().split(',').map(e => e.toLowerCase())
                if (_tagList.indexOf(value) === -1) {
                  $(v).parents(".searchItem").addClass("d-none");
                } else {
                  $(v).parents(".searchItem").removeClass("d-none");
                }
              } else {
                $(v).parents(".searchItem").removeClass("d-none");
              }
            } else {
              $(v).parents(".searchItem").removeClass("d-none");
            }
          } else {
            $(v).parents(".searchItem").removeClass("d-none");
          }
        } else {
          $(v).parents(".searchItem").removeClass("d-none");
        }
      } else {
        $(v).parents(".searchItem").removeClass("d-none");
      }
    })
  }

  const handleAddFolder = () => {
    if (folderName !== '') {
      props.addFolder({ FolderName: folderName })
      setIsAddMore(false)
      setFolderName('')
    }
  }

  const hadleContactAddToFolder = () => {
    if (!addtoFolder) {
      notify("Select Folder")
    }
    if (!!addtoFolder && !!selectedContactId) {
      props.addContactINFolder({
        contact_id: selectedContactId,
        folder_id: addtoFolder,
        // tag: tagList
      })
    }
  }

  // const handleTag = (e) => {
  //   const { name, value } = e.target
  //   setTag(value.split(' ').join('').trim())
  // }

  // const handleTagKey = (e) => {
  //   if ((e.key === 'Enter' || e.key === ' ') && tagList.length < 3) {
  //     setTag('')
  //     setTagList(prevState => ([...prevState, e.target.value]))
  //   }
  // }

  useEffect(() => {
    if (!!contactList) {
      const _data = contactList.map((e) => {
        if (e?.userdetails?.length > 0) {
          const comapyDetails = e?.userdetails[0]?.companydetails?.sort((a, b) => a.company_order - b.company_order)
          return {
            owner_name_chinese: e.userdetails[0]?.owner_name_chinese,
            owner_name_english: e.userdetails[0]?.owner_name_english,
            company_name: comapyDetails?.length === 0 ? e.userdetails[0]?.companydetails[0]?.company_name_english : comapyDetails[0]?.company_name_english,
            companydesignation: comapyDetails?.length === 0 ? e.userdetails[0]?.companydetails[0]?.companydesignation : comapyDetails[0]?.companydesignation,
            contact: e.userdetails[0].contact,
            telegramId: e.userdetails[0].telegramId,
            website: comapyDetails?.length === 0 ? e.userdetails[0]?.companydetails[0]?.website : comapyDetails?.website,
          }
        }
      })
      setCsvData([..._data])
    }

  }, [contactList])

  const csvHeaders = [
    { label: "Contact name in Chiense", key: "owner_name_chinese" },
    { label: "Contact name in English", key: "owner_name_english" },
    { label: "Company Name", key: "company_name" },
    { label: "Designation", key: "companydesignation" },
    { label: "Contact", key: "contact" },
    { label: "Telegram Id", key: "telegramId" },
    { label: "Website", key: "website" },
  ];

  const csvReport = {
    data: csvData.filter(e => e !== undefined),
    headers: csvHeaders,
    filename: 'addmy.co contacts.csv'
  }


  const handleUpdateFolderName = () => {
    const details = {
      Folder: folderName,
      user_id: userId
    }
    props.updateFolderName({ details: details, id: selectedFolder })
  }
  useEffect(() => {
    if (props.updateFolder.success) {
      setFolderName('')
      setIsUpdateFolder(false)
      props.getFolder()
    }
  }, [props.updateFolder])


  return (
    <React.Fragment>
      {!userName ? <Spinner /> : <div>
        {
          (loading) ?
            <Spinner /> :
            <div>
              {/* <CSVLink {...csvReport}>Export to CSV</CSVLink> */}

              <div className='row justify-content-center '>
                <div className='col-md-8 order-1 order-md-0'>
                  <div className='position-relative contactSearch'>
                    <input type="search" id="form1" className="text-start preview-badge w-100 border-0" style={{
                      paddingRight: "30px",
                      background: '#ffffff',
                      color: '#00A3CC',
                      // background: theme.theme !== '' ? theme.theme : '#00A3CC',
                      // color: theme.font !== '' && theme.font,
                      // borderColor: '#00A3CC' 
                    }} placeholder={`${t('serchBynameAndTag')}`} onChange={(e) => handleChange(e)} value={searchText} />
                    <div className='position-absolute d-flex align-items-center pointer' style={{ top: '0px', bottom: '0px', right: '0px', paddingRight: '10px' }}>
                      <i className="bi bi-search"></i>
                    </div>
                  </div>
                </div>
                {contactList?.length !== 0 && <div className=' col-md-4 order-0 order-md-1'>
                  {
                    csvReport &&
                    <CSVLink {...csvReport} style={{ textDecoration: 'none' }}>
                      <div className='text-center'>
                        <div className='text-white bg-red rounded py-2 px-3 pointer text-decoration-none' >{t('exportContacts')}</div>
                      </div>
                    </CSVLink>
                  }
                </div>}
              </div>
              <div className='row justify-content-center my-3 folder-list'>
                <div className='col-12 pb-3 d-flex justify-content-between' style={{ overflowX: "auto" }}>
                  <div className='d-flex'>
                    <div className={classNames('me-2 py-1 px-4 rounded pointer', {
                      "border": selectedFolder !== 'All',
                    })} onClick={() => setSelectedFolder('All')} style={{
                      minWidth: "135px",
                      color: selectedFolder === 'All' ? '#ffffff' : '',
                      backgroundColor: selectedFolder === 'All' ? '#007CB6' : '#ffffff',
                    }}>All</div>
                    {
                      folderList.map((e, i) => {
                        return <div className={classNames('me-2 py-1 px-4 pointer d-flex rounded justify-content-between align-item-center', {

                        })} key={i} onClick={() => setSelectedFolder(e._id)} style={{
                          minWidth: "135px",
                          color: selectedFolder === e._id ? '#ffffff' : '',
                          backgroundColor: selectedFolder === e._id ? '#007CB6' : '#ffffff',
                        }}>
                          <div>{e.Folder}</div>
                          {e.user_id !== null ? <div className='pointer'>
                            <img src='/images/dots.png' style={{ width: '16px', filter: selectedFolder === e._id ? 'invert(0)' : 'invert(1)' }} onClick={() => {
                              setIsUpdateFolder(true)
                              setFolderName(e.Folder)
                            }} /></div>
                            : null}
                        </div>
                      })
                    }
                  </div>
                  {userType !== '0' &&
                    <div className='me-2 border py-1 rounded text-white bg-red pointer text-center ' style={{ minWidth: '150px' }}
                      onClick={() => setIsAddMore(true)}>+ {t('addMoreFolder')}</div>}

                </div>
              </div>
              <div className='row justify-content-center contactListDiv'>
                <div className='col-12'>
                  {
                    (contactList && displayContact.length === 0) ? <div className='d-flex justify-content-center mt-5' style={{ fontWeight: 'bold', fontSize: '20px' }}>{t('noContacts')}</div> :
                      <div className='row justify-content-between contact-list'>
                        {
                          displayContact?.map((list, index) => {
                            return (
                              <React.Fragment key={index}>
                                {selectedFolder === 'All' ? <Contact
                                  list={list}
                                  index={index}
                                  setIsFolderList={setIsFolderList}
                                  setSelectedContactId={setSelectedContactId}
                                  theme={theme}
                                /> :
                                  <FolderContact list={list} theme={theme} path={folderList[0]?.profile_image} />}
                              </React.Fragment>
                            )
                          })
                        }
                      </div>
                  }
                </div>
              </div>

            </div>
        }
        <Modal
          show={isAddMore}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setIsAddMore(false)}
        >
          <React.Fragment>
            <div className='p-5'>
              <div className='m-auto'>
                <input className='form-control' type="text" name="addFolder" value={folderName} placeholder={`${t('enterFolderName')}`}
                  onChange={(e) => {
                    setFolderName(e.target.value)
                  }} />
              </div>
              <div className='d-flex justify-content-center mt-4'>
                <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                  setIsAddMore(false)
                  setFolderName('')
                }}>{t('Cancel')}</div>
                <div className='border rounded mx-4 px-4 py-2 pointer' onClick={handleAddFolder}>{t('create')}</div>
              </div>
            </div>
          </React.Fragment>
        </Modal>


        <Modal
          show={isFolderList}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            setIsFolderList(false)
            // setTag(null)
          }}
        >
          <React.Fragment>
            <div className='p-5'>

              <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                {
                  folderList.map((e, i) => {
                    return (
                      <div key={i} className={'d-flex justify-content-between align-items-center py-2 border-bottom'}>
                        <div>{e.Folder}</div>
                        <div><input type={'checkbox'} className='pointer' value={e._id} checked={addtoFolder === e._id} onChange={() => setAddtoFolder(e._id)} /></div>
                      </div>
                    )
                  })
                }
              </div>
              {/* <div>
                {userType !== '0' && <div className="row mt-2">
                  <div className="col text-black" >
                    <div className="form-group mb-1">
                      <div className={classNames("mb-1 d-flex align-items-center ", {
                        "form-control": tagList.length > 2
                      })}>
                        {tagList.length !== 0 && <>{
                          tagList.map((e, i) => {
                            return (
                              <div key={i} className={"mx-2 px-2 border d-flex align-items-center"}>
                                {e}
                                <img src='/images/rejected.png' width={'10px'} className='ms-3 pointer' onClick={() => {
                                  const list = [...tagList]
                                  list.splice(i, 1)
                                  setTagList([...list])
                                }} />
                              </div>)
                          })
                        }</>}
                        {tagList.length < 3 && <input type="tags" name="tags" id="tags" className={classNames("form-control")} placeholder={`${t('tags')}`} value={tag} onChange={handleTag} onKeyDown={handleTagKey} />}
                      </div>
                    </div>
                  </div>
                </div>}

              </div> */}
              <div className='d-flex justify-content-center mt-4'>
                <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                  setIsFolderList(false)
                  // setTagList([])
                  // setTag(null)
                  setAddtoFolder(null)
                  setAddtoFolder(null)
                }}>{t('Cancel')}</div>
                <div className='border rounded mx-4 px-4 py-2 pointer' onClick={hadleContactAddToFolder}>{t('addtoFolder')}</div>
              </div>
            </div>
            <Toaster />
          </React.Fragment>
        </Modal>

        <Modal
          show={isUpdateFolder}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setIsUpdateFolder(false)}
        >
          <React.Fragment>
            <div className='p-5' >
              <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                <input className='form-control' type="text" name="addFolder" value={folderName} placeholder='Enter folder name'
                  onChange={(e) => {
                    setFolderName(e.target.value)
                  }} />
              </div>
              <div className='d-flex justify-content-center mt-4'>
                <div className='border rounded mx-4 px-4 py-2 pointer' onClick={handleUpdateFolderName}>{t('updateFolder')}</div>
                <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                  setIsUpdateFolder(false)
                  setFolderName('')
                  props.deleteFolder(selectedFolder)
                }}>{t('deleteFolder')}</div>
              </div>
            </div>
          </React.Fragment>
        </Modal>
      </div>}

    </React.Fragment>
  )
}
const mapStateToProp = state => ({
  getContactListReducer: state.getContactListReducer,
  removeContactReducer: state.removeContactReducer,
  getFolderReducer: state.folderReducer,
  addFolderReducer: state.folderReducer.addFolder,
  addContactInFolderReducer: state.folderReducer.addContactInFolder,
  updateFolder: state.folderReducer.updateFolder,
  userDetailsReducer: state.userDetailsReducer,
  backGroundReducer: state.themeReducer.getBackGround,
  deleteFolderReducer: state.folderReducer.deleteFolder,
})

const mapDispatchToProps = dispatch => ({
  getContactList: (user_id) => dispatch(getContactList(user_id)),
  getFolder: () => dispatch(getFolder()),
  addFolder: (folder) => dispatch(addFolder(folder)),
  deleteFolder: (id) => dispatch(deleteFolder(id)),
  updateFolderName: (details) => dispatch(updateFolderName(details)),
  addContactINFolder: (details) => dispatch(addContactINFolder(details)),
  removeContact: (contact_id) => dispatch(removeContact(contact_id)),
  resetRemoveContact: () => dispatch({ type: RESET_REMOVE_CONTACT }),
  resetRemoveFolder: () => dispatch({ type: RESET_DELETE_FOLDER }),
})

export default connect(mapStateToProp, mapDispatchToProps)(ContactList)