import { GET_CONTACT_LIST_ERROR, GET_CONTACT_LIST_LOADING, GET_CONTACT_LIST_SUCCESS } from "../../Constant";

let initialState = {
 loading: false,
 data: null,
 error: false,
 errors: null,
 success: false
};

export default function getContactListReducer(state = initialState, action) {
 switch (action.type) {
  case GET_CONTACT_LIST_LOADING:
   return { ...state, loading: true, error: false, errors: [], success: false };

  case GET_CONTACT_LIST_SUCCESS:
   return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

  case GET_CONTACT_LIST_ERROR:
   return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

  // case RESET_ADD_COMAPNY:
  //   return { ...state, loading: false, error: false, success: false };

  default:
   return state;
 }
}
