import axios from "axios";
import { getPaymentOption_Url, membershipCheckOut_Url, payment_Url, success_membership_Url } from "../../../apiSheet";
import { GET_PAYMENT_CONFIG_ERROR, GET_PAYMENT_CONFIG_LOADING, GET_PAYMENT_CONFIG_SUCCESS, MEMBERSHIP_CHECKOUT_SESSION_ERROR, MEMBERSHIP_CHECKOUT_SESSION_LOADING, MEMBERSHIP_CHECKOUT_SESSION_SUCCESS, MEMBERSHIP_STRIPE_PAYMENT_ERROR, MEMBERSHIP_STRIPE_PAYMENT_LOADING, MEMBERSHIP_STRIPE_PAYMENT_SUCCESS, PAYPAL_PAYMENT_ERROR, PAYPAL_PAYMENT_LOADING, PAYPAL_PAYMENT_SUCCESS, TONCOIN_PAYMENT_ERROR, TONCOIN_PAYMENT_LOADING, TONCOIN_PAYMENT_SUCCESS } from "../../Constant";

export const submitToncoinPayment = (paymentDetails) => {
  return (dispatch) => {
    dispatch({
      type: TONCOIN_PAYMENT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${payment_Url}`,
      data: paymentDetails
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: TONCOIN_PAYMENT_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: TONCOIN_PAYMENT_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: TONCOIN_PAYMENT_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const submitPaypalPayment = (paymentDetails) => {
  return (dispatch) => {
    dispatch({
      type: PAYPAL_PAYMENT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${payment_Url}`,
      data: paymentDetails
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: PAYPAL_PAYMENT_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: PAYPAL_PAYMENT_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: PAYPAL_PAYMENT_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const submitStripePayment = (paymentDetails) => {
  return (dispatch) => {
    dispatch({
      type: MEMBERSHIP_CHECKOUT_SESSION_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${membershipCheckOut_Url}`,
      data: paymentDetails
    };
    axios(requestOptions)
      .then((resp) => {
        console.log("resp", resp);
        dispatch({
          type: MEMBERSHIP_CHECKOUT_SESSION_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: MEMBERSHIP_CHECKOUT_SESSION_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: MEMBERSHIP_CHECKOUT_SESSION_ERROR,
              errors: validationError
            });
          }
      });
  }

}


export const successStripePaymentAction = (paymentDetails) => {
  return (dispatch) => {
    dispatch({
      type: MEMBERSHIP_STRIPE_PAYMENT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${success_membership_Url}`,
      data: paymentDetails
    };
    axios(requestOptions)
      .then((resp) => {
        console.log("resp", resp);
        dispatch({
          type: MEMBERSHIP_STRIPE_PAYMENT_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: MEMBERSHIP_STRIPE_PAYMENT_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: MEMBERSHIP_STRIPE_PAYMENT_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const getPAymentOptions = () => {
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENT_CONFIG_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getPaymentOption_Url}`
    };
    axios(requestOptions)
      .then((resp) => {
        console.log("resp", resp);
        dispatch({
          type: GET_PAYMENT_CONFIG_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_PAYMENT_CONFIG_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_PAYMENT_CONFIG_ERROR,
              errors: validationError
            });
          }
      });
  }

}
