import { ADD_IMAGE_BACKGROUND_ERROR, ADD_IMAGE_BACKGROUND_LOADING, ADD_IMAGE_BACKGROUND_SUCCESS, GET_BACKGROUND_ERROR, GET_BACKGROUND_LOADING, GET_BACKGROUND_SUCCESS, GET_IMAGE_BACKGROUND_ERROR, GET_IMAGE_BACKGROUND_LOADING, GET_IMAGE_BACKGROUND_SUCCESS, GET_SYSTEM_IAMGE_ERROR, GET_SYSTEM_IAMGE_LOADING, GET_SYSTEM_IAMGE_SUCCESS, RESET_ADD_IMAGE_BACKGROUND, RESET_GET_BACKGROUND, RESET_GET_IMAGE_BACKGROUND, RESET_GET_SYSTEM_IAMGE, RESET_SET_BACKGROUND, SET_BACKGROUND_ERROR, SET_BACKGROUND_LOADING, SET_BACKGROUND_SUCCESS } from "../../Constant";

let initialState = {
  getBackGround: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  setThemeAndBackground: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  getSystemBackGround: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  addCoustomImage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  getCoustomImage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },

};

export default function themeReducer(state = initialState, action) {
  switch (action.type) {

    // getBackGround
    case GET_BACKGROUND_LOADING:
      return { ...state, getBackGround: { ...state.getBackGround, loading: true, data: null, error: false, errors: null, success: false } };

    case GET_BACKGROUND_SUCCESS:
      return { ...state, getBackGround: { ...state.getBackGround, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_BACKGROUND_ERROR:
      return { ...state, getBackGround: { ...state.getBackGround, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_BACKGROUND:
      return { ...state, getBackGround: { ...state.getBackGround, loading: false, error: false, success: false } };

    // setThemeAndBackground
    case SET_BACKGROUND_LOADING:
      return { ...state, setThemeAndBackground: { ...state.setThemeAndBackground, loading: true, data: null, error: false, errors: null, success: false } };

    case SET_BACKGROUND_SUCCESS:
      return { ...state, setThemeAndBackground: { ...state.setThemeAndBackground, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case SET_BACKGROUND_ERROR:
      return { ...state, setThemeAndBackground: { ...state.setThemeAndBackground, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_SET_BACKGROUND:
      return { ...state, setThemeAndBackground: { ...state.setThemeAndBackground, loading: false, error: false, success: false } };
    
      // getSystemBackGround
    case GET_SYSTEM_IAMGE_LOADING:
      return { ...state, getSystemBackGround: { ...state.getSystemBackGround, loading: true, data: null, error: false, errors: null, success: false } };

    case GET_SYSTEM_IAMGE_SUCCESS:
      return { ...state, getSystemBackGround: { ...state.getSystemBackGround, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_SYSTEM_IAMGE_ERROR:
      return { ...state, getSystemBackGround: { ...state.getSystemBackGround, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_SYSTEM_IAMGE:
      return { ...state, getSystemBackGround: { ...state.getSystemBackGround, loading: false, error: false, success: false } };

      // add coustom image
    case ADD_IMAGE_BACKGROUND_LOADING:
      return { ...state, addCoustomImage: { ...state.addCoustomImage, loading: true, data: null, error: false, errors: null, success: false } };

    case ADD_IMAGE_BACKGROUND_SUCCESS:
      return { ...state, addCoustomImage: { ...state.addCoustomImage, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case ADD_IMAGE_BACKGROUND_ERROR:
      return { ...state, addCoustomImage: { ...state.addCoustomImage, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_ADD_IMAGE_BACKGROUND:
      return { ...state, addCoustomImage: { ...state.addCoustomImage, loading: false, error: false, success: false } };


      // get coustom image
    case GET_IMAGE_BACKGROUND_LOADING:
      return { ...state, getCoustomImage: { ...state.getCoustomImage, loading: true, data: null, error: false, errors: null, success: false } };

    case GET_IMAGE_BACKGROUND_SUCCESS:
      return { ...state, getCoustomImage: { ...state.getCoustomImage, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_IMAGE_BACKGROUND_ERROR:
      return { ...state, getCoustomImage: { ...state.getCoustomImage, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_IMAGE_BACKGROUND:
      return { ...state, getCoustomImage: { ...state.getCoustomImage, loading: false, error: false, success: false } };


    default:
      return state;
  }
}
