import axios from 'axios';
import classNames from 'classnames'
import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getVideo_Url } from '../../apiSheet';
import Spinner from '../../components/Spinner/Spinner';
import { deleteCompany, getCompanyDetails, updateCompany } from '../../services/Action/userAction';
import { RESET_DELETE_COMAPNY_DETAILS, RESET_UPDATE_COMAPNY } from '../../services/Constant';
import $ from 'jquery'

import Toaster from '../../Toaster/Toaster'

function UpdateCompany(props) {


  const notify = (msg) => toast.error(msg)
  const location = useLocation()
  const navigate = useNavigate()

  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  const numberValidation = RegExp(/^[0-9]*$/)
  const userType = localStorage.getItem('usertype')

  const [loadingMedia, setLoadingMedia] = useState(false)

  const userName = useSelector(state => state.userDetailsReducer.data)?.data?.username
  const initialState = {
    company_name_english: '',
    company_name_chinese: '',
    companydesignation: '',
    image: '',
    video: '',
    description: '',
    contact: '',
    fax: '',
    // telegramId: '',
    email: '',
    website: '',
    // fanpage: '',
    company_order: '',
    socialId: {
      telegramId: '',
      WhatsApp: '',
      SnapChat: '',
      Instagram: '',
      Linkedin: '',
      Youtube: '',
      Skype: '',
      Facebook: '',
      Wechat: '',
      Twitter: '',
      Line: ''
    },
  }



  const socialOptions = [
    // { label: 'WhatsApp', value: 'WhatsApp' },
    // { label: 'SnapChat', value: 'SnapChat' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Instagram', value: 'Instagram' },
    // { label: 'Linkedin', value: 'Linkedin' },
    { label: 'Youtube', value: 'Youtube' },
    // { label: 'Skype', value: 'Skype' },
    // { label: 'Wechat', value: 'Wechat' },
    // { label: 'Twitter', value: 'Twitter' },
    // { label: 'Line', value: 'Line' },
  ]



  const initialError = {
    company_name_english: false,
    company_name_chinese: false,
    companydesignation: false,
    telegramId: false,
    company_order: false,
    email: false,
  }

  const [loading, setLoading] = useState(true)
  const [selectedSocialMedia, setSelectedSocialMedia] = useState(socialOptions)
  const [listSocialIds, setListSocialIds] = useState([])
  const [mediaType, setMediaType] = useState([])
  const [state, setState] = useState([])
  const [errors, setErrors] = useState([])
  const [index, setIndex] = useState(0)

  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })
  // use Effect

  useEffect(() => {
    props.getCompanyDetails()
  }, [])

  useEffect(() => {
    if (!loading) {
      document.getElementsByClassName('dropdown-heading')[0]?.setAttribute("id", "dropdown-heading")
      if (document.querySelectorAll('.dropdown-heading-value>.gray').length !== 0) {
        document.querySelectorAll('.dropdown-heading-value>.gray')[0].innerHTML = 'Select Social Media'
      }
    }
  }, [$(".clear-selected-button").length, selectedSocialMedia, state.socialId])


  useEffect(() => {
    if (props.updateCompanyReducer.success) {
      toast.success('Update Company Successful')
      setTimeout(() => {
        navigate(`/${userName}/2`)
        props.resetUpdateCompany()
      }, 2000);
    }
  }, [props.updateCompanyReducer])

  useEffect(() => {
    const type = state?.map((e, i) => {
      if (state[i]?.image === '') {
        if (state[i]?.video === '') {
          return '0'
        } else {
          return '1'
        }
      } else return "0"
    })
    setMediaType([...type])
  }, [state])

  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [props.backGroundReducer])
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [])


  useEffect(() => {
    setLoading(props.companyDetailsReducer.loading)
    if (props.companyDetailsReducer.success) {
      const detailsReducer = props.companyDetailsReducer.data.data
      const data = detailsReducer.sort((a, b) => a.company_order - b.company_order).map((e, i) => {
        const socialID = {
          // telegramId: e.telegramId ?? '',
          // WhatsApp: e.WhatsApp ?? '',
          // SnapChat: e.SnapChat ?? '',
          Instagram: e.Instagram ?? '',
          Facebook: e.Facebook ?? '',
          Youtube: e.Youtube ?? '',
          telegramId: e.telegramId ?? ''
          // Linkedin: e.Linkedin ?? '',
          // Skype: e.Skype ?? '',
          // Wechat: e.WeChat ?? '',
          // Twitter: e.Twitter ?? '',
          // Line: e.Line ?? ''
        }

        const socialList = { ...socialID }
        Object.keys(socialList)?.map((list) => (socialList[list] === '' || socialList[list] === undefined || socialList[list] === null) && delete socialList[list])
        const list = Object.keys(socialList)?.map(list => {
          return socialOptions.find(option => option.value === list)
        })
        listSocialIds[i] = list
        return {
          _id: e._id,
          company_name_english: e.company_name_english,
          company_name_chinese: e.company_name_chinese,
          companydesignation: e.companydesignation ?? '',
          image: e?.image,
          video: e?.video,
          description: e.description,
          contact: e.contact ?? '',
          fax: e.fax,
          socialId: {
            ...socialID
          },
          email: e.email,
          website: e.website,
          // fanpage: e.fanpage,
          company_order: e.company_order
        }
      })

      const errorList = data?.map(e => {
        return initialError
      })

      if (!!location.state) {
        if (location.state.addMore) {
          setState([...data, { ...initialState }])
          setListSocialIds([...listSocialIds, []])
          setErrors([...errorList, { ...initialError }])
          setIndex([...data, { ...initialState }].length - 1)
        }

      } else {
        setState([...data])
        setErrors([...errorList])
      }

      // setMediaType(data[index]?.image ? "0" : '1')
      // setState([...data])
      // setErrors([...errorList])
    }
  }, [props.companyDetailsReducer])

  // useEffect(() => {
  //   if (listSocialIds !== undefined && listSocialIds?.length !== 0) {
  //     const _list = listSocialIds[index]
  //     setSelectedSocialMedia(_list)
  //   }
  // }, [listSocialIds?.length, index])

  // functions
  const handleInput = (e, index) => {
    const { name, value } = e.target

    const list = state?.map((e, i) => {
      if (i === index) {
        if (name === 'company_order') {
          if (numberValidation.test(value)) {
            return { ...e, company_order: value }
          } else return { ...e, company_order: '0' }
        } else {
          return { ...e, [name]: value }
        }
      } else return e
    })
    setState([...list])


    switch (name) {
      case "company_name_english":
        if (value === '') {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "company_name_english": "Enter Company name in english" }
            } else return e
          })
          setErrors([...list])
        } else {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "company_name_english": false }
            } else return e
          })
          setErrors([...list])
        }
        break;

      case "company_name_chinese":
        if (value === '') {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "company_name_chinese": 'Enter Company name in Chinese' }
            } else return e
          })
          setErrors([...list])
        } else {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "company_name_chinese": false }
            } else return e
          })
          setErrors([...list])
        }
        break;

      case "companydesignation":
        if (value === '') {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "companydesignation": 'Enter Company designation' }
            } else return e
          })
          setErrors([...list])
        } else {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "companydesignation": false }
            } else return e
          })
          setErrors([...list])
        }
        break;
      case "company_order":
        if (value === '' || value === 0) {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "company_order": 'Enter order for company_order' }
            } else return e
          })
          setErrors([...list])
        } else {
          const OrderList = state?.map(e => { return e.company_order.toString() })
          if (OrderList.includes(value)) {
            const list = errors?.map((e, i) => {
              if (i === index) {
                return { ...e, "company_order": "This display order already assigned" }
              } else return e
            })
            // notify(`${t('alredyOrderPresent')}`)
            setErrors([...list])
          } else if (parseInt(value) > state.length) {
            const list = errors?.map((e, i) => {
              if (i === index) {
                return { ...e, "company_order": "This display order can not be grater than lenght of list" }
              } else return e
            })
            // notify(`${t('orderGreaterthanLenght')}`)
            setErrors([...list])
          } else {
            const list = errors?.map((e, i) => {
              if (i === index) {
                return { ...e, "company_order": false }
              } else return e
            })
            setErrors([...list])
          }
        }
        break;

      case "email":
        if (!validEmailRegex.test(value) || value === '') {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "email": "Enter valid email" }
            } else return e
          })
          setErrors([...list])
        } else {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "email": false }
            } else return e
          })
          setErrors([...list])
        }
        break;

      default: break;
    }

  }

  const hadleSocialValue = (e, index) => {
    const { name, value } = e.target
    const list = state?.map((e, i) => {
      if (i === index) {
        return {
          ...e,
          socialId: {
            ...e.socialId,
            [name]: value
          }
        }
      } else return e
    })
    setState([...list])
    switch (name) {
      case "telegramId":
        if (value === '') {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "telegramId": 'Enter TelegamId' }
            } else return e
          })
          setErrors([...list])
        } else {
          const list = errors?.map((e, i) => {
            if (i === index) {
              return { ...e, "telegramId": false }
            } else return e
          })
          setErrors([...list])
        }
        break;
      default: break;
    }
  }


  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const base64 = new FileReader()
    base64.readAsDataURL(file)
    base64.onload = () => {
      resolve(base64.result)
    }
    base64.onerror = (error) => { reject("error", error) }
  })

  const getDuration = (file) => {
    const url = URL.createObjectURL(file)
    return new Promise((resolve) => {
      const video = document.createElement('video')
      const source = document.createElement('source')
      video.preload = 'metadata'
      source.src = url
      video.appendChild(source)
      video.onloadedmetadata = () => {
        resolve(video.duration)
      }
    })

  }

  const getVideoUrl = (video) => {
    return new Promise((resolve, rejcet) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        url: `${getVideo_Url}`,
        data: {
          "video": video
        }
      };

      axios(requestOptions).then(res => {
        resolve(res.data)
      }).catch(error => {
        rejcet("error", error)
      })
    })
  }

  const handleMedia = async (event, index) => {
    const file = event.target.files[0]
    const type = event.target.files[0].type
    const mediaUrl = URL.createObjectURL(event.target.files[0])
    const baseFile = await convertBase64(event.target.files[0]).then(res => { return res })
    const list = state?.map(async (e, i) => {
      if (i === index) {
        if (type.includes('image')) {
          // mediaType[index] = '0'
          return {
            ...e,
            image: mediaUrl,
            video: '',
            _image: baseFile,
            _video: ''
          }
        } else if (type.includes('video')) {
          const duration = await getDuration(file)
          setLoadingMedia(true)
          const video = await getVideoUrl(file).then((res) => {
            setLoadingMedia(false)
            return res
          })
          if (duration <= 60) {
            return {
              ...e,
              image: '',
              video: mediaUrl,
              _video: video.data[0].video,
              _image: ''
            }
          } else {
            notify(`${t('videoMaxSize')}`)
            return {
              ...e,
              image: '',
              video: '',
              _video: '',
              _image: ''
            }
          }
        }
      } else {
        return e
      }
    })
    const result = await Promise.all(list)
    setState([...result])

  }

  const removeMedia = (event, index) => {
    const list = state?.map((e, i) => {
      if (i === index) {
        return {
          ...e,
          image: '',
          video: '',
          _image: '',
          _video: ''
        }
      } else {
        return e
      }
    })
    setState([...list])
  }

  const checkUrl = (str) => {
    if (str !== '' && str !== undefined && str !== null) {
      if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
        return str
      } else {
        return "https://" + str
      }
    } else return ''

  }


  const handlesubmitData = () => {
    // find method

    state.find((e, i) => {
      if (e.company_name_english === '') {
        setIndex(i)
        const list = errors?.map((e, j) => {
          if (j === i) {
            // notify('Enter Company name in english')
            return { ...e, "company_name_english": "Enter Company name in english" }
          } else return e
        })
        setErrors([...list])
      } else if (e.company_name_chinese === '') {
        setIndex(i)
        const list = errors?.map((e, j) => {
          if (j === i) {
            // notify('Enter Company name in Chinese')
            return { ...e, "company_name_chinese": 'Enter Company name in Chinese' }
          } else return e
        })
        setErrors([...list])
      } else if (e.companydesignation === '') {
        setIndex(i)
        const list = errors?.map((e, j) => {
          if (j === i) {
            // notify('Enter Company designation')
            return { ...e, "companydesignation": 'Enter Company designation' }
          } else return e
        })
        setErrors([...list])
      } else if (e.socialId.telegramId === '') {
        setIndex(i)
        const list = errors?.map((e, j) => {
          if (j === i) {
            // notify('Enter TelegamId')
            return { ...e, "telegramId": 'Enter TelegamId' }
          } else return e
        })
        setErrors([...list])
      } else if (e.company_order === 0 || e.company_order === '0' || e.company_order === '') {
        setIndex(i)
        const list = errors?.map((e, j) => {
          if (j === i) {
            // notify('Enter order for company_order')
            return { ...e, "company_order": 'Enter order for company_order' }
          } else return e
        })
        setErrors([...list])
      }
    })

    const isValid = state?.map((e, i) => {
      if (
        e.company_name_english !== '' &&
        e.company_name_chinese !== '' &&
        e.companydesignation !== '' &&
        e.company_order !== '' &&
        e.company_order !== '0' &&
        e.company_order !== 0 &&
        e.socialId.telegramId !== '' &&
        !errors[i].email &&
        !errors[i].company_order
      ) {
        return true
      } else return false
    })

    if (isValid.every(e => e)) {
      const _state = state?.map((e) => {
        const socialList = {
          Youtube: checkUrl(e.socialId.Youtube),
          Instagram: checkUrl(e.socialId.Instagram),
          Facebook: checkUrl(e.socialId.Facebook),
        }

        // Object.keys(socialList).map((list) => (socialList[list] === '' || socialList[list] === undefined || socialList[list] === null) && delete socialList[list])

        return {
          ...(e._id && { _id: e._id }),
          company_name_english: e.company_name_english,
          company_name_chinese: e.company_name_chinese,
          companydesignation: e.companydesignation,
          image: e._image && e._image !== '' && e._image,
          video: e._video && e._video !== '' && e._video,
          description: e.description,
          contact: e.contact,
          fax: e.fax,
          telegramId: e.socialId.telegramId,
          email: e.email,
          website: e.website,
          // fanpage: e.fanpage,
          company_order: e.company_order,
          ...socialList
        }
      })
      const details = {
        user_id: props.userDetailsReducer?.data?.data?._id,
        userDetails: [..._state]
      }
      props.updateCompanyDetails(details)

    }
  }

  // const handleAddmore = () => {
  //   setState([...state, initialState])
  //   setIndex(index + 1)
  //   setListSocialIds([...listSocialIds, []])
  //   setErrors([...errors, initialError])
  // }

  const handleDeleteComapny = () => {
    const id = state[index]?._id
    if (!!id) {
      props.deleteCompany(id)
      // navigate(-1)
      // setIndex(index - 1)

    } else {
      state.splice(index, 1)
      setIndex(index - 1)
    }
  }

  useEffect(() => {
    // setLoading(props.deleteCompanyReducer.loading)
    if (props.deleteCompanyReducer.success) {
      // navigate(`/${username}/2`)
      setIndex(index - 1)
      props.getCompanyDetails()
      props.resetDeleteCompany()
    }
  }, [props.deleteCompanyReducer])


  return (
    <React.Fragment>
      {!userName ? <Spinner /> : <>
        {state.length === 0 ? <> {t('nodetails')} </> : <div className='position-relative'>
          {/*  left */}
          <div className='position-absolute pointer' style={{ left: '-5px', top: '0px' }} onClick={() => setIndex(index - 1)}>
            {state && state.length > 1 && index > 0 && <span>
              <img src='/images/left-arrow.png' width='20px' height='25px' />
            </span>}
          </div>

          {/* right */}
          <div className='position-absolute pointer' style={{ right: '-5px', top: '0px' }} onClick={() => setIndex(index + 1)}>
            {state && state.length > 1 && index < state.length - 1 && <span>
              <img src='/images/right-arrow.png' width='20px' height='25px' />
            </span>}
          </div>


          {/* middle */}
          <div className="">

            {/* add more */}
            <div className="row justify-content-center mt-3 " >
              {state.length > 1 && <div className="text-end mb-2 me-5">
                <span className="bg-red py-2 px-5 pointer text-white rounded" onClick={() => handleDeleteComapny()}>{t('delete')}</span>
              </div>
              }
            </div>
            <div className="row justify-content-center mt-3  mb-2">
              {/* comapny details */}
              <div className="col-12">
                <input type="text" className={classNames("form-control mb-2", {
                  "error": errors[index]?.company_name_english
                })} placeholder={`${t('companyNameEnglish')}`} name='company_name_english' id='company_name_english' value={state[index]?.company_name_english} onChange={(e) => handleInput(e, index)} />
                {
                  errors[index]?.company_name_english && <div className='h6 text-danger ps-3'>{errors[index]?.company_name_english}</div>
                }
              </div>
              <div className="col-12">
                <input type="text" className={classNames("form-control mb-2 ", {
                  "error": errors[index]?.company_name_chinese
                })} placeholder={`${t('companyNameChinese')}`} name='company_name_chinese' id='company_name_chinese' value={state[index]?.company_name_chinese} onChange={(e) => handleInput(e, index)} />
                {
                  errors[index]?.company_name_chinese && <div className='h6 text-danger ps-3'>{errors[index]?.company_name_chinese}</div>
                }
              </div>
              <div className="col-12">
                <input type="text" className={classNames("form-control mb-2 ", {
                  "error": errors[index]?.companydesignation
                })} placeholder={`${t('companyDesignation')}`} name='companydesignation' id='companydesignation' value={state[index]?.companydesignation} onChange={(e) => handleInput(e, index)} />
                {
                  errors[index]?.companydesignation && <div className='h6 text-danger ps-3'>{errors[index]?.companydesignation}</div>
                }
              </div>

              {/* media */}
              <div className="col-12 text-center">
                <div className='profile-img'>
                  <input type="file" className="input-file file-upload d-none" id="file-upload" name='profile_image' accept={userType !== '0' ? 'video/*, image/jpeg, image/jpg, image/png' : 'image/jpeg, image/jpg, image/png'} onChange={(e) => handleMedia(e, index)} />
                  <label htmlFor='file-upload'>
                    {
                      loadingMedia ? <div className='videoImage_Placeholder'><Spinner /></div> : <>
                        {
                          mediaType[index] === '0' ? <>
                            {
                              (state[index]?.image && state[index]?.image !== '') ? <img src={state[index]?.image} id="profileImage" alt="Personal Profile Image" style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: "0px",
                              }} /> : null
                            }</> : <>{
                              (state[index]?.video && state[index]?.video !== '') ? <video controls autoPlay muted loop style={{ objectFit: 'fill' }} src={state[index]?.video}><source id="profileVideo" alt="Personal Profile Video" /></video> : null
                            }</>
                        }
                        {
                          (!state[index]?.image && state[index]?.image === '' && !state[index]?.video && state[index]?.video === '') &&
                          <>
                            <div className=' my-2 pointer videoImage_Placeholder'>
                              <div className='text-center'>
                                {/* <img src='/images/upload.png' width={'30px'} />
                            {userType === '0' ? <div style={{
                              color: theme.font !== '' ? theme.font : '#007CB6',
                            }}>{t('uploadCompanyImage')} ({t('size')} 580*325)</div> : <div className='text-blue'>{t('uploadCompanyImage')} ({t('size')} 580*325)<br /> {t('video')} ({t('2MinVideoSize')})
                            </div>
                            } */}
                                <img src={'/images/loginPage/image.png'} className="img-fluid" />
                                <p className='mb-0'>Please upload </p>
                                <p className='mb-0'>640 width by 360 high Image</p>
                                <p className='mb-0'>or</p>
                                <p className='mb-0 text-yellow'>Premium Member Upload 1 Minute Video</p>
                              </div>
                            </div>
                          </>
                        }
                      </>
                    }

                  </label>
                </div>
                <div className='my-2 d-flex justify-content-center'>
                  <div className='mx-2'>
                    <label htmlFor='file-upload' style={{
                      color: theme.font !== '' ? theme.font : '#ffffff',
                      background: (!!theme.theme && theme.theme !== '') ? theme.theme : '#007CB6',
                    }} className='  py-1 px-3 pointer rounded'>
                      {t('Browse')}
                    </label>
                  </div>
                  <div className='mx-2  py-1 px-3 pointer rounded' style={{
                    color: theme.font !== '' ? theme.font : '#ffffff',
                    background: (!!theme.theme && theme.theme !== '') ? theme.theme : '#007CB6',
                  }} onClick={(e) => removeMedia(e, index)}>{t('Cancel')}</div>
                </div>
              </div>


              {/* description */}
              <div className="col-12">
                <textarea className="form-control compChamber-area" rows={'5'} placeholder={`${t('companyDescription')}`} id="description" name='description' value={state[index]?.description} onChange={(e) => handleInput(e, index)} />
              </div>
            </div>

            {/* <div className="mb-2 mt-2" id='social-select'>
              <MultiSelect
                options={socialOptions}
                value={selectedSocialMedia}
                onChange={(e) => {
                  handleSelectedSocial(e)
                }}
                labelledBy={`${t('selectSocialMedia')}`} />
            </div> */}
            <div className="row justify-content-center gx-2">
              {/* contact details */}
              {/* <div className="col-12 mt-2">
                <input type="number" className="form-control mb-2 " placeholder={`${t('tel/Contact')}`} name='contact' id='contact' value={state[index]?.contact} onChange={(e) => handleInput(e, index)} />
              </div>

              <div className="col-12 mt-2">
                <input type="text" className="form-control mb-2 " placeholder={`${t('fax')}`} name='fax' id='fax' value={state[index]?.fax} onChange={(e) => handleInput(e, index)} />
              </div> */}
              <div className="col-12 col-md-6">
                <input type="text" className="form-control mb-2 " placeholder={`${t('website')}`} name="website" id='website' value={state[index]?.website} onChange={(e) => handleInput(e, index)} />
              </div>
              <div className="col-12 col-md-6">
                <input type="text" className={classNames("form-control mb-2", {
                  "error": errors[index]?.telegramId
                })} placeholder={`https://t.me/Telegram Id`} name='telegramId' id='telegramId' value={state[index]?.socialId.telegramId} onChange={(e) => hadleSocialValue(e, index)} />
                {
                  errors[index]?.telegramId && <div className='h6 text-danger ps-3'>{errors[index]?.telegramId}</div>
                }
              </div>

              {/* <div className="col-12  ">
                <input type="text" className={classNames("form-control mb-2 ", {
                  "error": errors[index]?.email
                })} placeholder={`${t('email')}`} name='email' id='email' value={state[index]?.email} onChange={(e) => handleInput(e, index)} />
              </div> */}

              {/* <div className="col-6 ">
                <input type="text" className="form-control mb-2 " placeholder={`${t('fanPage')}`} name='fanpage' id='fanpage' value={state[index]?.fanpage} onChange={(e) => handleInput(e, index)} />
              </div> */}

              <div className="row justify-content-start gx-2 mb-2">
                {selectedSocialMedia &&
                  selectedSocialMedia?.length !== 0 && selectedSocialMedia?.map((e, i) => {
                    return (
                      <div className="col-md-6 col-12 mb-2" key={i}>
                        <div className="form-group mb-0 d-flex align-items-center" >
                          <input type="text" name={e?.value} id={e?.value} className="form-control ml-5 " value={state[index]?.socialId[e?.value]} onChange={(e) => hadleSocialValue(e, index)}
                            style={{
                            }} placeholder={`https://${e?.label}`} />
                        </div>
                      </div>
                    )
                  })}
              </div>

              {/* company_order */}
              <div className="col-12">
                <input type="number" className={classNames("form-control mb-2", {
                  "error": errors[index]?.company_order
                })} placeholder={`${t('displayOrder')}`} name='company_order' id='company_order' value={state[index]?.company_order} onChange={(e) => handleInput(e, index)} />
                {
                  errors[index]?.company_order && <div className='h6 text-danger ps-3'>{errors[index]?.company_order}</div>
                }
              </div>

              {/* submit */}
              <div className="col-12 bg-red" onClick={handlesubmitData}>
                <button type="submit" className="btn btn-theme1 w-100 "><span className="font-color text-white ">{t('Save')}</span></button>
              </div>
            </div>
          </div>
        </div>}
      </>}
      <Toaster />
    </React.Fragment>
  )
}

const mapStateToProp = state => ({
  companyDetailsReducer: state.companyDetailsReducer,
  userDetailsReducer: state.userDetailsReducer,
  updateCompanyReducer: state.updateCompanyReducer,
  backGroundReducer: state.themeReducer.getBackGround,
  deleteCompanyReducer: state.deleteCompanyReducer,
})

const mapDispatchToProps = dispatch => ({
  getCompanyDetails: user => dispatch(getCompanyDetails(user)),
  updateCompanyDetails: details => dispatch(updateCompany(details)),
  resetUpdateCompany: () => dispatch({ type: RESET_UPDATE_COMAPNY }),
  deleteCompany: id => dispatch(deleteCompany(id)),
  resetDeleteCompany: id => dispatch({ type: RESET_DELETE_COMAPNY_DETAILS }),
})

export default connect(mapStateToProp, mapDispatchToProps)(UpdateCompany)