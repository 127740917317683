import axios from "axios";
import { deleteChamber_Url, getChamberDetails_Url, updateChamber_Url } from "../../../apiSheet";
import { CHAMBER_DETAILS_ERROR, CHAMBER_DETAILS_LOADING, CHAMBER_DETAILS_SUCCESS, DELETE_CHAMBER_DETAILS_ERROR, DELETE_CHAMBER_DETAILS_LOADING, DELETE_CHAMBER_DETAILS_SUCCESS, SAVE_CHAMBER_DETAILS_ERROR, SAVE_CHAMBER_DETAILS_LOADING, SAVE_CHAMBER_DETAILS_SUCCESS } from "../../Constant";

export const getChamberDetails = () => {
 return (dispatch) => {
  dispatch({
   type: CHAMBER_DETAILS_LOADING,
  });
  const requestOptions = {
   method: "GET",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: getChamberDetails_Url,
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: CHAMBER_DETAILS_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: CHAMBER_DETAILS_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: CHAMBER_DETAILS_ERROR,
       errors: validationError
      });
     }
   });
 };
}

export const saveChamberDetails = (details) => {
 return (dispatch) => {
  dispatch({
   type: SAVE_CHAMBER_DETAILS_LOADING,
  });
  const requestOptions = {
   method: "POST",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: updateChamber_Url,
   data: details
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: SAVE_CHAMBER_DETAILS_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: SAVE_CHAMBER_DETAILS_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: SAVE_CHAMBER_DETAILS_ERROR,
       errors: validationError
      });
     }
   });
 };
}

export const deleteChamber = (id) => {
 return (dispatch) => {
  dispatch({
   type: DELETE_CHAMBER_DETAILS_LOADING,
  });
  const requestOptions = {
   method: "DELETE",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: `${deleteChamber_Url}/${id}`,
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: DELETE_CHAMBER_DETAILS_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: DELETE_CHAMBER_DETAILS_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: DELETE_CHAMBER_DETAILS_ERROR,
       errors: validationError
      });
     }
   });
 };
}