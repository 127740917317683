import { GET_PAYMENT_CONFIG_ERROR, GET_PAYMENT_CONFIG_LOADING, GET_PAYMENT_CONFIG_SUCCESS, MEMBERSHIP_CHECKOUT_SESSION_ERROR, MEMBERSHIP_CHECKOUT_SESSION_LOADING, MEMBERSHIP_CHECKOUT_SESSION_SUCCESS, MEMBERSHIP_STRIPE_PAYMENT_ERROR, MEMBERSHIP_STRIPE_PAYMENT_LOADING, MEMBERSHIP_STRIPE_PAYMENT_SUCCESS, PAYPAL_PAYMENT_ERROR, PAYPAL_PAYMENT_LOADING, PAYPAL_PAYMENT_SUCCESS, RESET_GET_PAYMENT_CONFIG, RESET_MEMBERSHIP_CHECKOUT_SESSION, RESET_MEMBERSHIP_STRIPE_PAYMENT, RESET_PAYPAL_PAYMENT, RESET_TONCOIN_PAYMENT, TONCOIN_PAYMENT_ERROR, TONCOIN_PAYMENT_LOADING, TONCOIN_PAYMENT_SUCCESS } from "../../Constant";

let initialState = {
  paypalPayment: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  toncoinPayment: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  stripePayment: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  successPayment: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  getPaymentOptions: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  }

};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {

    // toncoin
    case TONCOIN_PAYMENT_LOADING:
      return { ...state, toncoinPayment: { ...state.toncoinPayment, loading: true, data: null, error: false, errors: null, success: false } };

    case TONCOIN_PAYMENT_SUCCESS:
      return { ...state, toncoinPayment: { ...state.toncoinPayment, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case TONCOIN_PAYMENT_ERROR:
      return { ...state, toncoinPayment: { ...state.toncoinPayment, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_TONCOIN_PAYMENT:
      return { ...state, toncoinPayment: { ...state.toncoinPayment, loading: false, error: false, success: false } };

    // paypal
    case PAYPAL_PAYMENT_LOADING:
      return { ...state, paypalPayment: { ...state.paypalPayment, loading: true, data: null, error: false, errors: null, success: false } };

    case PAYPAL_PAYMENT_SUCCESS:
      return { ...state, paypalPayment: { ...state.paypalPayment, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case PAYPAL_PAYMENT_ERROR:
      return { ...state, paypalPayment: { ...state.paypalPayment, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_PAYPAL_PAYMENT:
      return { ...state, paypalPayment: { ...state.paypalPayment, loading: false, error: false, success: false } };

    // Stripe
    case MEMBERSHIP_CHECKOUT_SESSION_LOADING:
      return { ...state, stripePayment: { ...state.stripePayment, loading: true, data: null, error: false, errors: null, success: false } };

    case MEMBERSHIP_CHECKOUT_SESSION_SUCCESS:
      return { ...state, stripePayment: { ...state.stripePayment, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case MEMBERSHIP_CHECKOUT_SESSION_ERROR:
      return { ...state, stripePayment: { ...state.stripePayment, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_MEMBERSHIP_CHECKOUT_SESSION:
      return { ...state, stripePayment: { ...state.stripePayment, loading: false, error: false, success: false } };

    // Stripe Sucess
    case MEMBERSHIP_STRIPE_PAYMENT_LOADING:
      return { ...state, successPayment: { ...state.successPayment, loading: true, data: null, error: false, errors: null, success: false } };

    case MEMBERSHIP_STRIPE_PAYMENT_SUCCESS:
      return { ...state, successPayment: { ...state.successPayment, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case MEMBERSHIP_STRIPE_PAYMENT_ERROR:
      return { ...state, successPayment: { ...state.successPayment, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_MEMBERSHIP_STRIPE_PAYMENT:
      return { ...state, successPayment: { ...state.successPayment, loading: false, error: false, success: false } };

    // Payment Options
    case GET_PAYMENT_CONFIG_LOADING:
      return { ...state, getPaymentOptions: { ...state.getPaymentOptions, loading: true, data: null, error: false, errors: null, success: false } };

    case GET_PAYMENT_CONFIG_SUCCESS:
      return { ...state, getPaymentOptions: { ...state.getPaymentOptions, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_PAYMENT_CONFIG_ERROR:
      return { ...state, getPaymentOptions: { ...state.getPaymentOptions, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_PAYMENT_CONFIG:
      return { ...state, getPaymentOptions: { ...state.getPaymentOptions, loading: false, error: false, success: false } };

    default:
      return state;
  }
}
