import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function FailedPayment() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <div className='p-4 bg-white'>
      <div>
        <div className='d-flex justify-content-center mb-3'><img src='/images/error.png' style={{ width: '100px' }} /></div>
        <div className='text-center text-danger h5'>{`${t('failedPayment')}`}</div>

        <div className='d-flex justify-content-center mt-3'>
          <div className='bg-red text-white h6 py-2 px-4 border rounded pointer me-2' onClick={() => {
            navigate('/membership-plane')
          }}>{`${t('Retry')}`}</div>
          <div className='bg-red text-white h6 py-2 px-4 border rounded pointer' onClick={() => {
            navigate('/')
          }}>{`${t('Cancel')}`}</div>
        </div>
      </div>
    </div>
  )
}

export default FailedPayment