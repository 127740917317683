import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Banner from '../../components/Banner/Banner'
import { login } from '../../services/Action/Register/registerAction'
// import { getUserDetails } from '../../services/Action/userAction'
// import { RESET_LOGIN } from '../../services/Constant'
import Toaster from '../../Toaster/Toaster'
import { getUserDetails } from '../../services/Action/userAction'

function LoginPage(props) {
  const notify = (msg) => toast(msg)
  const location = useLocation()
  const navigate = useNavigate()
  // const userDetails = useSelector(state => state.loginReducer.data)
  const [state, setState] = useState({
    userName: '',
    password: ''
  })
  const [errors, setErrors] = useState({
    userName: false,
    password: false
  })

  useEffect(() => {
    if (props.loginReducer.error) {
      notify(props.loginReducer.errors)
    } else if (props.loginReducer.success) {
      // props.getUserDetails()
      const profileType = localStorage.getItem("profilestatus")
      const companyType = localStorage.getItem("companystatus")

      if (profileType === '0' && companyType === '0') {
        navigate('/add-profile')
      } else if (profileType !== '0' && companyType === '0') {
        navigate('/add-company')
      } else {
        if (location.state !== null && location.state !== undefined && !!location.state.addToContact) {
          navigate(location.state.addToContact)
        } else {

          props.getUserDetails()
        }
      }
    }
  }, [props.loginReducer])

  useEffect(() => {
    if (props.userDetailsReducer.success) {
      const username = localStorage.getItem("username")
      window.location.reload()
      navigate(`/${username}/1`)
    }
  }, [props.userDetailsReducer])

  const handleInput = (e) => {
    const { name, value } = e.target

    setState(prevState => ({
      ...prevState,
      [name]: value
    }))

    switch (name) {
      case 'userName':
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            userName: 'Enter username'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            userName: false
          }))
        }
        break;

      case 'password':
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            password: 'Enter password'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            password: false
          }))
        }
        break;

      default: break;
    }
  }

  const handleLogin = () => {
    if (state.userName === '') {
      setErrors(prevState => ({
        ...prevState,
        userName: 'Enter Username'
      }))
      notify(errors.userName)
    }
    if (state.password === '') {
      setErrors(prevState => ({
        ...prevState,
        password: 'Enter Password'
      }))
      notify(errors.password)
    }
    if (!errors.userName &&
      !errors.password &&
      state.user !== '' &&
      state.password !== '') {
      const userDetails = {
        username: state.userName,
        password: state.password
      }
      props.login(userDetails)
    }
  }

  const [passwordState, setPasswordState] = useState(false);
  const showPassword = () => {
    if (passwordState) {
      setPasswordState(false)
    } else {
      setPasswordState(true)
    }
  }

  return (
    <React.Fragment>
      <div className="px-0 auth-bg ">
        {/* <div className="cold d-flex justify-content-center">
          <h1>AddMy.Co</h1>
          <a href='https://t.me/addmyCompany' target="blank"><img src="./images/loginPage/telegram.png" height="40px" className="m-sm-3 px-3 " alt="" /></a>
        </div> */}
        <div className="pt-md-5 pt-3 scroll-handler">
          <div className="into ">
            <div className="more ">
              <div className="cold d-flex justify-content-between px-3 position-relative mt-4 align-items-center">
                <a href='https://t.me/addmyCompany' target="blank" className='tg-icon'><img src="./images/message.png" className='mt-0' alt="" /></a>
                <h1 className='mx-3 mb-0'>AddMy.Co</h1>
                <a href='https://t.me/dynamicnamecard' target="blank" className='tg-icon'><img src="./images/coopration.png" className='mt-0' alt="" /></a>
              </div>
              <div className="think_in logo-pos">
                <img src="./images/loginPage/image.png" className="hold_in" alt="" />
                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className="shows mt-60">
                      <div className="input-group text_bold">
                        <input type="text" className={classNames("form-control text-center ", {
                          "error": errors.userName
                        })} placeholder="Username" name='userName' value={state.userName} onChange={handleInput} />
                      </div>
                      {/* {errors.userName && <div className='text-red'>* {errors.userName}</div>} */}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center ">
                  <div className="col-10">
                    <div className="shows mt-2">
                      <div className="text_bold password-field">
                        <input type={passwordState ? "text" : "password"} className={classNames("form-control text-center ", {
                          "error": errors.password
                        })} placeholder="Password" name='password' value={state.password} onChange={handleInput} />
                        <i className={`pointer fa-regular ${passwordState ? "fa-eye-slash" : "fa-regular fa-eye"}`} onClick={showPassword}></i>
                      </div>
                      {/* {errors.password && <div className='text-red'>* {errors.password}</div>} */}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center" >
                  <div className="col-10 pb-3">
                    <div className="d-flex justify-content-center my-1">
                      <a href="/forgot-password" className="list_now">
                        <p className="heading_in text-dark mb-0">forgot password </p>
                      </a>
                      <p className="sold_it px-2 mb-0 list_now ">Or</p>
                      <Link to={'/register'} className="list_now mb-0 text-dark">Register</Link>
                    </div>
                    <button className="over_button p-2 " type='submit' onClick={handleLogin}>Login</button>
                  </div>
                </div>
                <Banner />
              </div>
            </div>
          </div>
        </div>

      </div>
      <Toaster />
    </React.Fragment>
  )
}

// export default LoginPage

const mapStateToProp = state => ({
  loginReducer: state.loginReducer,
  userDetailsReducer: state.userDetailsReducer

})

const mapDispatchToProps = dispatch => ({
  login: user => dispatch(login(user)),
  getUserDetails: user => dispatch(getUserDetails(user)),
  // getUserDetails: user => dispatch(getUserDetails())
  // resetReducer: () => dispatch({type: RESET_LOGIN})
})

export default connect(mapStateToProp, mapDispatchToProps)(LoginPage)