import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import QrCodeGenrate from '../../components/QrCodeGenrate/QrCodeGenrate'
import Spinner from '../../components/Spinner/Spinner'
import { getPublicLandingPage } from '../../services/Action/PublicAction/PublicAction'
// import { getUserDetails } from '../../services/Action/userAction'
import { socialMediaIcons } from '../../services/Constant'
import Toaster from '../../Toaster/Toaster'

function PublicProfile(props) {
  const { username } = useParams()
  const { t } = useTranslation()
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(true)
  const [socialIcon, setSocialIcon] = useState([])

  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })

  const navigate = useNavigate()
  const [state, setState] = useState({
    id: '',
    username: '',
    company_name_english: '',
    company_name_chinese: '',
    owner_name_english: '',
    owner_name_chinese: '',
    profile_image: '',
    socialId: {
      telegramId: '',
      WhatsApp: '',
      SnapChat: '',
      Instagram: '',
      Linkedin: '',
      Youtube: '',
      Skype: '',
      Facebook: '',
      WeChat: '',
      Twitter: '',
      Line: '',
      TikTok: ''
    },
    tags: '',
    video: '',
    contact_no: '',
    email: '',
    address1: '',
    address2: '',
    address3: '',
  })

  // useEffect(() => {
  //   if (token === null) {
  //     props.getPublicLandingPage(username)
  //   }
  // }, []);
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [props.backGroundReducer]);
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, []);

  useEffect(() => {
    if (token !== null) {
      setLoading(props.userDetailsReducer?.loading)
      if (props.userDetailsReducer?.success) {
        const data = props.userDetailsReducer.data.data
        const socialID = {
          telegramId: data.telegramId,
          WhatsApp: data.WhatsApp,
          SnapChat: data.SnapChat,
          Instagram: data.Instagram,
          Linkedin: data.Linkedin,
          Youtube: data.Youtube,
          Skype: data.Skype,
          Facebook: data.Facebook,
          WeChat: data.WeChat,
          Twitter: data.Twitter,
          Line: data.Line,
          Email: data.email,
          TikTok: data.TikTok
        }
        setState(prevState => ({
          ...prevState,
          id: data._id,
          username: data.username,
          company_name_english: data.companydata?.company_name_english,
          company_name_chinese: data.companydata?.company_name_chinese,
          owner_name_english: data.owner_name_english,
          owner_name_chinese: data.owner_name_chinese,
          profile_image: data.profile_image,
          video: data.video,
          socialId: {
            ...prevState.socialId,
            ...socialID
          },
          // tags: data.tags,
          contact_no: data.contact,
          email: data.email,
          address1: data.address1,
          address2: data.address2,
          address3: data.address3,
        }))
      }
    } else {
      // get user details by username and set state
      setLoading(props.PublicLandingPage.loading)
      if (props.PublicLandingPage.success) {
        const data = props.PublicLandingPage.data.data[0]
        const socialID = {
          telegramId: data.telegramId,
          WhatsApp: data.WhatsApp,
          SnapChat: data.SnapChat,
          Instagram: data.Instagram,
          Linkedin: data.Linkedin,
          Youtube: data.Youtube,
          Skype: data.Skype,
          Facebook: data.Facebook,
          WeChat: data.WeChat,
          Twitter: data.Twitter,
          Line: data.Line,
          Email: data.email,
          TikTok: data.TikTok
        }
        setState(prevState => ({
          ...prevState,
          id: data._id,
          username: data.username,
          company_name_english: data.companydata.company_name_english,
          company_name_chinese: data.companydata.company_name_chinese,
          owner_name_english: data.owner_name_english,
          owner_name_chinese: data.owner_name_chinese,
          profile_image: data.profile_image,
          video: data.video,
          socialId: {
            ...prevState.socialId,
            ...socialID
          },
          // tags: data.tags,
          contact_no: data.contact,
          email: data.email,
          address1: data.address1,
          address2: data.address2,
          address3: data.address3,
        }))
      }
    }
  }, [props.userDetailsReducer, props.PublicLandingPage])

  useEffect(() => {
    const list = { ...state.socialId }
    delete list['telegramId']
    delete list['WhatsApp']
    const filterList = []

    Object.values(list).map((value, index) => value && value !== '' && filterList.push(Object.keys(list)[index]))

    const groupList = []
    filterList.map((list, index) => {
      if (index % 5 == 0) {
        const list = filterList.slice(index, index + 5)
        groupList.push(list)
      }
    })
    setSocialIcon([...groupList])

  }, [state.socialId])

  const redirectUrl = (str) => {
    if (str !== '' && str !== undefined) {
      if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
        window.open(str, "_blank")
      } else {
        window.open(`https://${str}`, '_blank')
      }
    } else {
      return
    }

  }

  const CopyContact = async (value) => {
    if (value !== '') {
      await navigator.clipboard.writeText(value).then(() => {
        toast.success('Copied Contact no.')
      })
    }
  }

  return (
    <React.Fragment>
      {
        loading ? <Spinner /> :
          <>
            <div className="row gx-2 justify-content-center mt-1">
              <div className="col-12">
                <div className="preview-badge mb-2 scrollable-field"
                  style={{ color: theme.font !== '' && theme.font, background: (!!theme.theme && theme.theme !== '') ? theme.theme : '#007CB6' }}
                >{state.company_name_english} </div>
              </div>

              <div className="col-12">
                <div className="preview-badge mb-2 scrollable-field"
                  style={{ color: theme.font !== '' && theme.font, background: (!!theme.theme && theme.theme !== '') ? theme.theme : '#007CB6' }}
                >{state.company_name_chinese}</div>
              </div>
            </div>

            <div className="great text-center my-2 profile-box" >
              {/* <img src={state.profile_image} className="img-fluid profile-icon" /> */}
              {
                (state.profile_image && state.profile_image !== '') ? <img src={state.profile_image} className="img-fluid profile-icon d-block mx-auto" style={{ borderRadius: '50%' }} id="profileImage" alt="Personal Profile Image" /> : <>
                  {
                    (state.video && state.video !== '') ? <video controls autoPlay muted loop playsinline className="profile-ico d-block mx-auto" style={{ borderRadius: '50%', objectFit: 'fill' }}><source src={state.video} id="profileVideo" alt="Personal Profile Video" /></video> : <img src={'/images/loginPage/image.png'} id="profileImage" alt="Personal Profile Image" />
                  }
                </>
              }
              {/* <img src={'/images/loginPage/image.png'} className="img-fluid profile-icon" /> */}
            </div>
            <div className="row gx-2 justify-content-center">
              <div className="col-7">
                <div className="preview-badge my-2"
                  style={{ color: theme.font !== '' && theme.font, background: (!!theme.theme && theme.theme !== '') ? theme.theme : '#007CB6' }}
                >{state.owner_name_english} </div>
              </div>

              <div className="col-7">
                <div className="preview-badge mb-2"
                  style={{ color: theme.font !== '' && theme.font, background: (!!theme.theme && theme.theme !== '') ? theme.theme :'#007CB6' }}
                >{state.owner_name_chinese}</div>
              </div>
            </div>
            <div className="your_list text-center mb-2 mt-3">
              <div id="carouselExample" className="carousel slide socialIcon-carousel" >
                <div className="carousel-inner">
                  <div className="carousel-item active social-icon">
                    <a onClick={() => navigate(`/${state.username}/2`)} style={{
                      background: theme.theme !== '' ? theme.theme : '#007CB6'
                    }}><img src='/images/company.svg' className='img-fluid' alt='#'/></a>
                    <a href="#" onClick={() => redirectUrl(state.socialId.WhatsApp)} style={{
                      background: theme.theme !== '' ? theme.theme : '#007CB6'
                    }}><i className="fa-brands fa-whatsapp"></i></a>
                    <a href="#" onClick={() => redirectUrl(`https://t.me/${state.socialId.telegramId}`)} style={{
                      background: theme.theme !== '' ? theme.theme : '#007CB6'
                    }}><i className="fa-brands fa-telegram"></i></a>
                    <a href={`tel:${state.contact_no}`} style={{
                      background: theme.theme !== '' ? theme.theme : '#007CB6'
                    }} onClick={() => { return CopyContact(state.contact_no) }}><i className="fa-solid fa-phone"></i></a>
                    <a onClick={() => navigate(`/${state.username}/3`)} style={{
                      background: theme.theme !== '' ? theme.theme : '#007CB6'
                    }}><img src='/images/chamber.svg' className='img-fluid' alt='#'/></a>
                  </div>
                  {
                    socialIcon?.map((array, index) => {
                      return (
                        <div key={index} className="carousel-item social-icon">
                          {
                            array.map((e, i) => {
                              return (
                                <a href="#"
                                  style={{ background: theme.theme !== '' && theme.theme }}
                                  onClick={() => redirectUrl(e === 'Email' ?
                                    `https://mail.google.com/mail/u/0/?fs=1&to=${state.socialId[e]}&tf=cm`
                                    : state.socialId[e])} key={i}><i className={socialMediaIcons[e]}></i></a>
                                // <a href="#"><img className='img-fluid' src={socialMediaIcons[e]} alt="" key={i} onClick={() => redirectUrl(state.socialId[e])} /></a>
                              )
                            })
                          }
                        </div>)
                    })
                  }

                </div>
                {socialIcon.length > 0 && <button className="carousel-control-prev" type="button" style={{ justifyContent: 'start' }} data-bs-target="#carouselExample" data-bs-slide="prev">
                  <div className='text-black'><img src='/images/left-arrow.png' width='15px' height='30px' /></div>
                </button>}
                {socialIcon.length > 0 && <button className="carousel-control-next" type="button" style={{ justifyContent: 'end' }} data-bs-target="#carouselExample" data-bs-slide="next">
                  <div className='text-black'><img src='/images/right-arrow.png' width='15px' height='30px' /></div>
                </button>}
              </div>
            </div>

            <div className='col-lg-10 col-md-8 col-12 mx-auto'>
              <div className="never_in preview-area profile-area form-control bg-white text-center p-md-3 " style={{
                border: `2px solid ${theme.theme !== '' ? theme.theme : '#000000'}`,
                color: theme.font !== '' ? theme.font : '#4434a1',
                fontSize: '20px',
                minHeight: '126px'
              }}>
                <p className='mb-0'>{state.address1}<br /> {state.address2} <br /> {state.address3}</p>
              </div>
            </div>
            {token === null && <div className="row justify-content-center mt-4 align-items-center">
              <div className="col-auto">
                <div className="for text-center d-grid">
                  <img src="/images/Asset23@3x.png" className="pointer" height="65px" alt="" />
                </div>
              </div>
              <div className="col-auto   d-flex">
                <div className='qr-container'>
                  <QrCodeGenrate url={`https://addmy.co/${state.username}`} />
                </div>
              </div>

              <div className="col-auto">
                <div className="for text-center d-grid">
                  <img src="/images/Asset22@3x.png" className="pointer" height="65px" alt="" />
                </div>
              </div>
            </div>}

            {token !== null && <div className="row mt-3">
              <div className="col text-black col-lg-10 col-md-8 col-12 mx-auto" >
                <div className="justify-content-center bg-red">
                  <button type="submit" className="btn btn-theme1 w-100 " onClick={() => navigate('/update-profile')} ><span className="font-color text-white">{t('updateProfile')}</span></button>
                </div>
              </div>
            </div>}
          </>
      }
      <Toaster />
    </React.Fragment>
  )
}

const mapStateToProp = state => ({
  userDetailsReducer: state.userDetailsReducer,
  PublicLandingPage: state.PublicPageReducer.getLandingPage,
  backGroundReducer: state.themeReducer.getBackGround,
  // addProfileReducer: state.addProfileReducer,

})

const mapDispatchToProps = dispatch => ({
  getPublicLandingPage: (userName) => dispatch(getPublicLandingPage(userName)),
})

export default connect(mapStateToProp, mapDispatchToProps)(PublicProfile)