import { REMOVE_CONTACT_ERROR, REMOVE_CONTACT_LOADING, REMOVE_CONTACT_SUCCESS, RESET_REMOVE_CONTACT } from "../../Constant";

let initialState = {
  loading: false,
  message: null,
  error: false,
  errors: null,
  success: false
};

export default function removeContactReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_CONTACT_LOADING:
      return { ...state, loading: true, error: false, errors: [], success: false };

    case REMOVE_CONTACT_SUCCESS:
      return { ...state, loading: false, error: false, errors: [], success: true, message: action.response.message };

    case REMOVE_CONTACT_ERROR:
      return { ...state, loading: false, error: true, errors: action.errors, success: false, message: null };

    case RESET_REMOVE_CONTACT:
      return { ...state, loading: false, error: false, errors: null, success: false, message: null };

    // case RESET_ADD_COMAPNY:
    //   return { ...state, loading: false, error: false, success: false };

    default:
      return state;
  }
}
