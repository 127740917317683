import { LANGUAGE_ERROR, LANGUAGE_LOADING, LANGUAGE_SUCCESS, RESET_LANGUAGE } from "../../Constant";

let initialState = {
  language: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },

};

export default function languageReducer(state = initialState, action) {
  switch (action.type) {

    // language
    case LANGUAGE_LOADING:
      return { ...state, language: { ...state.language, loading: true, data: null, error: false, errors: null, success: false } };

    case LANGUAGE_SUCCESS:
      return { ...state, language: { ...state.language, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case LANGUAGE_ERROR:
      return { ...state, language: { ...state.language, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_LANGUAGE:
      return { ...state, language: { ...state.language, loading: false, error: false, success: false } };


    default:
      return state;
  }
}
