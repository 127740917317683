import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {QrReader} from 'react-qr-reader'
function Scanner() {
  const [err, setErr] = useState(false);
  const {t} = useTranslation()
  const [result, setResult] = useState('');

  return (
    <div>
      {/* {
        !err ?
          <div>
            <QrReader
              facingMode={'environment'}
              delay={1000}
              onError={handleError}
              onScan={handleScan}
              // chooseDeviceId={()=>selected}
              style={{ width: "300px" }}
            />
            <p>231: {result}</p>
          </div>
          :
          <div className='mt-5 container text-center py-5 '>
            <h6 className='text-blue'>this is not available in web</h6>
          </div>
      } */}
     
      <QrReader
        delay={300}
        style={{ width: "100%" }}
        // onLoad={hadleLoad}
        // onError={handleError}
        // onScan={handleScan}
        onResult={(res, error) => {
          if (!!res) {
            setResult(res?.text);
            window.location.replace(res?.text)
          }
          if (!!error) {
            console.info(error);
            setErr(error)
          }
        }}
        constraints={{ facingMode: 'environment' }}
      />
       {
        err &&
        <h2 className='text-center mt-5 scanner-err d-none d-lg-block'>Requested Device not Found</h2>
      }
      {/* // constraints={{ facingMode: 'environment' }} */}
      {/* <h6 className='mt-4 text-center text-black'>{result}</h6> */}
    </div>
  )
}

export default Scanner