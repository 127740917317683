import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'
import { useTranslation } from 'react-i18next'

function ErrorPayment() {
 const navigate = useNavigate()
 const { t } = useTranslation()

 const params = useParams()
 const userDetails = useSelector(state => state.userDetailsReducer.data)

 const [loading, setLoading] = useState(true)
 useEffect(() => {
  if (!!params.refId && !!userDetails && !!userDetails?.data) {
   if (userDetails?.data?._id !== params.refId) {
    navigate('/')
   } else {
    setLoading(false)
   }
  }
 }, [userDetails])

 return (
  <div className='p-4 bg-white'>
   {loading ? <Spinner /> : <div>
    <div className='d-flex justify-content-center mb-3'><img src='/images/error.png' style={{ width: '100px' }} /></div>
    <div className='text-center text-danger h5'>{`${t('failedPayment')}`}</div>

    <div className='d-flex justify-content-center mt-3'>
     <div className='bg-red text-white h6 py-2 px-4 border rounded pointer me-2' onClick={() => {
      navigate('/referral/membership', {
       state: params
      })
     }}>{`${t('Retry')}`}</div>
     <div className='bg-red text-white h6 py-2 px-4 border rounded pointer' onClick={() => {
      navigate('/referral/membership', {
       state: null
      })
     }}>{`${t('Cancel')}`}</div>
    </div>
   </div>}
  </div>
 )
}

export default ErrorPayment