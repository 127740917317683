import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { findTelegramUser, getCheckOutSession, getReferralTenure } from '../../services/Action/Reffaral/RefferalAction'
import { RESET_CHECKOUT_SESSION, RESET_GET_REFERRAL_TENURE, RESET_SEARCH_TELEGRAM_USER } from '../../services/Constant'
import moment from 'moment/moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserDetails_Url } from '../../apiSheet'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

function ReferralMemberShip(props) {
  const userDetails = useSelector(state => state.userDetailsReducer.data)
  const location = useLocation()
  const { t } = useTranslation()
  const isRefferal = userDetails?.data?.isReferral
  const refStatus = userDetails?.data?.refstatue
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState('')
  const [memberID, setMemberId] = useState(null)
  const [searchTextError, setSearchTextError] = useState(false)
  const [state, setState] = useState({
    tenureList: [],
    selectTenure: ''
  })

  const [isValidUser, setIsValidUser] = useState(false)
  const [buttonShow, setButtonShow] = useState(true)
  const [validationMsg, setValidationMsg] = useState('')

  // const [isList, setIsList] = useState(false)

  useEffect(() => {
    if (parseInt(refStatus) !== 1 && parseInt(isRefferal) !== 1) {
      navigate('/')
    }
  }, [userDetails])

  useEffect(() => {
    if (!!location.state) {
      const details = async () => {
        const requestOptions = {
          method: "POST",
          headers: {
            "content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
          },
          url: `${getUserDetails_Url}`,
          data: {
            id: location.state.memberID
          }
        };
        const userDetails = await axios(requestOptions).then(res => {
          return res.data.data
        })
        setSearchText(userDetails.tgid)
        props.findTelegramUser({
          search: userDetails.tgid
        })
        props.getReferralTenure()
        setMemberId(location.state.membershipId)
        setState(prev => ({
          ...prev,
          selectTenure: location.state.membershipId
        }))
      }
      details()
    }
  }, [location.state])
  console.log("state", state);


  const handleInput = (e) => {
    setMemberId(null)
    setSearchText(e.target.value)
    if (e.target.value !== '') {
      setSearchTextError(false)
    }
    setIsValidUser(false)
    setButtonShow(true)
    setValidationMsg('')

    setState(prev => ({
      ...prev,
      selectTenure: ''
    }))
  }
  const handleSearch = (e) => {
    if (searchText === '') {
      setSearchTextError("Enter telegram username")
    }
    if (searchText !== '') {
      props.findTelegramUser({
        search: searchText
      })
    }
    if (props.referralTenureReducer.success) {
      props.resetgetReferralTenure()
    }
    setButtonShow(false)
  }

  useEffect(() => {
    if (props.searchTelegramUserReducer.success) {
      const data = props.searchTelegramUserReducer.data
      if (data.length !== 0) {
        const _data = data[0]
        if (parseInt(_data.isReferral) === 1) {
          setValidationMsg('You can not add membership for referral user')
          setIsValidUser(true)
          props.resetgetReferralTenure()
        } else {
          const _data = data[0]
          setMemberId(_data._id)
          setValidationMsg('User is verified')
          props.getReferralTenure()
        }
      } else {
        setValidationMsg('Telegram id does not exist in system')
        setIsValidUser(true)
        props.resetgetReferralTenure()
      }
      props.resetFindTelegramUser()
    }
  }, [props.searchTelegramUserReducer])

  useEffect(() => {
    if (props.referralTenureReducer.success) {
      const data = props.referralTenureReducer.data
      setState(prev => ({
        ...prev,
        tenureList: data
      }))
    }
  }, [props.referralTenureReducer])

  const handleCheckOut = () => {
    props.getCheckOutSession({
      user: memberID,
      membership: state.selectTenure
    })
  }

  useEffect(() => {
    if (props.checkoutSessionReducer.success) {
      const data = props.checkoutSessionReducer.data
      window.open(data.url, "_self")
      props.resetcheckout()
    }

  }, [props.checkoutSessionReducer])
  return (
    <div>
      <h1 className="text-center my-2" style={{
        color: '#ffffff',
        background: '#00A3CC'
      }}>{t('addMemberShip')}</h1>
      <div className='h6 mt-3 px-2 py-2 mb-0' style={{
        color: '#ffffff',
        background: '#00A3CC'
      }}>{t('inputTelegram')}</div>
      <div className='mt-3 row justify-content-between'>
        <div className='position-relative contactSearch refMembership col-sm-8 pe-0'>
          <div className='mx-3' style={{ position: 'absolute', left: '15px', top: '7px' }}><b className='d-none d-sm-inline-block'>https://t.me/</b></div>
          <input type="search" id="form1" className="text-start preview-badge w-100 border-0" style={{
            padding: "0px 30px 0px 120px",
            background: '#ffffff',
            color: '#00A3CC',
          }} placeholder={`Search telegram id`} onChange={handleInput} value={searchText} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              return handleSearch()
            } else return
          }} />
        </div>
        {props.searchTelegramUserReducer.success || buttonShow && <div className='col-sm-1 col-2 mx-auto mt-2 mt-sm-0  border py-1 rounded text-white bg-red pointer text-center ' style={{ minWidth: '150px' }} onClick={handleSearch}>Search User</div>}
      </div>
      {!isValidUser && <div className='h6 ps-2 text-success mt-2'>{validationMsg}</div>}
      {
        isValidUser && <h6 className="text-center py-3" style={{ // && search reducer success
          color: 'red',
          background: '#ffffff',
          margin: '100px 0px'
        }}>{validationMsg}</h6>
      }
      {props.referralTenureReducer.success && !isValidUser && validationMsg !== '' && <div className='mt-3'>
        <label><strong>{t('tenureForPrimum')}:</strong></label>
        <select class="form-select" name="catagory"
          value={state.selectTenure}
          onChange={(e) => {
            setState(state => ({
              ...state,
              selectTenure: e.target.value
            }))
          }}>
          <option value=''>{t('selectTenure')}</option>
          {
            state.tenureList.map((e, i) => {
              return <option value={e._id} key={i}>{e.membershiperiod} Year</option>
            })
          }
        </select>
      </div>}
      {state.selectTenure !== '' && <><div className='mt-4'>
        <div className="text-center h4 my-2" style={{
          color: '#ffffff',
          background: '#00A3CC'
        }}>{t('reviewPaymentDetails')}</div>
      </div>
        <div className='bg-white py-4 px-4'>
          <div className='row align-items-center mb-3'>
            <div className='col-md-5  h6'>Telegram id :-</div>
            <input className='col-md-6 border rounded py-1' value={searchText} disabled />
          </div>
          <div className='row align-items-center mb-3'>
            <div className='col-md-5  h6'>Membership Tenure :-</div>
            <input className='col-md-6 border rounded py-1' value={`${state?.tenureList.find(e => e._id === state?.selectTenure)?.membershiperiod} Year`} disabled />
          </div>
          <div className='row align-items-center mb-3'>
            <div className='col-md-5 h6'>Start Date :-</div>
            <input className='col-6 border rounded py-1' value={moment().format("DD-MM-yyyy")} disabled />
          </div>
          <div className='row align-items-center mb-3'>
            <div className='col-md-5 h6'>End Date :-</div>
            <input className='col-6 border rounded py-1' value={moment().add(parseInt(state?.tenureList.find(e => e._id === state?.selectTenure)?.membershiperiod), 'y').format("DD-MM-yyyy")} disabled />
          </div>
          <div className='row align-items-center mb-3'>
            <div className='col-md-5 h6'>Price to pay  :-</div>
            <input className='col-6 border rounded py-1' value={`HK$ ${state?.tenureList.find(e => e._id === state?.selectTenure)?.price}`} disabled />
          </div>
          <div className=' mt-4 border py-1 rounded text-white bg-red pointer text-center ' style={{ minWidth: '150px' }} onClick={handleCheckOut}>{t('ProceedtoPay')}</div>
        </div>
      </>}
    </div>
  )
}

const mapStateToProp = state => ({
  searchTelegramUserReducer: state.RefferalReducer.searchTelegramUser,
  referralTenureReducer: state.RefferalReducer.referralTenure,
  checkoutSessionReducer: state.RefferalReducer.checkoutSession,
})

const mapDispatchToProps = dispatch => ({
  findTelegramUser: detail => dispatch(findTelegramUser(detail)),
  getReferralTenure: detail => dispatch(getReferralTenure(detail)),
  getCheckOutSession: detail => dispatch(getCheckOutSession(detail)),
  resetFindTelegramUser: detail => dispatch({ type: RESET_SEARCH_TELEGRAM_USER }),
  resetgetReferralTenure: detail => dispatch({ type: RESET_GET_REFERRAL_TENURE }),
  resetcheckout: detail => dispatch({ type: RESET_CHECKOUT_SESSION }),
})

export default connect(mapStateToProp, mapDispatchToProps)(ReferralMemberShip)