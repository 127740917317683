import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import { redirect, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LoginPage from './Pages/Login/LoginPage';
import RegisterPage from './Pages/RegisterPage/RegisterPage';
import { connect, useSelector } from 'react-redux';
import LandingPage from './Pages/LandingPage/LandingPage';
import Header from './components/Header/Header';
import Chamber from './Pages/ChamberPage/Chamber';
import BarcodeScanner from './Pages/BarcodeScanner/BarcodeScanner';
import ContactList from './Pages/ContactList/ContactList';
import { getContactNotification, getImessage, getUserDetails } from './services/Action/userAction';
import AddProfile from './Pages/AddProfile/AddProfile';
import AddCompany from './Pages/AddProfile/AddCompany';
import UpdateProfile from './Pages/UpdateProfile/UpdateProfile';
import { RESET_DETAILS, RESET_GET_BACKGROUND, RESET_LANGUAGE, RESET_LOGIN } from './services/Constant';
import Membership_page from './Pages/Membership_Page/Membership_page';
import PageNotFound from './Pages/PageNotFound';
import PaymentDetails from './Pages/Membership_Page/PaymentDetails';
import UpdateCompany from './Pages/UpdateProfile/UpdateCompany';
import $ from 'jquery'
import ScanMyCode from './Pages/Scanner/ScanMyCode';
import Scanner from './Pages/Scanner/Scanner';
import { Modal } from 'react-bootstrap';
import CompanyPreview from './Pages/UpdateProfile/CompanyPreview';
import PreviewProfile from './Pages/UpdateProfile/PreviewProfile';
import ChamberPreview from './Pages/UpdateProfile/ChamberPreview';
import Notification from './Pages/Notification/Notification';
import Landingpage from './Pages/PublicPage/Landingpage';
import Background from './Pages/Theme/Background';
import { getBackground } from './services/Action/Theme/themeAction';
import ThemePage from './Pages/Theme/ThemePage';
import ForgotPassword from './Pages/Login/ForgotPassword';
import IPage from './Pages/Notification/IPage';
import { language } from './services/Action/Language/languageAction';
import { getPublicLandingPage } from './services/Action/PublicAction/PublicAction';
import { useTranslation } from 'react-i18next';
import PublicProfile from './Pages/UpdateProfile/PublicProfile';
import RefferalRequestPage from './Pages/Refferal/RefferalRequestPage';
import RefferalImage from './Pages/Refferal/RefferalImage';
import ReferralMemberShip from './Pages/Refferal/ReferralMemberShip';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReferralContactPage from './Pages/Refferal/ReferralContactPage';
import ReferralReport from './Pages/Refferal/ReferralReport';
import SuccessPayment from './Pages/Refferal/SuccessPayment';
import ErrorPayment from './Pages/Refferal/ErrorPayment';
import FailedPayment from './Pages/Membership_Page/FailedPayment';
import SuccessStripePayment from './Pages/Membership_Page/SuccessStripePayment';

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];
function App(props) {
  const { i18n, t } = useTranslation()
  const token = localStorage.getItem('token')
  const profileType = localStorage.getItem("profilestatus")
  const companyType = localStorage.getItem("companystatus")
  const currentUserName = localStorage.getItem("username")
  const [showQrOption, setShowQrOption] = useState(false)
  const [showSettingOption, setShowSettingOption] = useState(false)
  const [logout, setLogout] = useState(false)
  // const [isShare, setIsShare] = useState(false)
  const [isLanguge, setIsLanguge] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const [publicUser, setIsPublicUser] = useState(false)
  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })
  // const [isDonatorMemberShip, setIsDonatorMembership] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [backgroundImage, setBackGroundImage] = useState(`url('/images/background.jpg')`)
  const navigate = useNavigate()
  const location = useLocation()
  const userDetails = useSelector(state => state.userDetailsReducer.data)
  const userId = userDetails !== null ? userDetails.data._id : null
  const userName = userDetails?.data?.username
  const userType = userDetails?.data?.usertype
  const isRefferal = userDetails?.data?.isReferral
  const refStatus = userDetails?.data?.refstatue
  const refImageStatus = userDetails?.data?.refimgstatue
  const [refferalOption, setRefferalOption] = useState(false)

  useEffect(() => {
    if (token !== null) {
      props.getUserDetails()
      props.getBackGround()
    }
  }, [])
  const [screenView, setScreenView] = useState(false)

  useEffect(() => {
    window.addEventListener("orientationchange", function (e) {
      if (window.matchMedia("(orientation: landscape)").matches) {
        setScreenView(false)
      } else {
        setScreenView(true)
      }
    }, false);
  }, [])

  useEffect(() => {

    if (userId !== null && userId !== undefined) {
      props.getContactNotification(userId)
      props.getImessage(userId)
    }
  }, [userId])

  useEffect(() => {
    if (props.loginReducer.success) {
      props.getUserDetails()
      props.getBackGround()
      if (profileType === '0' && companyType === '0') {
        navigate('/add-profile')
      } else if (profileType !== '0' && companyType === '0') {
        navigate('/add-company')
      } else {
        if (location.state !== null && location.state !== undefined && !!location.state.addToContact) {
          navigate(location.state.addToContact)
        } else {
          navigate(`/${localStorage.getItem('username')}/1`)
        }
      }
      props.resetLogin()
    }
  }, [props.loginReducer])

  useEffect(() => {
    if (props.notificationReducer.success && props.getImessageReducer.success) {
      const data = props.notificationReducer.data.filter(e => e.status === 0)
      const ImessageList = props.getImessageReducer.data
      if (data.length === 0 && ImessageList.length === 0) {
        setIsNotification(false)
      } else {
        setIsNotification(true)
      }
    }
  }, [props.notificationReducer, props.getImessageReducer])


  useEffect(() => {
    if (props.userDetailsReducer.success) {
      const details = userDetails?.data
      setSelectedLanguage((details?.languagetype)?.toString())
      localStorage.setItem("username", details?.username)
      localStorage.setItem("profilestatus", details?.profilestatus)
      localStorage.setItem("paymentstatus", details?.paymentstatus)

      localStorage.setItem("companystatus", details?.companystatus)
      localStorage.setItem("usertype", details?.usertype)
      localStorage.setItem("languageType", details?.languagetype)
    }
  }, [props.userDetailsReducer])

  const navigateSocialIcon = (url) => {
    // document.getElementById('sliderRef').click()
    setShowQrOption(false)
    setShowSettingOption(false)
    setRefferalOption(false)
    if (url === '/referral/membership') {
      navigate(url, {
        state: null
      })
    } else {
      navigate(url)
    }
  }

  useEffect(() => {
    if (props.userDetailsReducer.success) {
      const details = userDetails?.data
      localStorage.setItem("username", details?.username)
      localStorage.setItem("profilestatus", details?.profilestatus)
      localStorage.setItem("paymentstatus", details?.paymentstatus)
      localStorage.setItem("companystatus", details?.companystatus)
      localStorage.setItem("usertype", details?.usertype)
      localStorage.setItem("languageType", details?.languagetype)
    }
  }, [location.pathname])

  useEffect(() => {
    if (token !== null && (location.pathname === '/' || location.pathname === '/login' || location.pathname === '/register') && userName) {
      setIsPublicUser(false)
      navigate(`/${userName}/1`)
    }
  }, [userName, location.pathname])

  $('.offcanvas-backdrop').click(function (e) {
    e.preventDefault();
    setShowQrOption(false)
    setShowSettingOption(false)
    setRefferalOption(false)
  })

  const handleLogout = () => {
    setShowSettingOption(false)
    setRefferalOption(false)
    setLogout(false)
    props.resetUserDetails()
    props.resetBackground()
    window.localStorage.clear()
    navigate('/login')
  }

  useEffect(() => {
    if (token === null && location.pathname === '/') {
      navigate('/login')
    }
  }, [location.pathname])

  useEffect(() => {
    // if (publicUser) {
    if (props.PublicLandingPage.success) {
      const data = props.PublicLandingPage.data.data[0]
      if (data.theme !== undefined && data.theme !== null) {
        const _theme = data.theme
        if (_theme.Thumbnail !== null && _theme.Thumbnail !== undefined) {
          setBackGroundImage(_theme.Thumbnail)
        }
      }
    }
    // }

  }, [publicUser, props.PublicLandingPage])

  useEffect(() => {
    if (!publicUser) {
      if (props.backGroundReducer.success) {
        const image = props.backGroundReducer?.data?.data?.Thumbnail
        const _theme = props.backGroundReducer?.data?.data

        if (_theme !== null) {
          setTheme(prevState => ({
            ...prevState,
            font: _theme?.fontcolor === null ? '' : _theme.fontcolor,
            theme: _theme?.backgroundcolor !== null ? _theme.backgroundcolor : ''
          }))
        }

        if (image !== null && image !== '' && image !== undefined) {
          setTheme(prevState => ({
            ...prevState,
            font: _theme?.fontcolor === null ? '' : _theme?.fontcolor,
            theme: _theme?.backgroundcolor !== null ? _theme?.backgroundcolor : ''
          }))
        }

        if (image !== null && image !== '' && image !== undefined) {
          fetch(image).then(res => {
            if (res.status === 404) {
              setBackGroundImage(`url('/images/background.jpg')`)
            } else {
              setBackGroundImage(`url(${image})`)
            }
          }).catch(er => {
            setBackGroundImage(`url(${image})`)
          })
        }
      }
    }
  }, [props.backGroundReducer, publicUser])

  useEffect(() => {
    if (!publicUser) {
      if (props.backGroundReducer.success) {
        const _theme = props.backGroundReducer?.data?.data

        if (_theme !== null) {
          setTheme(prevState => ({
            ...prevState,
            font: _theme.fontcolor === null ? '' : _theme.fontcolor,
            theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
          }))
        }
      }
    }
  }, [])

  useEffect(() => {
    if (publicUser && props.PublicLandingPage.success) {
      const _theme = props.PublicLandingPage.data.data[0].theme
      if (_theme !== undefined && _theme !== null) {
        const _image = _theme.Thumbnail
        if (_image !== null && _image !== undefined) {
          setBackGroundImage(`url(${_image})`)
        }
      }
    }

  }, [props.PublicLandingPage, publicUser])

  const handleChangeLanguage = () => {
    const details = {
      languagetype: selectedLanguage
    }
    props.language(details)
  }

  useEffect(() => {
    if (props.languageReducer.success) {
      setIsLanguge(false)
      i18n.changeLanguage(selectedLanguage === '1' ? 'en' : selectedLanguage === '2' ? 'zh-Hant' : 'en')
      props.getUserDetails()
      props.getBackGround()
      props.resetLanguge()
      setSelectedLanguage('')
    }
  }, [props.languageReducer])

  let timer;
  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      handleLogout();
    }, 300000); // 10000ms = 10secs. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const [metaDetail, setMetaDetail] = useState({
    compEmg: "",
    compCh: "",
    ownerNameEng: "",
    ownerNameCh: "",
    userName: "",
    image: "",
    video: "",
  });
  useEffect(() => {
    const metaInfo = userDetails?.data;
    setMetaDetail({
      compEmg: metaInfo?.companydata?.company_name_english,
      compCh: metaInfo?.companydata?.company_name_chinese,
      ownerNameEng: metaInfo?.owner_name_english,
      ownerNameCh: metaInfo?.owner_name_chinese,
      userName: metaInfo?.username,
      image: metaInfo?.profile_image,
      video: metaInfo?.video,
    })
  }, [userDetails])

  return (
    <React.Fragment>
      <HelmetProvider>
        <div className="App">
          {!!props.userDetailsReducer.success && !!token &&
            <Helmet>
              <title>{`${metaDetail.compEmg} | ${metaDetail.compCh} \n ${metaDetail.ownerNameEng} | ${metaDetail.ownerNameCh}`}</title>
              <meta property="og:title" content={`${metaDetail.compEmg} | ${metaDetail.compCh} \n ${metaDetail.ownerNameEng} | ${metaDetail.ownerNameCh}`} />

              <meta property="og:url" content={`https://addmy.co/${metaDetail.userName}`} />
              <meta property="og:description" content={`${metaDetail.compEmg} | ${metaDetail.compCh} \n ${metaDetail.ownerNameEng} | ${metaDetail.ownerNameCh}`} />
              {
                metaDetail.image !== '' ? <meta property="og:image" content={metaDetail.image} /> : metaDetail.video !== '' ? <>
                  <meta property="og:video" content={metaDetail.video} />
                  <meta property="og:video:url" content={metaDetail.video} />
                  <meta property="og:video:secure_url" content={metaDetail.video} />
                </> :
                  <meta property="og:image" content="https://addmy.co/images/loginPage/image.png?v=1684916359" />
              }
              <meta property="og:video:width" content="350" />
              <meta property="og:video:height" content="200" />
              <meta property="og:type" content="video.movie" />
            </Helmet>
          }
          {
            screenView &&
            <div className='not-supported'>we do not support horizontal view</div>
          }
          {
            token === null ? <>
              <Routes>
                <Route path='/login' element={<LoginPage />} exact />
                <Route path='/register' element={<RegisterPage exact />} />
                <Route path='/forgot-password' element={<ForgotPassword exact />} />
              </Routes>
            </> :
              <div className='mx-auto position-relative main-boundry'
                style={{
                  backgroundImage: (!backgroundImage || backgroundImage === '') ? `url('/images/background.jpg')` : backgroundImage,
                  border: '2px solid black'
                  // color: theme.font !== '' ? theme.font : null,
                }}>
                <div className='content-area'>
                  <Header isNotification={isNotification} />
                  <div className="position-relative" style={{ margin: '0px 0px 20px 0px' }}>
                    <div className='rounded white-backdrop'>
                      <Routes>
                        <Route path={`/:username/1`} element={<LandingPage />} exact />
                        {/* public */}
                        <Route path='/:username' element={<Landingpage isPublic={setIsPublicUser} />} exact />

                        <Route path='/login' element={() => { redirect('/:username/1') }} />
                        <Route path='/register' element={() => { redirect('/:username/1') }} />
                        {/* add */}
                        <Route path='/add-profile' element={<AddProfile />} exact />
                        <Route path='/add-company' element={<AddCompany />} exact />

                        {/*  preview */}
                        <Route path='/preview-profile' element={<PreviewProfile isPublic={setIsPublicUser} />} exact />
                        <Route path='/:username/profile' element={<PreviewProfile isPublic={setIsPublicUser} />} exact />
                        <Route path={`/:username/2`} element={<CompanyPreview isPublic={setIsPublicUser} />} exact />
                        <Route path={`/:username/3`} element={<ChamberPreview isPublic={setIsPublicUser} />} exact />
                        <Route path={`/:username/5`} element={<PublicProfile isPublic={setIsPublicUser} />} exact />


                        <Route path={`/refferal/:username/image`} element={<RefferalImage />} exact />

                        {/* update */}
                        <Route path='/update-profile' element={<UpdateProfile />} exact />
                        <Route path={`/update-company`} element={<UpdateCompany />} exact />
                        <Route path={`/update-chamber`} element={<Chamber />} exact />
                        {/* notification */}
                        <Route path={`/notification`} element={<Notification />} exact />

                        {/* contact */}
                        <Route path={`/${userName}/4`} element={<ContactList />} exact />
                        {/* <Route path='/template' element={<Template />} exact /> */}
                        <Route path='/Qrcode' element={<BarcodeScanner />} exact />
                        <Route path='/membership-plane' element={<Membership_page />} exact />
                        <Route path='/payment-details/:id/:option' element={<PaymentDetails />} exact />
                        <Route path='/scan-code/:id' element={<ScanMyCode />} exact />
                        <Route path='/scanner' element={<Scanner />} exact />
                        <Route path='/background' element={<Background />} exact />
                        <Route path='/theme' element={<ThemePage />} exact />
                        <Route path='/contact-notification' element={<IPage />} exact />
                        {/* <Route path='/referral-request' element={<RefferalRequestPage />} exact /> */}
                        <Route path='/referral' element={<ReferralContactPage />} exact />
                        <Route path={`/referral/membership`} element={<ReferralMemberShip />} exact />
                        <Route path={`/referral/report`} element={<ReferralReport />} exact />
                        <Route path={`/success/:refId/:memberID/:membershipId`} element={<SuccessPayment />} exact />
                        <Route path={`/failed/:refId/:memberID/:membershipId`} element={<ErrorPayment />} exact />

                        <Route path={`/success/:id`} element={<SuccessStripePayment />} exact />
                        <Route path={`/failed/:id`} element={<FailedPayment />} exact />
                        <Route path='/*' element={<PageNotFound />} exact />
                      </Routes>
                    </div>
                  </div>
                  {
                    (location.pathname !== '/add-profile' &&
                      location.pathname !== '/add-company' &&
                      !location.pathname.includes('/payment-details/')) && <>

                      <div className='bottom-navbar'>
                        <button className='btn' type='button' onClick={(e) => navigateSocialIcon("/preview-profile")}>
                          <img src='/images/profileIcon.png' className='socialIcon pointer' />
                        </button>
                        <button type='button' className='btn'>
                          <img src='/images/subCompany.png' className='socialIcon pointer' onClick={() => navigateSocialIcon(`/${userName}/2`)} />
                        </button>
                        <button className='btn'>
                          <img src='/images/chamberPage.png' className='socialIcon pointer' onClick={() => navigateSocialIcon(`/${userName}/3`)} />
                        </button>

                        <div className='nav-logo pointer' onClick={() => {
                          window.open(userDetails?.data?.logoTelegramUrl !== '' ? userDetails?.data?.logoTelegramUrl : 'https://t.me/dynamicnamecard', '_blank')
                        }}><img src={userDetails?.data?.logoImage !== '' ? userDetails?.data?.logoImage : "/images/loginPage/image.png"} alt="alt" /></div>

                        <button className='btn' type='button'>
                          <img src='/images/heart.png' className='socialIcon pointer' onClick={() => navigateSocialIcon(`/${userName}/4`)} />
                        </button>
                        {/* {parseInt(refStatus) === 1 && parseInt(isRefferal) === 1 && <button className='btn' onClick={() => {
                          setRefferalOption(!refferalOption)
                          setShowQrOption(false)
                          setShowSettingOption(false)
                        }}>
                          <img src='/images/chamberPage.png' className='socialIcon pointer' />
                        </button>
                        } */}
                        {
                          refferalOption && <div className='offcanvas-body' style={{
                            position: 'absolute',
                            right: '200px',
                            // bottom: '227px',
                            top: '-304px',
                            background: (!!theme.theme && theme.theme !== '') ? theme.theme : '#49A0D5',
                            width: '197px'
                          }}>
                            <h6 className='p-3 pointer mb-0' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff'
                            }} onClick={() => {
                              setRefferalOption(false)
                              navigateSocialIcon(`/referral/membership`)
                            }}>
                            {t('Addmembership')}
                            </h6>
                            <hr className='m-0 text-white' />
                            {parseInt(isRefferal) === 1 && parseInt(refImageStatus) === 1 && <><h6 className='p-3 pointer mb-0' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff'
                            }} onClick={() => {
                              setRefferalOption(false)
                              navigateSocialIcon(`/refferal/${currentUserName}/image`)
                            }}>
                              {t('myImages')}
                            </h6>
                              <hr className='m-0 text-white' /></>}
                            <h6 className='p-3 pointer mb-0' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff'
                            }} onClick={() => {
                              setRefferalOption(false)
                              navigateSocialIcon(`/referral/report`)
                            }}>
                              {t('myReport')}
                            </h6>
                          </div>
                        }
                        <button type='button' className='btn'>
                          <img src='/images/scanner-sidebar.png' className='socialIcon pointer' onClick={() => navigateSocialIcon(`/scan-code/${userId}`)} />
                        </button>
                        <button type='button' className='btn' onClick={() => {
                          setShowSettingOption(!showSettingOption)
                          setShowQrOption(false)
                          setRefferalOption(false)
                        }}>
                          <img src='/images/settingIcon.png' className='socialIcon pointer' />
                        </button>
                        {
                          showSettingOption && <div className='offcanvas-body' style={{
                            position: 'absolute',
                            right: '0px',
                            bottom: '70px',
                            background: (!!theme.theme && theme.theme !== '') ? theme.theme : '#49A0D5',
                            width: '197px'
                          }}>

                            {parseInt(refStatus) === 1 && parseInt(isRefferal) === 1 && <><h6 className='p-3 pointer mb-0' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff',
                            }} onClick={() => {
                              setRefferalOption(!refferalOption)
                              setShowQrOption(false)
                            }}>
                              <img src="/images/left-arrow.png" alt="" style={{
                                width: '15px',
                                height: '20px'
                              }}/> {t('Referral')}
                            </h6>
                              <hr className='m-0 text-white' /></>}
                            <h6 className='p-3 pointer mb-0' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff'
                            }} onClick={() => {
                              setShowSettingOption(false)
                              setRefferalOption(false)
                              navigateSocialIcon(`/membership-plane`)
                            }}>
                              {t('membership')}
                            </h6>
                            <hr className='m-0 text-white' />



                            <h6 className='p-3 pointer mb-0' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff'
                            }} onClick={() => {
                              navigateSocialIcon(`/background`)
                            }}>
                              {t('background')}
                            </h6>
                            <hr className='m-0 text-white' />
                            <h6 className='p-3 pointer mb-0' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff'
                            }} onClick={() => {
                              navigateSocialIcon(`/theme`)
                              setShowSettingOption(false)
                              setRefferalOption(false)
                            }}>
                              {t('theme')}
                            </h6>
                            <hr className='m-0 text-white' />

                            <h6 className='p-3 pointer mb-0' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff'
                            }} onClick={() => {
                              setIsLanguge(true)
                              setShowSettingOption(false)
                              setRefferalOption(false)
                              // document.getElementById('sliderRef').click()
                              // navigateSocialIcon(`/membership-plane`)
                            }}>
                              {t('language')}
                            </h6>
                            <hr className='m-0 text-white' />
                            <h6 className='p-3 pointer mb-0' style={{
                              color: theme.font !== '' ? theme.font : '#ffffff'
                            }} onClick={() => {
                              // document.getElementById('sliderRef').click()
                              setShowSettingOption(false)
                              setRefferalOption(false)
                              setLogout(true)
                            }}>
                              {t('logout')}
                            </h6>
                          </div>
                        }
                        {/* </div> */}
                      </div>
                    </>
                  }
                </div>
              </div>
          }

          {
            token === null && (location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/forgot-password' && location.pathname !== '/') && <>
              <div className='mx-auto position-relative main-boundry'
                style={{
                  backgroundImage: (!backgroundImage || backgroundImage === '') ? `url('/images/background.jpg')` : backgroundImage,
                  border: '2px solid black'
                  // color: theme.font !== '' ? theme.font : null,
                }}>
                <div className='content-area'>
                  <Header isNotification={isNotification} />
                  <div className="position-relative" style={{ margin: '0px 0px 20px 0px' }}>
                    <div className='rounded white-backdrop'>
                      <Routes>
                        <Route path='/:username' element={<Landingpage isPublic={setIsPublicUser} />} exact />
                        <Route path='/:username/2' element={<CompanyPreview isPublic={setIsPublicUser} />} exact />
                        <Route path='/:username/3' element={<ChamberPreview isPublic={setIsPublicUser} />} exact />
                        <Route path='/:username/profile' element={<PreviewProfile isPublic={setIsPublicUser} />} exact />
                      </Routes>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          <Modal
            show={logout}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <React.Fragment>
              <div className='p-5'>
                <h5 className='text-blue text-center'>
                  {t('sureLogout')}
                </h5>
                <div className='d-flex justify-content-center mt-4'>
                  <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                    setLogout(false)
                  }}>{t('Cancel')}</div>
                  <div className='border rounded mx-4 px-4 py-2 pointer' onClick={handleLogout}>{t('logout')}</div>
                </div>
              </div>
            </React.Fragment>
          </Modal>

          {/* language */}

          <Modal
            show={isLanguge}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => {
              setIsLanguge(false)
            }}
          >
            <React.Fragment>
              <div className='p-5'>
                <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                  <div className={'d-flex justify-content-between align-items-center py-2 border-bottom'}>
                    <div>English</div>
                    <div><input type={'checkbox'} className='pointer' value='1' checked={selectedLanguage === '1'} onChange={(e) => setSelectedLanguage(e.target.value)} /></div>
                  </div>
                  <div className={'d-flex justify-content-between align-items-center py-2 border-bottom'}>
                    <div>Chiense (Traditional)</div>
                    <div><input type={'checkbox'} className='pointer' value='2' checked={selectedLanguage === '2'} onChange={(e) => setSelectedLanguage(e.target.value)} /></div>
                  </div>
                </div>
                <div className='d-flex justify-content-center mt-4'>
                  <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                    setIsLanguge(false)
                    setSelectedLanguage('')
                  }}>{t('Cancel')}</div>
                  <div className='border rounded mx-4 px-4 py-2 pointer' onClick={handleChangeLanguage}>{t('saveLanguage')}</div>
                </div>
              </div>

            </React.Fragment>
          </Modal>
        </div>
      </HelmetProvider>
    </React.Fragment >
  );
}

const mapStateToProp = state => ({
  loginReducer: state.loginReducer,
  userDetailsReducer: state.userDetailsReducer,
  notificationReducer: state.notificationReducer,
  languageReducer: state.languageReducer.language,
  backGroundReducer: state.themeReducer.getBackGround,
  getImessageReducer: state.getImessageReducer,
  PublicLandingPage: state.PublicPageReducer.getLandingPage,
})

const mapDispatchToProps = dispatch => ({
  getUserDetails: user => dispatch(getUserDetails(user)),
  getPublicLandingPage: (username) => dispatch(getPublicLandingPage(username)),
  getContactNotification: (user_id) => dispatch(getContactNotification(user_id)),
  getImessage: (user_id) => dispatch(getImessage(user_id)),
  getBackGround: () => dispatch(getBackground()),
  language: (details) => dispatch(language(details)),
  resetUserDetails: user => dispatch({ type: RESET_DETAILS }),
  resetLogin: () => dispatch({ type: RESET_LOGIN }),
  resetBackground: () => dispatch({ type: RESET_GET_BACKGROUND }),
  resetLanguge: () => dispatch({ type: RESET_LANGUAGE }),
  // resetUserDetails: () => dispatch({type: RESET_DETAILS })
})
export default connect(mapStateToProp, mapDispatchToProps)(App)