import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getBanner } from '../../services/Action/Register/registerAction'
import Slider from "react-slick";
function PrevArrow(props) {
      const { onClick, style, className } = props;
      return (
            <img src='./images/left-arrow.png' className={className} style={{ ...style, display: "block", zIndex: "9", left: "-8px", cursor: "pointer", width: "15px", height: "auto" }} onClick={onClick} />
      );
}
function NextArrow(props) {
      const { onClick, style, className } = props;
      return (
            <img src='./images/right-arrow.png' className={className} style={{ ...style, display: "block", zIndex: "9", right: "-8px", cursor: "pointer", width: "15px", height: "auto" }} onClick={onClick} />
      );
}
function Banner(props) {
      const [banners, setBanners] = useState([])
      // const navigate = useNavigate()
      const bannerDetails = useSelector(state => state.getBannerReducer.data?.data)
      useEffect(() => {
            if (!props.getBannerReducer.success) {
                  props.banner()
            }
      }, [])

      useEffect(() => {
            if (props.getBannerReducer.success) {
                  bannerDetails?.map((e, i) => {
                        const groupBanner = []
                        if (i % 2 === 0) {
                              const list = bannerDetails.slice(i, i + 2)
                              groupBanner.push(list)
                              setBanners(prevState => [...prevState, list])
                        }
                  })
            }
      }, [props.getBannerReducer])

      var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                  {
                        breakpoint: 767,
                        settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              initialSlide: 1,
                              arrows: false
                        }
                  }
            ]
      };
      const redirectUrl = (str) => {
            if (str !== '' && str !== undefined) {
                  if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
                        return str
                  } else {
                        return `https://${str}`
                  }
            } else {
                  return
            }

      }
      return (
            <div className="container auth-banner">
                  {
                        bannerDetails?.length !== 0 && <Slider {...settings}>
                              {
                                    bannerDetails?.map((banner, i) => {

                                          return (<div className='item' key={i}>
                                                <a href={redirectUrl(banner.Link)} target="blank"><img src={banner?.Banner} className='mx-3 pointer login-banner-img mx-auto' alt={banner?.Title} /></a>
                                          </div>)
                                    })
                              }
                        </Slider>
                  }
            </div>
      )
}

// export default Banner

const mapStateToProp = state => ({
      getBannerReducer: state.getBannerReducer

})

const mapDispatchToProps = dispatch => ({
      banner: user => dispatch(getBanner())
      // resetReducer: () => dispatch({type: RESET_LOGIN})
})

export default connect(mapStateToProp, mapDispatchToProps)(Banner)