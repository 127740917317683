import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { register } from '../../services/Action/Register/registerAction';
import { toast } from 'react-toastify'
import classNames from 'classnames';
import Toaster from '../../Toaster/Toaster';
import { Modal } from 'react-bootstrap';
import Banner from '../../components/Banner/Banner';
import axios from 'axios';
import { RESET_REGISTRATION } from '../../services/Constant';
import { country_Url, getRandomUserName_Url } from '../../apiSheet';
import Spinner from '../../components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

function RegisterPage(props) {
  const notify = (msg) => toast.error(msg)

  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  const validTelegramUser = RegExp(/^[a-zA-Z0-9_]*$/gm)

  const navigate = useNavigate()
  const { t } = useTranslation()


  const [state, setState] = useState({
    tgid: '',
    username: '',
    email: '',
    country: '',
    countryCode: ''
  })

  const loaderStyle = {
    content: {
      border: 'none',
      background: 'transparent'
    }
  }

  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState({
    username: false,
    tgid: false,
    email: false,
  })

  const [isRegister, setIsRegister] = useState(false)

  const getCountryName = async () => {
    let ip = "";
    await axios.get("https://api.ipify.org/?format=json").then(async (res1) => {
      ip = res1.data.ip;
    });
    let url = "https://ipinfo.io/" + ip + "?token=86245e8218f941";
    await axios.get(url).then(async (res) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "content-Type": "application/json",
        },
        url: country_Url,
        data: { country_code: res.data.country }
      };
      await axios(requestOptions).then(res => {
        setState(prevState => ({
          ...prevState,
          country: res?.data?.data[0]?.country_name,
          countryCode: res?.data?.data[0]?.country_code
        }))
      })
    })
  }

  const getRandomName = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      url: getRandomUserName_Url,
    };
    await axios(requestOptions).then(resp => {
      setState(prevState => ({
        ...prevState,
        username: resp.data.username
      }))
    })
  }


  useEffect(() => {
    getCountryName()
    getRandomName()
  }, [])

  useEffect(() => {
    setLoading(props.registrationReducer.loading)
    if (props.registrationReducer.success) {
      setIsRegister(true)
      props.resetRegistration()
    }
    if (props.registrationReducer.error) {
      if (typeof (props.registrationReducer.errors) === 'string') {
        setErrors(prevState => ({
          ...prevState,
          username: props.registrationReducer.errors
        }))
        notify(props.registrationReducer.errors)
        setTimeout(() => {
          navigate('/register')
        }, 2000);

      } else {
        const error = Object.keys(props?.registrationReducer?.errors)
        error.map(e => {
          setErrors(prevState => ({
            ...prevState,
            e: props.registrationReducer.errors[e].message
          }))
          notify(props.registrationReducer.errors[e].message)
          setTimeout(() => {
            navigate('/register')
          }, 2000);
        })
      }
    }

  }, [props.registrationReducer])


  const handleInput = (e) => {
    const { name, value } = e.target

    setState(prevState => ({
      ...prevState,
      [name]: value
    }))

    switch (name) {
      case 'tgid':
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            tgid: 'Enter Telegram username'
          }))
        }
        else if (!isNaN(value[0])) {
          setErrors(prevState => ({
            ...prevState,
            tgid: "First charecter not number"
          }))
        }
        else if (value.length < 5) {
          setErrors(prevState => ({
            ...prevState,
            tgid: "Required minimum 5 charecters"
          }))
        }
        else if (!validTelegramUser.test(value)) {
          setErrors(prevState => ({
            ...prevState,
            tgid: "Enter valid telegram id"
          }))
          notify("Username contain a-z, A-Z, 0-9 and _ value")
        }
        else {
          setErrors(prevState => ({
            ...prevState,
            tgid: false
          }))
        }
        break;

      case 'username':
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            username: 'Enter username'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            username: false
          }))
        }
        break;

      case 'email':
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            email: 'Enter Email'
          }))
        }
        else if (!validEmailRegex.test(value)) {
          setErrors(prevState => ({
            ...prevState,
            email: 'Enter Valid Email'
          }))
        }
        else {
          setErrors(prevState => ({
            ...prevState,
            email: false
          }))
        }
        break;

      default: break;
    }
  }

  const handleSubmit = () => {
    if (state.tgid === '') {
      setErrors(prevState => ({
        ...prevState,
        tgid: 'Enter Telegram username'
      }))
      notify(errors.tgid)
    }
    if (state.username === '') {
      setErrors(prevState => ({
        ...prevState,
        username: 'Enter username'
      }))
    }
    if (state.email === '') {
      setErrors(prevState => ({
        ...prevState,
        email: 'Enter Email'
      }))
      notify(errors.email)
    }
    if (
      state.tgid !== '' &&
      state.username !== '' &&
      state.email !== '' && 
      !errors.tgid) {
      const userDetails = {
        tgid: state.tgid,
        username: state.username,
        email: state.email,
        country: state.country,
        countryCode: state.countryCode
      }
      props.registration(userDetails)
    } else {
      const errorMsg = Object.values(errors).find(e => !!e)
      notify(errorMsg)
    }
  }

  return (
    <React.Fragment>
      <div className="px-0 auth-bg">
        {/* <div className="cold d-flex justify-content-center">
          <h1>AddMy.Co</h1>
          <a href='https://t.me/addmyCompany' target="blank"><img src="/images/loginPage/telegram.png" height="40px" className="m-md-3 px-3 " alt="" /></a>
        </div> */}
        <div className="scroll-handler pt-md-5 pt-3">
          <div className="into">
            <div className="more">
              <div className="cold d-flex justify-content-between px-3 position-relative mt-4 align-items-center">
                <a href='https://t.me/addmyCompany' target="blank" className='tg-icon'><img src="./images/message.png" className='mt-0' alt="" /></a>
                <h1 className='mx-3 mb-0'>AddMy.Co</h1>
                <a href='https://t.me/dynamicnamecard' target="blank" className='tg-icon'><img src="./images/coopration.png" className='mt-0' alt="" /></a>
              </div>
              <div className="think_in logo-pos">
                <img src="/images/loginPage/image.png" height="140px" className="hold_in" alt="" />
                <div className="row justify-content-center ">
                  <div className="col-10  ">
                    <div className="shows mt-60">
                      <div className="input-group text_bold position-relative">
                        <input type="text" className={classNames("form-control text-center ", {
                          "error": errors.tgid
                        })} placeholder={`${t('telegramId')}`} name='tgid' value={state.tgid} onChange={handleInput} />
                        <div className='position-absolute pointer ' data-tooltip-id='tooltip' style={{ top: '5px', right: '5px' }} data-tooltip-html="Use Real TG Username for Register <br/>and Join our Channel verify for <br/> review all Submission,<br /> if Not your registered will be Removed">
                          <Tooltip id='tooltip' />

                          <img src='/images/info.png' height='30px' />
                        </div>
                      </div>
                      {errors.tgid && <div className='text-red'>* {errors.tgid}</div>}
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center ">
                  <div className="col-10">
                    <div className="shows">
                      <div className="input-group mt-2 text_bold">
                        <input type="text" className={classNames("form-control text-center ", {
                          "error": errors.email
                        })} placeholder={`${t('gmailForPassword')}`} name='email' value={state.email} onChange={handleInput} />
                      </div>
                      {errors.email && <div className='text-red'>* {errors.email}</div>}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10 mb-2">
                    <div className="shows">
                      <div className="input-group mt-2 text_bold">
                        <input type="text" className={classNames("form-control text-center ", {
                          "error": errors.country
                        })} placeholder="Select your Country" name='country' value={state.country} onChange={handleInput} disabled />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10 text-center pb-2">
                    <div className='mb-2'> {t('alredyHaveAccount')} <Link to={'/login'} className="list_now text-dark">{t('login')}</Link></div>
                    <button className="over_button p-2 " onClick={handleSubmit}>{t('register')}</button>
                  </div>
                </div>
                <Banner />
              </div>
            </div>

          </div>
        </div>

      </div>
      <Modal
        show={isRegister}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setIsRegister(false)
          setTimeout(() => {
            navigate('/login')
          }, [500])
        }}
      >
        <React.Fragment>
          <div className='p-4 text-center'>
            <div>
              <img src='/images/check.png' style={{ width: '60px' }} />
            </div>
            <h5 className='my-3 fw-m'>
              {t('registerSuccess')}
            </h5>
            <div style={{ fontSize: '14px' }}>
              {t('sendLoginToMail')}
            </div>
            <div className='mt-5'>
              <span className='px-4 py-2 border pointer' style={{
                background: '#20BF55',
                color: '#ffffff',
                borderRadius: '10px'
              }} onClick={() => {
                setIsRegister(false)
                setTimeout(() => {
                  navigate('/login')
                }, [500])
              }}>{t('ok')}</span>
            </div>
          </div>
          <div>
          </div>
        </React.Fragment>
      </Modal>


      <Modal
        show={loading}
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <React.Fragment>
          <div>
            <Spinner />
          </div>
        </React.Fragment>
      </Modal>


      <Toaster />
    </React.Fragment>
  )
}

// export default RegisterPage

const mapStateToProp = state => ({
  registrationReducer: state.registrationReducer
})

const mapDispatchToProps = dispatch => ({
  registration: user => dispatch(register(user)),
  resetRegistration: () => dispatch({ type: RESET_REGISTRATION })
})

export default connect(mapStateToProp, mapDispatchToProps)(RegisterPage)