import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect, useSelector } from 'react-redux'
import { deleteImageAction, editImageAction, getRefferalImage } from '../../services/Action/Reffaral/RefferalAction'
import { RESET_DELETE_IMAGE, RESET_EDIT_IMAGE } from '../../services/Constant'
import { useNavigate } from 'react-router-dom'

function ReferralImageList(props) {
    const [state, setState] = useState({
        image: '',
        imageFile: ''
    })

    const userDetails = useSelector(state => state.userDetailsReducer.data)
    const isRefferal = userDetails?.data?.isReferral
    const refStatus = userDetails?.data?.refstatue
    const navigate = useNavigate()
    useEffect(() => {
        if (parseInt(refStatus) !== 1 && parseInt(isRefferal) !== 1) {
            navigate('/')
        }
    }, [userDetails])

    const [error, setError] = useState(false)

    const [isOpen, setIsopen] = useState(false)

    const handleMedia = (e) => {
        var reader = new FileReader()
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = function (render) {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = render.target.result;

            //Validate the File Height and Width.
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (width > 760 || height > 2100) {
                    setError("Max W 900 x H 2100  allowed")
                } else {
                    if (((e.target.files[0].size / (1024 * 1024)).toFixed(2)) > 1) {
                        setError("Max 1 MB size is allowed")
                    } else {
                        setError(false)
                    }
                }
            };

        }
        setState(prev => ({
            ...prev,
            image: URL.createObjectURL(e.target.files[0]),
            imageFile: e.target.files[0]
        }))
    }

    const handleUpload = () => {
        if (state.imageFile !== '' && !error) {
            props.editImageAction({
                id: props.data.id,
                details: {
                    image: [state.imageFile],
                    name: state.imageFile.name
                }
            })
        }
    }

    useEffect(() => {

        if (props.editImageReducer.success) {
            setState({
                image: '',
                imageFile: ''
            })
            setIsopen(false)
            setError(false)
            props.getRefferalImageAction()
            props.resetEdit()
        }

    }, [props.editImageReducer])


    const handleDelete = () => {
        const imageArray = props?.data.image.split('/')
        const imageName = imageArray[imageArray.length - 1]

        props.deleteImageAction({
            id: props?.data.id,
            name: imageName
        })
    }

    useEffect(() => {
        if (props.deleteImageReducer.success) {
            props.getRefferalImageAction()
            props.resetDelete()
        }
    }, [props.deleteImageReducer])
    return (
        <React.Fragment key={props?.index}>
            <div className='col-sm-6 col-12 text-center searchItem position-relative mb-3'  >
                {/* <img src={props?.list.userdetails?.profile_image !== '' ? `${props?.path}${props?.list.userdetails?.profile_image}` : "/images/loginPage/image.png"} className="pointer contact-img"
                style={{ borderRadius: '50%' }} onError={imgDefault} onClick={() => navigate(`/${props?.list.userdetails?.username}`)} />
         */}
                <div className='position-absolute pointer' style={{ top: '-5px', right: '5px' }} onClick={() => {
                    setIsopen(true)
                    setState(prev => ({
                        ...prev,
                        image: props?.data.image
                    }))
                }}>
                    <img src='/images/editing.png' width='20px' />
                </div>
                <img src={props?.data.image} className=" img-fluid pointer ref-img" onClick={() => {
                    props.setIsopen(true)
                    props.setPrevImage(props?.data.image)
                }} />

                <div className=' border-radius-25 fs-w searchStrEng mt-1 pointer' style={{
                    backgroundColor: (!!props?.theme?.theme && props?.theme?.theme !== '') ? props?.theme.theme : '#007CB6',
                    color: props?.theme.font !== '' ? props?.theme?.font : '#ffffff',
                }}>
                    {props?.catagory.find(e => e._id === props?.data?.categoryname)?.categoryname}
                </div>

                {/* <div className='d-none mt-1 border-radius-25 fs-w searchStrTags'>{props?.list.userdetails?.tags}</div> */}
                <div className='bg-red text-white mt-1 border-radius-25 fs-w pointer' onClick={handleDelete}>Delete</div>
            </div>
            <Modal
                show={isOpen}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size='md'
                onHide={() => setIsopen(false)}
            >
                <div className='py-3'>
                    <div className='text-center mb-4 h3'>Edit Image</div>
                    <div className='rounded mx-5 py-3' style={{
                        border: `2px solid ${error ? 'red' : 'green'}`
                    }}>
                        <div className='profile-img mb-1'>
                            <input type="file" className="input-file file-upload d-none" id="file-upload" name='profile_image' accept={'image/jpeg, image/jpg, image/png'} onChange={(e) => handleMedia(e)} />
                            <label htmlFor='file-upload'>
                                <img src={state.image} id="profileImage" alt="Personal Profile Image" style={{
                                    width: '180px',
                                    height: '250px',
                                    borderRadius: "0px",
                                }} />
                            </label>
                        </div>
                        <div className='my-2 d-flex justify-content-center mb-1'>
                            <div className='mx-2'>
                                <label htmlFor='file-upload' className='  py-1 px-3 pointer rounded text-white bg-red'>
                                    Browse
                                </label>
                            </div>
                            {/* <div className='mx-2  py-1 px-3 pointer rounded' style={{
                                color: props?.theme.font !== '' ? props?.theme.font : '#ffffff',
                                background: (!!props?.theme?.theme && props?.theme?.theme !== '') ? props?.theme?.theme : '#ffffff',
                            }} onClick={(e) => removeMedia(e, index)}>{t('Cancel')}</div> */}
                        </div>
                        <div>
                            {error && <div className='text-center'><h6 className='text-red'>{error}</h6></div>}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center my-4'>
                        <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                            setIsopen(false)
                            setState({
                                image: '',
                                imageFile: ''
                            })
                            setError(false)
                        }}>Cancel</div>
                        {state.imageFile !== '' && !error && <div className='border rounded mx-4 px-4 py-2 pointer text-white bg-red' onClick={handleUpload}>Update</div>}
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProp = state => ({
    editImageReducer: state.RefferalReducer.editImage,
    deleteImageReducer: state.RefferalReducer.deleteImage,
})

const mapDispatchToProps = dispatch => ({
    editImageAction: (details) => dispatch(editImageAction(details)),
    deleteImageAction: (details) => dispatch(deleteImageAction(details)),
    getRefferalImageAction: () => dispatch(getRefferalImage()),
    resetEdit: () => dispatch({ type: RESET_EDIT_IMAGE }),
    resetDelete: () => dispatch({ type: RESET_DELETE_IMAGE }),
})

export default connect(mapStateToProp, mapDispatchToProps)(ReferralImageList)