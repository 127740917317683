import axios from "axios";
import { addToContact_Url, invitation_Url } from "../../../apiSheet";
import { ADD_TO_CONTACT_ERROR, ADD_TO_CONTACT_LOADING, ADD_TO_CONTACT_SUCCESS, INVITATION_ERROR, INVITATION_LOADING, INVITATION_SUCCESS } from "../../Constant";

export const addToContact = (details) => {
 return (dispatch) => {
  dispatch({
   type: ADD_TO_CONTACT_LOADING,
  });
  const requestOptions = {
   method: "POST",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: addToContact_Url,
   data: details,
  };
  axios(requestOptions)
   .then((resp) => {

    dispatch({
     type: ADD_TO_CONTACT_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: ADD_TO_CONTACT_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: ADD_TO_CONTACT_ERROR,
       errors: validationError,
       errorData: data.data.data
      });
     }
   });
 };
}

export const invitation = (details) => {
 return (dispatch) => {
  dispatch({
   type: INVITATION_LOADING,
  });
  const requestOptions = {
   method: "POST",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: invitation_Url,
   data: details,
  };
  axios(requestOptions)
   .then((resp) => {

    dispatch({
     type: INVITATION_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: INVITATION_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: INVITATION_ERROR,
       errors: validationError
      });
     }
   });
 };
}
