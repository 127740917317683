import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import QrCodeGenrate from '../../components/QrCodeGenrate/QrCodeGenrate'
import ShareSocial from '../../components/ShareSocial/ShareSocial'
import Spinner from '../../components/Spinner/Spinner'
import { getContactNotification, getLandingPage } from '../../services/Action/userAction'
import { RESET_ADD_TO_CONTACT, RESET_INVITATION, socialMediaIcons } from '../../services/Constant'
import Toaster from '../../Toaster/Toaster'
import { addToContact, invitation } from '../../services/Action/Contact/contactAction'
import { addContactINFolder, addFolder, getFolder } from '../../services/Action/Folder/folderAction'

function LandingPage(props) {
  const { t } = useTranslation()
  const { username } = useParams()
  const currenUserName = localStorage.getItem('username')
  localStorage.setItem('companystatus', '1')
  const userDetails = useSelector(state => state.landingPageREducer.data)
  const userId = userDetails !== null ? userDetails?.data?._id : null
  const navigate = useNavigate()
  const [deferredPrompt, setDeferredPrompt] = useState(null)

  const [loading, setLoading] = useState(true)
  const [isShare, setIsShare] = useState(false)

  const [showQrOption, setShowQrOption] = useState(false)
  const scanRef = useRef()
  const currentDiv = useRef()

  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })

  const [status, setStatus] = useState(0)

  const [isOpen, setIsOpen] = useState(false)
  const [isAddtoFolderOpen, setIsAddtoFolderOpen] = useState(false)
  const [isSendRequest, setIsSendRequest] = useState(false)
  const [errorData, setErrorData] = useState(null)


  const userType = localStorage.getItem('usertype')

  const [folderList, setFloderList] = useState([])
  const [addtoFolder, setAddtoFolder] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const [folderName, setFolderName] = useState('')

  const notify = (msg) => toast.error(msg)

  const [state, setState] = useState({
    profile_image: '',
    video: '',
    company_name_english: '',
    company_name_chinese: '',
    owner_name_english: '',
    owner_name_chinese: '',
    address1: '',
    address2: '',
    address3: '',
    contact: '',
    socialId: {}
  })

  const [socialIcon, setSocialIcon] = useState([])

  useEffect(() => {
    if (currenUserName !== username) {
      navigate(`/${username}`)
    } else {
      props.getLandingPage()
    }
  }, [currenUserName])

  useEffect(() => {
    if (props.userDetailsReducer.success) {
      const status = props.userDetailsReducer.data.data.companystatus
      localStorage.setItem('companystatus', status)
    }
  }, [props.userDetailsReducer])

  
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }

    }
  }, [props.backGroundReducer])
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [])

  useEffect(() => {
    setLoading(props.landingPageREducer.loading)
    if (props.landingPageREducer.success) {
      const details = userDetails[0]?.userDoc.sort((a, b) => a.company_order - b.company_order)[0]
      setState(prevState => ({
        ...prevState,
        company_name_english: details?.company_name_english,
        company_name_chinese: details?.company_name_chinese,
        profile_image: userDetails[0]?.profile_image,
        video: userDetails[0]?.video,
        owner_name_english: userDetails[0]?.owner_name_english,
        owner_name_chinese: userDetails[0]?.owner_name_chinese,
        address1: userDetails[0]?.address1,
        address2: userDetails[0]?.address2,
        address3: userDetails[0]?.address3,
        contact: userDetails[0]?.contact,
        socialId: {
          Telegram: userDetails[0]?.telegramId,
          Facebook: userDetails[0]?.Facebook,
          Instagram: userDetails[0]?.Instagram,
          SnapChat: userDetails[0]?.SnapChat,
          Line: userDetails[0]?.Line,
          Skype: userDetails[0]?.Skype,
          Twitter: userDetails[0]?.Twitter,
          WeChat: userDetails[0]?.WeChat,
          WhatsApp: userDetails[0]?.WhatsApp,
          Youtube: userDetails[0]?.Youtube,
          Linkedin: userDetails[0]?.Linkedin,
          Email: userDetails[0]?.email,
          TikTok: userDetails[0]?.TikTok
        }
      }))
    }
  }, [props.landingPageREducer])

  useEffect(() => {
    const list = { ...state.socialId }
    delete list['Telegram']
    delete list['WhatsApp']
    const filterList = []

    Object.values(list).map((value, index) => value && value !== '' && filterList.push(Object.keys(list)[index]))

    const groupList = []
    filterList.map((list, index) => {
      if (index % 5 == 0) {
        const list = filterList.slice(index, index + 5)
        groupList.push(list)
      }
    })
    setSocialIcon([...groupList])

  }, [state.socialId])

  const redirectUrl = (str) => {
    if (str !== '' && str !== undefined) {
      if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
        window.open(str, "_blank")
      } else {
        window.open(`https://${str}`, '_blank')
      }
    } else {
      return
    }
  }

  window.addEventListener('beforeinstallprompt', (e) => {
    setDeferredPrompt(e)
  });

  const handleInstallApp = async () => {
    if (deferredPrompt !== null) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        deferredPrompt = null;
      }
    }

  }

  const CopyContact = async (value) => {
    if (value !== '') {
      await navigator.clipboard.writeText(value).then(() => {
        toast.success('Copied Contact no.')
      })
    }
  }


  useEffect(() => {

    setLoading(props.getFolderReducer?.getFolder.loading)
    if (props.getFolderReducer?.getFolder.success) {
      const list = props.getFolderReducer.getFolder.data.data
      setFloderList([...list])
      setIsOpen(false)
    }
  }, [props.getFolderReducer])

  useEffect(() => {
    if (props.addToContactReducer.success) {
      setIsSendRequest(true)
      setTimeout(() => {
        navigate('/')
        setIsSendRequest(false)
        props.resetAddContact()
      }, 3000)
    }
    if (props.addToContactReducer.error) {
      if (!!props.addToContactReducer.errorData) {
        setErrorData(props.addToContactReducer.errorData)
        setIsOpen(true)
      } else {
        notify(props.addToContactReducer.errors)
      }
      props.resetAddContact()
    }
  }, [props.addToContactReducer])

  const handleApprove = (status) => {
    props.invitation({
      status: status,
      id: errorData._id
    })
    setStatus(status)
  }


  useEffect(() => {
    if (props.invitationReducer.success) {
      if (parseInt(status) === 1) {
        props.getFolder()
        setIsAddtoFolderOpen(true)
        props.getContactNotification(userId)
      } else {
        setIsOpen(false)
        setIsAddtoFolderOpen(false)
      }
      props.resetInvitation()
    }

  }, [props.invitationReducer])


  const hadleContactAddToFolder = () => {
    if (!addtoFolder) {
      notify("Select Folder")
    }
    if (!!addtoFolder && !!errorData.contact_id) {
      props.addContactINFolder({
        contact_id: errorData.contact_id,
        folder_id: addtoFolder
      })
    }

  }

  useEffect(() => {
    if (props.addContactInFolderReducer.success) {
      setIsAddtoFolderOpen(false)
      setIsAddMore(false)
      setAddtoFolder(false)
      setFolderName('')
      setErrorData(null)
    }

  }, [props.addContactInFolderReducer])

  const handleAddFolder = () => {
    if (folderName !== '') {
      props.addFolder({ FolderName: folderName })
      setIsAddMore(false)
      setFolderName('')
    }
  }

  useEffect(() => {
    if (props.addFolderReducer.success) {
      setIsAddMore(false)
      setFolderName('')
      props.getFolder()
    }
  }, [props.addFolderReducer])



  return (
    <React.Fragment>
      {
        loading ? <Spinner /> : <div className="" style={{ color: theme.font !== '' ? theme.font : null }}>
          <div className="tags mt-2">
            <div className='preview-badge mb-2 w-100 scrollable-field' style={{
              color: theme.font !== '' ? theme.font : '#ffffff',
              background: theme.theme !== '' ? theme.theme : '#007CB6',
              fontWeight: 'bold',
              fontSize: '1.2rem'
            }}>{state.company_name_english}</div>
            <div className='preview-badge mb-2 w-100 scrollable-field' style={{
              color: theme.font !== '' ? theme.font : '#ffffff',
              background: theme.theme !== '' ? theme.theme : '#007CB6',
              fontWeight: 'bold',
              fontSize: '1.2rem'
            }}>{state.company_name_chinese}</div>
            {/* <div className="input-group mb-2">
              <input type="text" className="form-control text-center bg-blue border-0" value={state.company_name_english} style={{
                borderRadius: '25px',
                background: theme.theme !== '' ? theme.theme : '#0092c8',
                color: theme.font !== '' ? theme.font : '',
                fontWeight: 'bold',
                fontSize: '1.2rem'
              }} disabled />
            </div>
            <div className="input-group mb-2">
              <input type="text" className="form-control text-center bg-blue border-0" value={state.company_name_chinese} style={{
                borderRadius: '25px',
                background: theme.theme !== '' ? theme.theme : '#0092c8',
                color: theme.font !== '' ? theme.font : '',
                fontWeight: 'bold',
                fontSize: '1.2rem'
              }} disabled />
            </div> */}
            <div className="great text-center mt-2 profile-box" >
              {/* <img src={state.profile_image} className="img-fluid profile-icon" /> */}
              {
                (state.profile_image !== '' && state.profile_image !== undefined && state.profile_image !== null) ? <img src={state.profile_image} className="img-fluid profile-icon" style={{ borderRadius: '50%' }} /> : (state.video !== '' && state.video !== undefined && state.video !== null) ?
                  <video controls style={{ borderRadius: '50%', objectFit: 'fill' }} autoPlay muted loop><source src={state.video} id="profileVideo" alt="Personal Profile Video" /></video> :
                  <img src={'/images/loginPage/image.png'} className="img-fluid profile-icon" style={{ borderRadius: '50%' }} />
              }
              {/* <img src={'/images/loginPage/image.png'} className="img-fluid profile-icon" /> */}
            </div>

            <div className="row justify-content-center mt-2">
              <div className="col-7">
                <div className="input-group mb-2">
                  <div className='preview-badge mb-2 w-100' style={{
                    color: theme.font !== '' ? theme.font : '#ffffff',
                    background: theme.theme !== '' ? theme.theme : '#007CB6',
                    fontWeight: 'bold',
                    fontSize: '1.2rem'
                  }}>{state.owner_name_english}</div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center  ">
              <div className="col-7">
                <div className="input-group mb-2">
                  <div className='preview-badge mb-2 w-100' style={{
                    color: theme.font !== '' ? theme.font : '#ffffff',
                    background: theme.theme !== '' ? theme.theme : '#007CB6',
                    fontWeight: 'bold',
                    fontSize: '1.2rem'
                  }}>{state.owner_name_chinese}</div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center my-2">
              <div className="col-lg-12 col-md-8 col-12">
                <div className="your_list text-center ">
                  <div id="carouselExample" className="carousel slide socialIcon-carousel" >
                    <div className="carousel-inner">
                      <div className="carousel-item active social-icon">
                        <a onClick={() => navigate(`/${username}/2`)} style={{
                          background: theme.theme !== '' ? theme.theme : '#007CB6'
                        }}><img src='/images/company.svg' className='img-fluid' alt='#' /></a>
                        <a href="#" onClick={() => redirectUrl(state.socialId.WhatsApp)} style={{
                          background: theme.theme !== '' ? theme.theme : '#007CB6'
                        }}><i className="fa-brands fa-whatsapp"></i></a>
                        <a href="#" onClick={() => redirectUrl(`https://t.me/${state.socialId.Telegram}`)} style={{
                          background: theme.theme !== '' ? theme.theme : '#007CB6'
                        }}><i className="fa-brands fa-telegram"></i></a>
                        <a href={`tel:${state.contact}`} style={{
                          background: theme.theme !== '' ? theme.theme : '#007CB6'
                        }} onClick={() => { return CopyContact(state.contact) }}><i className="fa-solid fa-phone" ></i></a>
                        <a onClick={() => navigate(`/${username}/3`)} style={{
                          background: theme.theme !== '' ? theme.theme : '#007CB6'
                        }}><img src='/images/chamber.svg' className='img-fluid' alt='#' /></a>
                      </div>
                      {
                        socialIcon?.map((array, index) => {
                          return (
                            <div key={index} className="carousel-item social-icon">
                              {
                                array.map((e, i) => {
                                  return (
                                    <a href="#" onClick={() => redirectUrl(e === 'Email' ?
                                      `https://mail.google.com/mail/u/0/?fs=1&to=${state.socialId[e]}&tf=cm`
                                      : state.socialId[e])} key={i} style={{
                                        background: theme.theme !== '' ? theme.theme : '#007CB6'
                                      }}><i className={socialMediaIcons[e]}></i></a>
                                    // <a href="#" onClick={() => redirectUrl(state.socialId[e])} key={i}><img className='img-fluid' src={socialMediaIcons[e]} alt="" /></a>
                                  )
                                })
                              }
                            </div>)
                        })
                      }
                    </div>
                    {socialIcon.length > 0 && <button className="carousel-control-prev" type="button" style={{ justifyContent: 'start' }} data-bs-target="#carouselExample" data-bs-slide="prev">
                      <div className='text-black'><img src='/images/left-arrow.png' width='15px' height='30px' /></div>
                    </button>}
                    {socialIcon.length > 0 && <button className="carousel-control-next" type="button" style={{ justifyContent: 'end' }} data-bs-target="#carouselExample" data-bs-slide="next">
                      <div className='text-black'><img src='/images/right-arrow.png' width='15px' height='30px' /></div>
                    </button>}
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center d-none d-md-flex">
              <div className="col-lg-10 col-md-8 col-12">
                <div className="never_in text-center p-2 preview-area profile-area form-control rounded border-0" style={{
                  background: '#ffffff',
                  // color: theme.font !== '' ? theme.font : '#4434a1',
                  // border: `2px solid ${theme.theme !== '' ? theme.theme : '#dee2e6'}`,
                  fontWeight: 'bold',
                  fontSize: '20px',
                  borderRadius: '10px !important',
                }}>
                  <p>{state.address1}<br /> {state.address2} <br /> {state.address3}</p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-2 align-items-center">
              <div className="col-auto d-flex">
                <div className='qr-container'>
                  <QrCodeGenrate url={`https://addmy.co/${username}`} />
                </div>
              </div>

              {/* <div className="col-auto">
                <div className="for text-center d-grid" onClick={handleInstallApp}>
                  <img src="/images/Asset22@3x.png" className="pointer" height="65px" alt="" />
                </div>
              </div> */}
            </div>

            <div className="row justify-content-center align-items-center">
              <div className="col-auto d-flex">
                <div className='my-3' onClick={() => { setIsShare(true) }}>
                  <img src='/images/shareIcon.png' className='socialIcon pointer' style={{ width: '55px' }} />
                </div>
              </div>
              <div className="col-auto d-flex">
                <div className='my-3' onClick={() => {
                  setShowQrOption(false)
                  navigate(`/scanner`)
                }}>
                  <img src='/images/scannerIcon.png' className='socialIcon pointer' style={{ width: '55px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>}
      <Modal
        show={isShare}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setIsShare(false)
        }}
      >
        <React.Fragment>
          <div className='p-5'>
            <ShareSocial />
          </div>
        </React.Fragment>
      </Modal>




      <Modal
        show={isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setIsOpen(false)
          // setTag(null)
        }}
      >
        <React.Fragment>
          <div className='m-4 text-center'><h5>This user already added you in his contact list , do you want to add him in your contact as well?</h5></div>
          <div className='pt-2 px-5 pb-4 d-flex justify-content-center'>
            <div className='me-2 border py-2 rounded text-white bg-green pointer text-center ' style={{ width: '150px' }}
              onClick={() => handleApprove(1)}
            >{t('Approve')}</div>

            <div className='me-2 border py-2 rounded text-white bg-red pointer text-center ' style={{ width: '150px' }}
              onClick={() => handleApprove(2)}
            >{t('Reject')}</div>
          </div>
          <Toaster />
        </React.Fragment>
      </Modal>

      <Modal
        show={isAddtoFolderOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setIsAddtoFolderOpen(false)
          // setTag(null)
        }}
      >
        <React.Fragment>
          <Modal.Header><h4>In which folder you want to add this contact</h4></Modal.Header>
          <div className='pt-2 px-5 pb-5'>
            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {
                folderList.map((e, i) => {
                  return (
                    <div key={i} className={'d-flex justify-content-between align-items-center py-2 border-bottom'}>
                      <div>{e.Folder}</div>
                      <div><input type={'checkbox'} className='pointer' value={e._id} checked={addtoFolder === e._id} onChange={() => setAddtoFolder(e._id)} /></div>
                    </div>
                  )
                })
              }
            </div>
            {userType !== '0' &&
              <div className='me-2 mt-2 border py-1 rounded text-white bg-red pointer text-center ' style={{ minWidth: '150px' }}
                onClick={() => setIsAddMore(true)}>+ {t('addMoreFolder')}</div>}
            <div>

            </div>
            <div className='d-flex justify-content-center mt-4'>
              <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                setIsAddtoFolderOpen(false)
                setAddtoFolder(null)
              }}>{t('Cancel')}</div>
              <div className='border rounded mx-4 px-4 py-2 pointer' onClick={hadleContactAddToFolder}>{t('addtoFolder')}</div>
            </div>
          </div>
          <Toaster />
        </React.Fragment>
      </Modal>

      <Modal
        show={isAddMore}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      // onHide={() => setIsAddMore(false)}
      >
        <React.Fragment>
          <div className='p-5'>
            <div className='m-auto'>
              <input className='form-control' type="text" name="addFolder" value={folderName} placeholder={`${t('enterFolderName')}`}
                onChange={(e) => {
                  setFolderName(e.target.value)
                }} />
            </div>
            <div className='d-flex justify-content-center mt-4'>
              <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                setIsAddMore(false)
                setFolderName('')
              }}>{t('Cancel')}</div>
              <div className='border rounded mx-4 px-4 py-2 pointer' onClick={handleAddFolder}>{t('create')}</div>
            </div>
          </div>
        </React.Fragment>
      </Modal>
      <Toaster />
    </React.Fragment >
  )
}

const mapStateToProp = state => ({
  landingPageREducer: state.landingPageREducer,
  backGroundReducer: state.themeReducer.getBackGround,
  addToContactReducer: state.contactReducer.addToContact,
  getFolderReducer: state.folderReducer,
  addContactInFolderReducer: state.folderReducer.addContactInFolder,
  addFolderReducer: state.folderReducer.addFolder,
  invitationReducer: state.contactReducer.invitation,
  userDetailsReducer: state.userDetailsReducer
})

const mapDispatchToProps = dispatch => ({
  getLandingPage: () => dispatch(getLandingPage()),
  getContactNotification: (user_id) => dispatch(getContactNotification(user_id)),
  addToContact: (details) => dispatch(addToContact(details)),

  getFolder: () => dispatch(getFolder()),
  addContactINFolder: (details) => dispatch(addContactINFolder(details)),

  invitation: (details) => dispatch(invitation(details)),
  resetInvitation: () => dispatch({ type: RESET_INVITATION }),

  addFolder: (folder) => dispatch(addFolder(folder)),
  resetAddContact: () => dispatch({ type: RESET_ADD_TO_CONTACT }),
})

export default connect(mapStateToProp, mapDispatchToProps)(LandingPage)