import axios from "axios";
import { getPublicChamberPage_Url, getPublicCompanyPage_Url, getPublicLandingPage_Url, getPublicProfile_Url } from "../../../apiSheet";
import { PUBLIC_CHAMBER_PAGE_ERROR, PUBLIC_CHAMBER_PAGE_LOADING, PUBLIC_CHAMBER_PAGE_SUCCESS, PUBLIC_COMPANY_PAGE_ERROR, PUBLIC_COMPANY_PAGE_LOADING, PUBLIC_COMPANY_PAGE_SUCCESS, PUBLIC_LANDING_PAGE_ERROR, PUBLIC_LANDING_PAGE_LOADING, PUBLIC_LANDING_PAGE_SUCCESS, PUBLIC_PROFILE_PAGE_ERROR, PUBLIC_PROFILE_PAGE_LOADING, PUBLIC_PROFILE_PAGE_SUCCESS } from "../../Constant";

export const getPublicLandingPage = (username) => {
  return (dispatch) => {
    dispatch({
      type: PUBLIC_LANDING_PAGE_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      url: `${getPublicLandingPage_Url}`,
      data: { username: username }
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: PUBLIC_LANDING_PAGE_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: PUBLIC_LANDING_PAGE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: PUBLIC_LANDING_PAGE_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const getPublicComapnyPage = (username) => {
  return (dispatch) => {
    dispatch({
      type: PUBLIC_COMPANY_PAGE_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      url: `${getPublicCompanyPage_Url}`,
      data: { username: username }
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: PUBLIC_COMPANY_PAGE_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: PUBLIC_COMPANY_PAGE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: PUBLIC_COMPANY_PAGE_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const getPublicChamberPage = (username) => {
  return (dispatch) => {
    dispatch({
      type: PUBLIC_CHAMBER_PAGE_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      url: `${getPublicChamberPage_Url}`,
      data: { username: username }
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: PUBLIC_CHAMBER_PAGE_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: PUBLIC_CHAMBER_PAGE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: PUBLIC_CHAMBER_PAGE_ERROR,
              errors: validationError
            });
          }
      });
  };
}
export const getPublicProfilePage = (username) => {
  return (dispatch) => {
    dispatch({
      type: PUBLIC_PROFILE_PAGE_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      url: `${getPublicProfile_Url}`,
      data: { username: username }
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: PUBLIC_PROFILE_PAGE_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: PUBLIC_PROFILE_PAGE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: PUBLIC_PROFILE_PAGE_ERROR,
              errors: validationError
            });
          }
      });
  };
}
