import { REGISTRATION_ERORR, REGISTRATION_LOADING, REGISTRATION_SUCCESS, RESET_REGISTRATION } from "../../Constant";

let initialState = {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false
};

export default function registrationReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTRATION_LOADING:
      return { ...state, loading: true, error: false, errors: [], success: false };
    case REGISTRATION_SUCCESS:
      return { ...state, loading: false, error: false, errors: [], success: true, data: action.data };
    case REGISTRATION_ERORR:
      return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };
    case RESET_REGISTRATION:
      return { ...state, loading: false, error: false, success: false, errors: null };

    default:
      return state;
  }
}
