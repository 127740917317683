import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { getReferralReport } from '../../services/Action/Reffaral/RefferalAction'
import {
  BootstrapTable,
  TableHeaderColumn,
} from "react-bootstrap-table";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUsername_Url } from '../../apiSheet';
import { CSVLink } from 'react-csv';

function ReferralReport(props) {
  const [state, setState] = useState([])

  const userDetails = useSelector(state => state.userDetailsReducer.data)
  const location = useLocation()
  const isRefferal = userDetails?.data?.isReferral
  const refStatus = userDetails?.data?.refstatue

  useEffect(() => {
    if (parseInt(refStatus) !== 1 && parseInt(isRefferal) !== 1) {
      navigate('/')
    }
  }, [userDetails])
  
  useEffect(() => {
    props.getReferralReport()
  }, [])

  const navigate = useNavigate()

  useEffect(() => {
    if (props.ReferralReportReducer.success) {
      const data = props.ReferralReportReducer.data
      console.log("data", data);
      const list = data.map(async (e, i) => {
        const requestOptions = {
          method: "POST",
          headers: {
            "content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
          },
          url: `${getUsername_Url}`,
          data: {
            tgid: e.freemember_tgid
          }
        };
        const username = await axios(requestOptions).then(res => {
          return res.data.username
        })
        return {
          ...e,
          srNo: i + 1,
          membership_period: `${e.membership_period} Year`,
          price: `HK$ ${e.price}`,
          // exp_date: moment(e.join_date, 'DD-MM-yyyy').add(e.membership_period, 'year').format('DD-MM-yyyy'),
          status: moment(e.join_date, 'DD-MM-yyyy').add(e.membership_period, 'year').isAfter(moment()) ? 'Active' : 'Expired',
          username: username
        }
      })
      Promise.all(list).then(res => {
        setState(res)
      })
    }
  }, [props.ReferralReportReducer])

console.log("state", state);

  const renderSizePerPageDropDown = (props) => {
    const limits = [];
    props.sizePerPageList.forEach((limit) => {
      limits.push(
        <DropdownItem
          key={limit}
          onClick={() => props.changeSizePerPage(limit)}
        >
          {limit}
        </DropdownItem>
      );
    });
    return (
      <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
        <DropdownToggle color="default" caret>
          {props.currSizePerPage}
        </DropdownToggle>
        <DropdownMenu>{limits}</DropdownMenu>
      </Dropdown>
    );
  };

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    noDataText: 'No Referral Available',
    // searchField: createCustomSearchField,
    sizePerPageDropDown: renderSizePerPageDropDown,
  };

  const statusFormate = (cell) => {
    return cell === 'Active' ? <td className='text-success'>{cell}</td> : <td className='text-danger'>{cell}</td>
  }
  const csvHeaders = [
    { label: "Member telegram Id", key: "freemember_tgid" },
    { label: "Tenure", key: "membership_period" },
    { label: "Active On", key: "join_date" },
    // { label: "Expire On", key: "exp_date" },
    { label: "Status", key: "status" },
    // { label: "Contact", key: "contact" },
  ];

  const csvReport = {
    data: state.filter(e => e !== undefined),
    headers: csvHeaders,
    filename: 'addmy.co referral_report.csv'
  }

  return (
    <div>
      <h1 className="text-center my-2" style={{
        color: '#ffffff',
        background: '#00A3CC'
      }}>Referral Report</h1>

      {state.length !== 0 && <div className='my-3 d-flex justify-content-end'>
        <CSVLink {...csvReport} style={{ textDecoration: 'none' }}>
          <div className='text-center'>
            <div className='text-white bg-red rounded py-2 px-3 pointer text-decoration-none' >Export Referral Report</div>
          </div>
        </CSVLink>
      </div>}

      <div className='py-4 px-3 bg-white '>

        <BootstrapTable
          bordered={false}
          data={state}
          version="4"
          // pagination
          // selectRow={{
          //   mode: "checkbox",
          //   onSelect: (value, item) => handleSelectRow("select", value, item),
          //   onSelectAll: (value, item) => handleSelectRow("all", item, value),
          // }}
          options={options}
          // search
          scrollTop={"top"}
          tableContainerClass={`table-responsive table-striped table-hover`}
        >

          <TableHeaderColumn dataField="srNo" width='50' isKey>
            <span className="fs-sm">NO.</span>
          </TableHeaderColumn>
          {/* <TableHeaderColumn dataField="referral_user_tgid" width='200'>
      <span className="fs-sm">User TG id</span>
     </TableHeaderColumn> */}

          <TableHeaderColumn dataField="freemember_tgid" width='200' dataFormat={(cell, value) => {
            return <td className='pointer h6' style={{color: 'blue'}} onClick={() => {
              navigate(`/${value.username}`)
            }}><u>{cell}</u></td>
          }}>
            <span className="fs-sm">Member TG id</span>
          </TableHeaderColumn>
          <TableHeaderColumn dataField="membership_period" width='100' >
            <span className="fs-sm">Tenure</span>
          </TableHeaderColumn>
          <TableHeaderColumn dataField="join_date" width='100'>
            <span className="fs-sm">Active On</span>
          </TableHeaderColumn>
          <TableHeaderColumn dataField="price" width='100'>
            <span className="fs-sm">Price(HK$)</span>
          </TableHeaderColumn>
          {/* <TableHeaderColumn dataField="exp_date" width='100'>
            <span className="fs-sm">Expire On</span>
          </TableHeaderColumn> */}
          <TableHeaderColumn dataField="status" width='150' dataFormat={statusFormate}>
            <span className="fs-sm">Status</span>
          </TableHeaderColumn>

          {/* <TableHeaderColumn
          isKey
          dataField="id"
          dataFormat={actionFormatter.bind(this)}
        >
          <span className="fs-sm text-center d-block">Actions</span>
        </TableHeaderColumn> */}
        </BootstrapTable>
        <div className='mt-4 text-center h6'>You have upgraded/renewed <u>{state.length}</u> member</div>
      </div>
    </div>
  )
}
const mapStateToProp = state => ({
  ReferralReportReducer: state.RefferalReducer.ReferralReport,
})

const mapDispatchToProps = dispatch => ({
  getReferralReport: detail => dispatch(getReferralReport(detail)),
})

export default connect(mapStateToProp, mapDispatchToProps)(ReferralReport)