// export const baseUrl = 'http://192.168.0.49:4000'
export const baseUrl = 'https://admin.addmy.co';

export const login_Url = `${baseUrl}/login`
export const register_Url = `${baseUrl}/register`
export const registrationBanner_Url = `${baseUrl}/banner`
export const forgotPassword_Url = `${baseUrl}/forgotpassword`

export const getProfile_Url = `${baseUrl}/getprofile`
export const getCompany_Url = `${baseUrl}/getcompanyprofile`
export const addProfile_Url = `${baseUrl}/addprofile`
export const addComapny_Url = `${baseUrl}/companyprofile`
export const updateProfile_Url = `${baseUrl}/updateprofile`
export const updateCompany_Url = `${baseUrl}/updatecompany`
export const deleteCompany_Url = `${baseUrl}/deletecompany`
export const landingPage_Url = `${baseUrl}/landingpage`

export const SystemImage_Url = `${baseUrl}/system`
export const country_Url = `${baseUrl}/country`

//  contact
export const getContactList_Url = `${baseUrl}/getcontact`
export const removeContactList_Url = `${baseUrl}/removefromcontact`
export const getContactNotification_Url = `${baseUrl}/contactlist`

//  member ship
export const memberTenure_Url = `${baseUrl}/membershiptenure`
export const paymentDetails_Url = `${baseUrl}/purchase`

// payment
export const toncoinPayment_Url = `${baseUrl}/toncoin`
export const paypalPayment_Url = `${baseUrl}/paypal`
export const payment_Url = `${baseUrl}/toncoinpaypal`

// chamber
export const getChamberDetails_Url = `${baseUrl}/getchamber`
export const updateChamber_Url = `${baseUrl}/updatechamber`
export const deleteChamber_Url = `${baseUrl}/deletechamber`

// folder
export const getFolder_Url = `${baseUrl}/getfolder`
export const addFolder_Url = `${baseUrl}/addfolder`
export const addContactFolder_Url = `${baseUrl}/addcontactfolder`

// video
export const getVideo_Url = `${baseUrl}/video`
export const chamberVideo_Url = `${baseUrl}/chambervideo`

// add to contcat and notification
export const addToContact_Url = `${baseUrl}/addtocontact`
export const invitation_Url = `${baseUrl}/invitationcontact`

// uniq username
export const getRandomUserName_Url = `${baseUrl}/username`

// public api
export const getPublicLandingPage_Url = `${baseUrl}/getlandingpage`
export const getPublicCompanyPage_Url = `${baseUrl}/getcompany`
export const getPublicChamberPage_Url = `${baseUrl}/getchambers`
export const getPublicProfile_Url = `${baseUrl}/getprofiles`

//  background images

export const getBackground_Url = `${baseUrl}/getbackground`
export const setBackground_Url = `${baseUrl}/backgroundimage`

//  background images
export const getNotification_Url = `${baseUrl}/getnotification`

//  language
export const language_Url = `${baseUrl}/language`

//  cdelete folder
export const deleteFolder_Url = `${baseUrl}/deletefolder`
export const updateFolder_Url = `${baseUrl}/editfolder`

// public background
export const addImageBackGround_Url = `${baseUrl}/image`
export const getImageBackGround_Url = `${baseUrl}/getimage`

//  delete notification
export const deleteNotification_Url = `${baseUrl}/deletenotification`

//  send requssest for  refferal 

export const addReferral_Url = `${baseUrl}/addReferral`

export const getImageCatagory_Url = `${baseUrl}/categorylist`
export const getRefferalImage_Url = `${baseUrl}/getrefimageupload`


export const uploadImage_Url = `${baseUrl}/refimageupload`
export const editImage_Url = `${baseUrl}/refimageupload`
export const deleteImage_Url = `${baseUrl}/deleteimage`

export const searchTelegramUsername_Url = `${baseUrl}/searchtelegramid`
export const getReferralTenure_Url = `${baseUrl}/referralmembershiplist`
export const getReferralReport_Url = `${baseUrl}/referralReportlist`


export const checkout_session_Url = `${baseUrl}/create-checkout-session`
export const paymentSuccess_Url = `${baseUrl}/success`
export const getUsername_Url = `${baseUrl}/getusername`
export const getUserDetails_Url = `${baseUrl}/getuserdetails`


export const getPaymentOption_Url = `${baseUrl}/getpaymentconfiguration`

export const membershipCheckOut_Url = `${baseUrl}/stripe_payment`
export const success_membership_Url = `${baseUrl}/success_stripe_payment`