import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Toaster from '../../Toaster/Toaster'
import { toast } from 'react-toastify'

function RefferalRequestPage(props) {

 const [loading, setLoading] = useState(true)

 const userDetails = useSelector(state => state.userDetailsReducer.data)
 const userName = userDetails?.data?.username
 const UserType = userDetails?.data?.usertype


 const navigate = useNavigate()

 useEffect(() => {
  if (!!userName && !!UserType && parseInt(UserType) === 0) {
   navigate(`/${userName}`)
   toast.error("You need to Purchase Premium Membership for become Refferal ")
  } else {
   setLoading(false)
  }
 }, [UserType, userName])


 // const handleSendRequest = () => {
 //  props.sendReffaralRequest()
 // }


 // useEffect(() => {

 //  if (props.sendRequestReducer.success) {
 //   props.getUserDetails()
 //  }

 // }, [props.sendRequestReducer])


 return (
  <div className='bg-white'>
   {!loading && <>
    <div className='py-5 px-3'>
     <div className='text-center'>
      <h5>You are not a referral user, to become a refferal user connect with us on our Telegram Channle </h5>
     </div>
     <div className='d-flex justify-content-center align-items-center py-2'>
      <div className='border rounded py-2 px-4 pointer bg-red text-white' onClick={() => {
       window.open("https://t.me/AddmyCompany", "_blank")
      }}>Connect now</div>
     </div>
    </div>

   </>}
   <Toaster />
  </div>
 )
}


const mapStateToProp = state => ({
 // sendRequestReducer: state.RefferalReducer.sendRequest,
})

const mapDispatchToProps = dispatch => ({
 // sendReffaralRequest: user => dispatch(sendReffaralRequest(user)),
 // getUserDetails: user => dispatch(getUserDetails(user))
})

export default connect(mapStateToProp, mapDispatchToProps)(RefferalRequestPage)
