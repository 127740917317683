import { IMASG_ERROR, IMASG_LOADING, IMASG_SUCCESS, RESET_IMASG } from "../../Constant";

let initialState = {
 loading: false,
 data: null,
 error: false,
 errors: null,
 success: false
};

export default function getImessageReducer(state = initialState, action) {
 switch (action.type) {
  case IMASG_LOADING:
   return { ...state, loading: true, error: false, errors: [], success: false };

  case IMASG_SUCCESS:
   return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

  case IMASG_ERROR:
   return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

  case RESET_IMASG:
    return { ...state, loading: false, error: false, success: false };

  default:
   return state;
 }
}
