
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../../components/Spinner/Spinner'
import { getPublicComapnyPage, getPublicLandingPage } from '../../services/Action/PublicAction/PublicAction'
import { deleteCompany, getCompanyDetails } from '../../services/Action/userAction'
import { RESET_DELETE_COMAPNY_DETAILS } from '../../services/Constant'
import Toaster from '../../Toaster/Toaster'
import { hexagonalIcon } from '../../services/Constant'

function CompanyPreview(props) {
  const { username } = useParams()
  const { t } = useTranslation()
  const userType = localStorage.getItem('usertype')
  const navigate = useNavigate()
  const currentUser = localStorage.getItem('username')
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(true)
  const [state, setState] = useState([])
  const [index, setIndex] = useState(0)
  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })
  const [bottomIcon, setBottomIcon] = useState([])

  useEffect(() => {
    if (token !== null && currentUser === username) {
      props.getCompanyDetails()
      if (props.backGroundReducer.success) {
        const _theme = props.backGroundReducer?.data?.data
        if (_theme !== null) {
          setTheme(prevState => ({
            ...prevState,
            font: _theme.fontcolor === null ? '' : _theme.fontcolor,
            theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
          }))
        }
      }
    } else {
      props.isPublic(true)
      props.getPublicComapnyPage(username)
      props.getPublicLandingPage(username)
    }
  }, [])

  useEffect(() => {
    const socialList = state.map((e, i) => {
      const list = {
        'Telegram': e.socialId.Telegram,
        'Youtube': e.socialId.Youtube,
        'Facebook': e.socialId.Facebook,
        'Instagram': e.socialId.Instagram,
        'website': e.website,
        // 'fanpage': e.chamberfanpage,
      }
      const filterList = []

      Object.values(list).map((value, index) => value && value !== '' && filterList.push(Object.keys(list)[index]))

      const groupList = []
      filterList.map((list, index) => {
        if (index % 5 == 0) {
          const list = filterList.slice(index, index + 5)
          groupList.push(list)
        }
      })
      return [...groupList]
    })

    setBottomIcon([...socialList])
  }, [state])

  useEffect(() => {
    if (currentUser === username) {
      if (props.backGroundReducer.success) {
        const _theme = props.backGroundReducer?.data?.data
        if (_theme !== null) {
          setTheme(prevState => ({
            ...prevState,
            font: _theme.fontcolor === null ? '' : _theme.fontcolor,
            theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
          }))
        }
      }
    }
  }, [props.backGroundReducer])

  useEffect(() => {
    if (currentUser !== username) {
      if (props.PublicLandingPage.success) {
        const _theme = props.PublicLandingPage.data.data[0].theme
        if (_theme !== null && _theme !== undefined) {
          setTheme(prevState => ({
            ...prevState,
            font: _theme.fontcolor === null ? '' : _theme.fontcolor,
            theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
          }))
        }
      }
    }
  }, [props.PublicLandingPage])

  useEffect(() => {
    setLoading(props.userDetailsReducer.loading)
    if (props.userDetailsReducer.success) {
      const status = props.userDetailsReducer.data.data.companystatus
      localStorage.setItem('companystatus', status)
    }
  }, [props.userDetailsReducer])

  useEffect(() => {
    // const companyStatus = localStorage.getItem('companystatus')
    if (token !== null && currentUser === username) {
      setLoading(props.companyDetailsReducer.loading)
      if (props.companyDetailsReducer.success) {
        const detailsReducer = props.companyDetailsReducer.data.data
        const status = props.userDetailsReducer.data.data.companystatus
        if (status === '0') {
          navigate('/add-company')
        } else if (detailsReducer.length === 0) {
          navigate(`/update-company`)
        }
        else {
          const data = detailsReducer.sort((a, b) => a.company_order - b.company_order).map(e => {
            const socialID = {
              Telegram: e.telegramId,
              WhatsApp: e.WhatsApp,
              SnapChat: e.SnapChat,
              Instagram: e.Instagram,
              Linkedin: e.Linkedin,
              Youtube: e.Youtube,
              Skype: e.Skype,
              Facebook: e.Facebook,
              WeChat: e.WeChat,
              Twitter: e.Twitter,
              Line: e.Line,
            }
            return {
              _id: e._id,
              company_name_english: e.company_name_english,
              company_name_chinese: e.company_name_chinese,
              companydesignation: e.companydesignation ?? '',
              image: e?.image,
              video: e?.video,
              description: e.description,
              contact: e.contact ?? '',
              fax: e.fax,
              telegramId: e.telegramId,
              email: e.email,
              website: e.website,
              // fanpage: e.fanpage,
              company_order: e.company_order,
              socialId: {
                ...socialID
              },
            }
          })
          setState([...data])
        }
      }
    } else {
      //  public company details and set state
      setLoading(props.PublicComapnyPageReducer.loading)
      if (props.PublicComapnyPageReducer.success) {
        const detailsReducer = props.PublicComapnyPageReducer.data.data
        if (detailsReducer.length === 0) {
          navigate(`/${username}`)
        } else {
          const data = detailsReducer.sort((a, b) => a.company_order - b.company_order).map(e => {
            const socialID = {
              Telegram: e.telegramId,
              WhatsApp: e.WhatsApp,
              SnapChat: e.SnapChat,
              Instagram: e.Instagram,
              Linkedin: e.Linkedin,
              Youtube: e.Youtube,
              Skype: e.Skype,
              Facebook: e.Facebook,
              WeChat: e.WeChat,
              Twitter: e.Twitter,
              Line: e.Line,
            }
            return {
              _id: e._id,
              company_name_english: e.company_name_english,
              company_name_chinese: e.company_name_chinese,
              companydesignation: e.companydesignation ?? '',
              image: e?.image,
              video: e?.video,
              description: e.description,
              contact: e.contact ?? '',
              fax: e.fax,
              telegramId: e.telegramId,
              email: e.email,
              website: e.website,
              // fanpage: e.fanpage,
              company_order: e.company_order,
              socialId: {
                ...socialID
              },
            }
          })
          setState([...data])
        }
      }
    }
  }, [props.companyDetailsReducer, props.PublicComapnyPageReducer])

  const redirectUrl = (str) => {
    if (str !== '' && str !== undefined) {
      if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
        window.open(str, "_blank")
      } else {
        window.open(`https://${str}`, '_blank')
      }
    } else {
      return
    }
  }

  const CopyContact = async (value) => {
    if (value !== '') {
      await navigator.clipboard.writeText(value).then(() => {
        toast.success('Copied Contact no.')
      })
    }
  }

  return (
    <React.Fragment>
      {
        loading ? <Spinner /> :
          <>
            <div className='position-relative'>
              {/*  left */}
              <div className='position-absolute pointer' style={{ left: '-21px', top: '71px' }} onClick={() => setIndex(index - 1)}>
                {state && state.length > 1 && index > 0 && <span>
                  <img src='/images/left-arrow.png' width='15px' height='40px' />
                </span>}
              </div>

              {/* right */}
              <div className='position-absolute pointer' style={{ right: '-21px', top: '71px' }} onClick={() => setIndex(index + 1)}>
                {state && state.length > 1 && index < state.length - 1 && <span>
                  <img src='/images/right-arrow.png' width='15px' height='40px' />
                </span>}
              </div>

              <div className="row justify-content-between">
                <div className="col-12 mb-3">
                  <div className="your_list text-center">
                    <div id="carouselExample" className="carousel slide socialIcon-carousel" >
                      <div className="carousel-inner">
                        <div className="carousel-item active social-icon d-flex justify-content-between">
                          <a onClick={() => navigate(`/${username}/profile`)} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }}><img src='/images/profile.svg' className='img-fluid' alt='#' /></a>
                          {/* <a href={`https://mail.google.com/mail/u/0/?fs=1&to=${state[index]?.email}&tf=cm`} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }}><i className="fa-solid fa-envelope"></i></a> */}
                          <a href="#" onClick={() => redirectUrl(state[index].socialId.WhatsApp)} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }}><i className="fa-brands fa-whatsapp"></i></a>
                          <a href="#" onClick={() => redirectUrl(`${state[index].socialId.Telegram}`)} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }}><i className="fa-brands fa-telegram"></i></a>
                          <a href={`tel:${state[index].contact}`} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }} onClick={() => { return CopyContact(state[index].contact) }}><i className="fa-solid fa-phone"></i></a>
                          <a onClick={() => navigate(`/${username}/3`)} style={{
                            background: theme.theme !== '' ? theme.theme : '#007CB6'
                          }}><img src='/images/chamber.svg' className='img-fluid' alt='#' /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classNames("preview-badge mb-2 scrollable-field")} style={{
                color: theme.font !== '' && theme.font,
                background: theme.theme !== '' && theme.theme
              }}>{state[index]?.company_name_english}</div>
              <div className={classNames("preview-badge mb-2 scrollable-field")} style={{
                color: theme.font !== '' && theme.font,
                background: theme.theme !== '' && theme.theme
              }}>{state[index]?.company_name_chinese}</div>
              <div className={classNames("preview-badge mb-2")} style={{
                color: theme.font !== '' && theme.font,
                background: theme.theme !== '' && theme.theme
              }}>{state[index]?.companydesignation}</div>

              <div className='profile-img'>
                <label htmlFor='file-upload' style={{
                  // border: `2px solid ${theme.theme !== '' ? theme.theme : '#2FB0E9'}`,
                  borderRadius: '10px'
                }}>
                  {
                    (state?.[index]?.video && state?.[index]?.video !== '') ? <video controls autoPlay muted loop style={{ objectFit: 'fill' }} src={state?.[index]?.video}><source id="profileVideo" alt="Personal Profile Video" /></video> :
                      (state?.[index]?.image && state?.[index]?.image !== '') ? <img src={state?.[index]?.image} id="profileImage" alt="Personal Profile Image" style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: "0px",
                      }} /> : <img src={'/images/loginPage/image.png'} id="profileImage" alt="Personal Profile Image" />
                  }
                </label>
              </div>

              <div className='mt-2 custom-preview'>
                <div className="preview-area form-control"
                  style={{
                    // color: theme.font !== '' && theme.font,
                    // border: `2px solid ${theme.theme !== '' ? theme.theme : '#dee2e6'}`,
                    // background: theme.theme !== '' && theme.theme,
                  }}
                >{state[index]?.description !== '' ? state[index]?.description : 'Company description'}</div>
              </div>

              <div className="row justify-content-between">
                <div className="col-12 mb-3">
                  <div className="your_list text-center ">
                    <div id="carouselExample2" className="carousel slide socialIcon-carousel" >
                      <div className="carousel-inner">
                        {
                          bottomIcon[index]?.map((array, i) => {
                            return (
                              <div key={i} className={classNames("carousel-item social-icon octagone-icons mt-3", {
                                'active': i === 0
                              })}>
                                {
                                  array.map((e, i) => {
                                    return <div key={i} onClick={() => {
                                      if (e === 'Telegram') {
                                        redirectUrl(state[index].socialId.Telegram)
                                      } else if (e === 'website') {
                                        redirectUrl(state[index]?.website)
                                      } else {
                                        redirectUrl(state[index].socialId[e])
                                      }
                                    }} >
                                      <a href={'#'} style={{
                                        background: theme.theme !== '' ? theme.theme : '#007CB6'
                                      }}> <i className={hexagonalIcon[e]}></i></a>

                                    </div>

                                  })
                                }
                              </div>)
                          })
                        }
                      </div>
                      {/* {bottomIcon?.[index]?.length > 1 && <button className="carousel-control-prev" type="button" style={{ justifyContent: 'start' }} data-bs-target="#carouselExample2" data-bs-slide="prev">
                        <div className='text-black'><img src='/images/left-arrow.png' width='15px' height='30px' /></div>
                      </button>}
                      {bottomIcon?.[index]?.length > 1 && <button className="carousel-control-next" type="button" style={{ justifyContent: 'end' }} data-bs-target="#carouselExample2" data-bs-slide="next">
                        <div className='text-black'><img src='/images/right-arrow.png' width='15px' height='30px' /></div>
                      </button>} */}
                    </div>
                  </div>
                </div>
              </div>

              {(token !== null && currentUser === username) && <div className="row justify-content-center">
                <div className="col-sm-6 col-6 text-black" >
                  <div className="justify-content-center bg-red">
                    <button type="submit" className="btn btn-theme1 w-100 " onClick={() => navigate(`/update-company`)} ><span className="font-color text-white">{t('updateCompany')}</span></button>
                  </div>
                </div>
                {((userType === '1' || userType === '2') || (userType === '0' && state.length < 3)) && <div className="col-sm-6 col-6 text-black" >
                  <div className="justify-content-center">
                    {/* <button type="submit" className="btn btn-theme1 w-100 " onClick={() => handleDeleteComapny(state[index]?._id)} ><span className="font-color text-white">{t('delete')}</span></button> */}
                    <button type="submit" className="btn btn-theme1 bg-green w-100 rounded-0" onClick={() => navigate(`/update-company`, {
                      state: {
                        addMore: true
                      }
                    })}><span className="font-color text-white">{t('AddmoreCompany')}</span></button>
                  </div>
                </div>}
              </div>}
            </div>
          </>
      }
      <Toaster />
    </React.Fragment >
  )
}
const mapStateToProp = state => ({
  companyDetailsReducer: state.companyDetailsReducer,
  PublicComapnyPageReducer: state.PublicPageReducer.getCompanyPage,
  deleteCompanyReducer: state.deleteCompanyReducer,
  backGroundReducer: state.themeReducer.getBackGround,
  PublicLandingPage: state.PublicPageReducer.getLandingPage,
  userDetailsReducer: state.userDetailsReducer,
  // updateCompanyReducer: state.updateCompanyReducer,
})

const mapDispatchToProps = dispatch => ({
  getCompanyDetails: user => dispatch(getCompanyDetails(user)),
  getPublicComapnyPage: username => dispatch(getPublicComapnyPage(username)),
  deleteCompany: id => dispatch(deleteCompany(id)),
  getPublicLandingPage: (username) => dispatch(getPublicLandingPage(username)),
  resetDeleteCompany: id => dispatch({ type: RESET_DELETE_COMAPNY_DETAILS }),
  // updateCompanyDetails: details => dispatch(updateCompany(details)),
  // resetUpdateCompany: () => dispatch({type: RESET_UPDATE_COMAPNY}),

})

export default connect(mapStateToProp, mapDispatchToProps)(CompanyPreview)