import { combineReducers } from 'redux';
import { RESET_STORE } from '../Constant';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import registrationReducer from './Registration/registrationReducer'
import loginReducer from './Registration/loginReducer'
import getBannerReducer from './Registration/getBannerReducer'
import userDetailsReducer from './UserDetails/userDetailsReducer'
import companyDetailsReducer from './UserDetails/companyDetailsReducer'
import addProfileReducer from './UserDetails/addProfileReducer'
import updateProfileReducer from './UserDetails/updateProfileReducer'
import updateCompanyReducer from './UserDetails/updateCompanyReducer'
import addCompanyReduce from './UserDetails/addCompanyReduce'
import landingPageREducer from './UserDetails/landingPageREducer'
import getContactListReducer from './ContactList/getContactListReducer'
import removeContactReducer from './ContactList/removeContactReducer'
import memberShipTenureReduce from './MemberShip/memberShipTenureReduce'
import paymentReducer from './Payment/PaymentReducer'
import chamberReducer from './Chamber/chamberReducer'
import folderReducer from './Folder/folderReducer'
import PublicPageReducer from './PublicPage/PublicPageReducer'
import contactReducer from './Contact/contactReducer'
import deleteCompanyReducer from './UserDetails/deleteCompanyReducer'
import themeReducer from './Theme/themeReducer'
import notificationReducer from './ContactList/getNotificationReducer'
import forgotPasswordReducer from './Registration/forgotPasswordReducer'
import getImessageReducer from './ContactList/getImessageReducer'
import languageReducer from './Language/languageReducer'
import NotificationReducer from './Notification/NotificationReducer'
import RefferalReducer from './Refferal/RefferalReducer'

const appReducer = combineReducers({
 registrationReducer,
 loginReducer,
 userDetailsReducer,
 getBannerReducer,
 addProfileReducer,
 updateProfileReducer,
 addCompanyReduce,
 landingPageREducer,
 getContactListReducer,
 removeContactReducer,
 memberShipTenureReduce,
 paymentReducer,
 companyDetailsReducer,
 updateCompanyReducer,
 chamberReducer,
 folderReducer,
 PublicPageReducer,
 contactReducer,
 deleteCompanyReducer,
 themeReducer,
 notificationReducer,
 forgotPasswordReducer,
 getImessageReducer,
 languageReducer,
 NotificationReducer,
 RefferalReducer,

})

const rootReducer = (state, action) => {
 if (action.type === RESET_STORE) {
  return appReducer(undefined, action);
 }
 return appReducer(state, action)
}
export default rootReducer;