import { CHECKOUT_SESSION_ERROR, CHECKOUT_SESSION_LOADING, CHECKOUT_SESSION_SUCCESS, DELETE_IMAGE_ERROR, DELETE_IMAGE_LOADING, DELETE_IMAGE_SUCCESS, EDIT_IMAGE_ERROR, EDIT_IMAGE_LOADING, EDIT_IMAGE_SUCCESS, GET_CATAGORY_LIST_ERROR, GET_CATAGORY_LIST_LOADING, GET_CATAGORY_LIST_SUCCESS, GET_REFERRAL_REPORT_ERROR, GET_REFERRAL_REPORT_LOADING, GET_REFERRAL_REPORT_SUCCESS, GET_REFERRAL_TENURE_ERROR, GET_REFERRAL_TENURE_LOADING, GET_REFERRAL_TENURE_SUCCESS, GET_REFFERAL_IMAGES_ERROR, GET_REFFERAL_IMAGES_LOADING, GET_REFFERAL_IMAGES_SUCCESS, RESET_CHECKOUT_SESSION, RESET_DELETE_IMAGE, RESET_EDIT_IMAGE, RESET_GET_CATAGORY_LIST, RESET_GET_REFERRAL_REPORT, RESET_GET_REFERRAL_TENURE, RESET_GET_REFFERAL_IMAGES, RESET_SEARCH_TELEGRAM_USER, RESET_SEND_REQUEST_FOR_REFFERAL, RESET_SUCCESS_PAYMENT, RESET_UPLOAD_IMAGE, SEARCH_TELEGRAM_USER_ERROR, SEARCH_TELEGRAM_USER_LOADING, SEARCH_TELEGRAM_USER_SUCCESS, SEND_REQUEST_FOR_REFFERAL_ERROR, SEND_REQUEST_FOR_REFFERAL_LOADING, SEND_REQUEST_FOR_REFFERAL_SUCCESS, SUCCESS_PAYMENT_ERROR, SUCCESS_PAYMENT_LOADING, SUCCESS_PAYMENT_SUCCESS, UPLOAD_IMAGE_ERROR, UPLOAD_IMAGE_LOADING, UPLOAD_IMAGE_SUCCESS } from "../../Constant";

let initialState = {
  sendRequest: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  getRefferalImage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  getImageCatagoryList: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  uploadImage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  editImage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  deleteImage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  searchTelegramUser: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  referralTenure: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  ReferralReport: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  checkoutSession: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  paymentSuccess: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },

};

export default function RefferalReducer(state = initialState, action) {
  switch (action.type) {

    // sen request
    case SEND_REQUEST_FOR_REFFERAL_LOADING:
      return { ...state, sendRequest: { ...state.sendRequest, loading: true, data: null, error: false, errors: null, success: false } };

    case SEND_REQUEST_FOR_REFFERAL_SUCCESS:
      return { ...state, sendRequest: { ...state.sendRequest, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case SEND_REQUEST_FOR_REFFERAL_ERROR:
      return { ...state, sendRequest: { ...state.sendRequest, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_SEND_REQUEST_FOR_REFFERAL:
      return { ...state, sendRequest: { ...state.sendRequest, loading: false, error: false, success: false } };


    //  get Image
    case GET_REFFERAL_IMAGES_LOADING:
      return { ...state, getRefferalImage: { ...state.getRefferalImage, loading: true, data: null, error: false, errors: null, success: false } };

    case GET_REFFERAL_IMAGES_SUCCESS:
      return { ...state, getRefferalImage: { ...state.getRefferalImage, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_REFFERAL_IMAGES_ERROR:
      return { ...state, getRefferalImage: { ...state.getRefferalImage, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_REFFERAL_IMAGES:
      return { ...state, getRefferalImage: { ...state.getRefferalImage, loading: false, error: false, success: false } };


    //  get CatagoryList
    case GET_CATAGORY_LIST_LOADING:
      return { ...state, getImageCatagoryList: { ...state.getImageCatagoryList, loading: true, data: null, error: false, errors: null, success: false } };

    case GET_CATAGORY_LIST_SUCCESS:
      return { ...state, getImageCatagoryList: { ...state.getImageCatagoryList, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_CATAGORY_LIST_ERROR:
      return { ...state, getImageCatagoryList: { ...state.getImageCatagoryList, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_CATAGORY_LIST:
      return { ...state, getImageCatagoryList: { ...state.getImageCatagoryList, loading: false, error: false, success: false } };


    //  upload Image
    case UPLOAD_IMAGE_LOADING:
      return { ...state, uploadImage: { ...state.uploadImage, loading: true, data: null, error: false, errors: null, success: false } };

    case UPLOAD_IMAGE_SUCCESS:
      return { ...state, uploadImage: { ...state.uploadImage, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case UPLOAD_IMAGE_ERROR:
      return { ...state, uploadImage: { ...state.uploadImage, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_UPLOAD_IMAGE:
      return { ...state, uploadImage: { ...state.uploadImage, loading: false, error: false, success: false } };


    //  Edit Image
    case EDIT_IMAGE_LOADING:
      return { ...state, editImage: { ...state.editImage, loading: true, data: null, error: false, errors: null, success: false } };

    case EDIT_IMAGE_SUCCESS:
      return { ...state, editImage: { ...state.editImage, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case EDIT_IMAGE_ERROR:
      return { ...state, editImage: { ...state.editImage, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_EDIT_IMAGE:
      return { ...state, editImage: { ...state.editImage, loading: false, error: false, success: false } };

    //  DELETE Image
    case DELETE_IMAGE_LOADING:
      return { ...state, deleteImage: { ...state.deleteImage, loading: true, data: null, error: false, errors: null, success: false } };

    case DELETE_IMAGE_SUCCESS:
      return { ...state, deleteImage: { ...state.deleteImage, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case DELETE_IMAGE_ERROR:
      return { ...state, deleteImage: { ...state.deleteImage, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_DELETE_IMAGE:
      return { ...state, deleteImage: { ...state.deleteImage, loading: false, error: false, success: false } };

    //  Search user
    case SEARCH_TELEGRAM_USER_LOADING:
      return { ...state, searchTelegramUser: { ...state.searchTelegramUser, loading: true, data: null, error: false, errors: null, success: false } };

    case SEARCH_TELEGRAM_USER_SUCCESS:
      return { ...state, searchTelegramUser: { ...state.searchTelegramUser, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case SEARCH_TELEGRAM_USER_ERROR:
      return { ...state, searchTelegramUser: { ...state.searchTelegramUser, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_SEARCH_TELEGRAM_USER:
      return { ...state, searchTelegramUser: { ...state.searchTelegramUser, loading: false, error: false, success: false } };

    //  referral Tenure
    case GET_REFERRAL_TENURE_LOADING:
      return { ...state, referralTenure: { ...state.referralTenure, loading: true, data: null, error: false, errors: null, success: false } };

    case GET_REFERRAL_TENURE_SUCCESS:
      return { ...state, referralTenure: { ...state.referralTenure, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_REFERRAL_TENURE_ERROR:
      return { ...state, referralTenure: { ...state.referralTenure, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_REFERRAL_TENURE:
      return { ...state, referralTenure: { ...state.referralTenure, loading: false, error: false, success: false } };

    // referral report
    case GET_REFERRAL_REPORT_LOADING:
      return { ...state, ReferralReport: { ...state.ReferralReport, loading: true, data: null, error: false, errors: null, success: false } };

    case GET_REFERRAL_REPORT_SUCCESS:
      return { ...state, ReferralReport: { ...state.ReferralReport, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_REFERRAL_REPORT_ERROR:
      return { ...state, ReferralReport: { ...state.ReferralReport, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_REFERRAL_REPORT:
      return { ...state, ReferralReport: { ...state.ReferralReport, loading: false, error: false, success: false } };


    // checkout sessiom
    case CHECKOUT_SESSION_LOADING:
      return { ...state, checkoutSession: { ...state.checkoutSession, loading: true, data: null, error: false, errors: null, success: false } };

    case CHECKOUT_SESSION_SUCCESS:
      return { ...state, checkoutSession: { ...state.checkoutSession, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case CHECKOUT_SESSION_ERROR:
      return { ...state, checkoutSession: { ...state.checkoutSession, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_CHECKOUT_SESSION:
      return { ...state, checkoutSession: { ...state.checkoutSession, loading: false, error: false, success: false } };


    // payment success
    case SUCCESS_PAYMENT_LOADING:
      return { ...state, paymentSuccess: { ...state.paymentSuccess, loading: true, data: null, error: false, errors: null, success: false } };

    case SUCCESS_PAYMENT_SUCCESS:
      return { ...state, paymentSuccess: { ...state.paymentSuccess, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case SUCCESS_PAYMENT_ERROR:
      return { ...state, paymentSuccess: { ...state.paymentSuccess, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_SUCCESS_PAYMENT:
      return { ...state, paymentSuccess: { ...state.paymentSuccess, loading: false, error: false, success: false } };

    default:
      return state;
  }
}
