import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addImageBackground, getBackground, getImageBackground, getSystemBackground, setBackground } from '../../services/Action/Theme/themeAction'
import { RESET_ADD_IMAGE_BACKGROUND, RESET_SET_BACKGROUND } from '../../services/Constant'
import { getImageCatagoy } from '../../services/Action/Reffaral/RefferalAction'

function Background(props) {
  const userType = localStorage.getItem('usertype')
  const { t } = useTranslation()

  const [collection, setCollection] = useState([])
  const [selectedFolder, setSelectedFolder] = useState('')
  const [displayImage, SetDisplayImage] = useState([])
  const [imageCatagory, setImageCatagory] = useState([])
  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })

  console.log("collo", collection);

  const navigate = useNavigate()
  const [coustomBackGround, setCounstomBackground] = useState([])
  const [isOpen, setIsopen] = useState(false)
  const [prevImage, setPrevImage] = useState(null)
  useEffect(() => {
    props.getSystemBackground()
    props.getImageBackground()
    props.getImageCatagoy()
  }, [])

  useEffect(() => {
    if (props.getCoustomImage.success) {
      const imageList = props.getCoustomImage.data.data.map(e => {
        return {
          url: e.image,
          date: e.date
        }
      }).flat(2)
      setCounstomBackground([...imageList].map(e => {
        return {
          url: e.url[0],
          date: e.date,
          type: 'user',  // type "user" because this image added by current login user ..
          user: []
        }
      }))
    }
  }, [props.getCoustomImage])



  useEffect(() => {
    if (props.systemImageReducer.success) {
      setCollection(props.systemImageReducer.data.data)
      console.log(props.systemImageReducer.data.data, "props.systemImageReducer.data.data");
      // setSelectedFolder(props.systemImageReducer.data.data[0]?.categoryname)
      // const list = props.systemImageReducer?.data?.data[0]?.Thumbnail.map(e => {
      //   return {
      //     url: e,
      //     type: props.systemImageReducer?.data?.data[0]?.type,
      //     date: props.systemImageReducer?.data?.data[0]?.date,
      //     user: props.systemImageReducer?.data?.data[0]?.user
      //   }
      // })
      // SetDisplayImage(list)
    }
  }, [props.systemImageReducer])
  useEffect(() => {

    if (selectedFolder !== 'myImages') {
      const data = collection.filter(e => {
        return e?.categoryname === selectedFolder
      })
      debugger
      const list = data.map(e => {
        const _list = e?.Thumbnail.map(i => {
          return {
            url: i,
            type: e?.type,
            date: e?.date,
            user: e?.user[0]
          }
        })
        return _list
      }).flat(2)
      SetDisplayImage(list)
    } else {
      const list = [...coustomBackGround]
      SetDisplayImage([...list])
    }
  }, [selectedFolder])

  useEffect(() => {
    if (props.addCoustomImage) {
      props.getSystemBackground()
      props.getImageBackground()
    }
  }, [props.addCoustomImage])


  const handleSaveImage = () => {
    const details = prevImage?.url
    props.setBackground({
      Thumbnail: details
    })
    setIsopen(false)
  }

  useEffect(() => {
    if (props.setBackGround.success) {
      setPrevImage(null)
      setIsopen(false)
      props.resetBackGround()
      window.location.reload()
      navigate('/')
    }
  }, [props.setBackGround])

  useEffect(() => {
    if (props.getImageCatagoryListReducer.success) {
      const data = props.getImageCatagoryListReducer.data
      setSelectedFolder(data[0]._id)
      setImageCatagory([...data])
    }
  }, [props.getImageCatagoryListReducer])

  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [props.backGroundReducer])

  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [])

  const handleMedia = (event) => {
    const detail = {
      image: Object.values(event.target.files)
    }

    props.addImageBackground(detail)
  }


  return (
    <div>
      {userType !== '0' && <div className='row justify-content-center'>
        <div className=' d-flex justify-content-end'>
          <input type="file" className="input-file file-upload d-none" multiple='multiple' id="file-upload" name='backgroundImage' accept={'image/jpeg, image/jpg, image/png'} onChange={(e) => handleMedia(e)} />
          <label htmlFor='file-upload'>
            <div className='pointer bg-red text-white rounded py-1 px-2'>{t('uploadYourImage')}</div>
          </label>
        </div>
      </div>}
      <div className='row justify-content-center my-3 folder-list'>
        <div className='col-12 pb-3 d-flex justify-content-between' style={{ overflowX: "auto" }}>
          <div className='d-flex'>

            {
              imageCatagory?.map((e, i) => {
                return <div className={classNames('me-2 py-1 px-4 pointer d-flex rounded justify-content-between align-item-center', {

                })} key={i} onClick={() => setSelectedFolder(e?._id)} style={{
                  minWidth: "135px",
                  color: selectedFolder === e?._id ? '#ffffff' : '',
                  backgroundColor: selectedFolder === e._id ? '#00A3CC' : '#ffffff',
                }}>
                  <div>{e?.categoryname}</div>

                </div>
              })
            }
            {userType !== '0' && <div className={classNames('me-2 py-1 px-4 pointer d-flex rounded justify-content-between align-item-center', {
            })} onClick={() => setSelectedFolder('myImages')} style={{
              minWidth: "135px",
              color: selectedFolder === 'myImages' ? '#ffffff' : '',
              backgroundColor: selectedFolder === 'myImages' ? '#00A3CC' : '#ffffff',
            }}>
              <div>{t('myImages')}</div>

            </div>}
          </div>
        </div>
      </div>

      <div className='row justify-content-center contactListDiv backGroundImage' >
        <div className='col-12' style={{ maxHeight: '660px', overflowY: 'auto' }}>
          {
            (displayImage && displayImage.length === 0) ? <div className='d-flex justify-content-center mt-5' style={{ fontWeight: 'bold' }}>{t('noImageUpload')}</div> :
              <div className='d-flex flex-wrap'>
                {
                  displayImage?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map((e, index) => {
                    debugger
                    return (
                      <img src={e.url} key={index} className="img-fluid pointer mx-3 my-2"
                        style={{ width: '120px', height: '120px' }} onClick={() => {
                          setPrevImage(e)
                          setIsopen(true)
                        }} />
                    )
                  })
                }
              </div>
          }
        </div>
      </div>
      <Modal
        show={isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setIsopen(false)
          setPrevImage(null)
        }}
      >
        <React.Fragment>
          <div className='p-5'>
            <img src={prevImage?.url} alt="" style={{ width: '100%', height: 'auto' }} />
            {parseInt(prevImage?.type) === 1 && <div className='bg-red h5 text-center text-white py-2 mt-2 border rounded pointer' onClick={() => {
              navigate('/referral', {
                state: prevImage
              })
            }}>
              <Trans i18nKey={'referDiscount'} components={{ 1: <br /> }} /></div>}
            <div className='mt-5 d-flex justify-content-center'>
              <div className='mx-3 py-2 px-4 rounded border pointer bg-blue text-white' onClick={handleSaveImage}>{t('saveAsBAckGround')}</div>
              <div className='mx-3 py-2 px-4 rounded border pointer' onClick={() => {
                setIsopen(false)
                setPrevImage(null)
              }}>{t('Cancel')}</div>
            </div>

          </div>
        </React.Fragment>
      </Modal>
    </div>
  )
}


const mapStateToProp = state => ({
  systemImageReducer: state.themeReducer.getSystemBackGround,
  backGroundReducer: state.themeReducer.getBackGround,
  getCoustomImage: state.themeReducer.getCoustomImage,
  addCoustomImage: state.themeReducer.addCoustomImage,
  setBackGround: state.themeReducer.setThemeAndBackground,
  getImageCatagoryListReducer: state.RefferalReducer.getImageCatagoryList,



  // getContactListReducer: state.getContactListReducer,
  // invitationReducer: state.contactReducer.invitation,
})

const mapDispatchToProps = dispatch => ({
  getSystemBackground: () => dispatch(getSystemBackground()),
  getImageBackground: () => dispatch(getImageBackground()),
  setBackground: details => dispatch(setBackground(details)),
  addImageBackground: details => dispatch(addImageBackground(details)),
  getImageCatagoy: () => dispatch(getImageCatagoy()),
  resetBackGround: () => dispatch({ type: RESET_SET_BACKGROUND }),

})

export default connect(mapStateToProp, mapDispatchToProps)(Background)  