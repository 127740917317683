import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { paymentSuccess } from '../../services/Action/Reffaral/RefferalAction'
import { RESET_SUCCESS_PAYMENT } from '../../services/Constant'
import Spinner from '../../components/Spinner/Spinner'
import { useTranslation } from 'react-i18next'
import { getMemberShipTenure } from '../../services/Action/MemberShip/memberShipAction'
import { getPAymentOptions } from '../../services/Action/Payment/PaymentAction'

function SuccessPayment(props) {
 const navigate = useNavigate()
 const { t } = useTranslation()
 const params = useParams()
 const userDetails = useSelector(state => state.userDetailsReducer.data)
 const [loading, setLoading] = useState(true)

 useEffect(() => {
  if (!!params.refId && !!userDetails && !!userDetails?.data) {
   if (userDetails?.data?._id !== params.refId) {
    navigate('/')
   } else {
    if (!!params && !props.paymentSuccessReducer.success) {
     props.paymentSuccess({
      user: params.memberID,
      membership: params.membershipId
     })
    }
   }

  }
 }, [userDetails])

 useEffect(() => {
  if (props.paymentSuccessReducer.success) {
   setLoading(false)
   props.resetSuccess()
  }
 }, [props.paymentSuccessReducer])

 return (
  <div className='p-4 bg-white'>
   {loading ? <Spinner /> : <div>
    <div className='d-flex justify-content-center mb-3'><img src='/images/check.png' style={{ width: '100px' }} /></div>
    <div className='text-center text-success h5'>{`${t('successPayment')}`}.</div>
    <div className='d-flex justify-content-center mt-3'><div className='bg-red text-white h6 py-2 px-4 border rounded pointer' onClick={() => {
     props.getPAymentOptions()
     props.getMemberShipTenure()
     navigate('/referral/report')
    }}>OK</div></div>
   </div>}
  </div>
 )
}

const mapStateToProp = state => ({
 paymentSuccessReducer: state.RefferalReducer.paymentSuccess
})

const mapDispatchToProps = dispatch => ({
 paymentSuccess: detail => dispatch(paymentSuccess(detail)),
 getMemberShipTenure: () => dispatch(getMemberShipTenure()),
 getPAymentOptions: () => dispatch(getPAymentOptions()),
 resetSuccess: detail => dispatch({ type: RESET_SUCCESS_PAYMENT }),
})

export default connect(mapStateToProp, mapDispatchToProps)(SuccessPayment)