import { COMAPNY_DETAILS_ERROR, COMAPNY_DETAILS_LOADING, COMAPNY_DETAILS_SUCCESS, RESET_COMAPNY_DETAILS } from "../../Constant";

let initialState = {
 loading: false,
 data: null,
 error: false,
 errors: null,
 success: false,
};

export default function companyDetailsReducer(state = initialState, action) {
 switch (action.type) {
  case COMAPNY_DETAILS_LOADING:
   return { ...state, loading: true, error: false, errors: [], success: false };

  case COMAPNY_DETAILS_SUCCESS:
   return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

  case COMAPNY_DETAILS_ERROR:
   return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

  case RESET_COMAPNY_DETAILS:
   return { ...state, loading: false, error: false, errors: false, success: false };

  default:
   return state;
 }
}
