import React, { useEffect, useState } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { connect } from 'react-redux'

function QrCodeGenrate(props) {

  const { url } = props

  // const [theme, setTheme] = useState({
  //   theme: '',
  //   font: ''
  // })
  // useEffect(() => {
  //   if (props.backGroundReducer.success) {
  //     const _theme = props.backGroundReducer?.data?.data
  //     if (_theme !== null) {
  //       setTheme(prevState => ({
  //         ...prevState,
  //         font: _theme.fontcolor === null ? '' : _theme.fontcolor,
  //         theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
  //       }))
  //     }
  //   }
  // }, [props.backGroundReducer])
  // useEffect(() => {
  //   if (props.backGroundReducer.success) {
  //     const _theme = props.backGroundReducer?.data?.data
  //     if (_theme !== null) {
  //       setTheme(prevState => ({
  //         ...prevState,
  //         font: _theme.fontcolor === null ? '' : _theme.fontcolor,
  //         theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
  //       }))
  //     }
  //   }
  // }, [])

  return (
    <React.Fragment>
      {/* <QRCode value={url ?? ''} logoImage='/images/loginPage/image.png' bgColor='#ffffff' fgColor={theme.theme !== '' ? theme.theme: '#00aeef'} id='qrcode' /> */}
      <QRCode value={url ?? ''} logoImage='/images/loginPage/image.png' fgColor='#00A3CC' id='qrcode' qrStyle={'dots'} />
    </React.Fragment>
  )
}

const mapStateToProp = state => ({
  // backGroundReducer: state.themeReducer.getBackGround,
  // addProfileReducer: state.addProfileReducer,

})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProp, mapDispatchToProps)(QrCodeGenrate)