import axios from "axios";
import { language_Url } from "../../../apiSheet";
import { LANGUAGE_ERROR, LANGUAGE_LOADING, LANGUAGE_SUCCESS } from "../../Constant";

export const language = (details) => {
 return (dispatch) => {
  dispatch({
   type: LANGUAGE_LOADING,
  });
  const requestOptions = {
   method: "POST",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: language_Url,
   data: details
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: LANGUAGE_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: LANGUAGE_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: LANGUAGE_ERROR,
       errors: validationError
      });
     }
   });
 }

}
