import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

function Header(props) {

  const navigate = useNavigate()
  const userDetails = useSelector(state => state.userDetailsReducer?.data?.data)
  const userTelegramId = userDetails?.telegramId
  const token = localStorage.getItem('token')


  const redirectUrl = (str) => {
    if (str !== '' && str !== undefined) {
      if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
        window.open(str, "_blank")
      } else {
        window.open(`https://${str}`, '_blank')
      }
    } else {
      return
    }
  }

  const handleNotification = () => {
    if (token === null) {
      navigate('/login')
    } else {
      navigate('/notification')
    }
  }

  return (
    <div className='m-md-3'>
      <div className='bg-blue main-header d-flex justify-content-between align-items-center'>
        {/* left side */}
        <div className='d-flex justify-content-between align-items-center pointer' onClick={() => navigate('/')}>
          <div ><img src="/images/loginPage/image.png" alt="alt" /></div>
          <div className='mx-2 headingFont'>AddMy.Co</div>
        </div>
        {/* right side */}
        <div className='d-flex justify-content-between align-items-center ' >
          <div className='right-icons'>
            <img src='/images/message.png' className='pointer img-fluid' alt="alt" onClick={() => window.open(`https://t.me/AddmyCompany`, '_blank')} />
          </div>
          <div className='mx-2 right-icons' style={{
            position: "relative"
          }}>
            <img src='/images/notification.png' alt="alt" className='pointer img-fluid' onClick={handleNotification} />
            {/* {props.isNotification && <img src="/images/full-stop.png" alt="" style={{ position: 'absolute', top: '-36px', right: '-7px' }}/>} */}
            {props.isNotification &&
              <img src='/images/notificationDot.png' className='notification-dot' />
            }
          </div>
          {/* <a className='right-icons' onClick={() => redirectUrl(userTelegramId)}> */}
          <a className='right-icons' href="https://t.me/dynamicnamecard" target="blank">
            <img src='/images/coopration.png' alt="alt" className='pointer img-fluid' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header