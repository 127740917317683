import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

function ReferralContactPage() {

 const { t } = useTranslation()
 const navigate = useNavigate();
 const location = useLocation()

 useEffect(() => {
  if (location.state === null) {
   navigate(-1)
  }
 }, [])
 const checkUrl = (str) => {
  if (str !== '' && str !== undefined && str !== null) {
   if (str.indexOf("http://t.me/") === 0 || str.indexOf("https://t.me/") === 0) {
    return str
   } else {
    return "https://t.me/" + str
   }
  } else return ''

 }
// console.log("location?.state?.user[0]?.tgid", location?.state);
 const handleRedirect = () => {
  window.open(checkUrl(location?.state?.user?.tgid), "_blank")
 }

 return (
  <div>
   <div className='bg-white p-3'>
    <div className='h5 mt-3'>{t('refMsg1')}</div>
    <div className='h5 mt-3'>{t('refMsg2')}</div>
    <div className='h5 mt-3'>{t('refMsg3')}</div>
    <div className='h5 mt-3'>{t('refMsg4')}</div>
    <div className='mt-5 d-flex justify-content-center bg-red text-white py-2 h5 pointer' onClick={handleRedirect}>Connect with me on telegram</div>

   </div>
   {/* <div className='mt-5 d-flex justify-content-center'>
    <div className='mx-3 py-2 px-4 rounded border pointer bg-red text-white h5' onClick={() => {
     navigate(-1)
    }}>{'Back'}</div>
   </div> */}
  </div>
 )
}

export default ReferralContactPage