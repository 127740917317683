import 'dropify/dist/js/dropify.js'
import 'dropify/dist/css/dropify.css'
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import App from './App';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'font-awesome/css/font-awesome.min.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import rootReducer from './services/Reducer';
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n/config';
import 'react-data-grid/lib/styles.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-tooltip/dist/react-tooltip.css'

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

serviceWorkerRegistration.register();
reportWebVitals();
