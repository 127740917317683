import { CHAMBER_DETAILS_ERROR, CHAMBER_DETAILS_LOADING, CHAMBER_DETAILS_SUCCESS, DELETE_CHAMBER_DETAILS_ERROR, DELETE_CHAMBER_DETAILS_LOADING, DELETE_CHAMBER_DETAILS_SUCCESS, RESET_DELETE_CHAMBER_DETAILS, RESET_SAVE_CHAMBER_DETAILS, SAVE_CHAMBER_DETAILS_ERROR, SAVE_CHAMBER_DETAILS_LOADING, SAVE_CHAMBER_DETAILS_SUCCESS } from "../../Constant";

let initialState = {
  chamber_details: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  save_chamber: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  delete_chamber: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  }
};

export default function chamberReducer(state = initialState, action) {
  switch (action.type) {
    case CHAMBER_DETAILS_LOADING:
      return { ...state, chamber_details: { ...state.chamber_details, loading: true, error: false, errors: [], success: false } };

    case CHAMBER_DETAILS_SUCCESS:
      return { ...state, chamber_details: { ...state.chamber_details, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case CHAMBER_DETAILS_ERROR:
      return { ...state, chamber_details: { ...state.chamber_details, loading: false, error: true, errors: action.errors, success: false, data: null } };


    //  save chamber
    case SAVE_CHAMBER_DETAILS_LOADING:
      return { ...state, save_chamber: { ...state.save_chamber, loading: true, error: false, errors: [], success: false } };

    case SAVE_CHAMBER_DETAILS_SUCCESS:
      return { ...state, save_chamber: { ...state.save_chamber, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case SAVE_CHAMBER_DETAILS_ERROR:
      return { ...state, save_chamber: { ...state.save_chamber, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_SAVE_CHAMBER_DETAILS:
      return { ...state, save_chamber: { ...state.save_chamber, loading: false, error: false, errors: [], success: false } };

    //  delete chamber
    case DELETE_CHAMBER_DETAILS_LOADING:
      return { ...state, delete_chamber: { ...state.delete_chamber, loading: true, error: false, errors: [], success: false } };

    case DELETE_CHAMBER_DETAILS_SUCCESS:
      return { ...state, delete_chamber: { ...state.delete_chamber, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case DELETE_CHAMBER_DETAILS_ERROR:
      return { ...state, delete_chamber: { ...state.delete_chamber, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_DELETE_CHAMBER_DETAILS:
      return { ...state, delete_chamber: { ...state.delete_chamber, loading: false, error: false, errors: [], success: false } };


    default:
      return state;
  }
}
