import axios from "axios";
import { CHECKOUT_SESSION_ERROR, CHECKOUT_SESSION_LOADING, CHECKOUT_SESSION_SUCCESS, DELETE_IMAGE_ERROR, DELETE_IMAGE_LOADING, DELETE_IMAGE_SUCCESS, EDIT_IMAGE_ERROR, EDIT_IMAGE_LOADING, EDIT_IMAGE_SUCCESS, GET_CATAGORY_LIST_ERROR, GET_CATAGORY_LIST_LOADING, GET_CATAGORY_LIST_SUCCESS, GET_REFERRAL_REPORT_ERROR, GET_REFERRAL_REPORT_LOADING, GET_REFERRAL_REPORT_SUCCESS, GET_REFERRAL_TENURE_ERROR, GET_REFERRAL_TENURE_LOADING, GET_REFERRAL_TENURE_SUCCESS, GET_REFFERAL_IMAGES_ERROR, GET_REFFERAL_IMAGES_LOADING, GET_REFFERAL_IMAGES_SUCCESS, RESET_GET_CATAGORY_LIST, RESET_GET_REFFERAL_IMAGES, SEARCH_TELEGRAM_USER_ERROR, SEARCH_TELEGRAM_USER_LOADING, SEARCH_TELEGRAM_USER_SUCCESS, SEND_REQUEST_FOR_REFFERAL_ERROR, SEND_REQUEST_FOR_REFFERAL_LOADING, SEND_REQUEST_FOR_REFFERAL_SUCCESS, SUCCESS_PAYMENT_ERROR, SUCCESS_PAYMENT_LOADING, SUCCESS_PAYMENT_SUCCESS, UPLOAD_IMAGE_ERROR, UPLOAD_IMAGE_LOADING, UPLOAD_IMAGE_SUCCESS } from "../../Constant";
import { addReferral_Url, checkout_session_Url, deleteImage_Url, getImageCatagory_Url, getReferralReport_Url, getReferralTenure_Url, getRefferalImage_Url, paymentSuccess_Url, searchTelegramUsername_Url, uploadImage_Url } from "../../../apiSheet";
// import Stripe from '@stripe/stripe-js'



export const sendReffaralRequest = () => {
  return (dispatch) => {
    dispatch({
      type: SEND_REQUEST_FOR_REFFERAL_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${addReferral_Url}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SEND_REQUEST_FOR_REFFERAL_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SEND_REQUEST_FOR_REFFERAL_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: SEND_REQUEST_FOR_REFFERAL_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const getImageCatagoy = () => {
  return (dispatch) => {
    dispatch({
      type: GET_CATAGORY_LIST_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getImageCatagory_Url}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_CATAGORY_LIST_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_CATAGORY_LIST_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: RESET_GET_CATAGORY_LIST,
              errors: validationError
            });
          }
      });
  }

}
export const getRefferalImage = () => {
  return (dispatch) => {
    dispatch({
      type: GET_REFFERAL_IMAGES_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getRefferalImage_Url}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_REFFERAL_IMAGES_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_REFFERAL_IMAGES_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_REFFERAL_IMAGES_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const uploadImage = (details) => {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_IMAGE_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${uploadImage_Url}`,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPLOAD_IMAGE_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPLOAD_IMAGE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: UPLOAD_IMAGE_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const editImageAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_IMAGE_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${uploadImage_Url}/${details.id}`,
      data: details.details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_IMAGE_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: EDIT_IMAGE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: EDIT_IMAGE_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const deleteImageAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_IMAGE_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteImage_Url}/${details.id}/${details.name}`
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_IMAGE_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_IMAGE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: DELETE_IMAGE_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const findTelegramUser = (details) => {
  return (dispatch) => {
    dispatch({
      type: SEARCH_TELEGRAM_USER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${searchTelegramUsername_Url}`,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SEARCH_TELEGRAM_USER_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SEARCH_TELEGRAM_USER_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: SEARCH_TELEGRAM_USER_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const getReferralTenure = () => {
  return (dispatch) => {
    dispatch({
      type: GET_REFERRAL_TENURE_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getReferralTenure_Url}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_REFERRAL_TENURE_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_REFERRAL_TENURE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_REFERRAL_TENURE_ERROR,
              errors: validationError
            });
          }
      });
  }

}
export const getReferralReport = () => {
  return (dispatch) => {
    dispatch({
      type: GET_REFERRAL_REPORT_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getReferralReport_Url}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_REFERRAL_REPORT_SUCCESS,
          response: {
            data: resp.data.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_REFERRAL_REPORT_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_REFERRAL_REPORT_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const getCheckOutSession = (details) => {
  return (dispatch) => {
    dispatch({
      type: CHECKOUT_SESSION_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${checkout_session_Url}`,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        // const result = stripe.redirectToCheckout({ 
        //   sessionId: session.id, 
        // }); 
        dispatch({
          type: CHECKOUT_SESSION_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CHECKOUT_SESSION_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: CHECKOUT_SESSION_ERROR,
              errors: validationError
            });
          }
      });
  }

}

export const paymentSuccess = (details) => {
  return (dispatch) => {
    dispatch({
      type: SUCCESS_PAYMENT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${paymentSuccess_Url}`,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SUCCESS_PAYMENT_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SUCCESS_PAYMENT_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: SUCCESS_PAYMENT_ERROR,
              errors: validationError
            });
          }
      });
  }

}


