import { FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_LOADING, FORGOT_PASSWORD_SUCCESS, RESET_FORGOT_PASSWORD } from "../../Constant";

let initialState = {
 loading: false,
 data: null,
 error: false,
 errors: null,
 success: false
};

export default function forgotPasswordReducer(state = initialState, action) {
 switch (action.type) {
  case FORGOT_PASSWORD_LOADING:
   return { ...state, loading: true, error: false, errors: [], success: false };

   case FORGOT_PASSWORD_SUCCESS:
   return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

   case FORGOT_PASSWORD_ERROR:
   return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

   case RESET_FORGOT_PASSWORD:
   return { ...state, loading: false, error: false, errors: null, success: false};

  default:
   return state;
 }
}
