import { BANNER_ERROR, BANNER_LOADING, BANNER_SUCCESS } from "../../Constant";

let initialState = {
 loading: false,
 data: null,
 error: false,
 errors: null,
 success: false
};

export default function getBannerReducer(state = initialState, action) {
 switch (action.type) {
  case BANNER_LOADING:
   return { ...state, loading: true, error: false, errors: [], success: false };

   case BANNER_SUCCESS:
   return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

   case BANNER_ERROR:
   return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

  default:
   return state;
 }
}
