import axios from "axios";
import { forgotPassword_Url, login_Url, register_Url, registrationBanner_Url } from "../../../apiSheet";
import { BANNER_ERROR, BANNER_LOADING, BANNER_SUCCESS, FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_LOADING, FORGOT_PASSWORD_SUCCESS, LOGIN_ERROR, LOGIN_LOADING, LOGIN_SUCCESS, REGISTRATION_ERORR, REGISTRATION_LOADING, REGISTRATION_SUCCESS } from "../../Constant";

export const register = (user) => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // method: "post",
      url: register_Url,
      data: user
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: REGISTRATION_SUCCESS,
          response: resp.data
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: REGISTRATION_ERORR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message ?? data.data.error;
            dispatch({
              type: REGISTRATION_ERORR,
              errors: validationError
            });
          }
      });
  };
};

export const login = (user) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // method: "post",
      url: login_Url,
      data: user
    };
    axios(requestOptions)
      .then((resp) => {
        localStorage.setItem('token', resp?.data.data.token)
        localStorage.setItem('username', resp?.data?.data?.username)
        localStorage.setItem('profilestatus', resp?.data.data.profilestatus)
        localStorage.setItem('companystatus', resp?.data.data.companystatus)
        localStorage.setItem('usertype', resp?.data.data.usertype)
        localStorage.setItem('paymentstatus', resp?.data.data.paymentstatus)
        dispatch({
          type: LOGIN_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LOGIN_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: LOGIN_ERROR,
              errors: validationError
            });
          }
      });
  };
}


export const getBanner = () => {
  return (dispatch) => {
    dispatch({
      type: BANNER_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url: registrationBanner_Url
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: BANNER_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: BANNER_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: BANNER_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const forgotPassword = (details) => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: forgotPassword_Url,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: FORGOT_PASSWORD_ERROR,
              errors: validationError
            });
          }
      });
  };
}