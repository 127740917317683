import axios from "axios";
import { addImageBackGround_Url, getBackground_Url, getImageBackGround_Url, setBackground_Url, SystemImage_Url } from "../../../apiSheet";
import { ADD_IMAGE_BACKGROUND_ERROR, ADD_IMAGE_BACKGROUND_LOADING, ADD_IMAGE_BACKGROUND_SUCCESS, GET_BACKGROUND_ERROR, GET_BACKGROUND_LOADING, GET_BACKGROUND_SUCCESS, GET_IMAGE_BACKGROUND_ERROR, GET_IMAGE_BACKGROUND_LOADING, GET_IMAGE_BACKGROUND_SUCCESS, GET_SYSTEM_IAMGE_ERROR, GET_SYSTEM_IAMGE_LOADING, GET_SYSTEM_IAMGE_SUCCESS, SET_BACKGROUND_ERROR, SET_BACKGROUND_LOADING, SET_BACKGROUND_SUCCESS } from "../../Constant";

export const getBackground = () => {
 return (dispatch) => {
  dispatch({
   type: GET_BACKGROUND_LOADING,
  });
  const requestOptions = {
   method: "GET",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: getBackground_Url,
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: GET_BACKGROUND_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: GET_BACKGROUND_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: GET_BACKGROUND_ERROR,
       errors: validationError
      });
     }
   });
 };
}
export const setBackground = (details) => {
 return (dispatch) => {
  dispatch({
   type: SET_BACKGROUND_LOADING,
  });
  const requestOptions = {
   method: "POST",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: setBackground_Url,
   data: details
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: SET_BACKGROUND_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: SET_BACKGROUND_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: SET_BACKGROUND_ERROR,
       errors: validationError
      });
     }
   });
 };
}
export const getSystemBackground = () => {
 return (dispatch) => {
  dispatch({
   type: GET_SYSTEM_IAMGE_LOADING,
  });
  const requestOptions = {
   method: "GET",
   headers: {
    "content-Type": "application/json",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: SystemImage_Url,
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: GET_SYSTEM_IAMGE_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: GET_SYSTEM_IAMGE_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: GET_SYSTEM_IAMGE_ERROR,
       errors: validationError
      });
     }
   });
 };
}


export const addImageBackground = (details) => {
 return (dispatch) => {
  dispatch({
   type: ADD_IMAGE_BACKGROUND_LOADING,
  });
  const requestOptions = {
   method: "POST",
   headers: {
    "content-Type": "multipart/form-data",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: addImageBackGround_Url,
   data: details
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: ADD_IMAGE_BACKGROUND_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: ADD_IMAGE_BACKGROUND_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: ADD_IMAGE_BACKGROUND_ERROR,
       errors: validationError
      });
     }
   });
 };
}

export const getImageBackground = (details) => {
 return (dispatch) => {
  dispatch({
   type: GET_IMAGE_BACKGROUND_LOADING,
  });
  const requestOptions = {
   method: "GET",
   headers: {
    "content-Type": "multipart/form-data",
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
   },
   url: getImageBackGround_Url,
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: GET_IMAGE_BACKGROUND_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: GET_IMAGE_BACKGROUND_ERROR,
      netError: error,
     });

    } else
     if (data.status === 422) {
      let validationError = data.data.message;
      dispatch({
       type: GET_IMAGE_BACKGROUND_ERROR,
       errors: validationError
      });
     }
   });
 };
}
