import axios from "axios";
import { addContactFolder_Url, addFolder_Url, deleteFolder_Url, getFolder_Url, updateFolder_Url } from "../../../apiSheet";
import { ADD_CONTACT_IN_FOLDER_ERROR, ADD_CONTACT_IN_FOLDER_LOADING, ADD_CONTACT_IN_FOLDER_SUCCESS, ADD_FOLDER_ERROR, ADD_FOLDER_LOADING, ADD_FOLDER_SUCCESS, DELETE_FOLDER_ERROR, DELETE_FOLDER_LOADING, DELETE_FOLDER_SUCCESS, GET_FOLDER_ERROR, GET_FOLDER_LOADING, GET_FOLDER_SUCCESS, UPDATE_FOLDER_ERROR, UPDATE_FOLDER_LOADING, UPDATE_FOLDER_SUCCESS } from "../../Constant";

export const getFolder = () => {
  return (dispatch) => {
    dispatch({
      type: GET_FOLDER_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: getFolder_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_FOLDER_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_FOLDER_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_FOLDER_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const addFolder = ({ FolderName }) => {
  return (dispatch) => {
    dispatch({
      type: ADD_FOLDER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: addFolder_Url,
      data: {
        "Folder": FolderName
      }
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_FOLDER_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_FOLDER_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: ADD_FOLDER_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const addContactINFolder = (details) => {
  return (dispatch) => {
    dispatch({
      type: ADD_CONTACT_IN_FOLDER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: addContactFolder_Url,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_CONTACT_IN_FOLDER_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_CONTACT_IN_FOLDER_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: ADD_CONTACT_IN_FOLDER_ERROR,
              errors: validationError
            });
          }
      });
  };
}


export const deleteFolder = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_FOLDER_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteFolder_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_FOLDER_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_FOLDER_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: DELETE_FOLDER_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const updateFolderName = ({details, id}) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FOLDER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updateFolder_Url}/${id}`,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_FOLDER_SUCCESS,
          response: {
            data: resp.data,
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_FOLDER_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: UPDATE_FOLDER_ERROR,
              errors: validationError
            });
          }
      });
  };
}
