import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserDetails,
  updateProfile,
} from "../../services/Action/userAction";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import Spinner from "../../components/Spinner/Spinner.js";
import $ from "jquery";
import { RESET_ADD_PROFILE, RESET_DETAILS } from "../../services/Constant";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../../apiSheet";

function UpdateProfile(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userType = localStorage.getItem("usertype");
  const paymentstatus = localStorage.getItem("paymentstatus");
  const notify = (msg) => toast.error(msg);
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const validUserName = RegExp(/^(?=.{8,16}$)[a-zA-Z0-9_]*$/gm);

  const socialOptions = [
    { label: "WhatsApp", value: "WhatsApp" },
    { label: "Wechat", value: "Wechat" },
    { label: "Facebook", value: "Facebook" },
    { label: "Instagram", value: "Instagram" },
    { label: "Line", value: "Line" },
    { label: "Linkedin", value: "Linkedin" },
    { label: "SnapChat", value: "SnapChat" },
    { label: "Skype", value: "Skype" },
    { label: "TikTok", value: "TikTok" },
    { label: "Youtube", value: "Youtube" },
    { label: "Twitter", value: "Twitter" },
  ];
  const [selectedSocialMedia, setSelectedSocialMedia] = useState(socialOptions);
  const [loading, setLoading] = useState(true);
  // const [tagList, setTagList] = useState([])
  const [mediaType, setMediaType] = useState("0");
  const [userNameEnabled, setUserNameEnabled] = useState(true);
  const [userNameNotChange, setUserNameNotChange] = useState(false);

  // const profileDetails =useSelector (state => state.addProfileReducer.data)
  const [state, setState] = useState({
    id: "",
    username: "",
    owner_name_english: "",
    owner_name_chinese: "",
    profile_image: "",
    socialId: {
      telegramId: "",
      WhatsApp: "",
      SnapChat: "",
      Instagram: "",
      Linkedin: "",
      Youtube: "",
      Skype: "",
      Facebook: "",
      Wechat: "",
      Twitter: "",
      Line: "",
      TikTok: "",
    },
    // tags: '',
    video: "",
    contact_no: "",
    email: "",
    address1: "",
    address2: "",
    address3: "",
  });

  const [errors, setErrors] = useState({
    username: false,
    owner_name_english: false,
    owner_name_chinese: false,
    telegramId: false,
    contact_no: false,
    email: false,
    address1: false,
    address2: false,
    address3: false,
  });

  const [theme, setTheme] = useState({
    theme: "",
    font: "",
  });

  useEffect(() => {
    if (props.addProfileReducer.success) {
      navigate(`/preview-profile`);
      props.resetAddProfileReducer();
      props.getUserDetails();
    }
  }, [props.addProfileReducer]);

  useEffect(() => {
    setLoading(props.userDetailsReducer.loading);
    if (props.userDetailsReducer.success) {
      const data = props.userDetailsReducer.data.data;
      const socialID = {
        telegramId: data.telegramId,
        WhatsApp: data.WhatsApp,
        SnapChat: data.SnapChat,
        Instagram: data.Instagram,
        Linkedin: data.Linkedin,
        Youtube: data.Youtube,
        Skype: data.Skype,
        Facebook: data.Facebook,
        Wechat: data.WeChat,
        Twitter: data.Twitter,
        Line: data.Line,
        TikTok: data.TikTok,
      };
      setState((prevState) => ({
        ...prevState,
        id: data._id,
        username: data.username,
        owner_name_english: data.owner_name_english,
        owner_name_chinese: data.owner_name_chinese,
        profile_image: data.profile_image,
        video: data.video,
        socialId: {
          ...prevState.socialId,
          ...socialID,
        },
        // tags: data.tags,
        contact_no: data.contact,
        email: data.email,
        address1: data.address1,
        address2: data.address2,
        address3: data.address3,
      }));
      // const socialList = { ...socialID }
      // Object.keys(socialList).map((list) => (socialList[list] === '' || socialList[list] === undefined || socialList[list] === null || list === 'telegramId') && delete socialList[list])
      // const list = Object.keys(socialList).map(list => {
      //   return socialOptions.find(option => option.value === list)
      // })
      // setSelectedSocialMedia(list)
      // const taglist = data.tags !== undefined ? data.tags !== '' ? data.tags.split(',') : [] : []
      // setTagList([...taglist])
    }
  }, [props.userDetailsReducer]);

  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data;
      if (_theme !== null) {
        setTheme((prevState) => ({
          ...prevState,
          font: _theme.fontcolor === null ? "" : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : "",
        }));
      }
    }
  }, [props.backGroundReducer]);
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data;
      if (_theme !== null) {
        setTheme((prevState) => ({
          ...prevState,
          font: _theme.fontcolor === null ? "" : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : "",
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (state.profile_image === "") {
      if (state.video === "") {
        setMediaType("0");
      } else {
        setMediaType("1");
      }
    } else {
      setMediaType("0");
    }
  }, [state.profile_image, state.video]);

  // useEffect(() => {
  //   if (!loading) {
  //     document.getElementsByClassName('dropdown-heading')[0].setAttribute("id", "dropdown-heading")
  //     if (document.querySelectorAll('.dropdown-heading-value>.gray').length !== 0) {
  //       document.querySelectorAll('.dropdown-heading-value>.gray')[0].innerHTML = 'Select Social Media'
  //     }
  //   }
  // }, [$(".clear-selected-button").length, selectedSocialMedia, state.socialId])

  const handleChange = (e) => {
    const { name, value } = e.target;
    // if (name === 'tags') {
    //   setState(prevState => ({
    //     ...prevState,
    //     tags: value.split(' ').join('').trim()
    //   }))
    // } else {
    //   setState(prevState => ({
    //     ...prevState,
    //     [name]: value
    //   }))
    // }

    if (name === "username") {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (name === "owner_name_english") {
      if (value.length <= 24) {
        setState((prevState) => ({
          ...prevState,
          owner_name_english: value,
        }));
      } else return;
    } else if (name === "owner_name_chinese") {
      if (value.length <= 12) {
        setState((prevState) => ({
          ...prevState,
          owner_name_chinese: value,
        }));
      } else return;
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    switch (name) {
      case "username":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            username: "Enter UserName",
          }));
        } else if (!validUserName.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            username:
              "min length 8 and max lenght 16 and Username contain a-z, A-Z, 0-9 and _ value",
          }));
          // notify('min length 8 and max lenght 16')
        } else {
          setErrors((prevState) => ({
            ...prevState,
            username: false,
          }));
        }
        break;

      case "owner_name_english":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_name_english: "Enter Owner English name",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_name_english: false,
          }));
        }
        break;

      case "owner_name_chinese":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_name_chinese: "Enter Owner Chinese name",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_name_chinese: false,
          }));
        }
        break;

      case "email":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter email",
          }));
        } else if (!validEmailRegex.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter valid email",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "contact_no":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            contact_no: "Enter contact number",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            contact_no: false,
          }));
        }
        break;

      case "address1":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            address1: "Enter address1",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            address1: false,
          }));
        }
        break;
      case "address2":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            address2: "Enter address2",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            address2: false,
          }));
        }
        break;
      case "address3":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            address3: "Enter address3",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            address3: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const hadleSocialValue = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      socialId: {
        ...prevState.socialId,
        [name]: value,
      },
    }));

    switch (name) {
      case "telegramId":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            telegramId: "Enter Telegram Id",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            telegramId: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const getDuration = (file) => {
    const url = URL.createObjectURL(file);
    return new Promise((resolve) => {
      const video = document.createElement("video");
      const source = document.createElement("source");
      video.preload = "metadata";
      source.src = url;
      video.appendChild(source);
      video.onloadedmetadata = () => {
        resolve(video.duration);
      };
    });
  };

  const onChangePicture = async (e) => {
    const file = await e.target.files[0];
    const type = await file.type;
    const mediaUrl = URL.createObjectURL(file);

    if (type.includes("image")) {
      // mediaType[index] = '0'
      setState((prevState) => ({
        ...prevState,
        profile_image: mediaUrl,
        video: "",
        _profile_image: file,
        _video: "",
      }));
    } else if (type.includes("video")) {
      const duration = await getDuration(file);
      // const video = await getVideoUrl(file)
      if (duration <= 6) {
        setState((prevState) => ({
          ...prevState,
          profile_image: "",
          video: mediaUrl,
          _profile_image: "",
          _video: file,
        }));
      } else {
        notify(`${t("profilevideoMaxSize")}`);
        setState((prevState) => ({
          ...prevState,
          profile_image: "",
          video: "",
          _profile_image: "",
          _video: "",
        }));
      }
    }
  };

  const removeMedia = () => {
    setState((prevState) => ({
      ...prevState,
      profile_image: "",
      video: "",
      _profile_image: "",
      _video: "",
    }));
  };
  const checkUrl = (str) => {
    if (str !== "" && str !== undefined && str !== null) {
      if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
        return str;
      } else {
        return "https://" + str;
      }
    } else return "";
  };

  // const handleTagKey = (e) => {
  //   if ((e.key === 'Enter' || e.key === ' ') && tagList.length < 3) {
  //     setState(prevState => ({
  //       ...prevState,
  //       tags: ''
  //     }))
  //     setTagList(prevState => ([...prevState, e.target.value]))
  //   }
  // }

  const handleUpdate = async () => {
    if (state.username === "") {
      setErrors((prevState) => ({
        ...prevState,
        username: "Enter UserName",
      }));
    }

    if (state.owner_name_english === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_name_english: "The english field is mandatory.",
      }));
    }
    if (state.owner_name_chinese === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_name_chinese: "The chinese field is mandatory.",
      }));
    }
    if (state.socialId.telegramId === "") {
      setErrors((prevState) => ({
        ...prevState,
        telegramId: "The tgid field is mandatory.",
      }));
    }
    if (state.email === "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "The email field is mandatory.",
      }));
    }
    if (state.contact_no === "") {
      setErrors((prevState) => ({
        ...prevState,
        contact_no: "The contact field is mandatory.",
      }));
    }
    if (state.address1 === "") {
      setErrors((prevState) => ({
        ...prevState,
        address1: "The address1 field is mandatory.",
      }));
    }
    if (state.address2 === "") {
      setErrors((prevState) => ({
        ...prevState,
        address2: "The address2 field is mandatory.",
      }));
    }
    if (state.address3 === "") {
      setErrors((prevState) => ({
        ...prevState,
        address3: "The address3 field is mandatory.",
      }));
    }

    if (
      state.username !== "" &&
      errors.username === false &&
      state.owner_name_english !== "" &&
      state.owner_name_chinese !== "" &&
      state.socialId.telegramId !== "" &&
      state.email !== "" &&
      state.contact_no !== "" &&
      state.address1 !== "" &&
      state.address2 !== "" &&
      state.address3 !== ""
    ) {
      const socialList = {
        WhatsApp: checkUrl(state.socialId.WhatsApp),
        WeChat: checkUrl(state.socialId.Wechat),
        Line: checkUrl(state.socialId.Line),
        Instagram: checkUrl(state.socialId.Instagram),
        Facebook: checkUrl(state.socialId.Facebook),
        Twitter: checkUrl(state.socialId.Twitter),
        Youtube: checkUrl(state.socialId.Youtube),
        Linkedin: checkUrl(state.socialId.Linkedin),
        SnapChat: checkUrl(state.socialId.SnapChat),
        Skype: checkUrl(state.socialId.Skype),
        TikTok: checkUrl(state.socialId.TikTok),
      };
      // Object.keys(socialList).map((list) => (socialList[list] === '' || socialList[list] === undefined || socialList[list] === null) && delete socialList[list])
      debugger;
      await props.updateProfile({
        user_id: state.id,
        userDetails: {
          username: state.username,
          owner_name_english: state.owner_name_english,
          owner_name_chinese: state.owner_name_chinese,
          telegramId: state.socialId.telegramId,
          email: state.email,
          contact: state.contact_no,
          address1: state.address1,
          address2: state.address2,
          address3: state.address3,
          ...(state.profile_image !== "" && {
            profile_image: state._profile_image,
          }),
          ...(state._video !== "" && { video: state._video }),
          // tags: tagList.toString(),
          ...socialList,
        },
      });
    } else {
      // if(errors.username) {
      //   notify(errors.username)
      // }
      const errorMsg = Object.values(errors).find((e) => !!e);
      notify(errorMsg);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Spinner />
      ) : (
        userType === "0" &&
        paymentstatus === "null" && (
          <div className="my-1">
            <div
              className="text-center border"
              style={{
                fontSize: "18px",
                color: theme.font !== "" ? theme.font : "#000000",
                background:
                  !!theme.theme && theme.theme !== "" ? theme.theme : "#0092C8",
              }}
            >
              <span>
                <b>{t("updateMemberShipMsg")}</b>{" "}
                <span
                  className="pointer text-primary"
                  onClick={() => navigate("/membership-plane")}
                >
                  <b>{t("upgrdeNow")}</b>
                </span>
              </span>
            </div>
          </div>
        )
      )}
      {paymentstatus === "0" && (
        <div className="row my-2">
          <div
            className="col text-center border px-1"
            style={{
              fontSize: "18px",
              background:
                !!theme.theme && theme.theme !== "" ? theme.theme : "#0092C8",
              color: theme.font !== "" ? theme.font : "#000000",
            }}
          >
            <span>
              <b> {t("underReview")} </b>
            </span>
          </div>
        </div>
      )}

      <div className="row gx-2 mb-2 align-items-end">
        <div className="col">
          <b className="d-block d-sm-none">Profile URL:</b>
          <div
            className="d-flex align-items-center"
            style={{
              position: "relative",
            }}
          >
            <div
              className="me-3"
              style={{ position: "absolute", left: "15px" }}
            >
              <b className="d-none d-sm-inline-block">Profile URL:</b>{" "}
              https://addmy.co/{" "}
            </div>
            <div className="form-group mb-0 flex-grow-1 ">
              <input
                type="text"
                name="username"
                id="username"
                className={classNames("form-control profile-link", {
                  error: errors.username,
                })}
                placeholder={`${t("enterUserName")}`}
                value={`${state.username}`}
                onChange={handleChange}
                disabled={userNameEnabled}
              />
            </div>
          </div>
        </div>
        <div
          className="col-1 d-flex rounded justify-content-center align-items-end pointer p-1"
          onClick={() => {
            if (userType !== "0") {
              setUserNameEnabled(!userNameEnabled);
            } else {
              setUserNameEnabled(true);
              setUserNameNotChange(true);
            }
          }}
          style={{
            background:
              !!theme.theme && theme.theme !== "" ? theme.theme : "#007CB6",
          }}
        >
          <div className="form-group mb-0 ">
            <img src="/images/pencil.png" alt="" width={"25px"} />
          </div>
        </div>
      </div>

      <div className="row gx-2">
        <div className="col-6">
          <div className="form-group mb-2">
            <input
              type="text"
              name="owner_name_english"
              id="owner_name_english"
              className={classNames("form-control", {
                error: errors.owner_name_english,
              })}
              placeholder={`${t("ownerNameEnglish")}`}
              value={state.owner_name_english}
              onChange={handleChange}
            />
          </div>
          {errors.owner_name_english && (
            <div className="h6 text-danger ps-3">
              {errors.owner_name_english}
            </div>
          )}
        </div>

        <div className="col-6">
          <div className="form-group mb-2">
            <input
              type="text"
              name="owner_name_chinese"
              id="owner_name_chinese"
              className={classNames("form-control", {
                error: errors.owner_name_chinese,
              })}
              placeholder={`${t("ownerNameChiense")}`}
              value={state.owner_name_chinese}
              onChange={handleChange}
            />
          </div>
          {errors.owner_name_chinese && (
            <div className="h6 text-danger ps-3">
              {errors.owner_name_chinese}
            </div>
          )}
        </div>
      </div>

      {/* address */}

      {/* Profile */}
      <div className="profile-img mb-2">
        <input
          type="file"
          className="input-file file-upload d-none"
          id="file-upload"
          name="profile_image"
          accept={userType !== "0" ? "video/*, image/*" : "image/*"}
          onInput={onChangePicture}
        />
        <label htmlFor="file-upload">
          {mediaType === "0" ? (
            <>
              {state.profile_image !== "" &&
              state.profile_image !== null &&
              state.profile_image !== null ? (
                <img src={state.profile_image} alt="Personal Profile Image" />
              ) : null}
            </>
          ) : (
            <>
              {state.video && state.video !== "" ? (
                <video
                  controls
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "50%",
                    objectFit: "fill",
                  }}
                  autoPlay
                  muted
                  loop
                  src={state.video}
                >
                  <source alt="Personal Profile Video" />
                </video>
              ) : null}{" "}
            </>
          )}
          {state.profile_image === "" &&
            state.profile_image !== null &&
            state.profile_image !== null &&
            state.video === "" &&
            !state.video && (
              <div className=" my-2 d-flex justify-content-center pointer">
                <div className="text-center">
                  <img src="/images/loginPage/image.png" width={"180px"} />
                  {userType === "0" ? (
                    <div
                      style={{
                        color: theme.font !== "" ? theme.font : "#007CB6",
                      }}
                    >
                      {t("uploadProfileImage")} (180 x 180)
                    </div>
                  ) : (
                    <div className="text-blue">
                      {t("uploadProfileImage")} (180 x 180) <br /> {t("video")}{" "}
                      ({t("2MinVideoSize")})
                    </div>
                  )}
                </div>
              </div>
            )}
          {/* <img src={state.profile_image === '' ? "./images/loginPage/image.png" : state.profile_image} id="profileImage" alt="Personal Profile Image" /> */}
        </label>
      </div>
      <div className="d-flex justify-content-center mb-2">
        <div className="mx-2">
          <label
            htmlFor="file-upload"
            style={{
              color: theme.font !== "" ? theme.font : "#ffffff",
              background:
                !!theme.theme && theme.theme !== "" ? theme.theme : "#007CB6",
            }}
            className="border py-1 px-3 pointer rounded"
          >
            {t("Browse")}
          </label>
        </div>
        <div
          className="mx-2 border py-1 px-3 pointer rounded"
          style={{
            color: theme.font !== "" ? theme.font : "#ffffff",
            background:
              !!theme.theme && theme.theme !== "" ? theme.theme : "#007CB6",
          }}
          onClick={removeMedia}
        >
          {t("Cancel")}
        </div>
      </div>
      <p className="fw-semibold text-theme text-center">
        Please upload 180 X 180 Image or upgrade to premium for upload Video
      </p>

      {/* section */}
      <div className="row gx-2">
        <div className="col-12">
          <div className="form-group mb-2">
            <input
              type="text"
              name="telegramId"
              id="telegramId"
              className={classNames("form-control", {
                error: errors.telegramId,
              })}
              value={state.socialId["telegramId"]}
              placeholder={`${t("telegramId")}`}
              onChange={hadleSocialValue}
            />
          </div>
          {errors.telegramId && (
            <div className="h6 text-danger ps-3">{errors.telegramId}</div>
          )}
        </div>
      </div>

      <div className="row gx-2">
        <div className="col-12">
          <div className="form-group mb-2">
            <input
              type="text"
              name="contact_no"
              id="contact_no"
              className={classNames("form-control", {
                error: errors.contact_no,
              })}
              placeholder={`${t("contactNo")}`}
              value={state.contact_no}
              onChange={handleChange}
            />
          </div>
          {errors.contact_no && (
            <div className="h6 text-danger ps-3">{errors.contact_no}</div>
          )}
        </div>
      </div>

      <div className="row gx-2">
        <div className="col-12">
          <div className="form-group mb-2">
            <input
              type="text"
              name="WhatsApp"
              id="WhatsApp"
              className={classNames("form-control")}
              placeholder={`https://WhatsApp`}
              value={state.socialId["WhatsApp"]}
              onChange={(e) => hadleSocialValue(e)}
            />
          </div>
          {/* {
            errors.owner_name_chinese && <div className='h6 text-danger ps-3'>{errors.owner_name_chinese}</div>
          } */}
        </div>
      </div>

      <div className={classNames("row", { "mt-0": userType === "0" })}>
        <div className="col text-black">
          <div className="form-group mb-1">
            <input
              name="address1"
              id="address1"
              className={classNames("form-control", {
                error: errors.address1,
              })}
              placeholder={`${t("address1")}`}
              value={state.address1}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="form-group mb-2">
        <input
          name="address2"
          id="address2"
          className={classNames("form-control", {
            error: errors.address2,
          })}
          placeholder={`${t("address2")}`}
          value={state.address2}
          onChange={handleChange}
        />
      </div>

      <div className="row">
        <div className="col text-black">
          <div className="form-group mb-1">
            <input
              name="address3"
              id="address3"
              className={classNames("form-control", {
                error: errors.address3,
              })}
              placeholder={`${t("address3")}`}
              value={state.address3}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="py-2 my-2 bg-white form-group">
        <div className="h5 text-center">Below are optional</div>
      </div>

      <div className="form-group mb-2">
        <input
          type="email"
          name="email"
          id="email"
          className={classNames("form-control", {
            error: errors.email,
          })}
          placeholder={`${t("email")}`}
          value={state.email}
          onChange={handleChange}
        />
      </div>

      <div className="row justify-content-start">
        {selectedSocialMedia.length !== 0 &&
          selectedSocialMedia.map((e, i) => {
            if (e.value !== "WhatsApp") {
              return (
                <div className="col-md-6 col-12 mb-2" key={i}>
                  <div className="form-group mb-0 d-flex align-items-center">
                    <input
                      type="text"
                      name={e.value}
                      id={e.value}
                      className="form-control ml-5 "
                      value={state.socialId[e.value]}
                      onChange={(e) => hadleSocialValue(e)}
                      placeholder={`https://${e.label}`}
                    />
                  </div>
                </div>
              );
            } else return;
          })}
      </div>

      {/* {userType !== '0' && <div className="row mt-2">
        <div className="col text-black" >
          <div className="form-group mb-1">
            <div className={classNames("mb-1 d-flex align-items-center ", {
              "form-control": tagList.length > 2
            })}>
              {tagList.length !== 0 && <>{
                tagList.map((e, i) => {
                  return (
                    <div key={i} className={"mx-2 px-2 border d-flex align-items-center"}>
                      {e}
                      <img src='/images/rejected.png' width={'10px'} className='ms-3 pointer' onClick={() => {
                        const list = [...tagList]
                        list.splice(i, 1)
                        setTagList([...list])
                      }} />
                    </div>)
                })
              }</>}
              {tagList.length < 3 && <input type="tags" name="tags" id="tags" className={classNames("form-control")} placeholder={`${t('tags')}`} value={state.tags} onChange={handleChange} onKeyDown={handleTagKey} />}
            </div>
          </div>
        </div>
      </div>} */}

      <div className="row mt-3">
        <div className="col text-black">
          <div className="justify-content-center bg-red">
            <button
              type="submit"
              className="btn btn-theme1 w-100 "
              onClick={handleUpdate}
            >
              <span className="font-color text-white">{t("Update")}</span>
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={userNameNotChange}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setUserNameNotChange(false)}
      >
        <React.Fragment>
          <div className="p-5">
            <div className="m-auto">
              <div className="text-center">
                <b>{`"${t("customUrlWarn")}"`}</b>{" "}
                {`${t("upgradeMembershipForUsernameMsg")}`}{" "}
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div
                className="border rounded mx-4 px-4 py-2 pointer bg-blue text-white"
                onClick={() => {
                  setUserNameNotChange(false);
                  navigate("/membership-plane");
                }}
              >
                {t("updateMembership")}
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal>

      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  userDetailsReducer: state.userDetailsReducer,
  addProfileReducer: state.addProfileReducer,
  backGroundReducer: state.themeReducer.getBackGround,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (user) => dispatch(updateProfile(user)),
  getUserDetails: () => dispatch(getUserDetails()),
  resetUserDetails: () => dispatch({ type: RESET_DETAILS }),
  resetAddProfileReducer: () => dispatch({ type: RESET_ADD_PROFILE }),
});

export default connect(mapStateToProp, mapDispatchToProps)(UpdateProfile);
