import { RESET_UPDATE_PROFILE, UPDATE_PROFILE_ERROR, UPDATE_PROFILE_LOADING, UPDATE_PROFILE_SUCCESS } from "../../Constant";

let initialState = {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false
};

export default function updateProfileReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROFILE_LOADING:
      return { ...state, loading: true, error: false, errors: [], success: false };

    case UPDATE_PROFILE_SUCCESS:
      return { ...state, loading: false, error: false, errors: [], success: true, data: action.response.data };

    case UPDATE_PROFILE_ERROR:
      return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

    case RESET_UPDATE_PROFILE:
      return { ...state, loading: false, error: false, success: false };

    default:
      return state;
  }
}
