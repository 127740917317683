import { ADD_CONTACT_IN_FOLDER_ERROR, ADD_CONTACT_IN_FOLDER_LOADING, ADD_CONTACT_IN_FOLDER_SUCCESS, ADD_FOLDER_ERROR, ADD_FOLDER_LOADING, ADD_FOLDER_SUCCESS, DELETE_FOLDER_ERROR, DELETE_FOLDER_LOADING, DELETE_FOLDER_SUCCESS, GET_FOLDER_ERROR, GET_FOLDER_LOADING, GET_FOLDER_SUCCESS, RESET_ADD_CONTACT_IN_FOLDER, RESET_ADD_FOLDER, RESET_DELETE_FOLDER, RESET_GET_FOLDER, RESET_UPDATE_FOLDER, UPDATE_FOLDER_ERROR, UPDATE_FOLDER_LOADING, UPDATE_FOLDER_SUCCESS } from "../../Constant";

let initialState = {
  getFolder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  addFolder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  addContactInFolder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  deleteFolder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  updateFolder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },


};

export default function folderReducer(state = initialState, action) {
  switch (action.type) {

    // GEt Folder
    case GET_FOLDER_LOADING:
      return { ...state, getFolder: { ...state.getFolder, loading: true, data: null, error: false, errors: null, success: false } };

    case GET_FOLDER_SUCCESS:
      return { ...state, getFolder: { ...state.getFolder, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_FOLDER_ERROR:
      return { ...state, getFolder: { ...state.getFolder, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_FOLDER:
      return { ...state, getFolder: { ...state.getFolder, loading: false, error: false, success: false } };

  
    // Add Folder
    case ADD_FOLDER_LOADING:
      return { ...state, addFolder: { ...state.addFolder, loading: true, data: null, error: false, errors: null, success: false } };

    case ADD_FOLDER_SUCCESS:
      return { ...state, addFolder: { ...state.addFolder, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case ADD_FOLDER_ERROR:
      return { ...state, addFolder: { ...state.addFolder, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_ADD_FOLDER:
      return { ...state, addFolder: { ...state.addFolder, loading: false, error: false, success: false } };

  
    // Add Contact in Folder
    case ADD_CONTACT_IN_FOLDER_LOADING:
      return { ...state, addContactInFolder: { ...state.addContactInFolder, loading: true, data: null, error: false, errors: null, success: false } };

    case ADD_CONTACT_IN_FOLDER_SUCCESS:
      return { ...state, addContactInFolder: { ...state.addContactInFolder, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case ADD_CONTACT_IN_FOLDER_ERROR:
      return { ...state, addContactInFolder: { ...state.addContactInFolder, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_ADD_CONTACT_IN_FOLDER:
      return { ...state, addContactInFolder: { ...state.addContactInFolder, loading: false, error: false, success: false } };

    // Delete fOLDER
    case DELETE_FOLDER_LOADING:
      return { ...state, deleteFolder: { ...state.deleteFolder, loading: true, data: null, error: false, errors: null, success: false } };

    case DELETE_FOLDER_SUCCESS:
      return { ...state, deleteFolder: { ...state.deleteFolder, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case DELETE_FOLDER_ERROR:
      return { ...state, deleteFolder: { ...state.deleteFolder, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_DELETE_FOLDER:
      return { ...state, deleteFolder: { ...state.deleteFolder, loading: false, error: false, success: false } };


      // update fOLDER
    case UPDATE_FOLDER_LOADING:
      return { ...state, updateFolder: { ...state.updateFolder, loading: true, data: null, error: false, errors: null, success: false } };

    case UPDATE_FOLDER_SUCCESS:
      return { ...state, updateFolder: { ...state.updateFolder, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case UPDATE_FOLDER_ERROR:
      return { ...state, updateFolder: { ...state.updateFolder, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_UPDATE_FOLDER:
      return { ...state, updateFolder: { ...state.updateFolder, loading: false, error: false, success: false } };


    default:
      return state;
  }
}
