import { PAYMENT_DETAILS_ERROR, PAYMENT_DETAILS_LOADING, PAYMENT_DETAILS_SUCCESS, RESET_PAYMENT_DETAILS, RESET_TENURE, TENURE_ERROR, TENURE_LOADING, TENURE_SUCCESS } from "../../Constant";

let initialState = {
  loading: false,
  tenureData: null,
  paymentDetails: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  error: false,
  errors: null,
  success: false
};

export default function memberShipTenureReduce(state = initialState, action) {
  switch (action.type) {
    case TENURE_LOADING:
      return { ...state, loading: true, error: false, errors: [], success: false };

    case TENURE_SUCCESS:
      return { ...state, loading: false, error: false, errors: [], success: true, tenureData: action.response.data };

    case TENURE_ERROR:
      return { ...state, loading: false, error: true, errors: action.errors, success: false, data: null };

    case RESET_TENURE:
      return { ...state, loading: false, error: false, errors: null, success: false };

    case PAYMENT_DETAILS_LOADING:
      return {
        ...state, paymentDetails: {
          ...state.paymentDetails,
          loading: true,
          error: false,
          data: null,
          success: false,
          errors: null
        }
      };
    case PAYMENT_DETAILS_SUCCESS:
      return {
        ...state, paymentDetails: {
          ...state.paymentDetails,
          loading: false,
          error: false,
          data: action.response.data,
          success: true,
          errors: null
        }
      };
    case PAYMENT_DETAILS_ERROR:
      return {
        ...state, paymentDetails: {
          ...state.paymentDetails,
          loading: false,
          error: true,
          data: null,
          success: false,
          errors: action.errors
        }
      };
    case RESET_PAYMENT_DETAILS:
      return {
        ...state, paymentDetails: {
          ...state.paymentDetails,
          loading: false,
          error: false,
          success: false,
          errors: null
        }
      };

    // case RESET_ADD_COMAPNY:
    //   return { ...state, loading: false, error: false, success: false };

    default:
      return state;
  }
}
