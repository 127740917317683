import classNames from 'classnames'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import Spinner from '../../components/Spinner/Spinner'
import { invitation } from '../../services/Action/Contact/contactAction'
import { addContactINFolder, addFolder, getFolder } from '../../services/Action/Folder/folderAction'
import { deleteNotification, getContactNotification, getImessage } from '../../services/Action/userAction'
import { RESET_INVITATION } from '../../services/Constant'
import Toaster from '../../Toaster/Toaster'

function Notification(props) {

  const navigate = useNavigate()
  const { t } = useTranslation()

  const notify = (msg) => toast.error(msg)

  const userId = useSelector(state => state.userDetailsReducer.data)?.data?._id


  const [loading, setLoading] = useState(true)
  const [isShowFolder, setIsShowFolder] = useState(false)
  const [folderList, setFloderList] = useState([])
  const [status, setStatus] = useState(0)
  const [addtoFolder, setAddtoFolder] = useState(null)
  const [selectedContactId, setSelectedContactId] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const userType = localStorage.getItem('usertype')
  const [folderName, setFolderName] = useState('')
  // const contactList = useSelector(state => state?.notificationReducer?.data)

  const [contact, setContact] = useState([])
  const [theme, setTheme] = useState({
    theme: '',
    font: ''
  })

  useEffect(() => {
    props.getFolder()
  }, [])

  useEffect(() => {
    setLoading(props.notificationReducer.loading)
    if (props.notificationReducer.success) {
      props.getImessage()
    }
  }, [props.notificationReducer])

  useEffect(() => {
    setLoading(props.getImessageReducer.loading)
    if (props.getImessageReducer.success) {
      const notification_data = props?.notificationReducer?.data?.filter(e => e.status === 0).map(e => {
        return ({
          ...e,
          type: 0
        })
      })
      const message_data = props.getImessageReducer.data.map(e => {
        return ({
          ...e,
          type: !!e.freemember_id ? 2 : 1
        })
      })
      // setData(props.getImessageReducer.data)
      const list = [...notification_data].concat([...message_data])
      const sorting = list.sort((a, b) => moment(b.date) - moment(a.date))
      console.log("sorting", message_data);
      setContact([...sorting])
    }
  }, [props.getImessageReducer])

  useEffect(() => {
    setLoading(props.getFolderReducer?.getFolder.loading)
    if (props.getFolderReducer?.getFolder.success) {
      const list = props.getFolderReducer.getFolder.data.data
      setFloderList([...list])
    }
  }, [props.getFolderReducer])

  useEffect(() => {
    if (!!userId) {
      props.getContactNotification(userId)
    }
  }, [userId])

  useEffect(() => {
    if (props.deleteNotificationReducer.success) {
      props.getImessage()
    }
  }, [props.deleteNotificationReducer])

  const handleAddFolder = () => {
    if (folderName !== '') {
      props.addFolder({ FolderName: folderName })
      setIsAddMore(false)
      setFolderName('')
    }
  }



  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [props.backGroundReducer])
  useEffect(() => {
    if (props.backGroundReducer.success) {
      const _theme = props.backGroundReducer?.data?.data
      if (_theme !== null) {
        setTheme(prevState => ({
          ...prevState,
          font: _theme.fontcolor === null ? '' : _theme.fontcolor,
          theme: _theme.backgroundcolor !== null ? _theme.backgroundcolor : ''
        }))
      }
    }
  }, [])


  useEffect(() => {
    if (props.invitationReducer.success) {
      props.getContactNotification(userId)
      if (status === 1) {
        setIsShowFolder(true)
      } else {
        setIsShowFolder(false)
      }
      props.resetInvitation()
    }

  }, [props.invitationReducer])

  const handleApprove = (id, status, contectId) => {
    setSelectedContactId(contectId)
    // setIsShowFolder(true)
    props.invitation({
      status: status,
      id: id
    })
  }


  const hadleContactAddToFolder = () => {
    if (!addtoFolder) {
      notify("Select Folder")
    }
    if (!!addtoFolder && !!selectedContactId) {
      props.addContactINFolder({
        contact_id: selectedContactId,
        folder_id: addtoFolder
      })
    }

  }

  useEffect(() => {
    if (props.addContactInFolderReducer.success) {
      setIsShowFolder(false)
      setAddtoFolder(null)
    }

  }, [props.addContactInFolderReducer])

  useEffect(() => {
    if (props.addFolderReducer.success) {
      setIsAddMore(false)
      setFolderName('')
      props.getFolder()
    }
  }, [props.addFolderReducer])


  return (
    <React.Fragment>
      {
        (loading) ? <Spinner /> :
          <>
            {contact.length === 0 ? <>
              <div className='p-5 text-center ' style={{
                // color: '#007CB6',
                backgroundColor: '#FFFFFF'
              }}>
                <h6> {t('noNotification')} </h6>
              </div>
            </> : <div>
              {
                contact.map((e, index) => {
                  return (
                    <div key={index} className='mb-3'>
                      {
                        e.type === 0 ?
                          <div className='row align-items-center' style={{
                            color: '#000000',
                            backgroundColor: '#ffffff'
                          }}>
                            <div className='col-12 col-sm-8 py-3'>{e.flag === 0 ? <>
                              <b className='pointer' onClick={() => navigate(`/${e.userdetails[0].username}`)}><span style={{ textDecoration: 'underline' }}>{e.userdetails[0].owner_name_english}</span></b> wants to be in your contact list
                            </> : <>
                              <b className='pointer' onClick={() => navigate(`/${e.userdetails[0].username}`)}><span style={{ textDecoration: 'underline' }}>{e.userdetails[0].owner_name_english}</span></b> has added you in his contact list , Do you want to add him too ?
                            </>}</div>
                            <div className='col-12 col-sm-4 py-3'>
                              <div className='d-flex justify-content-end align-items-center'>
                                <div className='border p-2  pointer mx-2 bg-success text-white rounded' onClick={() => {
                                  handleApprove(e._id, 1, e.contact_id)
                                  setStatus(1)
                                }}>{t('Approve')}</div>
                                <div className='border p-2 pointer mx-2 bg-red text-white rounded' onClick={() => {
                                  handleApprove(e._id, 2, e.contact_id)
                                  setStatus(2)
                                }} >{t('Reject')}</div>
                              </div>
                            </div>
                          </div> :
                          e.type === 1 ?
                            <div className='row border p-2' style={{
                              color: '#000000',
                              backgroundColor: '#ffffff'
                            }}>
                              <div className='d-flex justify-content-between align-items-center px-1'>
                                <h5 className='mb-0' onClick={() => navigate(`/${e?.userDoc[0]?.username}`)}><b className='pointer' >{e?.userDoc[0]?.owner_name_english}</b></h5>
                                <div onClick={() => {
                                  props.deleteNotification(e._id)
                                }}>
                                  <button className='btn close-btn'><i class="fa-solid fa-xmark"></i></button></div>
                              </div>
                              <div className=' px-1'>
                                {e.message}
                                <span className='text-muted d-block text-end ms-auto'>{moment(e.date).format('DD-MM-yyyy')}</span>
                              </div>
                            </div>
                            : e.type === 2 ?
                              <div className='row border p-2' style={{
                                color: '#000000',
                                backgroundColor: '#ffffff'
                              }}>
                                <div className='d-flex justify-content-between align-items-center px-1'>
                                  {/* <h5 className='mb-0' onClick={() => navigate(`/${e?.userDoc[0]?.username}`)}><b className='pointer' >{e?.userDoc[0]?.owner_name_english}</b></h5> */}
                                  <div className='d-flex'>
                                    <div className=' px-1'>
                                      {e.message} <span className='pointer h6' onClick={() => {
                                        navigate('/update-profile')
                                      }}><u>Create now</u></span>
                                      <span className='text-muted d-block text-end ms-auto'>{moment(e.date).format('DD-MM-yyyy')}</span>
                                    </div>
                                    <div onClick={() => {
                                      props.deleteNotification(e._id)
                                    }}>
                                      <button className='btn close-btn'><i class="fa-solid fa-xmark"></i></button></div>
                                  </div>
                                </div>
                              </div> : <></>
                      }

                    </div>
                  )
                })
              }
            </div>}
          </>
      }

      <Modal
        show={isShowFolder}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setIsShowFolder(false)
          // setTag(null)
        }}
      >
        <React.Fragment>
          <Modal.Header><h4>In which folder you want to add this contact</h4></Modal.Header>
          <div className='pt-2 px-5 pb-5'>
            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {
                folderList.map((e, i) => {
                  return (
                    <div key={i} className={'d-flex justify-content-between align-items-center py-2 border-bottom'}>
                      <div>{e.Folder}</div>
                      <div><input type={'checkbox'} className='pointer' value={e._id} checked={addtoFolder === e._id} onChange={() => setAddtoFolder(e._id)} /></div>
                    </div>
                  )
                })
              }
            </div>
            {userType !== '0' &&
              <div className='me-2 mt-2 border py-1 rounded text-white bg-red pointer text-center ' style={{ minWidth: '150px' }}
                onClick={() => setIsAddMore(true)}>+ {t('addMoreFolder')}</div>}
            <div>

            </div>
            <div className='d-flex justify-content-center mt-4'>
              <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                setIsShowFolder(false)
                setAddtoFolder(null)
              }}>{t('Cancel')}</div>
              <div className='border rounded mx-4 px-4 py-2 pointer' onClick={hadleContactAddToFolder}>{t('addtoFolder')}</div>
            </div>
          </div>
          <Toaster />
        </React.Fragment>
      </Modal>

      <Modal
        show={isAddMore}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      // onHide={() => setIsAddMore(false)}
      >
        <React.Fragment>
          <div className='p-5'>
            <div className='m-auto'>
              <input className='form-control' type="text" name="addFolder" value={folderName} placeholder={`${t('enterFolderName')}`}
                onChange={(e) => {
                  setFolderName(e.target.value)
                }} />
            </div>
            <div className='d-flex justify-content-center mt-4'>
              <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                setIsAddMore(false)
                setFolderName('')
              }}>{t('Cancel')}</div>
              <div className='border rounded mx-4 px-4 py-2 pointer' onClick={handleAddFolder}>{t('create')}</div>
            </div>
          </div>
        </React.Fragment>
      </Modal>

    </React.Fragment >
  )
}

const mapStateToProp = state => ({
  // getContactListReducer: state.getContactListReducer,
  notificationReducer: state.notificationReducer,
  invitationReducer: state.contactReducer.invitation,
  backGroundReducer: state.themeReducer.getBackGround,

  getImessageReducer: state.getImessageReducer,
  deleteNotificationReducer: state.NotificationReducer,

  getFolderReducer: state.folderReducer,
  addContactInFolderReducer: state.folderReducer.addContactInFolder,
  addFolderReducer: state.folderReducer.addFolder,

})

const mapDispatchToProps = dispatch => ({
  getContactNotification: (user_id) => dispatch(getContactNotification(user_id)),
  // getContactList: (user_id) => dispatch(getContactList(user_id)),
  invitation: (details) => dispatch(invitation(details)),
  resetInvitation: () => dispatch({ type: RESET_INVITATION }),


  getImessage: () => dispatch(getImessage()),
  deleteNotification: (id) => dispatch(deleteNotification(id)),

  addContactINFolder: (details) => dispatch(addContactINFolder(details)),

  getFolder: () => dispatch(getFolder()),

  addFolder: (folder) => dispatch(addFolder(folder)),

})

export default connect(mapStateToProp, mapDispatchToProps)(Notification)  