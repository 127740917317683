import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { removeContact } from '../../services/Action/userAction'
import { Modal } from 'react-bootstrap'
import { t } from 'i18next'

function FolderContact(props) {
   const navigate = useNavigate()
   const imgDefault = (e) => {
      e.target.src = "/images/loginPage/image.png";
      e.target.onerror = null;
   }
   const [contactId, setContactID] = useState();

   const handleRemoveContact = (contact_id) => {

      setContactID(contact_id)
      setShow(true)
   }
   const removeFavContact = () => {
      props.removeContact(contactId)
   }
   const [show, setShow] = useState(false);

   return (<>
      <div className='col-md-6 col-6 text-center searchItem position-relative mb-3' key={props.index}>
         {/* <img src={props.list.userdetails?.profile_image !== '' ? `${props.path}${props.list.userdetails?.profile_image}` : "/images/loginPage/image.png"} className="pointer contact-img"
                style={{ borderRadius: '50%' }} onError={imgDefault} onClick={() => navigate(`/${props.list.userdetails?.username}`)} />
         */}
         {
            (props?.list?.userdetails?.profile_image !== '' && props?.list?.userdetails?.profile_image !== null && props?.list?.userdetails?.profile_image !== undefined) ?
               <img src={`${props.path}${props?.list?.userdetails?.profile_image}`} className="pointer contact-img"
                  style={{ borderRadius: '50%' }} onClick={() => navigate(`/${props?.list?.userdetails?.username}`)} />
               : (props?.list?.userdetails?.video !== '' && props?.list?.userdetails?.video !== null && props?.list?.userdetails?.video !== undefined) ?
                  <div >
                     <video controls autoPlay muted loop style={{ borderRadius: '50%', objectFit: 'fill' }} onClick={() => navigate(`/${props.list.userdetails?.username}`)} className="pointer contact-img"><source src={`${props.path}${props.list.userdetails?.video}`} id="profileVideo" alt="Personal Profile Video" /></video> </div> :
                  <img src={'/images/loginPage/image.png'} className="img-fluid profile-icon" style={{ borderRadius: '50%' }} />
         }


         <div className=' border-radius-25 fs-w searchStrEng mt-1 pointer' onClick={() => navigate(`/${props?.list.userdetails?.username}/profile`)} style={{
            backgroundColor: (!!props.theme.theme && props.theme.theme !== '') ? props.theme.theme : '#007CB6',
            color: props.theme.font !== '' ? props.theme.font : '#ffffff',
            padding: '0px 10px',
            overflowX: 'auto',
            whiteSpace: 'nowrap'
         }}>
            {props.list.userdetails?.owner_name_english}
         </div>

         <div className=' mt-1 border-radius-25 fs-w searchStrChines pointer' onClick={() => navigate(`/${props?.list.userdetails?.username}/profile`)} style={{
            backgroundColor: (!!props.theme.theme && props.theme.theme !== '') ? props.theme.theme : '#007CB6',
            color: props.theme.font !== '' ? props.theme.font : '#sffffff',
            padding: '0px 10px',
            overflowX: 'auto',
            whiteSpace: 'nowrap'
         }}>
            {props.list.userdetails?.owner_name_chinese}
         </div>
         {/* <div className='d-none mt-1 border-radius-25 fs-w searchStrTags'>{props.list.userdetails?.tags}</div> */}
         <div className='bg-red text-white mt-1 border-radius-25 fs-w pointer' onClick={() => handleRemoveContact(props.list.contact_id)}>Remove me</div>
      </div>
      <Modal
         show={show}
         aria-labelledby="contained-modal-title-vcenter"
         centered
      >
         <React.Fragment>
            <div className='p-5'>
               <h5 className='text-blue text-center'>
                  {t('sureRemove')}
               </h5>
               <div className='d-flex justify-content-center mt-4'>
                  <div className='border rounded mx-4 px-4 py-2 pointer' onClick={() => {
                     setShow(false)
                  }}>{t('no')}</div>
                  <div className='border rounded mx-4 px-4 py-2 pointer' onClick={removeFavContact} >{t('yes')}</div>
               </div>
            </div>
         </React.Fragment>
      </Modal>
   </>
   )
}

const mapStateToProp = state => ({
})

const mapDispatchToProps = dispatch => ({
   removeContact: (contact_id) => dispatch(removeContact(contact_id))
})

export default connect(mapStateToProp, mapDispatchToProps)(FolderContact)